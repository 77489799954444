<template>
    <div>
        <div class="loading-container">
            <div>
                <i class="fa fa-3x fa-spinner fa-spin"></i>
                <div class="loading-text">Your signing experience is loading.</div>
            </div>
        </div>
        <div class="iframe-container">
            <iframe id="iframe"
                    :src="invitationLink"
                    style="width: 100%; height: 100vh; border: none;">
            </iframe>
        </div>
        <!-- Modal components... -->
    </div>
</template>

<script>
    export default {
        name: 'LoanDepot',
        data() {
            return {
                invitationLink: ''  // This should be dynamically set from your Vuex store or props
            };
        },
        mounted() {
            this.invitationLink = this.$route.query.invitationLink || '';
            console.log("This is the invitationLink:", this.invitationLink);

            // Custom styles and scripts to be appended to iframe
            const interval = setInterval(() => {
                if (this.$refs.iframe && this.$refs.iframe.contentDocument) {
                    clearInterval(interval);
                    const head = this.$refs.iframe.contentDocument.head;
                    const style = document.createElement('style');
                    style.innerHTML = `
          #frmLogout { display: none; }
          .personal-signup .darkblue-bg { background: #501283 }
          .navbar-brand { display: none; }
          .company-lender-name span { display:none; word-spacing:-8px; font-style:italic }
          .company-lender-name:after { content:'loanDepot' }
          .loader .loading { color: #501283 }
          .loader .box1, .loader .box2, .loader .box3 { display: none }
        `;
                    head.appendChild(style);
                }
            }, 500);
        }
    };
</script>

<style>
    html {
        box-sizing: border-box;
        font-family: sans-serif;
        color: #212529;
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
    }

    *, *:before, *:after {
        box-sizing: inherit;
    }

    body {
        margin: 0;
        padding: 0;
    }

    .loading-container {
        position: absolute;
        z-index: 0;
        text-align: center;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
        -ms-transform: translateY(-50%);
    }

    .loading-text {
        font-size: 20px;
        padding: 2px;
    }

    .iframe-container {
        position: relative;
        z-index: 1;
    }
</style>


<!-- Add external CSS and JS in index.html or main.js -->
<!-- <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" /> -->
<!-- <script id="glance-cobrowse" type="text/javascript" src="https://www.glancecdn.net/cobrowse/CobrowseJS.ashx?group=20408&site=staging" data-groupid="20408" data-site="staging" charset="UTF-8"></script> -->
