<template>
    <section>
        <form action="/Document/Review" id="frmReviewDocument" method="post">
            <input type="hidden" id="NotarizeID" name="NotarizeID" value="904" />
            <div class="row">
                <div class="col-md-12 text-right">
                    <button class="btn btn-secondary" @click.prevent="completeSigning()">
                        Complete Signing
                    </button>
                </div>
                <div class="col-md-12 text-center">
                    <h2>Signing Session</h2>
                </div>
                <div class="col-md-12" style="text-align:center">
                    <button class="btn btn-success" @click.prevent="saveChanges()">
                        Save Changes
                    </button>
                </div>
            </div>
            <div id="divContent"
                 class="container-fluid"
                 v-if="filesToSign.length != 0">
                <ul class=" nav nav-pills custom-style-tabs" id="nav-documents">
                    <li v-for="(file, i) in filesToSign"
                        :key="file.id"
                        :class="{ active: i == activeDocIndex }">
                        <a data-toggle="pill"
                           @click.prevent="changeSelectedDocument(i)"
                           class="tablinks">{{ file.documentName }}</a>
                    </li>
                </ul>

                <ul class=" nav nav-pills custom-style-tabs" id="nav-taggedpages">
                    <li>
                        <a href="javascript:void(0)"
                           onclick="renderSmartTaggedPage(983, 1, this)"
                           class="tablinks">Page # {{ activePageIndex + 1 }}</a>
                    </li>
                </ul>

                <div id="divContent" class="tab-content">
                    <div style="text-align:center">
                        <button class="btn btn-success" @click.prevent="saveChanges()">
                            Save Changes
                        </button>
                    </div>
                    <div class="row" v-if="filesToSign[activeDocIndex]['isEnote'] == false">
                        <div class="col-md-2 thumbnail-container">
                            <div v-for="(thumbnail, i) in filesToSign[activeDocIndex][
                  'thumbnailPaths'
                ]"
                                 :key="i">
                                <div class="thumbnail-scroll">
                                    <img data-page-no="1"
                                         @load="onPreviewLoaded"
                                         @error="onPreviewLoaded"
                                         :src="
                      apiUrl + filesToSign[activeDocIndex]['thumbnailPaths'][i]
                    "
                                         @click.prevent="changeSelectedPage(i)"
                                         style="box-shadow: rgb(136, 136, 136) 5px 5px 5px; width: 90%" />
                                </div>
                            </div>
                        </div>
                        <div class=" col-md-8">
                            <div id="cc12">
                                <div ref="documentViewerRef" id="documentViewer">
                                    <img id="img-viewer"
                                         @load="onPreviewLoaded"
                                         @error="onPreviewLoaded"
                                         :src="
                      apiUrl +
                        filesToSign[activeDocIndex]['docImgPaths'][
                          activePageIndex
                        ]
                    " />
                                    <template v-for="(tag, i) in getTagsPerPage(
                      filesToSign[activeDocIndex]['smartTags']
                    )">
                                        <div @dblclick.prevent="smartTagClicked(tag, i)"
                                             class="smart-tag"
                                             :data-index="tag.id"
                                             :id="tag.id"
                                             style="position: absolute;"
                                             :style="{
                        top: tag['controlTop'],
                        left: tag['controlLeft'],
                        height: tag['controlHeight'],
                        width: tag['controlWidth'],
                      }">
                                            <i>Insert {{ tag.tagType }}</i>
                                            <div style="position: relative;"
                                                 :style="{
                          fontSize: tag['fontSize'] || '14px',
                        }">
                                                <span v-if="tag.imgUrl !== ''" class="ui-resizable">
                                                    <img :src="apiUrl + tag.imgUrl"
                                                         :style="{
                              height: tag['controlHeight'],
                              width:
                                tag['imgUrl'] !== '' ? '' : tag['controlWidth'],
                            }" />
                                                </span>
                                                <span v-else class="ui-resizable tagText">
                                                    {{ tag.displayText }}
                                                </span>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <checklist :items="checklist"
                                       @itemClicked="handleChecklistItemClicked" />
                        </div>
                    </div>
                    <div class="row" v-if="filesToSign[activeDocIndex]['isEnote'] == true">
                        <div class="col-md-10">
                            <iframe id="xmlDoc"
                                    style="width: 100%;height:500px"
                                    :srcdoc="'<div style=\' width: fit-content; margin: 0 auto;\'>' + filesToSign[activeDocIndex]['html'] + ' </div>'"></iframe>
                        </div>
                        <div class="col-md-2">
                            <checklist :items="checklist"
                                       @itemClicked="handleChecklistItemClicked" />
                            <button type="button"
                                    class="btn btn-secondary"
                                    style="width:100%"
                                    v-if="activeXmlTagId !== ''"
                                    @click.prevent="addXMLSignature">
                                Place Signature
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </section>

    <div ref="modalSignatureRef" class="modal" style="display: none;">
        <input type="hidden" id="MS_Mode" value="Signature" />
        <div id="divDrawing">
            <link href="https://fonts.googleapis.com/css?family=Allura&amp;display=swap"
                  rel="stylesheet" />
            <link href="https://fonts.googleapis.com/css?family=Dancing+Script&amp;display=swap"
                  rel="stylesheet" />
            <link href="https://fonts.googleapis.com/css?family=Mansalva&amp;display=swap"
                  rel="stylesheet" />
            <link href="https://fonts.googleapis.com/css?family=Satisfy&amp;display=swap"
                  rel="stylesheet" />
            <link href="https://fonts.googleapis.com/css?family=Sacramento&amp;display=swap"
                  rel="stylesheet" />
            <link href="https://fonts.googleapis.com/css?family=Vibes&amp;display=swap"
                  rel="stylesheet" />

            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <ul class="nav nav-tabs">
                            <li class="active">
                                <a data-toggle="tab" href="#Draw" aria-expanded="false">Draw</a>
                            </li>
                            <li class="">
                                <a data-toggle="tab" href="#Type" aria-expanded="true">Type</a>
                            </li>
                            <li><a data-toggle="tab" href="#Upload">Upload</a></li>
                        </ul>
                        <div>
                            <button type="button" class="close" data-dismiss="modal">
                                x
                            </button>
                        </div>
                    </div>
                    <div class="modal-body">
                        <div class="tab-content">
                            <div id="Draw" class="tab-pane fade active in">
                                <div>
                                    <div class="img-relative">
                                        <canvas ref="signatureCanvas"
                                                style=" width: 360px; height: 160px;"></canvas>
                                        <img id="commentImg"
                                             style="border: solid; display: none;"
                                             height="360px"
                                             width="160px" />
                                    </div>
                                    <div class="modal-footer">
                                        <div align="right">
                                            <button type="button"
                                                    class="btn btn-secondary"
                                                    @click.prevent="erase">
                                                Clear
                                            </button>
                                            <button type="button"
                                                    class="btn btn-success"
                                                    @click.prevent="saveDrawSignature">
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="Type" class="tab-pane fade ">
                                <input type="text"
                                       name="signature-text"
                                       v-model="signatureText"
                                       placeholder="Type here"
                                       class="form-control"
                                       maxlength="80" />

                                <div class="font-sample-preview">
                                    <div v-for="(item, index) in items"
                                         :key="index"
                                         class="signature-view"
                                         :class="{
                      active: activeSignatureFontFamily == item.fontFam,
                    }"
                                         @click.prevent="activeSignatureFontFamily = item.fontFam">
                                        <h1 :style="{ fontFamily: item.fontFam }">
                                            {{ signatureText }}
                                        </h1>
                                        <!--<canvas width="250" height="80" v-insert-message="signatureText" :ref="item.fontFam" :id="item.fontFam" :style="{fontFamily: item.fontFam}"></canvas>-->
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <div align="right" class="pull-right">
                                        <button type="button"
                                                class="btn btn-success"
                                                @click.prevent="saveFontSignature">
                                            Accept
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div id="Upload" class="tab-pane fade">
                                <div class="bdr upload-files">
                                    <img v-if="signatureImageBase64 !== ''"
                                         class="img-fluid img-responsive"
                                         :src="signatureImageBase64" />
                                    <img v-else
                                         src="/images/upload-icon.png"
                                         alt=""
                                         class="img-fluid img-responsive" />
                                    <p>
                                        <input ref="uploadSignatureFile"
                                               type="file"
                                               class="custom-file-input"
                                               accept="image/x-png,image/jpeg"
                                               @change="convertToBase64($event)" />
                                    </p>
                                </div>
                                <div class="modal-footer">
                                    <div align="right">
                                        <button type="button"
                                                class="btn btn-success btn-block"
                                                @click.prevent="saveImageSignature">
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div ref="modalENoteConsent" class="modal" style="display: none;">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <div>
                        <h2>Do you agree to the terms and conditions below?</h2>
                        <button type="button" class="close" data-dismiss="modal">
                            x
                        </button>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="tab-content">
                        <p>lorem</p>
                    </div>
                </div>
                <div class="modal-footer">
                    <div align="right">
                        <button type="button"
                                class="btn btn-danger btn-block pull-left"
                                @click.prevent="declineENote">
                            Decline
                        </button>
                        <button type="button"
                                class="btn btn-success btn-block pull-right"
                                @click.prevent="acceptENote">
                            Accept
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ApiService from "@/core/services/api-service";
    import { loadFileUrl } from "@/core/services/api-service";
    import Checklist from "@/components/Checklist.vue";
    import moment from "moment";
    import Swal from "sweetalert2";

    export default {
        name: "SignerRoom",
        emits: ["completed"],
        components: {
            Checklist,
        },
        async created() {
            let response = await ApiService.GenerateGetRequest("document/signer");
            if (response && response["isSuccess"]) {
                console.log("response docs", response["content"]);
                this.files = response["content"];
                const signerResponse = await ApiService.GenerateGetRequest("signer/details");
                this.signer = signerResponse["content"]
                console.log(this.signer)
                // LEAVING IN HERE FOR NOW AS WE DON"T HAVE PROPER ENOTES IN THE SYSTEM
                // modify data from server to add extra properties we may need to determine whhen and where to wetsignb
                // and show relevant documentsd
                //for (let j = 0; j < this.files.length; j++) {
                //    this.files[j];
                //    if (j == 1) {
                //        this.files[j].isEnote = true;
                //    }
                //    if (j == 0) {
                //        this.files[j].isEnote = false;
                //    }
                //}
            }
            this.loadLegend();
            this.allNoneENoteFilesFilledOut();
        },
        mounted: async function () {
            let self = this;

            this.$nextTick(() => {
                self.setUpMoveEventListeners();

                // signatture
                let signatureCanvas = this.$refs.signatureCanvas;

                let currentMousePosition = {
                    x: 0,
                    y: 0,
                };

                //previous mouse position
                let previousMousePosition = {
                    x: 0,
                    y: 0,
                };

                //make a 2D context
                this.signatureCanvasContext = signatureCanvas.getContext("2d");

                //set the line parameters
                this.signatureCanvasContext.lineWidth = 3;
                this.signatureCanvasContext.lineJoin = "round";
                this.signatureCanvasContext.lineCap = "round";
                this.signatureCanvasContext.strokeStyle = "black";

                //capture mouse moves
                this.$refs.signatureCanvas.addEventListener("mousemove", function (e) {
                    //store the old current mouse position and the previous mouse position
                    previousMousePosition.x = currentMousePosition.x;
                    previousMousePosition.y = currentMousePosition.y;

                    var rect = signatureCanvas.getBoundingClientRect();
                    //get the new mouse position
                    currentMousePosition.x = e.clientX - rect.left;
                    currentMousePosition.y = e.clientY - rect.top;
                });

                //mouse down
                signatureCanvas.addEventListener("mousedown", function (e) {
                    //add an additional listener to draw
                    signatureCanvas.addEventListener("mousemove", onPaint);
                });

                //mouse up
                signatureCanvas.addEventListener("mouseup", function () {
                    //remove the additional mouse move listener
                    signatureCanvas.removeEventListener("mousemove", onPaint);
                });

                //an additional mouse listener to paint part of a line
                var onPaint = () => {
                    //draw the line
                    this.signatureCanvasContext.beginPath();
                    this.signatureCanvasContext.moveTo(
                        previousMousePosition.x,
                        previousMousePosition.y
                    );
                    this.signatureCanvasContext.lineTo(
                        currentMousePosition.x,
                        currentMousePosition.y
                    );
                    this.signatureCanvasContext.closePath();
                    this.signatureCanvasContext.stroke();
                };
            });
        },
        data() {
            return {
                signatureType: "font", // Canvas / Font / Image
                signatureText: "Insert Signature",
                activeSignatureFontFamily: "",
                signatureCanvasContext: null,
                signatureImageBase64: "",
                initialsImageBase64: "",
                files: [],
                activeDocIndex: 0,
                activePageIndex: 0,
                apiUrl: loadFileUrl,
                tagsPerPage: [],
                activeSmartTagIndex: 0,
                signatureTypeId: 1,
                activeXmlTagId: "",
                signer: {},
                showEnotes: false,
                items: [
                    { fontFam: "Allura" },
                    { fontFam: "Dancing Script" },
                    { fontFam: "Sacramento" },
                    { fontFam: "Mansalva" },
                    { fontFam: "Satisfy" },
                    { fontFam: "Vibes" },
                ],

                checklist: [],

                numOfLoadedThumbnailPreviews: 0,
            };
        },
        computed: {
            assetsPath: function (path) {
                return path;
            },
            filesToSign() {
                var nonEnotes = this.files.filter((f) => f.isEnote === false)
                if (nonEnotes.length == 0 && this.files.length > 0) {
                    this.showEnotes = true;
                    $(this.$refs.modalENoteConsent).modal("show");
                }
                return this.files.filter((f) => f.isEnote === this.showEnotes);

            },
        },
        methods: {
            onPreviewLoaded() {

                this.$store.commit("App/updateLoaderOverride", {
                    showLoaderOverride: true,
                });
                this.numOfLoadedThumbnailPreviews++;
                let mainImageView = 1;
                let thumbnailPaths = this.filesToSign[this.activeDocIndex]["thumbnailPaths"] || [];
                console.log("loads", this.numOfLoadedThumbnailPreviews, " - ", thumbnailPaths.length);
                if ((this.numOfLoadedThumbnailPreviews >= thumbnailPaths.length + mainImageView) || this.filesToSign[this.activeDocIndex]['isEnote']) {
                    this.$store.commit("App/updateLoaderOverride", {
                        showLoaderOverride: false,
                    });
                }
            },
            goToXMLSignature(id) {
                var container = $("#xmlDoc");
                const iframe = document.getElementById("xmlDoc");
                const element = $(`SIGNATURE_LINE[_ID='${id}']`, container.contents());
                element.css({
                    background: "yellow",
                    border: "3px dotted rgba(0, 0, 0, 0.048)",
                });
                element[0].scrollIntoView({ behavior: "auto" });
                iframe.scrollIntoView({ behavior: "auto", block: "center" });
                //   this.addXMLSignature(id);
            },
            async addXMLSignature() {
                //bring up cnavas modal
                this.signatureTypeId = 1;

                let results = await ApiService.GenerateGetRequest(
                    "signer/signature?subType=1"
                );
                if (results.isSuccess && results.content.exist) {
                    var useExisting = confirm(
                        "Would you like to use your saved signature?"
                    );
                    if (useExisting) {
                        this.setXmlSignature(results.content.imageURL, this.activeXmlTagId);
                    } else {
                        this.signatureText = this.$store.getters["Auth/userFullName"];
                        this.showSignModal();
                    }
                } else {
                    this.signatureText = this.$store.getters["Auth/userFullName"];
                    this.showSignModal();
                }
            },
            setXmlSignature(path, id) {
                ;
                var index = this.filesToSign[this.activeDocIndex]["smartTags"].findIndex(
                    (x) => x.controlId == id
                );
                this.filesToSign[this.activeDocIndex]["smartTags"][index][
                    "imgUrl"
                ] = path;
                console.log(this.filesToSign[this.activeDocIndex]["smartTags"][index]);
                var iframe = $("#xmlDoc");
                var imgSrc = this.apiUrl + path;
                var element = $(`SIGNATURE_LINE[_ID='${id}']`, iframe.contents());

                element.css({
                    background: "none",
                    border: "none",
                });

                element.html(`<img style="height:20%; width:40%" src =${imgSrc} /> `);

                var index = this.filesToSign[this.activeDocIndex]["smartTags"].findIndex(
                    (x) => x.controlId == id
                );
                ;
                this.filesToSign[this.activeDocIndex]["smartTags"][index][
                    "userFilledOut"
                ] = true;
            },
            loadLegend() {
                // - get all tags and build checklist
                /**
                 * {
                 *      Id ==PageNo:
                 *      Text ==displayText
                 * }
                 * */
                this.checklist = [];
                console.log("this.filesToSign[this.activeDocIndex][smartTags]", this.filesToSign[this.activeDocIndex]["smartTags"]);
                for (
                    let i = 0;
                    i < this.filesToSign[this.activeDocIndex]["smartTags"].length;
                    i++
                ) {
                    // add additional property to denote whether a tag was filled out
                    if (
                        this.filesToSign[this.activeDocIndex]["smartTags"][i]["isTag"] ===
                        true || this.filesToSign[this.activeDocIndex]["smartTags"][i][
                        "userFilledOut"
                        ] === true
                    ) {
                        this.filesToSign[this.activeDocIndex]["smartTags"][i][
                            "userFilledOut"
                        ] = true;
                    } else {
                        this.filesToSign[this.activeDocIndex]["smartTags"][i][
                            "userFilledOut"
                        ] = false;
                    }

                    // displayText gets overwritten which messes with the view of the checklist, use another property to store its original value
                    if (
                        typeof this.filesToSign[this.activeDocIndex]["smartTags"][i][
                        "originalDisplayText"
                        ] === "undefined"
                    )
                        this.filesToSign[this.activeDocIndex]["smartTags"][i][
                            "originalDisplayText"
                        ] = this.filesToSign[this.activeDocIndex]["smartTags"][i][
                            "displayText"
                            ];

                    let obj = this.filesToSign[this.activeDocIndex]["smartTags"][i];

                    if (this.filesToSign[this.activeDocIndex]["isEnote"]) {
                        this.checklist.push({
                            Id: obj.controlId,
                            isEnote: true,
                            Text: obj.originalDisplayText,
                            IsCompleted: this.filesToSign[this.activeDocIndex]["smartTags"][i][
                                "userFilledOut"
                            ],
                        });
                    } else {
                        this.checklist.push({
                            Id: obj.pageNo,
                            isEnote: false,
                            Text: obj.originalDisplayText,
                            IsCompleted: this.filesToSign[this.activeDocIndex]["smartTags"][i][
                                "userFilledOut"
                            ],
                        });
                    }
                }
            },
            handleChecklistItemClicked(item) {
                // index start at 0. so if pageNo is 2 we minus 1
                if (item.isEnote) {
                    this.activeXmlTagId = item.Id;
                    this.goToXMLSignature(item.Id);
                } else {
                    this.activePageIndex = item.Id - 1;
                }
            },
            async completeSigning() {
                let results = await ApiService.GeneratePostRequest(
                    "transaction/sign",
                    this.files
                );
                if (results.isSuccess) {
                    Swal.fire({
                        title: 'Saved!',
                        text: 'Document successfully signed',
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    });
                    //this.$emit("completed");
                }
                // remove from here just tetsing
                this.$emit("completed");
            },
            async saveChanges() {
                let results = await ApiService.GeneratePostRequest(
                    "transaction/eSign/tags/update",
                    this.files
                );
                if (results.isSuccess) {
                    Swal.fire({
                        title: 'Saved!',
                        text: 'Changes saved',
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    });
                }
            },
            getTagsPerPage(tags) {
                // this.$nextTick(() => {
                //   this.setUpMoveEventListeners();
                // });
                return tags.filter((t) => t.pageNo == this.activePageIndex + 1);
            },
            setUpMoveEventListeners() {
                let self = this;
                // similar to jquery document onready -
                $(this.$refs.documentViewerRef)
                    .find(".smart-tag")
                    .draggable({
                        containment: "parent",
                        stop: function (event) {
                            var tagId = parseInt($(this).attr("data-index"));
                            var index = self.files[self.activeDocIndex]["smartTags"].findIndex(
                                (x) => tagId === x.id
                            );
                            self.files[self.activeDocIndex]["smartTags"][index][
                                "controlTop"
                            ] = $(this).css("top");
                            self.files[self.activeDocIndex]["smartTags"][index][
                                "controlLeft"
                            ] = $(this).css("left");
                        },
                    });
                $(this.$refs.documentViewerRef)
                    .find(".smart-tag")
                    .resizable({
                        resize: function (event, ui) {
                            var size = ui.size;
                            var tagId = parseInt($(this).attr("data-index"));
                            var index = self.files[self.activeDocIndex]["smartTags"].findIndex(
                                (x) => tagId === x.id
                            );
                            self.files[self.activeDocIndex]["smartTags"][index][
                                "controlHeight"
                            ] = size.height + "px";
                            self.files[self.activeDocIndex]["smartTags"][index][
                                "controlWidth"
                            ] = size.width + "px";

                            if (size.width > size.height) {
                                self.files[self.activeDocIndex]["smartTags"][index]["fontSize"] =
                                    size.width / 8 + "px";
                            } else {
                                self.files[self.activeDocIndex]["smartTags"][index]["fontSize"] =
                                    size.height / 5 + "px";
                            }
                        },
                    });
            },
            confirmTagTextInput(name) {
                if (
                    this.filesToSign[this.activeDocIndex]["smartTags"][this.activeSmartTagIndex][
                    "displayText"
                    ] != name
                ) {
                    const confirmed = window.confirm(
                        `Are you sure you want to replace tag with "${name}"`
                    );
                    return confirmed;
                }
                return false;
            },
            confirmTagImgInput(imgPath, name) {
                if (
                    this.filesToSign[this.activeDocIndex]["smartTags"][this.activeSmartTagIndex][
                    "imgUrl"
                    ] != imgPath
                ) {
                    const confirmed = window.confirm(
                        `Are you sure you want to replace tag with "${name}"`
                    );
                    return confirmed;
                }
                return false;
            },
            async smartTagClicked(tag, i) {
                debugger
                this.activeSmartTagIndex = this.filesToSign[this.activeDocIndex][
                    "smartTags"
                ].findIndex((x) => tag.id === x.id);
                switch (tag["tagType"]) {
                    case "FirstName":
                        if (
                            this.confirmTagTextInput(this.$store.getters["Auth/userFirstName"])
                        ) {
                            /// set first name from store
                            this.filesToSign[this.activeDocIndex]["smartTags"][
                                this.activeSmartTagIndex
                            ]["displayText"] = this.$store.getters["Auth/userFirstName"];
                            this.filesToSign[this.activeDocIndex]["smartTags"][
                                this.activeSmartTagIndex
                            ]["userFilledOut"] = true;
                        }
                        break;
                    case "LastName":
                        if (
                            this.confirmTagTextInput(this.$store.getters["Auth/userLastName"])
                        ) {
                            /// set last name from store
                            this.filesToSign[this.activeDocIndex]["smartTags"][
                                this.activeSmartTagIndex
                            ]["displayText"] = this.$store.getters["Auth/userLastName"];
                            this.filesToSign[this.activeDocIndex]["smartTags"][
                                this.activeSmartTagIndex
                            ]["userFilledOut"] = true;
                        }

                        break;
                    case "FullName":
                        /// set last name from store
                        if (
                            this.confirmTagTextInput(this.$store.getters["Auth/userFullName"])
                        ) {
                            /// set last name from store
                            this.filesToSign[this.activeDocIndex]["smartTags"][
                                this.activeSmartTagIndex
                            ]["displayText"] = this.$store.getters["Auth/userFullName"];
                            this.filesToSign[this.activeDocIndex]["smartTags"][
                                this.activeSmartTagIndex
                            ]["userFilledOut"] = true;
                        }

                        break;
                    case "Signature":
                        // bring up cnavas modal
                        this.signatureTypeId = 1;

                        let results = await ApiService.GenerateGetRequest(
                            "signer/signature?subType=1"
                        );
                        if (results.isSuccess && results.content.exist) {
                            var useExisting = confirm(
                                "Would you like to use your saved signature?"
                            );
                            if (useExisting) {
                                this.filesToSign[this.activeDocIndex]["smartTags"][
                                    this.activeSmartTagIndex
                                ]["imgUrl"] = results.content.imageURL;
                                this.filesToSign[this.activeDocIndex]["smartTags"][
                                    this.activeSmartTagIndex
                                ]["userFilledOut"] = true;
                            } else {
                                this.signatureText = this.$store.getters["Auth/userFullName"];
                                this.showSignModal();
                            }
                        } else {
                            this.signatureText = this.$store.getters["Auth/userFullName"];
                            this.showSignModal();
                        }
                        break;
                    case "Initial":
                        // bring up cnavas modal
                        this.signatureTypeId = 3;
                        let initials = await ApiService.GenerateGetRequest(
                            "signer/signature?subType=3"
                        );
                        console.log("intiials request", initials);
                        if (initials.isSuccess && initials.content.exist) {
                            var useExisting = confirm(
                                "Would you like to use your saved signature?"
                            );
                            if (useExisting) {
                                this.filesToSign[this.activeDocIndex]["smartTags"][
                                    this.activeSmartTagIndex
                                ]["imgUrl"] = initials.content.imageURL;
                                this.filesToSign[this.activeDocIndex]["smartTags"][
                                    this.activeSmartTagIndex
                                ]["userFilledOut"] = true;
                            } else {
                                this.signatureText = `${this.$store.getters[
                                    "Auth/userFirstName"
                                ].charAt(0)}.${this.$store.getters["Auth/userLastName"].charAt(
                                    0
                                )}`;
                                this.showSignModal();
                            }
                        } else {
                            this.signatureText = `${this.$store.getters[
                                "Auth/userFirstName"
                            ].charAt(0)}.${this.$store.getters["Auth/userLastName"].charAt(0)}`;
                            this.showSignModal();
                        }

                        break;
                    case "WhiteBox":

                        const whiteboxPath = "\\\\dpuatweb01\\eNotary\\images\\editing\\Whitebox.png";
                        if (this.confirmTagImgInput(whiteboxPath, "Whitebox")) {
                            this.filesToSign[this.activeDocIndex]["smartTags"][
                                this.activeSmartTagIndex
                            ]["imgUrl"] = whiteboxPath;
                            this.filesToSign[this.activeDocIndex]["smartTags"][
                                this.activeSmartTagIndex
                            ]["userFilledOut"] = true;
                        }
                        break;
                    case "Check":

                        const checkboxPath = "\\\\dpuatweb01\\eNotary\\images\\editing\\checked-checkbox.png";
                        if (this.confirmTagImgInput(checkboxPath, "Checkbox")) {
                            this.filesToSign[this.activeDocIndex]["smartTags"][
                                this.activeSmartTagIndex
                            ]["imgUrl"] = checkboxPath;
                            this.filesToSign[this.activeDocIndex]["smartTags"][
                                this.activeSmartTagIndex
                            ]["userFilledOut"] = true;
                        }

                        break;
                    case "Email":
                        if (this.confirmTagTextInput(this.$store.getters["Auth/userEmail"])) {
                            /// set last name from store
                            this.filesToSign[this.activeDocIndex]["smartTags"][
                                this.activeSmartTagIndex
                            ]["displayText"] = this.$store.getters["Auth/userEmail"];
                            this.filesToSign[this.activeDocIndex]["smartTags"][
                                this.activeSmartTagIndex
                            ]["userFilledOut"] = true;
                        }
                        break;
                    case "Date":
                        if (this.confirmTagTextInput(moment().format("YYYY-MM-DD"))) {
                            /// set last name from store
                            this.filesToSign[this.activeDocIndex]["smartTags"][
                                this.activeSmartTagIndex
                            ]["displayText"] = moment().format("YYYY-MM-DD");
                            this.filesToSign[this.activeDocIndex]["smartTags"][
                                this.activeSmartTagIndex
                            ]["userFilledOut"] = true;
                        }

                        break;
                    case "Day":
                        if (this.confirmTagTextInput(moment().format("Do"))) {
                            /// set last name from store
                            this.filesToSign[this.activeDocIndex]["smartTags"][
                                this.activeSmartTagIndex
                            ]["displayText"] = moment().format("Do");
                            this.filesToSign[this.activeDocIndex]["smartTags"][
                                this.activeSmartTagIndex
                            ]["userFilledOut"] = true;
                        }

                        break;
                    case "Month":
                        if (this.confirmTagTextInput(moment().format("MMMM"))) {
                            this.filesToSign[this.activeDocIndex]["smartTags"][
                                this.activeSmartTagIndex
                            ]["displayText"] = moment().format("MMMM");
                            this.filesToSign[this.activeDocIndex]["smartTags"][
                                this.activeSmartTagIndex
                            ]["userFilledOut"] = true;
                        }
                        break;
                    case "Year":
                        if (this.confirmTagTextInput(moment().format("YYYY"))) {
                            this.filesToSign[this.activeDocIndex]["smartTags"][
                                this.activeSmartTagIndex
                            ]["displayText"] = moment().format("YYYY");
                            this.filesToSign[this.activeDocIndex]["smartTags"][
                                this.activeSmartTagIndex
                            ]["userFilledOut"] = true;
                        }

                        break;
                    case "HalfYear":
                        if (
                            this.confirmTagTextInput(
                                moment()
                                    .startOf("year")
                                    .subtract(6, "months")
                                    .format("YYYY-MM-DD")
                            )
                        ) {
                            this.filesToSign[this.activeDocIndex]["smartTags"][
                                this.activeSmartTagIndex
                            ]["displayText"] = moment()
                                .startOf("year")
                                .subtract(6, "months")
                                .format("YYYY-MM-DD");
                            this.filesToSign[this.activeDocIndex]["smartTags"][
                                this.activeSmartTagIndex
                            ]["userFilledOut"] = true;
                        }

                        break;
                    case "Address":

                        const address = `${this.signer.address}, ${this.signer.city}, ${this.signer.stateName}, ${this.signer.countryName}`;
                        if (
                            this.confirmTagTextInput(address)
                        ) {
                            this.filesToSign[this.activeDocIndex]["smartTags"][
                                this.activeSmartTagIndex
                            ]["displayText"] = address;
                            this.filesToSign[this.activeDocIndex]["smartTags"][
                                this.activeSmartTagIndex
                            ]["userFilledOut"] = true;
                        }

                        break;
                    case "ZipCode":
                        const zipCode = this.signer.zipCode;
                        if (
                            this.confirmTagTextInput(zipCode)
                        ) {
                            this.filesToSign[this.activeDocIndex]["smartTags"][
                                this.activeSmartTagIndex
                            ]["displayText"] = zipCode;
                            this.filesToSign[this.activeDocIndex]["smartTags"][
                                this.activeSmartTagIndex
                            ]["userFilledOut"] = true;
                        }

                        break;
                    case "DateOfBirth":
                        const dob = `${this.signer.dobYear}-${this.signer.dobMonth}-${this.signer.dobDay}`;
                        if (
                            this.confirmTagTextInput(dob)
                        ) {
                            this.filesToSign[this.activeDocIndex]["smartTags"][
                                this.activeSmartTagIndex
                            ]["displayText"] = dob;
                            this.filesToSign[this.activeDocIndex]["smartTags"][
                                this.activeSmartTagIndex
                            ]["userFilledOut"] = true;
                        }

                        break;
                }
                console.log(tag["tagType"]);
                // if (tag["tagType"] !== "Signature" && tag["tagType"] !== "Initial")
                this.loadLegend();
                this.allNoneENoteFilesFilledOut();
            },

            allNoneENoteFilesFilledOut() {
                let allNoneENoteFilesCompleted = 0;
                this.filesToSign.forEach((item) => {
                    if (!item.isEnote) {
                        let tagsFilledOut = 0;
                        item["smartTags"].forEach((tag) => {
                            if (tag["userFilledOut"]) tagsFilledOut++;
                        });
                        if (tagsFilledOut === item["smartTags"].length)
                            allNoneENoteFilesCompleted++;
                    }

                });
                console.log("Completed Document Count", allNoneENoteFilesCompleted);
                console.log("Completed Document this.filesToSign", this.filesToSign);

                if (allNoneENoteFilesCompleted === this.filesToSign.length) {
                    // show eNote
                    let hasAtLeastOneEnote = this.files.find(element => element.isEnote === true);
                    if (hasAtLeastOneEnote) {
                        let enotes = this.files.filter(element => element.isEnote === true);
                        let shouldShow = false;
                        console.log("Enotes", enotes);
                        enotes.forEach((note) => {
                            note["smartTags"].forEach((tag) => {
                                if (!tag["userFilledOut"] && shouldShow === false) {
                                    shouldShow = true;
                                    $(this.$refs.modalENoteConsent).modal("show");
                                }
                            });
                        });
                    }

                }
            },
            showSignModal() {
                this.erase();
                $(this.$refs.modalSignatureRef).modal("show");
            },
            erase() {
                this.signatureImageBase64 = "";
                if (this.signatureCanvasContext)
                    this.signatureCanvasContext.clearRect(0, 0, 360, 160);
            },
            async saveDrawSignature() {
                let img = this.$refs.signatureCanvas.toDataURL();
                let results = await ApiService.GeneratePostRequest(
                    "signer/signature/upload",
                    {
                        subType: this.signatureTypeId,
                        imageData: img.split(",")[1],
                    }
                );
                if (results.isSuccess && results.content) {
                    const imgPath = results.content + "?timestamp=" + new Date().getTime();
                    if (this.filesToSign[this.activeDocIndex]["isEnote"] == true) {
                        this.setXmlSignature(imgPath, this.activeXmlTagId);
                    } else {
                        this.filesToSign[this.activeDocIndex]["smartTags"][
                            this.activeSmartTagIndex
                        ]["imgUrl"] = imgPath;
                    }
                    this.filesToSign[this.activeDocIndex]["smartTags"][
                        this.activeSmartTagIndex
                    ]["userFilledOut"] = true;
                }
                this.loadLegend();
                this.allNoneENoteFilesFilledOut();
                $(this.$refs.modalSignatureRef).modal("hide");
            },
            async saveFontSignature() {
                if (this.activeSignatureFontFamily == "") {
                    Swal.fire({
                        title: 'Please select a font',
                        text: '',
                        icon: 'info',
                        confirmButtonText: 'Ok'
                    });
                } else if (this.signatureText.length > (this.$store.getters["Auth/userFirstName"] + " " + this.$store.getters["Auth/userLastName"]).length) {
                    Swal.fire({
                        title: 'Signature is too long.',
                        text: '',
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    });
                } else {
                    this.signatureType = "canvas";

                    var canvas = document.createElement("canvas");
                    canvas.width = 250;
                    canvas.height = 80;
                    canvas.style.height = 200;
                    var ctx = canvas.getContext("2d");

                    ctx.font = "22px " + this.activeSignatureFontFamily;
                    var text = this.signatureText;
                    ctx.textAlign = "center";
                    ctx.fillText(text, 125, 40);
                    let img = canvas.toDataURL();
                    let results = await ApiService.GeneratePostRequest(
                        "signer/signature/upload",
                        {
                            subType: this.signatureTypeId,
                            imageData: img.split(",")[1],
                        }
                    );
                    if (results.isSuccess && results.content) {
                        const imgPath =
                            results.content + "?timestamp=" + new Date().getTime();
                        if (this.filesToSign[this.activeDocIndex]["isEnote"] == true) {
                            this.setXmlSignature(imgPath, this.activeXmlTagId);
                        } else {
                            this.filesToSign[this.activeDocIndex]["smartTags"][
                                this.activeSmartTagIndex
                            ]["imgUrl"] = imgPath;
                        }
                        this.filesToSign[this.activeDocIndex]["smartTags"][
                            this.activeSmartTagIndex
                        ]["userFilledOut"] = true;
                    }
                    $(this.$refs.modalSignatureRef).modal("hide");
                }
                this.loadLegend();
                this.allNoneENoteFilesFilledOut();
            },
            async saveImageSignature() {
                let results = await ApiService.GeneratePostRequest(
                    "signer/signature/upload",
                    {
                        subType: this.signatureTypeId,
                        imageData: this.signatureImageBase64.split(",")[1],
                    }
                );
                if (results.isSuccess && results.content) {
                    const imgPath = results.content + "?timestamp=" + new Date().getTime();
                    if (this.filesToSign[this.activeDocIndex]["isEnote"] == true) {
                        this.setXmlSignature(imgPath, this.activeXmlTagId);
                    } else {
                        this.filesToSign[this.activeDocIndex]["smartTags"][
                            this.activeSmartTagIndex
                        ]["imgUrl"] = imgPath;
                    }
                    this.filesToSign[this.activeDocIndex]["smartTags"][
                        this.activeSmartTagIndex
                    ]["userFilledOut"] = true;
                }
                this.loadLegend();
                this.allNoneENoteFilesFilledOut();
                $(this.$refs.modalSignatureRef).modal("hide");
            },
            convertToBase64(event) {
                const [file] = event.target.files;
                if (file) {
                    var reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        this.signatureImageBase64 = reader.result;
                    };
                }
            },
            changeSelectedDocument(index) {
                if (!this.filesToSign[index]["isEnote"]) {
                    this.numOfLoadedThumbnailPreviews = 0;
                    this.$store.commit("App/updateLoaderOverride", {
                        showLoaderOverride: true,
                    });
                    this.loadLegend();
                }

                this.activeDocIndex = index;
                this.loadLegend();
            },
            changeSelectedPage(index) {
                this.numOfLoadedThumbnailPreviews -= 1; // we minus 1 because one image view will load. based on the onPreviewLoaded logic
                this.$store.commit("App/updateLoaderOverride", {
                    showLoaderOverride: true,
                });
                this.activePageIndex = index;
            },

            async declineENote() {
                if (confirm("Are you sure you want to decline this concent?")) {
                    ;
                    var eNotes = this.filesToSign
                        .filter((x) => x.isEnote)
                        .map((x) => {
                            return {
                                id: x.packageDocumentId,
                                westSign: false,
                            };
                        });
                    let results = await ApiService.GeneratePostRequest(
                        "package/document/update/wetsign",
                        eNotes
                    );
                    if (results["isSuccess"]) {
                        Swal.fire({
                            title: 'eConsent declined. Closing Session',
                            text: '',
                            icon: 'warning',
                            confirmButtonText: 'Ok'
                        });
                        window.location = "/sign-out";
                    } else {
                        Swal.fire({
                            title: 'Error',
                            text: 'Something went wrong...',
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        });
                    }
                }
            },
            async acceptENote() {

                var eNotes = this.filesToSign
                    .filter((x) => x.isEnote)
                    .map((x) => {
                        return {
                            id: x.packageDocumentId,
                            westSign: false,
                        };
                    });
                let results = await ApiService.GeneratePostRequest(
                    "package/document/update/wetsign",
                    eNotes
                );
                if (results["isSuccess"]) {
                    this.showEnotes = true;
                    this.loadLegend();
                    $(this.$refs.modalENoteConsent).modal("hide");
                } else {
                    this.showEnotes = false;
                    Swal.fire({
                        title: 'Error',
                        text: 'Something went wrong...',
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    });
                }
            },
        },
    };
</script>

<style scoped>
    /* .smart-tag.ui-draggable.ui-draggable-handle.ui-resizable .ui-resizable {
      position: relative !important;
    } */

    #documentViewer {
        float: left;
        /*position: relative;*/
    }


    .smart-tag {
        float: left;
        cursor: move;
        border: 1px dotted rgb(168, 168, 168);
        background: #ffe6000e;
    }

    .tagText {
        font-family: Helvetica !important;
    }

    ul#nav-taggedpages a.tablinks {
        height: 30px;
        padding: 5px;
    }

    #img-viewer {
        border: 1px solid black;
        margin-top: 1px;
    }

    #myPublisherDiv,
    #mySubscriberDiv {
        width: 175px !important;
        height: 140px !important;
    }

    div#document-viewer div {
        cursor: pointer;
    }

    .thumbnail-container {
        height: 100vh;
        overflow: auto;
    }

    .thumbnail-scroll img {
        border: 1px solid black;
        margin: 1px;
        margin-right: 0px;
    }

    .thumbnail-scroll {
        height: 100%;
        padding-bottom: 20px;
        overflow-y: auto;
        width: 160px;
    }

    nav#context-menu {
        position: absolute;
        border: 1px solid #000;
        border-radius: 3px;
        padding: 10px;
        z-index: 1001;
        background: transparent;
        box-shadow: none;
    }

    .context-menu {
        display: none;
        position: absolute;
        z-index: 10000;
        padding: 12px 0;
        width: 240px;
        background-color: #fff;
        border: solid 1px #dfdfdf;
        box-shadow: 1px 1px 2px #cfcfcf;
    }

    nav#context-menu span {
        cursor: pointer;
    }

    .highlight-xml-signature {
        border: 3px dotted rgba(0, 0, 0, 0.048);
        background: yellow;
    }

    .smart-tag > i {
        text-align: center;
        font: italic normal 90% Georgia, serif;
        line-height: 150%;
        color: black;
        text-shadow: white 0 1px;
        background: #ddd;
        background-clip: padding-box;
        box-shadow: 0 0px 2px rgba(0, 0, 0, 0.5);
        border: 2px solid rgba(0, 0, 0, 0.5);
        border-radius: 3px;
        position: absolute;
        width: 250px;
        left: 50%;
        margin-left: -125px;
        padding: 10px 0;
        bottom: 100%;
        margin-bottom: 15px;
        visibility: hidden;
        opacity: 0;
        -webkit-transition: opacity 0.5s linear;
        -moz-transition: opacity 0.5s linear;
        -ms-transition: opacity 0.5s linear;
        -o-transition: opacity 0.5s linear;
        transition: opacity 0.5s linear;
    }

        .smart-tag > i:before,
        .smart-tag > i:after {
            content: "";
            position: absolute;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            top: 100%;
            left: 50%;
            margin-left: -10px;
        }

        .smart-tag > i:before {
            border-top: 10px solid #111;
            border-top: 10px solid rgba(0, 0, 0, 0.5);
            margin-top: 5px;
        }

        .smart-tag > i:after {
            border-top: 10px solid #ddd;
            margin-top: -2px;
        }

    .smart-tag:hover > i {
        visibility: visible;
        opacity: 1;
        z-index: 1;
    }
</style>

<!-- MOBILE ONLY -->
<style>
    @media screen and (max-width: 767px) {

        .k-card-header .menu {
            display: none !important;
        }

        .k-drawer-mini .k-drawer.k-drawer-start {
            display: none !important;
        }

        .k-drawer-mini .k-drawer-content .thumbnail-container {
            display: none !important;
        }

        .k-drawer-mini .k-drawer-content .signer-room-form-containter {
            overflow-x: scroll;
        }
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
        .k-drawer-mini .k-drawer-content .thumbnail-container {
            display: none !important;
        }
    }
</style>
<!-- MOBILE ONLY-->