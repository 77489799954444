<template>
    <div class="esignlogo"><img src="/images/esign-logo.png" height="120" /></div>
    <div class="col-center">
        <div id="loginForm">
            <div class="formarea">
                <select-account-login v-if="this.showAccountSelect" @onLogin="handleSubmit" @setAcocunt="setAcocunt" :clients="clients" :roles="roles" @setShowAccountSelect="setShowAccountSelect" />
                <div v-else>
                    <h2>LOG IN</h2>

                    <form>
                        <div class="group">
                            <label for="username">Email</label>
                            <input type="text" v-model="username" name="username" class="form-control" :class="{ 'is-invalid': submitted && !username }" />
                            <div v-show="submitted && !username" class="invalid-feedback">Email is required</div>
                        </div>
                        <div class="group">
                            <label htmlFor="password">Password</label>
                            <input type="password" v-model="password" name="password" class="form-control" :class="{ 'is-invalid': submitted && !password }" />
                            <div v-show="submitted && !password && !forgot" class="invalid-feedback">Password is required</div>
                        </div>
                        <div class="group">
                            <label v-show="errormsg">{{errormsg}}</label>
                        </div>
                        <div class="group loginbutton">
                            <button class="button login" :disabled="status.loggingIn" @click="handleSubmit">Login</button>
                        </div>
                        <div class="group reset">
                            <button @click="forgotPassword" class="resetpassword">Click here to reset your password</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="techsupport">
        <div><span>For Technical Support: </span></div>
        <div><span><a href="https://signiahelp.zendesk.com/hc/en-us">Stavvy Inc.</a></span></div>
        
        <div><span style="font-size:12px">&copy;{{new Date().getFullYear()}} Stavvy Inc. - Ref. #{{ref}} </span></div>
    </div>
    <section class="block-loader ctnr" v-if="showLoader">
        <div class="ldr">
            <div class="ldr-blk"></div>
            <div class="ldr-blk an_delay"></div>
            <div class="ldr-blk an_delay"></div>
            <div class="ldr-blk"></div>
        </div>
    </section>
</template>

<style scoped>
    /* Login Page */
    .col-center {
        align-items: stretch;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
        font-size: 14px;
        height: 100%;
        justify-content: center;
        line-height: 19.999px;
        margin-bottom: -14px;
        margin-left: -14px;
        margin-right: -14px;
        margin-top: -14px;
        padding-bottom: 15vh;
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 17vh;
        text-size-adjust: 100%;
        width: 100%;
        -webkit-box-align: stretch;
        -webkit-box-direction: normal;
        -webkit-box-orient: horizontal;
        -webkit-box-pack: center;
        vertical-align: middle;
        position: relative;
    }

    #loginForm {
        align-self: center;
        background-attachment: scroll;
        background-clip: border-box;
        background-color: #FFF;
        background-image: none;
        background-origin: padding-box;
        background-size: auto;
        box-sizing: border-box;
        color: rgb(106, 111, 140);
        display: block;
        flex-direction: column;
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
        font-stretch: 100%;
        font-style: normal;
        font-variant-caps: normal;
        font-variant-east-asian: normal;
        font-variant-ligatures: normal;
        font-variant-numeric: normal;
        font-weight: 600;
        /*height: 713px;*/
        line-height: 18px;
        max-width: 560px;
        padding-bottom: 0px;
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 0px;
        position: relative;
        text-align: left;
        text-size-adjust: 100%;
        text-transform: uppercase;
        vertical-align: middle;
        width: 560px;
        -webkit-box-direction: normal;
        -webkit-box-orient: vertical;
        -ms-flex-direction: column;
        flex-direction: column;
        vertical-align: middle;
        -ms-flex-item-align: center !important;
        align-self: center !important;
        border: 1px solid #ccc;
    }

    h2 {
        font: 600 16px/18px 'Arial','Open Sans',sans-serif;
        font-size: 22px;
        color: #000;
        padding-bottom: 5px;
        border-bottom: 10px solid #CCC;
        width: 78px;
    }

    label {
        margin-bottom: 0px;
    }

    form {
        font-size: 1.14285714rem;
        position: relative;
        max-width: 100%;
        margin: 14px 0 0 0;
    }

    h2:after {
    }

    #loginForm .formarea {
        padding: 60px 70px 50px 70px;
    }

    #loginForm label {
        color: #000;
        display: block;
        float: left;
        font: 600 16px/18px 'Arial','Open Sans',sans-serif;
        font-size: 12px;
    }

    #loginForm .backlink a {
        text-transform: none;
        font-size: 80%;
        font-weight: 300;
        color: #dcdada;
    }

    #loginForm label, #loginForm h4, #loginForm div.group.reset a {
        color: 000;
    }

    .resetpassword {
        background: none;
    }

        .resetpassword a {
            color: white;
        }

        .resetpassword:hover {
            text-decoration: underline;
        }

    #loginForm div.group.policy a {
        color: #629afd;
    }

    #loginForm input[type=text], #loginForm input[type=password] {
        color: black;
    }

        #loginForm input[type=text]:focus, #loginForm input[type=password]:focus {
            color: black;
        }

    input, button {
        border: none;
        padding: 15px 20px;
        border-radius: 5px;
        background: rgba(255,255,255,0.9);
        width: 100%;
        height: 44px;
        max-width: 100%;
        font: 400 13.3333px Arial;
    }

    input {
        border: 1px solid #CCC;
    }

    .techsupport, .techsupport a {
        color: #000;
    }

    .group .button {
        background: #1161ee;
        border: none;
        color: #fff;
        text-transform: uppercase;
    }

    input .input-validation-error, button .input-validation-error {
        background: rgb(170, 51, 51);
    }

    input:focus {
        background-color: white;
    }

    .group {
        margin-bottom: 15px;
    }

        .group.loginbutton {
            margin-top: 70px;
        }

        .group .button {
            background: #9747FF;
            border: none;
            color: #fff;
            text-transform: uppercase;
        }

    .hr {
        height: 2px;
        margin: 20px 0 30px 0;
        background: rgba(255,255,255,.2);
    }

    span.field-validation-error {
        display: inline-block;
        margin: 0;
        float: right;
        text-align: right;
    }

    .group.reset {
        text-transform: none;
        font-size: 80%;
        font-weight: 400;
        color: #000;
        text-align: center;
    }

    .group.policy {
        text-transform: none;
        font-size: 80%;
        font-weight: 400;
        color: #ebefef;
        text-align: center;
    }

        .group.policy .ref {
            color: #ebefef;
        }

    .invalid-feedback {
        color: #01ffff;
    }

    /* End Login Page */
</style>
<script>
    import { mapState, mapActions } from 'vuex'
    import ApiService from "@/core/services/api-service";
    import store from "../../store/index";
    import SelectAccountLogin from "./SelectLoginAccount.vue";
    export default {
        name: 'AdminLogin',
        components: {
            SelectAccountLogin
        },
        data() {
            return {
                username: '',
                password: '',
                submitted: false,
                forgot: false,
                ref: '',
                errormsg: '',
                showLoader: false,
                clients: [],
                roles: [],
                clientId: null,
                roleId: null,
                showAccountSelect: false,
            }
        },
        computed: {
            ...mapState('Admin', ['status']),
        },
        created() {
            // reset login status
            this.logout();
            store.commit("App/updateLoader", { showLoader: false });
            document.body.id = "login";
        },
        methods: {
            ...mapActions('Admin', ['loginUser', 'logout']),
            async handleSubmit(e, account = null) {
                e.preventDefault();
                store.commit("App/updateToken", "");

                if (account != null) {
                    this.clientId = account.clientId
                    this.roleId = account.roleId
                }
                if (account == null)
                {
                    this.showLoader = true;
                    const { username, password, clientId, roleId } = this;

                    let loginResponse = await ApiService.loginUser({ username, password });
                    console.log(loginResponse);
                    if (loginResponse.isSuccess) {
                        store.commit("App/updateToken", loginResponse.content.token);
                        let response = await ApiService.GeneratePostRequest("Account/prelogin", { "Username": this.username });
                        store.commit("App/updateToken", {});

                        if (response && response.isSuccess) {
                            this.showLoader = false;
                            var clients = response.content.clients;
                            var roles = response.content.roles;
                            if (clients.length > 1 || roles.length > 1) {
                                this.showAccountSelect = true;
                                this.clients = clients;
                                this.roles = roles;
                                return;
                            } else {
                                this.clientId = clients[0].clientId
                                this.roleId = roles[0].roleId
                                await this.login();
                            }
                        } else {
                            this.showLoader = false;
                            this.errormsg = response.message;
                        }
                    } else {
                        this.showLoader = false;
                        this.errormsg = loginResponse.message;
                    }
                } else {
                    await this.login();
                }

            },
            async login() {
                this.submitted = true;
                this.showLoader = true;
                this.forgot = false;
                this.errormsg = "";
                const { username, password, clientId, roleId } = this;

                if (this.username && this.password) {
                    return this.loginUser({ username, password, clientId, roleId }).then(
                        async response => {
                            this.showAccountSelect = false;
                            console.log("Login Details", response);
                            if (!response.isSuccess && response.content == "redirect") {
                                var inviteToken = this.$store.getters["Auth/invite"]
                                this.$router.push("/authenticate/" + inviteToken);
                            } else if (!response.isSuccess) {
                                this.errormsg = response.message;
                                this.showLoader = false;
                            } else {
                                if (response.message == "reset_pw") {
                                    this.$router.push('/admin/forgotpassword/' + response.content.token);
                                } else {
                                    var settings = await ApiService.GenerateGetRequest("Admin/LenderSettings");
                                    this.$store.commit("App/updateAppSettings", settings.content);
                                    if (settings != null && settings.isSuccess) {
                                        this.$store.commit("App/updateLogo", settings.content.logo);
                                        this.$emit('updateLogo');
                                    }

                                    if (!response.content.hasAccessToMenu && response.content.role.toLowerCase() === "notary")
                                        this.$router.push('/Admin/settings');
                                    else if (response.content.role.toLowerCase() !== "notary")
                                        this.$router.push('/admin')
                                    else
                                        this.$router.push('/Admin/orders');
                                }
                            }
                        }
                    );
                }
                this.showLoader = false;
            },
            setShowAccountSelect(status) {
                this.showAccountSelect = status;
            },
            async forgotPassword(e) {
                //check for email
                e.preventDefault();
                this.forgot = true;
                this.showLoader = true;
                this.errormsg = "";
                if (this.username) {
                    let response = await ApiService.GeneratePostRequest("Admin/ForgotPassword", { "Username": this.username });
                    if (response && response["isSuccess"] == true) {
                        this.errormsg = response["message"];
                    } else {
                        this.errormsg = response["message"];
                    }
                }
                else {
                    this.errormsg = "Please enter your email address.";
                }
                this.showLoader = false;

            },
        },
        async mounted() {
            document.title = "Stavvy - eSign";
            var response = await ApiService.getRef();
            this.ref = response;
        },
        beforeRouteLeave(to, from, next) {
            activeRequestsCount = 0;
            completedRequests = 0;
            store.commit("App/updateLoader", { showLoader: false });
            document.body.removeAttribute("id");
            next();
        },
        destroyed() {
            activeRequestsCount = 0;
            completedRequests = 0;
            store.commit("App/updateLoader", { showLoader: false });
            document.body.removeAttribute("id");
        },
    }
</script>