<template>

    <!--Waiting Page-->
    <card class="" style="margin:0;" id="overlay" v-show="loadPdfs">
        <waiting :hide="loaderFileCount !== files.length" :isRon="this.isRon" :mainBorrower="mainBorrower" ref="waitingPage" :totalDocumentCount="loaderFileCount.length" @documentsLoaded="allDocumentsLoaded"></waiting>
    </card>

    <card class="card">
        <cardHeader>
            <div class="row" v-if="signer.isRon">
                <div class="col-md-12 video-section">
                    <template v-if="!ReadyToSign">
                        <intro-countdown @signingReady="canBorrowerSign" :SignReady="SignReady" :secondsTillSigning="this.signer.appointmentTimeInSeconds" @readyToSign="showMeetingWaitModal" />
                    </template>
                    <template v-else>
                        <!--<kbutton @click="showMeetingWaitModal" style="float: right; z-index: 3; width: 170px; right: 0.5em;" class="k-button k-primary" v-if="!ReadyToSign && SignReady">I'm Ready to Sign</kbutton>-->
                        <videosession v-if="NotarizeID && NotarizeID != 0 && ReadyToSign" :NotarizeID="NotarizeID" :Type="'Signer'" v-on:close_waiting="NotaryConnected" ref="videoref"></videosession>
                        <!--<screenshare v-if="NotarizeID && NotarizeID != 0" :NotarizeID="NotarizeID" :sType="'Signer'"></screenshare>-->
                    </template>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="menu">
                        <div class="custom-toolbar">
                            <kbutton :icon="'menu'" :fill-mode="'flat'" @click="handleClick" />
                        </div>
                        <p>Menu</p>
                    </div>
                    <kbutton @click="toggleTagVisibility" class="k-button k-primary pull-left" style="float:left;" v-if="(!signer.isWitness || !signer.isRon) && (activeDrawerIdx != 3 && activeDrawerIdx != 4)">{{isReadMode ? 'Show Tags' : 'Hide Tags'}}</kbutton>
                    <kbutton @click="showRetakePictureModal" class="k-button k-primary pull-left" v-if="!signer.isWitness && ReadyToSign" style="margin-left:10px">Reupload Verification Documents</kbutton>
                    <kbutton @click="showIAttest" class="k-button k-primary pull-right complete-btn" v-if="showCompleteSigning && this.activeDrawerIdx != 3 && this.activeDrawerIdx != 4">Complete Session</kbutton>

                </div>
            </div>

            <div class="row">
                <div class="col-md-12" v-if="files.length > 0">
                    <h2 v-if="this.activeDrawerIdx != 3 && this.activeDrawerIdx != 4 " class="text-center">
                        {{this.formatStatus(this.currentDocType)}}: {{this.currentScrollDocName}}
                    </h2>
                </div>
            </div>
        </cardHeader>

        <cardBody>
            <section>
                <Drawer :expanded="expanded"
                        :position="position"
                        :mode="mode"
                        :mini="true"
                        :mini-width="45"
                        :width="250"
                        :items="dataItems"
                        :item="'CustomItem'"
                        @select="onSelect">
                    <template v-slot:CustomItem="{ props }">
                        <DrawerItem v-if="props.visible !== false"
                                    v-bind="props"
                                    @click="props.onClick"
                                    :disabled="props.disabled">
                            <span :class="'k-icon ' + props.icon" />
                            <span class="k-item-text">{{ props.text }}</span>
                            <span v-if="props['data-expanded'] !== undefined"
                                  :class="props['data-expanded'] ? downClass : null"
                                  :style="{
                              position: 'absolute',
                              right: '10px',
                            }" />
                        </DrawerItem>
                    </template>
                    <DrawerContent>
                        <div class="row" v-show="this.activeDrawerIdx == 1 || this.activeDrawerIdx == 2">
                            <div class="col-md-1 thumbnail-container">
                                <div></div>
                            </div>
                            <div class="col-md-11 signer-room-form-containter" @wheel="checkItemsInView($event)" @scroll="checkItemsInView($event)" style="overflow-y:auto;">
                                <section class="guided-arrow-container" style="position:absolute; width:100%;"></section>
                                <div>


                                </div>
                                <teleport v-if="renderJumpArrow && !isReadMode && !canDisplayTagNav"
                                          :to="teleportLocationForJumpArrow">
                                    <guided-arrow @click.prevent="jumpToNextGuidedArrow"
                                                  :pending="false"
                                                  :enabled="true"
                                                  :main="true"
                                                  :elementInView="currentTagInView"
                                                  :id="'navigateToNextControl'"
                                                  :direction="jumpArrowDirection"
                                                  :left="parseInt(pdfForm.viewport.width) + 15"
                                                  :top="jumpArrowTopPosition"
                                                  :text="jumpArrowText" />
                                </teleport>
                                <template v-if="pdfForms[currentFormIndex]">
                                    <teleport :to="'.signer-room-form-containter div[data-pagename=\'' + pdfForms[currentFormIndex].pdfName + '\']' + '[data-page=\'' + pdfForms[currentFormIndex].numpages + '\']'">
                                        <button @click="userClickedReviewed" v-if="showReviewContinueButton" class="review-button btn">
                                            {{isSigningDate ? 'Continue' : 'Mark as Reviewed' }}
                                        </button>
                                    </teleport>
                                </template>

                                <template v-if="pdfForms.length > 0">
                                    <template v-for="(pdf, pdfIndex) in pdfForms">
                                        <template v-if="pdf.allFormFields && pdf.allFormFields.length > 0" v-for="(data, i) in pdf.allFormFields">
                                            <teleport :to="'.signer-room-form-containter #' + pdf.pdfName + '-Page_' + data.belongsToPage">
                                                <guided-arrow v-if="data.value === '' && data.smartTag.tagType != 'AutomaticSignatureDate' && !isReadMode"
                                                              @click.prevent="guidedArrowClicked(data, i, pdfIndex)"
                                                              :pending="data.value === ''"
                                                              :enabled="data.guidedTagEnabled"
                                                              :id="pdf.pdfName + '-' + data.noSpaceIdAlias"
                                                              :direction="data.smartTag ? data.smartTag.tagType == 'CheckBox' ? 'right' : 'left' : 'left'"
                                                              :increaseZIndex="true"
                                                              :isnotarytag="data.isAdmin && !data.isWitness"
                                                              :optional="isTagOptional(data.smartTag)"
                                                              :left="guidedArrowLeftPosition(data.smartTag ? data.smartTag.tagType : '', data)"
                                                              :top="data.y - 8 " :text="guidedArrowText(data.smartTag ? data.smartTag.tagType : '', data)" />
                                            </teleport>
                                        </template>
                                    </template>
                                </template>
                            </div>
                        </div>
                        <div class="row" v-if="this.activeDrawerIdx == 4 && this.documentId == 0">
                            <printAndUploadView @setDocumentId="setDocumentId" @refreshDocuments="refreshDocuments" :wetDocs="this.toupload" />
                        </div>
                        <div class="row" v-if="this.activeDrawerIdx == 4 && this.documentId > 0">
                            <completed-document-view :documentId="documentId" />
                        </div>
                        <div class="row" v-if="this.activeDrawerIdx == 3 && this.documentId == 0">
                            <completed-section @setDocumentId="setDocumentId" />
                        </div>
                        <div class="row" v-if="this.activeDrawerIdx == 3 && this.documentId > 0">
                            <completed-document-view :documentId="documentId" />
                        </div>

                    </DrawerContent>
                </Drawer>
            </section>
        </cardBody>
    </card>

    <div ref="dateModal" class="modal" style="display: none;">
        <div>
            <div class="modal-dialog">
                <div class="modal-content" style=" background: white;">
                    <div class="modal-header">
                        <div>
                            <button type="button" class="close" data-dismiss="modal">
                                x
                            </button>
                        </div>
                    </div>
                    <div class="modal-body">
                        <input type="date"
                               name="date-form-text"
                               v-model="dateFieldText"
                               placeholder="Type here"
                               class="form-control"
                               maxlength="80" />

                        <div class="modal-footer">
                            <div align="right" class="pull-right">
                                <button type="button"
                                        class="btn btn-success"
                                        @click.prevent="saveDate">
                                    Accept
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div ref="modalFreeFormRef" class="modal" style="display: none;">
        <div>
            <div class="modal-dialog">
                <div class="modal-content" style=" background: white;">
                    <div class="modal-header">
                        <div>
                            <button type="button" class="close" data-dismiss="modal">
                                x
                            </button>
                        </div>
                    </div>
                    <div class="modal-body">
                        <input type="text"
                               name="free-form-text"
                               v-model="freeFormText"
                               placeholder="Type here"
                               class="form-control"
                               maxlength="80" />

                        <div class="modal-footer">
                            <div align="right" class="pull-right">
                                <button type="button"
                                        class="btn btn-success"
                                        @click.prevent="saveFreeForm">
                                    Accept
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div ref="modalSignatureRef" class="modal" style="display: none;">
        <input type="hidden" id="MS_Mode" value="Signature" />
        <div id="divDrawing">
            <link href="https://fonts.googleapis.com/css?family=Allura&amp;display=swap"
                  rel="stylesheet" />
            <link href="https://fonts.googleapis.com/css?family=Dancing+Script&amp;display=swap"
                  rel="stylesheet" />
            <link href="https://fonts.googleapis.com/css?family=Mansalva&amp;display=swap"
                  rel="stylesheet" />
            <link href="https://fonts.googleapis.com/css?family=Satisfy&amp;display=swap"
                  rel="stylesheet" />
            <link href="https://fonts.googleapis.com/css?family=Sacramento&amp;display=swap"
                  rel="stylesheet" />
            <link href="https://fonts.googleapis.com/css?family=Vibes&amp;display=swap"
                  rel="stylesheet" />

            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <ul class="nav nav-tabs">
                            <li class="active">
                                <a v-show="signingTypes.Draw" data-toggle="tab" href="#Draw">Draw</a>
                            </li>
                            <li class="">
                                <a v-show="signingTypes.Type" data-toggle="tab" href="#Type">Type</a>
                            </li>
                            <li><a v-show="signingTypes.Upload" data-toggle="tab" href="#Upload">Upload</a></li>
                        </ul>
                        <div>
                            <button type="button" class="close" data-dismiss="modal">
                                x
                            </button>
                        </div>
                    </div>
                    <div class="modal-body">
                        <div class="tab-content">
                            <div id="Draw" class='tab-pane fade' v-show="signingTypes.Draw">
                                <div>
                                    <div class="img-relative">
                                        <canvas ref="signatureCanvas"
                                                style=" width: 360px; height: 160px;"></canvas>
                                        <img id="commentImg"
                                             style="border: solid; display: none;"
                                             height="360px"
                                             width="160px" />
                                    </div>
                                    <div class="modal-footer">
                                        <div align="right">
                                            <button type="button"
                                                    class="btn btn-secondary"
                                                    @click.prevent="erase">
                                                Clear
                                            </button>
                                            <button type="button"
                                                    style="background-color: #5cb85c; border-color: #4cae4c;"
                                                    class="btn btn-secondary"
                                                    @click.prevent="saveDrawSignature">
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="Type" class='tab-pane fade' v-show="signingTypes.Type">
                                <input type="text"
                                       name="signature-text"
                                       readonly
                                       :value="signatureText"
                                       placeholder="Type here"
                                       class="form-control"
                                       :style="typeErrorList.length>0 ? 'border-color:red' : ''" />
                                <ul v-show="typeErrorList.length>0">
                                    <li class="errorLi" v-for="item in typeErrorList" :key="item">
                                        {{ item }}
                                    </li>
                                </ul>
                                <div class="font-sample-preview">
                                    <div v-for="(item, index) in fonts"
                                         :key="index"
                                         class="signature-view"
                                         :class="{active: activeSignatureFontFamily == item.fontFam}"
                                         :style="{color: lineColor }"
                                         @click.prevent="activeSignatureFontFamily = item.fontFam">
                                        <h1 :style="{ fontFamily: item.fontFam }">
                                            {{ signatureText }}
                                        </h1>
                                        <!--<canvas width="250" height="80" v-insert-message="signatureText" :ref="item.fontFam" :id="item.fontFam" :style="{fontFamily: item.fontFam}"></canvas>-->
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-success"
                                            style="display: block; width: 100%; margin-bottom: 30px;"
                                            @click.prevent="saveFontSignature">
                                        Accept
                                    </button>
                                </div>
                            </div>
                            <div id="Upload" class='tab-pane fade' v-show="signingTypes.Upload">
                                <div class="bdr upload-files">
                                    <img v-if="signatureImageBase64 !== ''"
                                         class="img-fluid img-responsive"
                                         :src="signatureImageBase64" />
                                    <img v-else
                                         src="/images/upload-icon.png"
                                         alt=""
                                         class="img-fluid img-responsive" />
                                    <p>
                                        <input ref="uploadSignatureFile"
                                               type="file"
                                               class="custom-file-input"
                                               accept="image/x-png,image/jpeg"
                                               @change="convertToBase64($event)" />
                                    </p>
                                </div>
                                <div class="modal-footer">
                                    <div align="right">
                                        <button type="button"
                                                class="btn btn-success btn-block"
                                                @click.prevent="saveImageSignature">
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div ref="modalMeetingWaitRef" class="modal fade" role="dialog" style="display: none;" id="WaitingModal">
        <div class="modal-dialog">
            <!-- Modal -->
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-body">
                    <div class="row text-center">
                        <div class="col-md-12">
                            <!--<img src="~/images/icon-recyle.png" class="icon-recyle" alt="">-->
                            <h3>Connecting with a Notary </h3>
                            <h3 style="font-weight: 300">Estimated wait time :</h3>
                            <span>{{waitSeconds}} seconds remaining</span>
                            <p> <small> Please wait while we connect you to a Notary. </small></p>
                            <div class="btn-screen-page text-center">
                                <button type="button" @click="CancelMeeting" class="btn-secondary">CANCEL MEETING</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div ref="modalRetakePicture" class="modal fade" role="dialog" style="display: none;">
        <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">×</button>
                    <h2 class="modal-title" id="modalRetakePictureTitle">--</h2>

                    <select name="" id="videoSource" style="display:none"></select>

                    <input type="hidden" id="hdnRetakePictureIsFront" />
                    <input type="hidden" id="hdnRetakePictureType" />
                    <input type="hidden" id="hdnRetakePictureSignerIdentity" />
                </div>
                <div class="modal-body">
                    <div id="divRetakePicture">
                        <button type="button" class="btn btn-primary" id="btnCaptureRetakePicture" @click="retakePicture">Retake Photo</button>
                        <div v-show="hideVideo">
                            <video id="retakeVideoPreview" autoplay class="img-responsive"></video>
                            <button type="button" class="btn btn-primary" id="btnSaveRetakePicture" @click="btnCapturePic">Capture</button>
                            <button type="button" class="btn btn-green" @click="cancelVideoCapture">Cancel</button>
                        </div>
                        <div v-show="pictureTaken">
                            <img v-bind:src="ImageBinary" style="width:50%; height:50%" />
                            <canvas id="canvas" style="display: none;"></canvas>
                            <br />
                        </div>

                    </div>
                    <div id="divReUploadPicture">
                        <input type="file" id="reuploadPictureUploader" @change="showReUploadPreview($event)" />
                    </div>
                    <div id="divRetakePreview" style="display: none">
                        <canvas id="canvasReUploadPicturePreview" style="width: 500px; height: 350px"></canvas>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-green" id="btnSaveRetakePicture" @click="saveReTakenPicture">Save</button>
                </div>
            </div>
        </div>
    </div>

    <div ref="modalPhotoUpload" class="modal fade" role="dialog" style="display: none;">
        <div class="modal-dialog" style="width:90%;">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="btn btn-green" style="float:right;" @click="closePhotoUpload">Close</button>
                </div>
                <div class="modal-body">
                    <photoupload :reupload="true" @close-modal="closePhotoUpload"></photoupload>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" ref="modalSignerTagsPlaced" role="dialog" id="incomingCallModal">
        <div class="modal-dialog">
            <div class="modal-content" style="padding: 20px;">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <div class="modal-header">
                    <h4 class="modal-title">Waiting Signers Queue</h4>
                </div>
                <div class="modal-body">
                    <div class="row text-center">
                        <div class="col-md-6">
                            <!--<img src="~/images/icon-recyle.png" class="icon-recyle" alt="">-->
                            <h3>Signer Tag Placed </h3>
                            <h3 style="font-weight: 300">Estimating wait time</h3>
                            <p> <small> A tag has been placed. {{tag1}} {{tag2}} </small></p>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button data-bb-handler="danger" type="button" class="btn btn-secondary" @click="cancelAlert">Cancel</button>
                </div>
            </div>
        </div>
    </div>

    <div style="vertical-align:bottom; display:none;">
        <k-button class="k-button k-primary" @click="showRetakePictureModal()" ref="button">
            Check refreshImageForNotary
        </k-button>
    </div>

</template>

<script>
    import ApiService from "@/core/services/api-service";
    import PDFForm from "@/core/services/pdf-form";
    import { PanelBar } from '@progress/kendo-layout-vue-wrapper';
    import { loadFileUrl } from "@/core/services/api-service";
    import Checklist from "@/components/Checklist.vue";
    import GuidedArrow from "@/components/GuidedArrow.vue";
    import { Button } from "@progress/kendo-vue-buttons";
    import { Drawer, DrawerContent, DrawerItem } from "@progress/kendo-vue-layout";
    import {
        Card,
        CardHeader,
        CardBody,
        CardTitle,
        CardSubtitle,
        CardActions,
    } from "@progress/kendo-vue-layout";
    import moment from "moment";
    import { PDFDocument, StandardFonts, rgb, degrees } from 'pdf-lib'
    import Swal from "sweetalert2";
    import CompletedSection from "@/components/CompletedDocuments/DocumentGrid"
    import CompletedDocumentView from "@/components/CompletedDocuments/DocumentView"
    import PrintAndUpload from "@/components/UploadedDocuments/PrintAndUpload"
    import GridDataHelper from "@/core/services/grid-data-helper";
    import OT from '@opentok/client';
    import Webcam from 'webcam-easy';
    import { startConnection } from '@/video.js';
    import VideoSessionVue from "../../components/VideoSession.vue";
    import ScreenShare from "../../components/ShareScreenSession.vue";
    import SmartTagManager from "@/core/services/smart-tag-manager";
    import PhotoUpload from "../../components/forms/invitation-steps/verify/PhotoUpload.vue";
    import CookieService from "@/core/services/cookie-service";
    import IntroCountdown from "@/components/SigningRoom/IntroCountdown";
    import Waiting from "../../components/WaitingParticipants.vue";
    import ContentLoaderOverlay from "@/components/ContentLoaderOverlay.vue";

    const MINIMUM_DOCUMENTS_BEFORE_SIGNING = 1;
    let IS_LAZY_LOADING = false;
    let NAVIGATED_WHILE_LAZY_LOADING = false;
    let PROCESSED_TAGS = [];


    let PIXEL_RATIO = window.devicePixelRatio || window.screen.availWidth / document.documentElement.clientWidth;


    const DOC_TYPE = {
        SIGN: "Review_And_Sign",
        REVIEW: "Review_Only"
    };
    const DOC_PARTICIPATION_STATUS = {
        PENDING_REVIEW: "Pending_Review",
        PENDING_SIGNING: "Pending_Signing",
        REVIEWED: "Reviewed",
        SIGNED: "Signed",
        UPLOADED: "Uploaded",
        REVIEW_SKIPPED: "Review_Skipped"
    };

    const SKIPPABLE_TAGS = [
        "CheckBox",
        "FreeForm",
        "Seal",
        "Date",
        "FullName",
        "LastName",
        "FirstName",
        "DateOfBirth",
        "Email",
        "Address",
        "ZipCode",
        "Whiteout",
        "OptionGroup",
        "OptionGroupValue",
    ];

    let SIGNING_ROOM_RENDER_SIZE = 0;
    let SIGNING_ROOM_THUMBNAIL_RENDER_SIZE = 0;
    const UPLOADED = "Uploaded";

    const COMPLETED_DOCUMENTS_STATUS = [DOC_PARTICIPATION_STATUS.REVIEWED, DOC_PARTICIPATION_STATUS.SIGNED, DOC_PARTICIPATION_STATUS.REVIEW_SKIPPED]
    const INCOMPLETE_DOCUMENTS_STATUS = [DOC_PARTICIPATION_STATUS.PENDING_REVIEW, DOC_PARTICIPATION_STATUS.PENDING_SIGNING]

    const CHECKBOX_SKIPPED_VALUE = "skipped";
    const CHECKBOX_CHECKED_VALUE = "checked";

    const REPLACE_TAG = (tag, replacementT) => {
        var replacementTag = replacementT || 'div';

        var outer = tag.outerHTML;


        // Replace opening tag
        var regex = new RegExp('<' + tag.tagName, 'i');
        var newTag = outer.replace(regex, '<' + replacementTag);

        // Replace closing tag
        regex = new RegExp('</' + tag.tagName, 'i');
        newTag = newTag.replace(regex, '</' + replacementTag);

        $(tag).text("Signature");
        $(tag).replaceWith(newTag);

        return $(newTag)[0].id;
    }

    let OFFSET_OF_LAST_PAGE = 0;
    const ALREADY_REVIEWED_IDS = [];
    let SHOW_I_ATTEST = true;
    let IS_SCROLLING = false;
    let IS_CURRENTLY_SIGNING = false;


    // last document reviewed page count - don't show i attest until last document has been scrolled to i attest
    let LAST_DOCUMENT_TO_REVIEW = {
        file: null,
        ReviewedPageCount: 0,
        NumPages: 0,
        // flag  to determine if this is the final document thats needed to review (All pages) before i attest can show. adds a delay of 3 seconds when fully reviewed if criteria met for i attest
        WaitingOnDocumentToClose: false
    };

    export default {
        name: "SignerRoomSA",
        emits: ["imageBlob"],
        //emits: ["completed"], - emit function called completed and computed called completed. conflicts.
        components: {
            Checklist,
            'kendo-panelbar': PanelBar,
            GuidedArrow,
            Drawer,
            DrawerItem,
            DrawerContent,
            "kbutton": Button,
            card: Card,
            cardHeader: CardHeader,
            cardBody: CardBody,
            cardTitle: CardTitle,
            cardSubtitle: CardSubtitle,
            cardActions: CardActions,
            completedSection: CompletedSection,
            completedDocumentView: CompletedDocumentView,
            printAndUploadView: PrintAndUpload,
            videosession: VideoSessionVue,
            screenshare: ScreenShare,
            photoupload: PhotoUpload,
            IntroCountdown,
            waiting: Waiting,
            ContentLoaderOverlay: ContentLoaderOverlay,
        },
        data() {
            return {
                // REWORK VARIABLES
                shouldForceClose: false,
                loaderFileCount: 0,


                mainBorrower: false,

                isReadMode: false,
                retakeVideoPreview: null,
                ImageBinary: null,
                canvas: document.getElementById("canvasReUploadPicturePreview"),
                videoElement: document.getElementById('retakeVideoPreview'),
                videoSelect: document.querySelector('select#videoSource'),
                pictureTaken: false,
                hideVideo: false,
                ReadyToSign: false,
                SignReady: false,
                signatureType: "font", // Canvas / Font / Image
                signatureText: "Insert Signature",
                freeFormText: "",
                dateFieldText: "",
                activeSignatureFontFamily: "",
                signatureCanvasContext: null,
                signatureImageBase64: "",
                initialsImageBase64: "",
                files: [],
                activeDocIndex: 0,
                activePageIndex: 0,
                apiUrl: loadFileUrl,
                tagsPerPage: [],
                activeSmartTagIndex: 0,
                signatureTypeId: 1,
                activeXmlTagId: "",
                signer: {},
                showEnotes: false,
                activeDrawerIdx: 1,
                currentScrollDocName: "",
                currentDocType: "",
                fonts: [
                    { fontFam: "Allura" },
                    { fontFam: "Dancing Script" },
                    { fontFam: "Sacramento" },
                    { fontFam: "Mansalva" },
                    { fontFam: "Satisfy" },
                    { fontFam: "Vibes" },
                ],
                documentId: 0,

                checklist: [],
                numOfLoadedThumbnailPreviews: 0,
                isScrolling: false,

                signingTypes: {
                    Draw: true,
                    Type: true,
                    Upload: true,
                },
                typeErrorList: [],
                // new items for signeer room rework
                settings: {
                    isContinuousScroll: true,
                    signingDate: new Date("2/22/2022")
                },
                disableComplete: true,
                showReviewContinueButton: false,
                pdfForms: [],
                currentControlIndex: -1,
                currentFormIndex: 0,
                currentPage: 1,
                initialLoad: true,
                renderJumpArrow: false,
                moveJumpArrowToNextPage: false,
                setControlIndexForDrawSignature: 0,
                needsToGoToNextForm: false,
                currentTagInView: false,
                allTagsCurrentlyAvailableToSign: [],
                scrollTagDirection: "bottom",


                checkboxesToSignOnPDFAfterIAttest: [],

                lastKnownVisibleForm: '',

                // helps in detecting if user is scroll up or down - NOT USED RIGHT NOW, SOME OF THIS MAY BE REMOVED
                lastScrollTop: 0,
                programmaticScroll: false,
                waitForUserScroll: false,
                programmaticScrollToNextPage: false,

                lineColor: "pink",
                // kendo Drawer
                downClass: "k-icon k-i-arrow-chevron-down",
                rightClass: "k-icon k-i-arrow-chevron-right",
                items: [
                    {
                        text: "Ready for Review",
                        icon: "k-i-eye",
                        ["data-expanded"]: this.isMobile() ? false : true,
                        id: 1,
                        selected: true,
                    },
                    {
                        separator: true,
                    },
                    {
                        text: "Ready for Review and Sign",
                        ["data-expanded"]: this.isMobile() ? false : true,
                        icon: "k-i-pencil",
                        id: 2,
                    },
                    {
                        separator: true,
                    },
                    {
                        text: "Print and Upload",
                        icon: "k-i-upload",
                        ["data-expanded"]: this.isMobile() ? false : true,
                        id: 4,
                    },
                    {
                        separator: true,
                    },
                    {
                        text: "Completed",
                        icon: "k-i-check-outline",
                        ["data-expanded"]: this.isMobile() ? false : true,
                        id: 3,
                    },
                ],
                expanded: this.isMobile() ? false : true,
                selectedId: 0,
                position: "start",
                mode: "push",
                currentElement: {
                    documentId: 0,
                    pageNo: 0
                },
                tag1: 0,
                tag2: 0,
                NotarizeID: 0,
                enableSigningByNotary: this.$store.getters["Invitation/isSigningEnabled"] || false,
                lenderSettings: [],
                //timer for waiting modal
                waitSeconds: 120,
                interval: null,
                fastSignFont: null,
                isRon: this.$store.getters["Invitation/isRon"] || false,
                isExpressSigning: false,
                showCompleteSigning: false,
                allTagsSigned: false,
                loadPdfs: true,
                iAttestText: "",
                isSpecialClient: false,
            };
        },
        async created() {


            window.addEventListener('message', function (value) {
                console.log("Prince value from iframe ", value)
                $("iframe").each((idx, iframe) => {
                    iframe.style.height = value.data + 100 + "px"; // (iframe.contentWindow.document.documentElement.scrollHeight + 100) + 'px';
                });
            }, false);
            this.lastScrollTop = $(".signer-room-form-containter").scrollTop();

            await this.fetchDocuments();

            await startConnection(this.callbackFunct);

            this.signerData = this.$store.getters["Auth/signerSessionInformation"];
            this.NotarizeID = this.signerData["notarizeId"];


            var settings = await ApiService.GenerateGetRequest("Admin/LenderSettings");
            if (settings != null && settings.isSuccess) {
                this.lenderSettings = settings.content;
            }

            var req = await ApiService.GeneratePostRequest("Admin/isSpecialClient/" + this.$store.getters["Auth/clientId"]);
            if (req.isSuccess)
                this.isSpecialClient = req.content;   

            //Determine if the signer is the main borrower
            var mainBorrowerResponse = await ApiService.GenerateGetRequest(`signer/getmainborrower?packageId=` + this.signer.loanPackageId);
            var isMain = false;
            var isWaiting = true;
            if (!this.isRon)
                this.mainBorrower = true;
            if (mainBorrowerResponse.content.participantMappingId == this.signerData.signerId && this.isRon) {
                this.mainBorrower = true;
                // this now happens in = allDocumentsLoaded()
                //document.getElementById("overlay").style.display = "none";
                //isMain = true;
                //isWaiting = false;

                // only want to call this for main borrower sine they can enter immediately. but for secondaries - waiting until documents loaded before
                // signal to updating waiting status.
                await this.canBorrowerSign();
                await ApiService.GeneratePostRequest('signer/updateWaiting/' + this.NotarizeID + "/" + isMain + "/" + isWaiting);

            }

            this.iAttestText = this.signer.participantType == "Legal_Witness" ? 'I attest that I have personally witnessed the electronic signing of these document by the named borrower(s).' : 'I attest that by my signature, I acknowledge that I have read, understand, and agree to the terms and conditions outlined.';// Todo move to config or something

        },
        beforeRouteLeave(to, from, next) {
            if (this.confirmLeavingWithoutAttesting() && to.path != "/") {
                next(false)
                window.location = to.path // this is the trick
            } else {
                window.removeEventListener('beforeunload', this.beforeWindowUnload);
                next()
            }
        },
        mounted: async function () {
            document.getElementById("overlay").style.display = "block";
            this.$nextTick(() => {
                // signatture
                let signatureCanvas = this.$refs.signatureCanvas;

                let currentMousePosition = {
                    x: 0,
                    y: 0,
                };

                //previous mouse position
                let previousMousePosition = {
                    x: 0,
                    y: 0,
                };

                //make a 2D context
                this.signatureCanvasContext = signatureCanvas.getContext("2d");

                //set the line parameters
                this.signatureCanvasContext.lineWidth = 3;
                this.signatureCanvasContext.lineJoin = "round";
                this.signatureCanvasContext.lineCap = "round";
                this.signatureCanvasContext.strokeStyle = this.lineColor;

                //capture mouse moves
                this.$refs.signatureCanvas.addEventListener("mousemove", function (e) {
                    //store the old current mouse position and the previous mouse position
                    previousMousePosition.x = currentMousePosition.x;
                    previousMousePosition.y = currentMousePosition.y;

                    var rect = signatureCanvas.getBoundingClientRect();
                    //get the new mouse position
                    currentMousePosition.x = e.clientX - rect.left;
                    currentMousePosition.y = e.clientY - rect.top;
                });

                //mouse down
                signatureCanvas.addEventListener("mousedown", function (e) {
                    //add an additional listener to draw
                    signatureCanvas.addEventListener("mousemove", onPaint);
                });

                //mouse up
                signatureCanvas.addEventListener("mouseup", function () {
                    //remove the additional mouse move listener
                    signatureCanvas.removeEventListener("mousemove", onPaint);
                });

                //an additional mouse listener to paint part of a line
                var onPaint = () => {
                    //draw the line
                    this.signatureCanvasContext.beginPath();
                    this.signatureCanvasContext.moveTo(
                        previousMousePosition.x,
                        previousMousePosition.y
                    );
                    this.signatureCanvasContext.lineTo(
                        currentMousePosition.x,
                        currentMousePosition.y
                    );
                    this.signatureCanvasContext.strokeStyle = this.lineColor;
                    this.signatureCanvasContext.closePath();
                    this.signatureCanvasContext.stroke();
                };
            });
        },
        computed: {
            completed: function () {
                return this.files.filter((f) => f.documentType != UPLOADED && (COMPLETED_DOCUMENTS_STATUS.includes(f.participantStatus) || (f.participantStatus == DOC_PARTICIPATION_STATUS.PENDING_SIGNING && f.documentType == DOC_TYPE.REVIEW)));
            },
            toreview: function () {
                return this.files.filter((f) => f.documentType != UPLOADED && ((f.participantStatus == DOC_PARTICIPATION_STATUS.PENDING_REVIEW && f.documentType == DOC_TYPE.REVIEW) || (f.participantStatus == DOC_PARTICIPATION_STATUS.PENDING_REVIEW && f.documentType == DOC_TYPE.SIGN && f.smartTags.filter((x) => x.signerId == f.signerId).length == 0)));
            },
            tosign: function () {
                return this.files.filter((f) => f.wetSigned == false && f.documentType != UPLOADED && f.smartTags.filter((x) => x.signerId == f.signerId).length > 0 && DOC_TYPE.SIGN == f.documentType && (f.participantStatus == DOC_PARTICIPATION_STATUS.PENDING_REVIEW || f.participantStatus == DOC_PARTICIPATION_STATUS.PENDING_SIGNING));
            },
            toupload: function () {
                return this.signer.isNotaryAttached ? [] : this.files.filter((f) => f.wetSigned == true && f.documentType != UPLOADED).map((item) => { return { ...item, selected: false } });
            },
            assetsPath: function (path) {
                return path;
            },
            pdfForm() {
                if (this.currentFormIndex >= 0) {
                    return this.pdfForms.length > 0 ? this.pdfForms[this.currentFormIndex] : {};
                } else if (this.pdfForms.length > 0) {
                    return this.pdfForms[this.pdfForms.length - 1]
                }
                else {
                    return {};
                }
            },
            isSignable() {
                if (this.isPresign) {
                    return ((this.signer.isRon && this.enableSigningByNotary) || !this.signer.isRon || this.isPresign) && this.signer.isDateSignable && this.signer.isWitness == false;
                }
                return ((this.signer.isRon && this.enableSigningByNotary) || !this.signer.isRon) && this.signer.isDateSignable && this.signer.isWitness == false;
            },
            isSigningDate() {
                return this.signer.isDateSignable
            },
            isPresign() {
                return this.signer.isPresign
            },
            filesToSign() {
                if (this.files.length > 0 && this.files[0].isEnote) {
                    this.showEnotes = true;
                    $(this.$refs.modalENoteConsent).modal("show");
                }
                return this.files.filter((f) => f.isEnote === this.showEnotes);
            },
            allDocumentsLoadedInUI() {
                let allLoaded = true;
                for (let j = 0; j < this.files.length; j++) {
                    if (this.files[j].isLoadedInUI === false) {
                        allLoaded = false;
                        break;
                    }
                }
                return allLoaded;
            },
            dataItems() {

                const newItems = this.items.map((item) => {
                    const { parentId, ...others } = item;
                    if (parentId !== undefined) {
                        const parent = this.items.find((parent) => parent.id === parentId);
                        return { ...others, visible: parent["data-expanded"] };
                    }
                    return item;
                });
                return newItems;
            },
            isCurrentTagOptional() {
                let index = this.findNextAvailableTagIndex();
                if (!this.allTagsCurrentlyAvailableToSign[index])
                    return true;
                let smartTag = this.allTagsCurrentlyAvailableToSign[index]["smartTag"];
                let tagName = smartTag["tagType"];
                return smartTag.isOptional;
            },
            jumpArrowDirection() {
                let index = this.findNextAvailableTagIndex();
                let val = "left";
                if (index < 0)
                    return "bottom";
                if (!this.currentTagInView && this.allTagsCurrentlyAvailableToSign[index].value === '') {
                    val = "bottom";
                }

                if (this.currentTagInView === false)
                    val = this.scrollTagDirection;

                return val;








                // SGN-1193 requirements. Leaving code below in case they change their minds.
                //let index = this.findNextAvailableTagIndex();
                //let val = index < 0 ? 'bottom' : this.currentTagInView && this.allTagsCurrentlyAvailableToSign[index].value === '' ? 'left' : 'bottom';

                //if (this.currentTagInView === false) {
                //    val = this.scrollTagDirection;

                //} else if (this.currentTagInView) {
                //    val = "left";
                //}


                //if (this.isCurrentTagOptional) {
                //    console.log("Tag is skippable ");
                //    val = "bottom";
                //}

                //return val;
            },
            jumpArrowText() {
                let index = this.findNextAvailableTagIndex();

                if (this.findNextAvailableTagIndex() < 0 && !this.allDocumentsLoadedInUI)
                    return "Loading Documents";

                if (index < 0)
                    return 'NEXT';
                let val = "";

                //this.currentTagInView && this.allTagsCurrentlyAvailableToSign[index].value === '' ? 'SIGN HERE' : 'NEXT/SKIP'

                if (this.currentTagInView && this.allTagsCurrentlyAvailableToSign[index].value === '' && this.isCurrentTagOptional) {
                    if (this.isMobile())
                        val = "Skip (OPT)"
                    else
                        val = "SKIP (OPTIONAL)";
                } else if (this.currentTagInView && this.allTagsCurrentlyAvailableToSign[index].value === '' && !this.isCurrentTagOptional) {
                    val = "SIGN HERE";
                } else if (!this.currentTagInView && this.allTagsCurrentlyAvailableToSign[index].value === '') {
                    val = "NEXT";
                }

                if (this.initialLoad) {
                    val = "Start Signing";
                }


                //if (this.isCurrentTagOptional) {
                //    console.log("Tag is skippable ");
                //    val = "NEXT/SKIP";
                //}
                return val;
            },
            jumpArrowTopPosition() {
                let index = this.findNextAvailableTagIndex();
                let val = this.allTagsCurrentlyAvailableToSign[index] ? this.allTagsCurrentlyAvailableToSign[index].y - 8 : 0;

                if (index < 0)
                    return 0;

                if (!this.currentTagInView)
                    val = this.allTagsCurrentlyAvailableToSign[0].y - 8;


                if (this.currentTagInView)
                    val = this.allTagsCurrentlyAvailableToSign[index].y - 8;

                let tag = this.allTagsCurrentlyAvailableToSign[index];

                if (this.isTagOptional(tag.smartTag)) {
                    let isIntersecting = this.isGuidedArrowIntersecting('ga-' + tag.pdfName + '-' + tag.noSpaceIdAlias);
                    if (isIntersecting)
                        val = this.allTagsCurrentlyAvailableToSign[index].y + 40;
                }


                return val;
            },
            teleportLocationForJumpArrow() {
                let loc = '';
                try {
                    loc = '.signer-room-form-containter #' + this.pdfForms[this.currentFormIndex].pdfName + '-Page_' + 1;

                } catch (e) {
                    return '.guided-arrow-container';
                }

                let index = this.findNextAvailableTagIndex();
                if (this.currentTagInView && index >= 0)
                    loc = '.signer-room-form-containter #' + this.allTagsCurrentlyAvailableToSign[index].pdfName + '-Page_' + this.allTagsCurrentlyAvailableToSign[index].belongsToPage;

                console.log("Teleport Jump Arrow Location: ", this.allTagsCurrentlyAvailableToSign, this.allTagsCurrentlyAvailableToSign[index], loc)
                if (!this.currentTagInView)
                    loc = '.guided-arrow-container';

                if (index < 0 && !this.allDocumentsLoadedInUI)
                    loc = '.guided-arrow-container';
                console.log("Teleport Jump Arrow Location: ", this.allTagsCurrentlyAvailableToSign, this.allTagsCurrentlyAvailableToSign[index], loc)

                return loc;
            },
            canDisplayTagNav() {
                return this.activeDrawerIdx == 3 || this.activeDrawerIdx == 4
            },
        },
        methods: {
            async logout() {
                let response = await ApiService.GeneratePostRequest("account/logout");
                if (response.isSuccess) {
                    await ApiService.GeneratePostRequest(`verify/logExit`);
                    this.$store.commit("Auth/signOut");
                    this.$store.commit("Admin/logout");
                    this.$store.commit("App/updateToken", null);
                    this.$router.push('/admin/ThankYou');
                }
            },
            confirmLeavingWithoutAttesting() {
                return this.signer && !this.signer.attested && this.activeDrawerIdx != 3 && this.activeDrawerIdx != 4 && !this.shouldForceClose;
            },
            beforeWindowUnload(e) {
                if (this.confirmLeavingWithoutAttesting()) {
                    e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
                    // // Chrome requires returnValue to be set
                    e.returnValue = '';
                }
            },
            toggleTagVisibility() {
                this.isReadMode = !this.isReadMode;
            },
            async fetchDocuments() {

                const signerResponse = await ApiService.GenerateGetRequest("signer/details");
                this.signer = signerResponse["content"];
                window.addEventListener('beforeunload', this.beforeWindowUnload);
                if (this.signer.isWitness || this.signer.participantType == "Legal_Witness") {
                    this.ReadyToSign = true;
                }


                let response = await ApiService.GenerateGetRequest("document/signer");
                if (response && response["isSuccess"]) {
                    let files = response["content"].map((file) => {
                        if (file.participantStatus != DOC_PARTICIPATION_STATUS.PENDING_REVIEW) {
                            ALREADY_REVIEWED_IDS.push(file.documentID);
                        }

                        let signerId = this.signerData ? this.signerData.signerId : "";
                        var smartTags = file.smartTags.map((item) => {
                            let obj = { ...item, controlIdAlias: item.controlId.replaceAll(".`0", ""), isAdmin: this.signer.id !== item.signerId, isEnote: file.isEnote, isWitness: this.signer.id !== item.signerId };
                            return obj;
                        });
                        //smartTags = smartTags.filter(x => x.tagType !== "AutomaticSignatureDate");
                        //var watermark = "";
                        //if (file.wetSigned) {
                        //    watermark = this.lenderSettings.wetSignWatermark;
                        //}
                        //if (file.status == "Notarized") {
                        //    watermark = this.lenderSettings.notarizeWatermark;
                        //}
                        //if (file.isEnote) {
                        //    watermark = this.lenderSettings.eSignDocWatermark;
                        //}

                        return {
                            documentID: file.documentID,
                            documentId: file.documentID,
                            //path: ApiService.EnvironmentURL() + "document/load?path=" + file.docURL + "&watermark=" + watermark,
                            path: ApiService.EnvironmentURL() + "document/load-doc?document=" + file.documentID + "&type="+ file.renderType +"&isSigning=true",
                            docURL: file.renderType,
                            name: file.description,
                            description: file.description,
                            noSpaceName: "_" + file.documentName.replaceAll(" ", "").replaceAll(/[^\w\s]/gi, '_'),
                            pages: file.isEnote ? 1 : file.noOfPages,
                            html: file.html,
                            documentStatus: file.status,
                            isEnote: file.isEnote,
                            signerId: file.signerId,
                            wetSigned: file.wetSigned,
                            documentType: file.documentType, //"Signing" => "Review_And_Sign", "Reviewing" => "Review_Only",
                            participantStatus: file.participantStatus,
                            smartTags: smartTags.filter((item) => item.tagType !== 'Whiteout'),// we don't need whiteout on front end
                            fieldLinks: [],
                            availableForPresign: file.availableForPresign,
                            isLoadedInUI: false,
                            tagsProcessed: false,
                        }
                    });
                    this.files = files;

                    const wetSignDocs = this.files.filter((f) => (f.wetSigned == true || f.documentType == UPLOADED));
                    const reviewSignDocs = this.files.filter((f) => f.wetSigned == false && f.documentType != UPLOADED);
                    const unreviewedWetDocs = this.files.filter((f) => f.participantStatus === DOC_PARTICIPATION_STATUS.PENDING_REVIEW);

                    this.currentScrollDocName = this.files[0].name;
                    this.currentDocType = this.files[0].documentType;

                    var idx = 4;
                    this.items = [];
                    this.items = [{
                        text: `Ready for Review (${this.toreview.length})`,
                        icon: "k-i-eye",
                        ["data-expanded"]: this.isMobile() ? false : true,
                        docId: 0,
                        id: 1,
                        disabled: this.toreview.length == 0
                    }];
                    this.items.push(...this.toreview.map((item) => {
                        idx++;
                        return {
                            text: item.name,
                            nospacename: item.noSpaceName,
                            icon: "k-i-minus",
                            id: idx,
                            parentId: 1,
                            parent: 1,
                            docId: item.documentID,

                        };
                    }));
                    idx++;
                    this.items.push(...[{
                        separator: true,
                    },
                    {
                        text: `Ready for Review and Sign (${this.tosign.length})`,
                        ["data-expanded"]: this.isMobile() ? false : true,
                        icon: "k-i-pencil",
                        id: 2,
                        docId: 0,
                        disabled: this.tosign.length == 0
                    }]);
                    this.items.push(...this.tosign.map((item) => {
                        idx++;
                        return {
                            text: item.name,
                            nospacename: item.noSpaceName,
                            icon: "k-i-minus",
                            id: idx,
                            parentId: 2,
                            parent: 2,
                            docId: item.documentID,
                            data: {
                                path: "#",
                            },
                        };
                    }));
                    idx++;
                    this.items.push(...[{
                        separator: true,
                    },
                    {
                        text: `Print and Upload (${this.toupload.length})`,
                        icon: "k-i-upload",
                        ["data-expanded"]: this.isMobile() ? false : true,
                        id: 4,
                        docId: 0,
                        disabled: this.signer.isNotaryAttached
                    }]);
                    this.items.push(...this.toupload.map((item) => {
                        idx++;
                        return {
                            text: item.name,
                            nospacename: item.noSpaceName,
                            icon: "k-i-minus",
                            id: idx,
                            parentId: 4,
                            parent: 4,
                            docId: item.documentID,
                            data: {
                                path: "#",
                            },
                        };
                    }));
                    idx++;
                    this.items.push(...[{
                        separator: true,
                    },
                    {
                        text: `Completed (${this.completed.length})`,
                        icon: "k-i-check-outline",
                        ["data-expanded"]: this.isMobile() ? false : true,
                        id: 3,
                        docId: 0,
                        disabled: false
                    }]);
                    this.items.push(...this.completed.map((item) => {
                        idx++;
                        return {
                            text: item.name,
                            nospacename: item.noSpaceName,
                            icon: "k-i-minus",
                            id: idx,
                            parentId: 3,
                            parent: 3,
                            docId: item.documentID,
                            data: {
                                path: "#",
                            },
                        };
                    }));

                    //default to completed section if all esignable documents are completed
                    if ((reviewSignDocs.length == this.completed.length && reviewSignDocs.length > 0) || (this.signer.signingType === "WET_SIGN" && this.signer.isNotaryAttached && unreviewedWetDocs.length == 0)) {
                        if (this.signer.isWitness || this.signer.participantType == "Legal_Witness") {
                            //kick out everyone
                            await ApiService.GeneratePostRequest('signer/closeConnection/' + this.NotarizeID);
                            await this.logout();
                        }
                        if (this.signer.attested) {
                            this.documentId = 0;
                            this.activeDrawerIdx = 3
                            this.loadPdfs = false;
                        } else {
                            this.showCompleteSigning = true;
                        }


                    }
                    //default to print and upload section if no esign documents
                    if (wetSignDocs.length === this.files.length && wetSignDocs.length > 1 && this.signer.signingType === "WET_SIGN" && !this.signer.isNotaryAttached && unreviewedWetDocs.length == 0) {
                        if (this.signer.attested) {
                            this.documentId = 0;
                            this.activeDrawerIdx = 4
                            this.loadPdfs = false;
                        } else {
                            this.showCompleteSigning = true;
                        }
                    }

                    const signerResponse = await ApiService.GenerateGetRequest("signer/details");
                    this.signer = signerResponse["content"];

                    if (this.signer.isWitness || this.signer.participantType == "Legal_Witness") {
                        this.ReadyToSign = true;
                    }
                    //set lender settings - font color and signing types
                    let lenderid = this.$store.state.App.lenderId;
                    ApiService.GenerateGetRequest(`signer/lenderSettings?lenderid=${lenderid}`).then(
                        response => {
                            this.lineColor = response.content.fontColor;
                            this.signingTypes.Draw = this.isMobile() ? false : response.content.drawSignature;
                            this.signingTypes.Type = response.content.typeSignature;
                            this.signingTypes.Upload = this.isMobile() ? false : response.content.uploadSignature;
                            this.fastSignFont = response.content.fastSignFont;
                            this.activeSignatureFontFamily = response.content.fastSignFont;
                            this.isExpressSigning = response.content.isExpressSigning != null ? response.content.isExpressSigning && this.signer.participantType == "Signer" : false;
                            for (const [key, value] of Object.entries(this.signingTypes)) {
                                if (value == true) {
                                    $('#' + key).addClass("active in")
                                    break;
                                }
                            }
                        }
                    );



                    if (this.loadPdfs) {
                        // SET UP PDF UI
                        let self = this;
                        this.$nextTick(() => {
                            // let documents load before user interaction
                            //this.$store.commit("App/updateLoaderOverride", {
                            //    showLoaderOverride: true,
                            //});
                            if (this.settings.isContinuousScroll) {
                                $(".signer-room-form-containter > div, .thumbnail-container > div").html("");

                                let filesToInitiallyLoad = this.files;
                                if (!this.isRon) {
                                    filesToInitiallyLoad = [];
                                    for (let i = 0; i < self.files.length; i++) {
                                        if (i < MINIMUM_DOCUMENTS_BEFORE_SIGNING) {
                                            filesToInitiallyLoad.push(self.files[i]);
                                            // since the borrower can only sign after the initial set of documents has been loaded
                                            // we can set them as loaded
                                            self.files[i].isLoadedInUI = true;

                                        }
                                    }
                                }

                                // let files = this.isSigningDate ? self.files : self.files.filter((file) => !file.isEnote);
                                self.loaderFileCount = filesToInitiallyLoad;
                                let files = filesToInitiallyLoad; // JSON.parse(JSON.stringify(filesToInitiallyLoad));
                                let width = $('.signer-room-form-containter').width() * 0.85;
                                if (files && files.length > 0 && files[0].isEnote) {
                                    // if first item is enote we need to add a dummy node to the UI. eNote rendeirng code copies the skeleton and injects the eNote iframe
                                    $('.signer-room-form-containter > div').append(`<div style="width: ${width}px;" data-page="1" class="DUMMYDOCUMENT" data-pagename="DUMMYDOCUMENT"></div>`);
                                }
                                // loading multiple pdfs
                                var pdfForm = new PDFForm();
                                let thumbWidth = $('.thumbnail-container').width();
                                if (thumbWidth < 70)
                                    thumbWidth *= 0.50;// smalls screen devices
                                else
                                    thumbWidth *= 0.75;

                                SIGNING_ROOM_RENDER_SIZE = width;
                                SIGNING_ROOM_THUMBNAIL_RENDER_SIZE = thumbWidth;

                                let eNotesToLoad = [];
                                this.$refs.waitingPage.updateNextDocName(files[0].name);
                                pdfForm.loadMulitplePDFs(this.signer.id, this.signer.id, files, 0, '.signer-room-form-containter > div', width, false, true, false, (page, currentObject, index) => {
                                    // after main view loaded, load previews cause we need access to the form controls from the loaded pdf form
                                    if (page === currentObject.pages) {// only execite when last page was rendered - for current processing document

                                        if (!currentObject.isEnote) {
                                            if (!this.isMobile()) {
                                                var previewForm = new PDFForm();
                                                previewForm.loadPdf('.thumbnail-container > div', currentObject.noSpaceName, currentObject.path, thumbWidth, false, false, (pageNum) => {
                                                    // post render after last page was called
                                                    if (pageNum === previewForm.numpages) {
                                                        this.$refs.waitingPage.updateNextDocCount();
                                                        let name = files[index + 1] ? files[index + 1].name : "";
                                                        this.$refs.waitingPage.updateNextDocName(name);
                                                    }
                                                });
                                            } else {
                                                this.$refs.waitingPage.updateNextDocCount();
                                                let name = files[index + 1] ? files[index + 1].name : "";
                                                this.$refs.waitingPage.updateNextDocName(name);
                                            }
                                        } else {
                                            eNotesToLoad.push(currentObject);
                                        }
                                    }


                                    // assign variables to SignerRoom object when all files loaded recursively
                                    if ((files.length - 1) === index && page === currentObject.pages) {
                                        // quick hack to have enote load last. when last pdf document is being rendered this code is called before the pdf is on the ui
                                        setTimeout(() => {
                                            var previewForm = new PDFForm();
                                            eNotesToLoad.forEach((note) => {
                                                this.$refs.waitingPage.updateNextDocName("eNote");
                                                this.$refs.waitingPage.updateNextDocCount();
                                                previewForm.loadEnoteThumbnail(note.noSpaceName, note.html, null, thumbWidth);
                                            });
                                            self.updatePreviewBorder();
                                        }, 1000)
                                        self.pdfForms = pdfForm.pdfForms;

                                        self.initiateContinuousScroll();
                                        self.updatePreviewBorder();
                                        for (let i = 0; i < filesToInitiallyLoad.length; i++) {
                                            for (let j = 0; j < self.files.length; j++) {
                                                if (filesToInitiallyLoad[i].documentId === self.files[j].documentId) {
                                                    self.files[j].isLoadedInUI = true;
                                                    console.log("RON count ", i, filesToInitiallyLoad.length - 1);
                                                }
                                            }
                                        }
                                        // if (self.completed.length !== self.files.length) {
                                        //     if (self.signer.sessionJson != null && self.signer.sessionJson != "") {
                                        //         try {
                                        //             var session = JSON.parse(self.signer.sessionJson);
                                        //             var doc = self.files.find(x => x.documentID == session.lastActionedDoc.id);
                                        //             this.currentElement.pageNo = session.lastActionedDoc.pageNo;
                                        //             this.currentElement.documentID = doc.documentID;
                                        //             self.scrollPageIntoViewCentered(`.signer-room-form-containter div[data-page="${parseInt(doc.pages)}"][data-pagename="${doc.noSpaceName}"]`);
                                        //             const Toast = Swal.mixin({
                                        //                 toast: true,
                                        //                 position: 'bottom-end',
                                        //                 showConfirmButton: false,
                                        //                 timer: 7000,
                                        //                 timerProgressBar: true,
                                        //                 didOpen: (toast) => {
                                        //                     toast.addEventListener('mouseenter', Swal.stopTimer)
                                        //                     toast.addEventListener('mouseleave', Swal.resumeTimer)
                                        //                 }
                                        //             })
                                        //             Toast.fire({
                                        //                 icon: 'success',
                                        //                 title: 'We have picked up where you left off'
                                        //             })
                                        //         } catch (error) {
                                        //             console.log(error)
                                        //         }

                                        //     }

                                        // }

                                    }

                                });

                            } else {
                                // opposite of continuous scroll goes here
                            }
                            return;

                        });

                    }


                    // LEAVING IN HERE FOR NOW AS WE DON"T HAVE PROPER ENOTES IN THE SYSTEM
                    // modify data from server to add extra properties we may need to determine whhen and where to wetsignb
                    // and show relevant documentsd
                    //for (let j = 0; j < this.files.length; j++) {
                    //    this.files[j];
                    //    if (j == 1) {
                    //        this.files[j].isEnote = true;
                    //    }
                    //    if (j == 0) {
                    //        this.files[j].isEnote = false;
                    //    }
                    //}
                }
            },
            async canBorrowerSign() {

                this.signerData = this.$store.getters["Auth/signerSessionInformation"];
                this.NotarizeID = this.signerData["notarizeId"];

                var response = await ApiService.GenerateGetRequest("package/canSign?notarizeId=" + this.NotarizeID);

                if (response.isSuccess) { this.SignReady = response.content; }
                else { this.SignReady = false; }
            },
            /*****************************************************
                         Functions for video/audio
             *****************************************************/
            imageUpdated(event) {
                const [file] = event.target.files;
                if (file) {
                    var reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        this.$emit("imageBlob", reader.result);
                    };
                }
            },
            retakePicture() {

                this.pictureTaken = false;
                this.hideVideo = true;
                const videoElement = document.getElementById('retakeVideoPreview');
                const canvasElement = document.getElementById('canvas');

                if (!this.retakeVideoPreview) {
                    this.retakeVideoPreview = new Webcam(videoElement, 'user', canvasElement);
                }
                this.retakeVideoPreview.start();

            },
            cancelVideoCapture() {
                this.pictureTaken = false;
                this.hideVideo = false;
                this.retakeVideoPreview.stop();
            },
            async showMeetingWaitModal() {

                //Determine if the signer is the main borrower, only trigger call if main borrower
                var mainBorrowerResponse = await ApiService.GenerateGetRequest(`signer/getmainborrower?packageId=` + this.signer.loanPackageId);
                if (mainBorrowerResponse.content.participantMappingId == this.signerData.signerId) {
                    $(this.$refs.modalMeetingWaitRef).modal("show");
                    //send call to notaries
                    ApiService.GeneratePostRequest(
                        `transaction/send/${"SignerInQueueAlert"}/${this.signerData["signerId"]}`);

                    this.startTimer();
                } else {
                    this.NotaryConnected();
                }

            },
            async callTimeout() {
                //callback if notary joins

                //else send email
                this.NotarizeID = this.signerData["notarizeId"];
                await ApiService.GeneratePostRequest(`notarize/NotaryMissedCall/${this.NotarizeID}/${this.signerData["firstName"] + " " + this.signerData["lastName"]}`);
                $(this.$refs.modalMeetingWaitRef).modal('hide');
                this.ReadyToSign = false;

                Swal.fire(
                    "Notary has not answered",
                    "Please try again.",
                    "error"
                );

            },
            async startTimer(seconds) {
                if (this.interval)
                    clearInterval(this.interval);
                let cookie = CookieService.Get("sst"); //cookie name qst
                if (cookie != "") {
                    CookieService.Delete("sst", this.waitSeconds);
                }
                //reset seconds
                this.waitSeconds = 120;
                CookieService.Create("sst", this.waitSeconds);

                this.interval = setInterval(() => {
                    this.waitSeconds -= 1;
                    CookieService.Update("sst", this.waitSeconds);
                    if (this.waitSeconds === 0) {
                        clearInterval(this.interval);
                        CookieService.Delete("sst", this.waitSeconds);
                        this.callTimeout();
                    }
                }, 1000);
            },
            CancelMeeting() {
                $(this.$refs.modalMeetingWaitRef).modal("hide");
                clearInterval(this.interval);
                CookieService.Delete("qst", this.waitSeconds);
                this.ReadyToSign = false;
            },

            showRetakePictureModal() {
                $(this.$refs.modalPhotoUpload).modal("show");
            },
            closePhotoUpload() {
                $(this.$refs.modalPhotoUpload).modal("hide");
            },
            //aync saveReTakenPicture() {

            //    console.log(this.ImageBinary);

            //    var token = "6fcf5fe8-2dfb-402f-9b62-3f45e4b9f8a6";

            //    if (true) {
            //        let res = await ApiService.GeneratePostRequest("Verification/reuploadpic", {
            //            imageData: this.ImageBinary,
            //            isFront: "true",
            //            signerIdentity: token
            //            //signerIdentity: $('#hdnRetakePictureSignerIdentity').val(),
            //        });
            //        if (res.success) {
            //            toggleModal('modalRetakePicture', false);
            //            videoHub.server.refreshImageForNotary(NotaryOfficerID, $('#hdnRetakePictureSignerIdentity').val(), res.imageURL, $('#hdnRetakePictureIsFront').val());
            //            $('#ImageBinary,#reuploadPictureUploader').val('');
            //        }
            //        else if (res.IsFront == "Empty") {
            //            //showMessage("Error", "Nothing to upload. Please try again!!");
            //            Swal.fire("Failed", "Nothing to upload. Please try again!!", "error");
            //            return false;
            //        }
            //        return false;
            //    }
            //    else {
            //        return false;
            //        //showMessage("Error", "Nothing to upload. Please try again!!");
            //        Swal.fire("Failed", "Nothing to upload. Please try again", "error");
            //    }
            //},
            async saveReTakenPicture() {
                var token = "6fcf5fe82dfb402f9b623f45e4b9f8a6";

                return await ApiService.GeneratePostRequest(
                    "verify/reuploadpic",
                    {
                        imageData: this.ImageBinary,
                        isFront: "true",
                        signerIdentity: token
                    }
                ).then((response) => {
                    if (response.success) {
                        Swal.fire("Changes Saved!", "", "success");
                        this.pictureTaken = false;
                        this.hideVideo = false;
                        $(this.$refs.modalRetakePicture).modal("hide");

                    } else { Swal.fire("Failed", response.message, "error"); }

                });

            },
            showReUploadPreview(event) {
                let input = $('#reuploadPictureUploader')[0];
                if (input.files && input.files[0]) {
                    if (input.files[0].size >= 10485760) //10mb
                    {
                        $('#ImageBinary,#reuploadPictureUploader').val('');
                        showMessage('Error', 'Please upload image less than 10mb!');
                        return;
                    }
                }
                const image = event.target.files[0];
                const reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onload = (event) => {
                    this.pictureTaken = true;
                    this.ImageBinary = event.target.result;
                };

            },
            btnCapturePic() {
                this.pictureTaken = true;
                const videoElement = document.getElementById('retakeVideoPreview');
                const canvasElement = document.getElementById('canvas');

                var context = canvasElement.getContext('2d');

                canvas.width = videoElement.offsetWidth;
                canvas.height = videoElement.offsetHeight;

                context.drawImage(videoElement, videoElement.offsetLeft, videoElement.offsetTop, 500, 350);

                var dataURL = canvasElement.toDataURL();
                dataURL = dataURL;

                this.ImageBinary = dataURL;
            },
            signerTagPlaced() {
                $(this.$refs.modalSignerTagsPlaced).modal("show");
            },
            async callbackFunct(type, data) {
                var self = this;
                switch (type) {
                    case "showWaitingModal":
                        this.showMeetingWaitModal();
                        break;
                    case "reuploadSignerID":
                        $(this.$refs.modalPhotoUpload).modal("show");
                        break;
                    case "loadAppliedTags":
                        console.log("signer signed a tag", data); //TODO use method below
                        if (data.tagSignerID == this.signer.id) {// skip for broadcaster
                            return;
                        }
                        let { tagDocumentID: tagID, imgUrl: value, timestamp } = data;
                        let signer = this.signer;
                        this.pdfForms.forEach((form) => {
                            form.allFormFields.forEach((field) => {
                                console.log(field.smartTag.id, " == ", tagID)
                                if (field.smartTag.id == tagID) {


                                    //handle received applied tag from any participant
                                    if (field.smartTag.tagType == "Signature" || field.smartTag.tagType == "Initials") {
                                        if (value.indexOf(self.apiUrl) < 0)
                                            value = self.apiUrl + value;

                                        this.setImageInPdfFromNotary(value, form.pdfName, field);

                                    }
                                    if (field.smartTag.tagType !== "AutomaticSignatureDate") {
                                        this.scrollItemIntoView(".signer-room-form-containter", `#ga-${form.pdfName}-${field.noSpaceIdAlias}`);
                                    }
                                    $(`#ga-${form.pdfName}-${field.noSpaceIdAlias}`).fadeOut('slow');
                                    this.updateControlData(field, value, false, true);
                                    this.insertTimeStamp(field, timestamp);
                                    this.checkAndEnableGuidedTags();
                                }
                            });
                        });
                        break;
                    case "enableSigning":
                        this.enableSigningByNotary = data;
                        this.renderJumpArrow = true;
                        this.checkAndEnableGuidedTags();
                        this.$store.commit("Invitation/enableSigning", data);
                        break;
                    case "dynamicSignerTag":
                        var { tag } = data;
                        var pdfPath = this.pdfForms.find((item) => item.documentId === tag.documentId).path;
                        var width = $(`.signer-room-form-containter div[data-page='${tag.smartTag.pageNo}'][data-pagename='${tag.pdfName}']`).width();
                        var height = $(`.signer-room-form-containter div[data-page='${tag.smartTag.pageNo}'][data-pagename='${tag.pdfName}']`).height();
                        var fullScale = await SmartTagManager.GetFullScale(pdfPath, width, height);
                        var scale = fullScale.width

                        console.log("dynamicSignerTag", data, fullScale);
                        var top = (tag.y * fullScale.height);// + ((parseFloat(tag.smartTag.controlHeight) / 2) * scale);
                        height = (tag.smartTag.tagType === "CheckBox" ? `height:30px;` : `height:${tag.smartTag.controlHeight * fullScale.height}px;`);
                        width = `width: ${tag.smartTag.controlWidth * scale}px;`;
                        if (tag.smartTag.tagType != "CheckBox" && tag.smartTag.tagType != "Signature" && tag.smartTag.tagType != "Initials") {
                            height = `height: ${tag.smartTag.controlHeight * fullScale.height}px;`;
                            width = `width: ${tag.smartTag.controlWidth * fullScale.width}px;`;
                        } else if (tag.smartTag.tagType == "CheckBox") {
                            height = "height: 30px;";
                            width = "width: 30px;";
                        }

                        var isImageTags = tag.smartTag.tagType == "Signature" || tag.smartTag.tagType == "Seal" || tag.smartTag.tagType == "Initials";
                        var imgContent = "";
                        var overFlowStyle = "hidden";
                        if (tag.isAdmin && isImageTags) {
                            imgContent = `<img src="${ApiService.EnvironmentURL() + "document/load?path=" + tag.smartTag.imgUrl}"  style="max-width: 100%;max-height: 100%;"/>`;
                            if (tag.smartTag.tagType != "Seal") {
                                imgContent += `<span id="${tag.smartTag.controlId.replaceAll("-", "_")}-time" style="z-index: 1;font-size: 0.35vw;position: absolute;left: ${tag.smartTag.controlWidth * scale + 5}px;   display: inline-block;    width: 160px; color:${this.lineColor};">${tag.smartTag.timeStamp.replaceAll("\r", "<br>")}</span>`;
                                overFlowStyle = "initial";
                            }
                        }

                        var left = (tag.x * scale);
                        $(`<div style="position:absolute;font-family:  'Helvetica', 'Arial', sans-serif;font-size:0.9vw; overflow:${overFlowStyle};outline: none;top: ${top}px; left: ${left}px;cursor: grab;z-index: 1;${width}; ` + height + '"></div>')
                            .append($(`<div>${imgContent}</div>`).attr({
                                id: tag.pdfName + "-" + tag.smartTag.controlId.replaceAll("-", "_"),
                                'data-index': tag.id,
                                style: `font-family:  'Helvetica', 'Arial', sans-serif;font-size:0.9vw; overflow:${overFlowStyle};outline: none;cursor: grab;z-index: 1;width:100%; ` + height
                            })).appendTo(`.signer-room-form-containter div[data-page='${tag.smartTag.pageNo}'][data-pagename='${tag.pdfName}'] > form`);


                        top = (tag.y * fullScale.height);
                        tag.y = top;
                        $("#" + tag.id).css("top", top + "px");
                        if (tag.smartTag.tagType == "CheckBox") {
                            top += 60;
                        }
                        tag.x = left;

                        tag.guidedTagEnabled = this.signer.id == tag.smartTag.signerId && this.enableSigningByNotary && this.isSigningDate;
                        tag.element = $("#" + tag.pdfName + "-" + tag.smartTag.controlId.replaceAll("-", "_"))[0];
                        tag.isAdmin = this.signer.id !== tag.smartTag.signerId,
                            tag.isWitness = this.signer.id !== tag.smartTag.signerId && this.signer.participantType != "Notary"
                        this.pdfForms.forEach((form) => {
                            if (form.documentId === tag.documentId) {
                                form.allFormFields.push(tag);
                            }
                        });
                        if (!tag.smartTag.isOptional && this.signer.id == tag.smartTag.signerId && this.enableSigningByNotary && this.isSigningDate) {
                            let idx = this.files.findIndex(x => tag.documentId === x.documentID);
                            if (idx >= 0 && this.files[idx].documentType == DOC_TYPE.REVIEW) {
                                this.files[idx].documentType = DOC_TYPE.SIGN;
                                tag.smartTag.isEnote = false;
                                this.files[idx].smartTags.push(tag.smartTag);
                                var item = this.items.find(x => x.docId == this.files[idx].documentID);
                                var itemDix = this.items.findIndex(x => x.docId == this.files[idx].documentID);
                                this.items.splice(itemDix, 1)
                                item.parentId = 2;
                                item.parent = 2;
                                const lastRSIdx = this.items.findIndex(x => x.id == 4);
                                this.items.splice(lastRSIdx - 1, 0, item)
                                var parentIdx = this.items.findIndex(x => x.id == 1);
                                this.items[parentIdx].text = `Ready for Review (${this.items.filter(x => x.parentId == 1).length})`
                                this.items[parentIdx].disabled = this.items.filter(x => x.parentId == 1).length == 0
                                parentIdx = this.items.findIndex(x => x.id == 2);
                                this.items[parentIdx].text = `Ready for Review and Sign (${this.items.filter(x => x.parentId == 2).length})`
                                this.items[parentIdx].disabled = this.items.filter(x => x.parentId == 2).length == 0
                            }
                        }
                        this.allTagsCurrentlyAvailableToSign.push(tag);
                        this.allTagsSigned = false;
                        this.checkAndEnableGuidedTags();
                        break;

                    // when notary send dynamic tag, witness load
                    case "dynamicSignerTagWitness":
                        if (this.signer.isWitness) {
                            var { tag } = data;
                            var pdfPath = this.pdfForms.find((item) => item.documentId === tag.documentId).path;
                            var width = $(`.signer-room-form-containter div[data-page='${tag.smartTag.pageNo}'][data-pagename='${tag.pdfName}']`).width();
                            var height = $(`.signer-room-form-containter div[data-page='${tag.smartTag.pageNo}'][data-pagename='${tag.pdfName}']`).height();
                            var fullScale = await SmartTagManager.GetFullScale(pdfPath, width, height);
                            var scale = fullScale.width

                            console.log("dynamicSignerTag", data, fullScale);
                            var top = (tag.y * fullScale.height);// + ((parseFloat(tag.smartTag.controlHeight) / 2) * scale);
                            height = (tag.smartTag.tagType === "CheckBox" ? `height:30px;` : `height:${tag.smartTag.controlHeight * fullScale.height}px;`);
                            width = `width: ${tag.smartTag.controlWidth * scale}px;`;
                            if (tag.smartTag.tagType != "CheckBox" && tag.smartTag.tagType != "Signature" && tag.smartTag.tagType != "Initials") {
                                height = `height: ${tag.smartTag.controlHeight * fullScale.height}px;`;
                                width = `width: ${tag.smartTag.controlWidth * fullScale.width}px;`;
                            } else if (tag.smartTag.tagType == "CheckBox") {
                                height = "height: 30px;";
                                width = "width: 30px;";
                            }

                            var isImageTags = tag.smartTag.tagType == "Signature" || tag.smartTag.tagType == "Seal" || tag.smartTag.tagType == "Initials";
                            var imgContent = "";
                            var overFlowStyle = "hidden";
                            if (tag.isAdmin && isImageTags) {
                                imgContent = `<img src="${ApiService.EnvironmentURL() + "document/load?path=" + tag.smartTag.imgUrl}"  style="max-width: 100%;max-height: 100%;"/>`;
                                if (tag.smartTag.tagType != "Seal") {
                                    imgContent += `<span id="${tag.smartTag.controlId.replaceAll("-", "_")}-time" style="z-index: 1;font-size: 0.35vw;position: absolute;left: ${tag.smartTag.controlWidth * scale + 5}px;   display: inline-block;    width: 160px; color:${this.lineColor};">${tag.smartTag.timeStamp.replaceAll("\r", "<br>")}</span>`;
                                    overFlowStyle = "initial";
                                }
                            }

                            var left = (tag.x * scale);
                            $(`<div style="position:absolute;font-family:  'Helvetica', 'Arial', sans-serif;font-size:0.9vw; overflow:${overFlowStyle};outline: none;top: ${top}px; left: ${left}px;cursor: grab;z-index: 1;${width}; ` + height + '"></div>')
                                .append($(`<div>${imgContent}</div>`).attr({
                                    id: tag.pdfName + "-" + tag.smartTag.controlId.replaceAll("-", "_"),
                                    'data-index': tag.id,
                                    style: `font-family:  'Helvetica', 'Arial', sans-serif;font-size:0.9vw; overflow:${overFlowStyle};outline: none;cursor: grab;z-index: 1;width:100%; ` + height
                                })).appendTo(`.signer-room-form-containter div[data-page='${tag.smartTag.pageNo}'][data-pagename='${tag.pdfName}'] > form`);


                            top = (tag.y * fullScale.height);
                            tag.y = top;
                            $("#" + tag.id).css("top", top + "px");
                            if (tag.smartTag.tagType == "CheckBox") {
                                top += 60;
                            }
                            tag.x = left;
                            tag.guidedTagEnabled = !this.signer.isWitness;
                            tag.element = $("#" + tag.pdfName + "-" + tag.smartTag.controlId.replaceAll("-", "_"))[0];
                            this.pdfForms.forEach((form) => {
                                if (form.documentId === tag.documentId) {
                                    form.allFormFields.push(tag);
                                }
                            });
                            this.allTagsCurrentlyAvailableToSign.push(tag);
                            this.checkAndEnableGuidedTags();
                        }
                        break;
                    case "newPage":
                        var { docId: documentId, pageNum: page } = data;
                        console.log("New Page Added ", documentId, page);
                        this.dynamicallyInsertPage(parseInt(documentId), parseInt(page));
                        break;

                    case "newPageWitness":
                        if (this.signer.isWitness) {
                            console.log(data);
                            var { docId: documentId, pageNum: page } = data;
                            console.log("New Page Added Witness", documentId, page);
                            this.dynamicallyInsertPage(parseInt(documentId), parseInt(page));
                        }
                        break;
                    case "showIAttestLegalWitness":
                        var { forSigner_ID } = data;
                        if (this.signer.participantType === "Legal_Witness" && forSigner_ID == this.signer.id) {
                            if (this.allDocumentsSignedAndReviewed(true) === true) {
                                this.showIAttest();
                                break;
                            } else {
                                this.SendMessageToNotary("Legal Witness has not actioned all documents.");
                            }
                        }
                        break;
                    case "closewaiting":
                        this.NotaryConnected();
                        break;
                    case "calldeclined":
                        this.callTimeout();
                        break;
                    // when signer signs tag  - the witness can load on the ui
                    case "notifyNotaryWitnessTagsPlaced":
                        if (this.signer.isWitness) {
                            console.log("tag came for witness", data);
                            let { from, tagID, control: value, timestamp } = data;
                            this.pdfForms.forEach((form) => {
                                form.allFormFields.forEach((field) => {
                                    console.log(field.smartTag.id, " == ", tagID)
                                    if (field.smartTag.id == tagID) {
                                        //this.scrollCurrentControlIntoView(null, field, form.pdfName, form.isEnote);
                                        if (field.smartTag.tagType == "Signature" || field.smartTag.tagType == "Initials" || field.smartTag.tagType == "Seal") {
                                            if (value.indexOf(this.apiUrl) < 0)
                                                value = this.apiUrl + value;

                                            this.setImageInPdf(value, form.pdfName, field, form.isEnote);
                                        }
                                        if (field.smartTag.tagType == "Date" || field.smartTag.tagType == "DateOfBirth") {
                                            this.dateFieldText = value;
                                        }


                                        if (this.signer.isWitness && field.smartTag.tagType !== "AutomaticSignatureDate") {
                                            this.scrollItemIntoView(".signer-room-form-containter", `#ga-${form.pdfName}-${field.noSpaceIdAlias}`);
                                        }

                                        $(`#ga-${form.pdfName}-${field.noSpaceIdAlias}`).fadeOut('slow');
                                        field.pdfName = form.pdfName;
                                        this.updateControlData(field, value, false, true);

                                        this.insertTimeStamp(field, timestamp);
                                        this.checkAndEnableGuidedTags();
                                    }
                                });
                            });
                        }
                        break;

                    case "receiveBorrowerScrollAsWitness":
                        if (this.signer.isWitness) {
                            let signerPosition = parseInt(data.position);
                            let signerHeight = parseInt(data.height);

                            let container = $(".signer-room-form-containter");
                            let clientHeight = container[0].scrollHeight;


                            let contentScale = signerHeight / clientHeight;
                            signerPosition /= contentScale;
                            container.scrollTop(signerPosition);

                        }
                        break;

                    case "endSession":

                        if (this.signer.isWitness || this.signer.participantType == "Legal_Witness") {
                            //end video call
                            this.$refs.videoref.disconnectSession();

                            //delete connection
                            await ApiService.GeneratePostRequest('signer/closeConnection/' + this.NotarizeID);
                            //kick out everyone
                            await this.logout();
                        }

                        break;
                    case "updateSignerWaiting":
                        if (data == "accept") {
                            document.getElementById("overlay").style.display = "none";
                            this.NotaryConnected();
                        } else {
                            this.shouldForceClose = true;
                            await ApiService.GeneratePostRequest('signer/closeConnection/' + this.NotarizeID);
                            this.$router.push('/admin/ThankYou');
                        }

                        break;

                    default:
                        console.log("method not found", type, data);
                }

            },
            async NotaryConnected() {
                this.CancelMeeting();
                this.ReadyToSign = true;
                this.$refs.videoref.initializeSession();
            },
            /*****************************************************
                            END AV FUNCTIONS
             *****************************************************/
            guidedArrowText(tagType, data) {
                let str = "";
                switch (tagType) {
                    case "Initials":
                        str = "Initial";
                        break;
                    case "Signature":
                        str = "Sign";
                        break;
                    case "CheckBox":
                        if (data.value === "" || data.value === CHECKBOX_SKIPPED_VALUE)
                            str = "";
                        else
                            str = "";
                        break;
                    case "FreeForm":
                        str = "Text";
                        break;
                    default:
                        str = tagType;
                }

                return str;
            },
            guidedArrowLeftPosition(tagType, data) {
                let value = data.x + data.width + 25;
                if (tagType === "CheckBox")
                    value = data.x - 38
                return value;
            },
            log(val) {
                // console.log("LOGG", val);
                return true;
            },
            setDocumentId(id) {
                this.documentId = id;
            },
            allDocumentsSignedAndReviewed(skipReview = false) {
                var self = this;
                this.disableComplete = true;



                if (this.allDocumentsLoadedInUI === false)
                    return false;


                console.log("Closing", this.pdfForms);
                if (this.pdfForms.length === 0 || this.showCompleteSigning) {
                    return false;
                }
                if (this.signer.participantType == "Signer" && this.files.filter(x => x.documentType == DOC_TYPE.SIGN).length > 0 && this.allTagsCurrentlyAvailableToSign.filter(x => x.smartTag.signerId == this.signer.id && x.value === "").length == 0 && this.allTagsSigned === false && this.isSignable) {
                    Swal.fire({
                        title: 'All Documents Signed!',
                        text: "Please scroll to the end to review the remaining document(s) to complete the session.",
                        icon: 'info',
                        confirmButtonText: 'Ok'
                    })
                    this.allTagsSigned = true;
                   
                }
                if (this.signer.participantType == "Signer" && this.allTagsSigned === true && this.isSignable) {
                    if (!this.pdfForms.find(x => x.formIsReviewed == false) && !this.showCompleteSigning) {
                        this.showCompleteSigning = true;
                    }
                }
                for (var i = 0; i < this.pdfForms.length; i++) {
                    let documentData = this.files[i];
                    let pdfData = this.pdfForms[i];
                    switch (documentData["documentType"]) {
                        case DOC_TYPE.SIGN:
                            if (!pdfData.allFieldsCompleted(false)) {
                                console.log("all fields not completed", documentData, pdfData);
                                return;

                            } else {
                                if (this.files[i].documentType == DOC_TYPE.SIGN && this.files[i].smartTags.filter(x => x.signerId == this.files[i].signerId).length > 0) {
                                    this.pdfForms[i].formIsReviewed = true;
                                    this.files[i].participantStatus = DOC_PARTICIPATION_STATUS.SIGNED;
                                    var item = this.items.find(x => x.docId == this.files[i].documentID);
                                    var idx = this.items.findIndex(x => x.docId == this.files[i].documentID);
                                    this.items.splice(idx, 1)
                                    item.parentId = 3;
                                    item.parent = 3;
                                    this.items.push(item)
                                    var parentIdx = this.items.findIndex(x => x.id == 2);
                                    this.items[parentIdx].text = `Ready for Review and Signing (${this.items.filter(x => x.parentId == 2).length})`
                                    this.items[parentIdx].disabled = this.items.filter(x => x.parentId == 2).length == 0
                                    parentIdx = this.items.findIndex(x => x.id == 3);
                                    this.items[parentIdx].text = `Completed (${this.items.filter(x => x.parentId == 3).length})`
                                } else if (this.files[i].smartTags.filter(x => x.signerId == this.signer.id).length == 0) {
                                    if (skipReview === true || this.isExpressSigning) {
                                        break;
                                    } else {
                                        if (this.files[i].participantStatus != DOC_PARTICIPATION_STATUS.REVIEWED) {
                                            return;
                                        }
                                    }
                                    break;
                                }

                            }
                            break;
                        case DOC_TYPE.REVIEW:
                            if (skipReview === true || this.isExpressSigning) {
                                break;
                            } else {
                                if (this.files[i].participantStatus == DOC_PARTICIPATION_STATUS.PENDING_REVIEW) {
                                    return;
                                }
                            }
                            break;
                    }
                }

                // if all documents are signed but the page is still loaded show render arrow to atleast jump to complete button
                if (!this.initialLoad) {
                    this.renderJumpArrow = false;
                }
                // this.disableComplete = false;
                if (!this.initialLoad) {
                    if (this.signer.isWitness || this.signer.participantType == "Legal_Witness") {
                        return true;
                    } else if (this.isExpressSigning && this.files.filter(x => x.documentType == DOC_TYPE.SIGN).length > 0) {
                        var text = "You have signed all of the required documents. There are additional documents that can be reviewed.<br/><br/>If you continue reviewing, please click Complete Session at the top of the screen when you are ready.<br/><br/>Once the session is completed, you will have the ability to print and/or download all of the documents in the package.";

                        if (this.isSpecialClient) {
                            text = "Almost Done!<br/><br/>Your last step is to complete your signing session.<br/><br/>I attest that by signature, I acknowledge that I have read, understand, and agree to the terms and conditions outlined."
                        }
                        Swal.fire({
                            title: 'Signing Complete!',
                            html: text,
                            icon: 'success',
                            confirmButtonColor: '#4cb64c',
                            denyButtonColor: '#3F51B5',
                            confirmButtonText: 'Complete Session',
                            showDenyButton: !this.isSpecialClient,
                            denyButtonText: "Continue Review",
                            focusDeny: true,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                        }).then(result => {
                            if (result.isConfirmed) {
                                ApiService.GeneratePostRequest(`signer/skip_review`).then(res => {
                                    this.files.forEach((file, frm) => {
                                        if (file.participantStatus == DOC_PARTICIPATION_STATUS.PENDING_REVIEW) {
                                            this.pdfForms[frm].formIsReviewed = true;
                                            this.files[frm].participantStatus = DOC_PARTICIPATION_STATUS.REVIEWED;
                                            var item = this.items.find(x => x.docId == this.files[frm].documentID);
                                            var idx = this.items.findIndex(x => x.docId == this.files[frm].documentID);
                                            this.items.splice(idx, 1)
                                            item.parentId = 3;
                                            item.parent = 3;
                                            this.items.push(item)
                                            var parentIdx = this.items.findIndex(x => x.id == 1);
                                            this.items[parentIdx].text = `Ready for Review (${this.items.filter(x => x.parentId == 1).length})`
                                            this.items[parentIdx].disabled = this.items.filter(x => x.parentId == 1).length == 0
                                            parentIdx = this.items.findIndex(x => x.id == 3);
                                            this.items[parentIdx].text = `Completed (${this.items.filter(x => x.parentId == 3).length})`
                                        }
                                    });

                                    if (res.isSuccess) {
                                        this.showIAttest();
                                    }
                                });
                            } else {
                                this.showCompleteSigning = true;
                            }
                        })
                    } else {
                        if (LAST_DOCUMENT_TO_REVIEW.NumPages !== LAST_DOCUMENT_TO_REVIEW.ReviewedPageCount) {
                            LAST_DOCUMENT_TO_REVIEW.WaitingOnDocumentToClose = true;
                            return;
                        }

                        var isOnlyEnotes = typeof this.files.find((file) => file.isEnote == false) === 'undefined';
                        var text = "";
                        if (isOnlyEnotes) {
                            text = "<br /><br />" + this.iAttestText;
                        }
                        var finalText = "Do you wish to continue reviewing?<br/><br/>If you continue reviewing, please click Complete Session at the top of the screen when you are ready.<br/><br/>Once the session is completed, you will have the ability to print and/or download all of the documents in the package." + text

                        if (this.isSpecialClient) {
                            finalText = "Do you wilsh to continue reviewing?<br/><br/>If you continue reviewing, please click Complete Session at the top of the screen when you are ready.<br/><br/>Once the session is completed, you will have the ability to print and/or download all of the documents in the package.<br/><br/>I attest that by my signature, I acknowledge that I have read, understand, and agree to the terms and conditions outlined."
                        }

                        

                        Swal.fire({
                            title: this.files.filter(x => x.documentType == DOC_TYPE.SIGN).length > 0 ? 'Signing and Review Complete!' : 'Review Complete!',
                            html: finalText,
                            icon: 'success',
                            confirmButtonColor: "#4cb64c",
                            denyButtonColor: '#3F51B5',
                            confirmButtonText: 'Complete Session',
                            focusDeny: true,
                            showDenyButton: !this.isSpecialClient,
                            denyButtonText: "Continue Review",
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                        }).then(result => {
                            if (result.isConfirmed) {
                                this.showIAttest();

                            } else {
                                this.showCompleteSigning = true;
                            }
                        })


                    }
                }
                return true;
            },
            async handleIAttestClicked() {

            },
            async showIAttest() {
                let self = this;
                let array = this.checkboxesToSignOnPDFAfterIAttest.map((id) => {
                    return {
                        id: id,
                        displayText: CHECKBOX_CHECKED_VALUE
                    }
                });
                console.log("signing multiple");
                let interval = setInterval(() => {
                    if (!IS_CURRENTLY_SIGNING) {
                        this.signMultipleOnPDF(array, () => {
                            var handler = async function () {
                                this.lenderid = this.$store.state.App.lenderId;
                                this.signerData = this.$store.getters["Auth/signerSessionInformation"];

                                var payload = {
                                    "SignerId": this.signerData["signerId"],
                                    "SignerName": this.signerData["firstName"] + " " + this.signerData["lastName"],
                                    "SignerEmail": this.signerData["emailAddress"],
                                    "PackageName": "",
                                    "NotarizeId": this.signerData["notarizeId"],
                                    "RequesterId": this.signerData["requesterId"],
                                    "Lender": ""
                                };
                                ApiService.GeneratePostRequest("signer/logAllSigned").then(() => {
                                    ApiService.GeneratePostRequest(`econsent/signingComplete/sendLenderNotification`, payload).then(() => {
                                        try {
                                            if (window.parent && typeof window.parent.CustomSigningRedirect === 'function') {
                                                this.signer.attested = true
                                                window.parent.CustomSigningRedirect();
                                            }
                                        } catch (error) {
                                            console.error(error);
                                        }
                                    });
                                    ApiService.GeneratePostRequest(`signer/sendSigningCompleteToNotary/` + this.signerData["notarizeId"]);
                                    this.signer.attested = true
                                    if (this.signer.signingType === "WET_SIGN" && !this.signer.isNotaryAttached) {
                                        this.activeDrawerIdx = 4
                                    } else {
                                        this.activeDrawerIdx = 3
                                    }
                                    this.documentId = 0
                                });

                                if (self.signer.isWitness || self.signer.participantType === "Legal_Witness") {
                                    await ApiService.GeneratePostRequest('signer/closeConnection/' + this.NotarizeID);
                                    self.$router.push('/admin/ThankYou');
                                }

                            }.bind(this)


                            // only show review for non-enote only packages
                            var isOnlyEnotes = typeof this.files.find((file) => file.isEnote == false) === 'undefined';
                            if (isOnlyEnotes) {
                                handler();
                                return;
                            }

                            console.log("this.signer.participantType", this.signer.participantType);
                            Swal.fire({
                                title: 'Signing Completed',
                                text: this.iAttestText,
                                icon: 'success',
                                confirmButtonText: 'Confirm',
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                            }).then(handler)
                        });
                        clearInterval(interval);
                    }
                }, LAST_DOCUMENT_TO_REVIEW.WaitingOnDocumentToClose ? 3000 : 100);// if last document is neeeded to completely review - wait 3 seconds before  i attest or 100ms if reviewed prior.
            },
            scrollCurrentControlIntoView(onComplete = null) {
                this.isScrolling = true;
                var self = this;
                var prefix = "";

                // if current control to scroll does not exist "i.e" first  document is a review document
                if (!this.pdfForm.allFormFields[this.currentControlIndex]) {
                    let isSuccessful = this.findAndSetNextSignableTag();
                    if (!isSuccessful) {
                        // if no more signing forms to fill scroll to complete button instead
                        this.allDocumentsSignedAndReviewed();
                        return;
                    }
                }


                let tag = this.allTagsCurrentlyAvailableToSign[this.findNextAvailableTagIndex()];
                if (this.findNextAvailableTagIndex() < 0 && !this.isRon) {
                    NAVIGATED_WHILE_LAZY_LOADING = true;
                    this.checkAndEnableGuidedTags();
                    return;
                }
                if (tag.smartTag.isEnote) {
                    // element should be signable tag not actual input
                    prefix = "ga-";
                }
                var element = document.getElementById(`${prefix}${tag.pdfName}-${tag.noSpaceIdAlias}`);

                $('.signer-room-form-containter').animate({
                    scrollTop: $('.signer-room-form-containter').scrollTop() - $('.signer-room-form-containter').offset().top + $(element).offset().top - 50,
                    complete: onComplete
                }, 1000, () => {
                    this.isScrolling = false;
                    this.checkForIntersectingAndAdjust();
                });
            },
            scrollItemIntoView(containerSelector, elementSelector) {
                this.isScrolling = true;
                $(containerSelector).animate({
                    scrollTop: $(containerSelector).scrollTop() - $(containerSelector).offset().top + $(elementSelector).offset().top
                }, 1000);
            },
            scrollPageIntoViewCentered(elementSelector, cb) {
                this.isScrolling = true;
                $('.signer-room-form-containter').animate({
                    scrollTop: $('.signer-room-form-containter').scrollTop() - $('.signer-room-form-containter').offset().top + $(elementSelector).offset().top - 50,
                }, 1000, () => {
                    if (cb)
                        cb();
                    this.isScrolling = false;
                    this.needsToGoToNextForm = false;
                });
                return;
                //document.querySelector(elementSelector).scrollIntoView({
                //    behavior: 'smooth',
                //    block: 'center',
                //    inline: 'center'
                //});
            },
            isGuidedArrowIntersecting(id) {
                try {

                    var selection = document.querySelector("#ga-navigateToNextControl");
                    var rectSelection = selection.getBoundingClientRect();
                    var rect = $(`#${id}`)[0].getBoundingClientRect();
                    if (rect.top + rect.height > rectSelection.top
                        && rect.left + rect.width > rectSelection.left
                        && rect.bottom - rect.height < rectSelection.bottom
                        && rect.right - rect.width < rectSelection.right) {
                        return true;
                    }

                } catch (e) {
                    console.log("isIntersecting in catch")
                }
                return false;
            },
            checkForIntersectingAndAdjust() {
                //let idx = this.findNextAvailableTagIndex();
                //let tag = this.allTagsCurrentlyAvailableToSign[idx];
                for (var i = 0; i < this.allTagsCurrentlyAvailableToSign.length; i++) {
                    let tag = this.allTagsCurrentlyAvailableToSign[i];
                    let isIntersecting = this.isGuidedArrowIntersecting('ga-' + tag.pdfName + '-' + tag.noSpaceIdAlias);
                    if (isIntersecting) {
                        let top = parseInt($("#ga-navigateToNextControl").css("top").replaceAll("px", ""));
                        $("#ga-navigateToNextControl").css("top", (top + 40) + "px")
                    }
                }
                //if (tag && this.isTagOptional(tag.smartTag)) {
                //    console.log("Adjusting optional tags");

                //    let isIntersecting = this.isGuidedArrowIntersecting('ga-' + tag.pdfName + '-' + tag.noSpaceIdAlias);
                //    if (isIntersecting) {
                //        let top = parseInt($("#ga-navigateToNextControl").css("top").replaceAll("px", ""));
                //        $("#ga-navigateToNextControl").css("top", (top + 40) + "px")
                //    }
                //}

            },
            isElementInViewport(el) {
                if (typeof jQuery === "function" && el instanceof jQuery) {
                    el = el[0];
                }

                var rect = el.getBoundingClientRect();

                return (
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
                );
            },
            async jumpToNextGuidedArrow(isAuto = false, lazyLoadingCompleted = true) {

                if (!this.isRon) {
                    this.checkAndEnableGuidedTags();
                    if (IS_LAZY_LOADING) {
                        NAVIGATED_WHILE_LAZY_LOADING = true;
                        return;
                    }

                    if (this.findNextAvailableTagIndex() < 0 && this.allDocumentsLoadedInUI) {
                        this.renderJumpArrow = false;
                        return;
                    }
                    if (lazyLoadingCompleted)
                        NAVIGATED_WHILE_LAZY_LOADING = false;
                } else {
                    if (this.findNextAvailableTagIndex() < 0) {
                        this.renderJumpArrow = false;
                        return;
                    }
                }
                this.moveJumpArrowToNextPage = true;
                this.checkAndEnableGuidedTags();
                let index = this.findNextAvailableTagIndex();
                if (this.allTagsCurrentlyAvailableToSign[index] &&
                    this.allTagsCurrentlyAvailableToSign[index].value === '' && !this.initialLoad && this.currentTagInView && isAuto != true) {
                    console.log("Complete current tag to move on", this.pdfForm.allFormFields[this.currentControlIndex]);
                    if (!this.isCurrentTagOptional)
                        return;
                    console.log("Skipping");
                    this.pdfForms.forEach((item) => {
                        item.allFormFields.forEach((field) => {
                            if (field.noSpaceId === this.allTagsCurrentlyAvailableToSign[index].noSpaceId) {
                                item.setField(field.noSpaceIdAlias, CHECKBOX_SKIPPED_VALUE);
                            }
                        });
                    });


                    this.allTagsCurrentlyAvailableToSign[index].value = CHECKBOX_SKIPPED_VALUE;
                    this.checkAndEnableGuidedTags();
                    let settings = await this.$store.getters["App/appSettings"];
                    if (settings.autoNavigate == true) {
                        this.jumpToNextGuidedArrow(true);
                    }
                }


                if (this.initialLoad) {
                    this.initialLoad = false;
                    this.scrollCurrentControlIntoView(() => {
                        this.moveJumpArrowToNextPage = false;
                    });
                } else {
                    if (!this.isSigningDate) {
                        // in pre close the user can skip
                    }

                    this.currentControlIndex++;
                    if (this.needsToGoToNextForm) {
                        this.setNextSigningForm()
                    }
                    this.moveToNextGuidedTag(() => {
                        this.moveJumpArrowToNextPage = false;
                    });
                }
                this.checkAndEnableGuidedTags();
                this.checkForIntersectingAndAdjust();
            },
            async guidedArrowClicked(control, index, pdfIndex) {

                if (this.signer.isWitness) { return; }
                console.log("guidedArrowClicked ", control)
                this.moveJumpArrowToNextPage = true;
                // handle case where use manually scrolls to tag and clicks it to update jump arrow
                if (this.initialLoad) {
                    this.initialLoad = false;
                }


                if (!control.guidedTagEnabled)
                    return;




                this.setControlIndexForDrawSignature = index;
                this.currentFormIndex = pdfIndex;
                this.currentScrollDocName = this.files[this.currentFormIndex].name
                this.currentDocType = this.files[this.currentFormIndex].documentType
                this.currentControlIndex = index;

                let tagType = control.smartTag.tagType;

                // if a checkbox tax is clicked and it was previously checked unset the control value & remove image on UI.
                // Remove the checkbox from the list of checkboxes to be submitted at the end of the signing process
                if (tagType === "CheckBox" && control.value === CHECKBOX_CHECKED_VALUE) {
                    this.pdfForm.setField(control.noSpaceIdAlias, CHECKBOX_SKIPPED_VALUE);
                    var id = REPLACE_TAG(control.element, 'div');
                    control.element = document.querySelector("#" + id);
                    this.checkboxesToSignOnPDFAfterIAttest = this.checkboxesToSignOnPDFAfterIAttest.filter((item) => {
                        if (item === control["smartTag"]["id"]) {
                            this.BroadcastAppliedTags(control["smartTag"]["id"], false, "");
                        }
                        return item !== control["smartTag"]["id"];
                    });
                    return;
                }

                this.signatureTypeId = tagType === "Initials" ? 3 : 1;
                if (tagType === "Signature") {
                    this.signatureText = this.signer.firstName + " " + this.signer.lastName
                } else if (tagType === "Initials") {
                    this.signatureText = this.signer.firstName.charAt(0) + "." + this.signer.lastName.charAt(0)
                }
                if (tagType === "Signature" || tagType === "Initials") {
                    this.setSignature(tagType);
                } else if (tagType === "FreeForm" || tagType === "Email" || tagType === "Address" || tagType === "ZipCode") {
                    this.showFreeFormModal();
                } else if (tagType === "Date" || tagType === "DateOfBirth") {
                    this.showDateModal();
                } else if (control.value === "" || control.value === CHECKBOX_SKIPPED_VALUE) {
                    let doAPICall = tagType !== "CheckBox";
                    // insert control text here
                    this.updateControlData(this.pdfForm.allFormFields[this.currentControlIndex], null, doAPICall);
                    let control = this.pdfForm.allFormFields[this.currentControlIndex];

                    // only fadeout if not checkbox since user can uncheck
                    if (tagType !== "CheckBox")
                        $(`#ga-${this.pdfForm.pdfName}-${control.noSpaceIdAlias}`).fadeOut('slow');
                    else {
                        this.BroadcastAppliedTags(control["smartTag"]["id"], true, "");
                        this.checkboxesToSignOnPDFAfterIAttest.push(control["smartTag"]["id"]);
                        let settings = await this.$store.getters["App/appSettings"];
                        if (settings.autoNavigate == true) {
                            this.jumpToNextGuidedArrow(true);
                        }
                    }

                }
                console.log("Pending CHeckboxes to sign", this.checkboxesToSignOnPDFAfterIAttest);

                //if (!this.signingDate && this.settings.isContinuousScroll)
                //    this.currentControlIndex = index;




                // check if current page has anymore fields to fill and if not move to next pdfForm
                this.checkAndEnableGuidedTags();
                this.allDocumentsSignedAndReviewed();
            },
            checkAndEnableGuidedTags() {
                if (this.isSignable && !this.signer.isWitness) {
                    let index = this.findNextAvailableTagIndex();
                    let tag = this.allTagsCurrentlyAvailableToSign[index];
                    if (index >= 0) {
                        // find pdf form and enable control
                        let found = false;
                        this.pdfForms.forEach((item) => {
                            if (item.pdfName === tag.pdfName) {
                                item.allFormFields.forEach((field, idx) => {
                                    if (field.noSpaceId === this.allTagsCurrentlyAvailableToSign[index].noSpaceId && found === false) {
                                        found = true;
                                        field.guidedTagEnabled = true;
                                        console.log("Tag Beging Activated", item, idx);
                                    }
                                });
                            }
                        });
                    } else {

                        if (this.isRon) {
                            this.renderJumpArrow = false;
                        } else {

                            // LAZY LOAD
                            if (this.allDocumentsLoadedInUI)
                                this.renderJumpArrow = false;
                            else
                                this.currentTagInView = false;
                        }
                    }
                }
                return;
            },
            moveToNextGuidedTag(cb) {
                this.scrollCurrentControlIntoView(cb);
                return;
            },
            findNextAvailableTagIndex() {
                return this.allTagsCurrentlyAvailableToSign.findIndex((item) => item.value == "" && !item.isAdmin);
            },
            findTagIndexById(id) {
                return this.allTagsCurrentlyAvailableToSign.findIndex((item) => item.smartTag.id == id);
            },
            findAndSetNextSignableTag() {
                var fieldIndex = -1;
                for (var i = 0; i < this.pdfForms.length; i++) {
                    var form = this.pdfForms[i];
                    if (form.allFormFields.length > 0) {
                        // in sure form fields aren't filled out
                        form.allFormFields.forEach((item, index) => {
                            if (item.value === "" && fieldIndex < 0)
                                fieldIndex = index;
                        });
                        if (fieldIndex >= 0) {
                            this.currentControlIndex = fieldIndex;
                            this.currentFormIndex = i;
                            this.currentPage = this.pdfForm.allFormFields[this.currentControlIndex].belongsToPage;
                            this.currentScrollDocName = this.files[this.currentFormIndex].name
                            this.currentDocType = this.files[this.currentFormIndex].documentType
                            this.checkAndEnableGuidedTags();
                            return true;
                        }
                    }
                }
                return false;
            },
            findNextSigningFormIndex() {
                let tag = this.allTagsCurrentlyAvailableToSign[0];
                let searchValue = tag ? tag.pdfName : null;
                let val = -1;
                for (var i = 0; i < this.files.length; i++) {
                    if (this.files[i].documentType === DOC_TYPE.SIGN && this.files[i].noSpaceName === searchValue) {
                        var form = this.pdfForms[i];
                        if (form.allFormFields.length > 0) {
                            // ensure form fields aren't filled out
                            val = form.allFormFields.findIndex((item) => item.noSpaceId === tag.noSpaceId);
                            break;
                        }
                    }
                }
                return val;
            },
            setNextSigningForm() {
                let file = null;
                // scroll next document into view if all form fields filled out on current document
                let nextDocumentIndex = this.findNextSigningFormIndex();
                console.log("Next SIgning DOc", nextDocumentIndex, this.files[nextDocumentIndex]);
                file = this.files[nextDocumentIndex];
                this.resetNewFormProperties(file, nextDocumentIndex);


                return file;
            },
            resetNewFormProperties(file, newFormIndex) {
                if (file) {
                    this.currentControlIndex = -1;
                    this.setControlIndexForDrawSignature = 0;
                    this.currentPage = 1;
                    this.currentFormIndex = newFormIndex;
                    this.needsToGoToNextForm = false;
                    this.currentScrollDocName = this.files[this.currentFormIndex].name
                    this.currentDocType = this.files[this.currentFormIndex].documentType
                    if (this.pdfForm.allFormFields[this.currentControlIndex])
                        this.pdfForm.allFormFields[this.currentControlIndex].guidedTagEnabled = true;
                }
            },
            updateControlData(controlData, value, apiCallToSignPDF = true, useIncomingValue = false) {

                // sometimes autoscroll from bowerroer mvoes faster than it reaches the witness. the borrower can be one page ahead before it's sent across, and throws off which tag elment to update.
                let pdfName = useIncomingValue === true ? (controlData.pdfName || this.pdfForm.pdfName) : this.pdfForm.pdfName;

                let control = $(`#${pdfName}-${controlData.noSpaceIdAlias}`);
                let val = value || "";

                // DO SWITCH CASE TO DETERMINE WHAT TO ADD TO CONTROL
                switch (controlData["smartTag"]["tagType"]) {
                    case "FullName":
                        val = useIncomingValue ? value : this.signer.firstName + " " + this.signer.lastName
                        break;
                    case "FirstName":
                        val = useIncomingValue ? value : this.signer.firstName
                        break;
                    case "LastName":
                        val = useIncomingValue ? value : this.signer.lastName
                        break;
                    case "CheckBox":
                        if (useIncomingValue && this.signer.isWitness) {
                            if (value === 'true') {
                                var id = REPLACE_TAG(controlData.element, 'img'); // by default UI locations are input. change to a more relevant tag for text
                                $("#" + id).attr("src", "/checkmark.png");
                                $("#" + id).css("max-width", "100%");
                                $("#" + id).css("opacity", "1");
                                $("#" + id).css("z-index", "1");
                                $("#" + id).parent().css("z-index", "1");
                                controlData.element = document.querySelector("#" + id);
                                val = CHECKBOX_CHECKED_VALUE;
                            } else {
                                var id = REPLACE_TAG(controlData.element, 'div');
                                controlData.element = document.querySelector("#" + id);
                                val = CHECKBOX_SKIPPED_VALUE;
                            }
                        } else {
                            var id = REPLACE_TAG(controlData.element, 'img'); // by default UI locations are input. change to a more relevant tag for text
                            $("#" + id).attr("src", "/checkmark.png");
                            $("#" + id).css("max-width", "100%");
                            $("#" + id).css("opacity", "1");
                            $("#" + id).css("z-index", "1");
                            $("#" + id).parent().css("z-index", "1");
                            controlData.element = document.querySelector("#" + id);
                            val = CHECKBOX_CHECKED_VALUE;
                        }
                        break;
                    case "Address":
                    case "Email":
                    case "ZipCode":
                    case "FreeForm":
                        val = useIncomingValue ? value : this.freeFormText;
                        var id = controlData.element.id; // by default UI locations are input. change to a more relevant tag for text
                        if (!controlData.isDynamic) {
                            id = REPLACE_TAG(controlData.element, 'div'); // by default UI locations are input. change to a more relevant tag for text
                            controlData.element = document.querySelector("#" + id);
                        }
                        $("#" + id).css("font-family", "'Helvetica', 'Arial', sans-serif").css("font-size", "0.9vw").css("overflow", "hidden").css("outline", "none");
                        $("#" + id).text(val);
                        $("#" + id).css("z-index", "1");
                        $("#" + id).parent().css("z-index", "1");
                        let control = this.pdfForm.allFormFields[this.setControlIndexForDrawSignature];
                        if (control && control.noSpaceIdAlias)
                            $(`#ga-${this.pdfForm.pdfName}-${control.noSpaceIdAlias}`).fadeOut('slow');
                        else
                            console.log("Control noSpaceIdAlias invalid for control ", control, 'for index ', this.setControlIndexForDrawSignature);
                        break;
                    case "AutomaticSignatureDate":
                        // create a Date object
                        const date = new Date();
                        // get the current date of the month
                        const currDate = date.getDate();
                        // get the current month of the year
                        const currMonth = date.getMonth() + 1;
                        // get the current year
                        const currYear = date.getFullYear();
                        // combine above fields to get current date
                        val = useIncomingValue ? value : `${currMonth}/${currDate}/${currYear}`;
                        var id2 = controlData.element.id
                        if (!controlData.isDynamic) {
                            id2 = REPLACE_TAG(controlData.element, 'div'); // by default UI locations are input. change to a more relevant tag for text
                            controlData.element = document.querySelector("#" + id2);
                        }
                        $("#" + id2).css("font-family", "'Helvetica', 'Arial', sans-serif").css("font-size", "0.7vw").css("overflow", "hidden").css("outline", "none");
                        $("#" + id2).css("z-index", "1");
                        $("#" + id2).parent().css("z-index", "1");
                        $("#" + id2).text(val);
                        $("#" + id2).css("z-index", "1");
                        $("#" + id2).parent().css("z-index", "1");
                        let control2 = this.pdfForm.allFormFields[this.setControlIndexForDrawSignature];
                        if (control && control.noSpaceIdAlias)
                            $(`#ga-${this.pdfForm.pdfName}-${control2.noSpaceIdAlias}`).fadeOut('slow');
                        else
                            console.log("Control noSpaceIdAlias invalid for control ", control, 'for index ', this.setControlIndexForDrawSignature);

                        break;
                    case "Date":
                    case "DateOfBirth":
                        if (controlData.smartTag.params) {
                            let format = JSON.parse(controlData.smartTag.params)["format"];
                            val = moment(useIncomingValue ? value : this.dateFieldText).format(format);
                        } else {
                            val = moment(useIncomingValue ? value : this.dateFieldText).format("MM/DD/YYYY");
                        }
                        $(`#ga-${this.pdfForm.pdfName}-${controlData.noSpaceIdAlias}`).fadeOut('slow');
                        break;

                }
                control.css("font-family", "'Helvetica', 'Arial', sans-serif").css("font-size", "1vw").css("overflow", "hidden").css("outline", "none").css("z-index", "1");
                control.parent().css("z-index", "1");
                if (controlData.isDynamic) {
                    control.text(val);
                    control.parent().css("overflow", "initial");
                    //let top = parseInt(control.parent().css("top").replaceAll("px", ""));
                    // control.parent().css("top", (top - (control.height())) + "px")
                }
                else
                    control.val(val);
                // control.css("color", this.lineColor).css("outline", "none");

                // check if other fields need to be updated
                console.log("this.pdfForm.", this.pdfForm);
                for (let i = 0; i < this.files[this.currentFormIndex].fieldLinks.length; i++) {
                    let obj = this.files[this.currentFormIndex].fieldLinks[i];
                    let link = obj[controlData.noSpaceIdAlias];
                    if (link) {
                        let foundLink = link;
                        // DO SWITCH CASE TO DETERMINE WHAT TO ADD TO CONTROL
                        this.pdfForm.setField(foundLink, new Date().toLocaleDateString());
                        control = $(`#${this.pdfForm.pdfName}-${foundLink}`);
                        control.val(new Date().toLocaleDateString());
                        this.allDocumentsSignedAndReviewed();
                        break;
                    }
                }


                // remove tag from signable list of tags
                let noSpaceId = controlData.noSpaceId;
                let idx = this.allTagsCurrentlyAvailableToSign.findIndex(item => item.noSpaceId == noSpaceId && item.smartTag.id == controlData.smartTag.id);
                if (idx >= 0 && this.allTagsCurrentlyAvailableToSign.length > 0)
                    this.allTagsCurrentlyAvailableToSign[idx].value = val;
                if (controlData["smartTag"]["tagType"] !== "CheckBox") {
                    let index = this.findNextAvailableTagIndex();
                    if (useIncomingValue) {
                        index = this.findTagIndexById(controlData.smartTag.id);
                    }
                    //let tag = this.allTagsCurrentlyAvailableToSign[index];

                    //let pdfName = tag.pdfName;
                    this.allTagsCurrentlyAvailableToSign.splice(idx, 1);
                }
                this.pdfForm.setField(controlData.noSpaceIdAlias, val);

                this.updateCurrentTagInView();


                this.checkAndEnableGuidedTags();

                this.updatePreviewBorder();


                if (!apiCallToSignPDF)
                    return;


                controlData.childTagParentPdfName = pdfName;
                this.signOnPDF(controlData, value, val);
                this.allDocumentsSignedAndReviewed();

                //let results = { isSuccess: true };


            },
            updateCurrentTagInView() {
                let index = this.findNextAvailableTagIndex();
                if (index >= 0) {
                    let field = this.allTagsCurrentlyAvailableToSign[index];
                    let el = field["element"];
                    if (!el)
                        el = $("#ga-" + field.pdfName + "-" + field.noSpaceId)[0];// because enotes are in an iframe their "element" isn't created at instantiation
                    let tagInView = this.isElementInViewport(el);
                    this.currentTagInView = tagInView;
                }

            },
            getControlText(control) {
                return "Data";
            },
            updatePreviewBorder() {
                var self = this;

                // process initial colors for PDF forms in thumbnail
                $(".thumbnail-container > div > div").each(function (index, item) {
                    var pageNum = parseInt($(item).data("page"));
                    var pageName = $(item).data("pagename");
                    self.pdfForms.forEach((pdfForm) => {
                        var controlsForPage = pdfForm.allFormFields.filter((item) => item.belongsToPage === pageNum && pageName === pdfForm.pdfName);



                        if (controlsForPage.length > 0) {
                            // check if all controls in this page  are optional
                            let allAreOptional = true;
                            let onlyNotaryTagsOnPage = true;

                            for (var i = 0; i < controlsForPage.length; i++) {
                                if (!controlsForPage[i].isAdmin) {
                                    onlyNotaryTagsOnPage = false;
                                    break;
                                }
                            }


                            // only update previewborder if
                            if (!onlyNotaryTagsOnPage) {
                                for (var i = 0; i < controlsForPage.length; i++) {
                                    if (!self.isTagOptional(controlsForPage[i].smartTag)) {
                                        allAreOptional = false;
                                        break;
                                    }
                                }




                                // check if all controls are complete
                                var isPageCompleted = true;
                                for (var i = 0; i < controlsForPage.length; i++) {
                                    if (controlsForPage[i].value === "") {
                                        isPageCompleted = false;
                                        break;
                                    }
                                }

                                if (allAreOptional)
                                    $(`.thumbnail-container div[data-page="${pageNum}"][data-pagename="${pageName}"]`).removeClass("fe-to-be-signed fe-signed fe-optional").addClass("fe-optional");
                                else if (!isPageCompleted)
                                    $(`.thumbnail-container div[data-page="${pageNum}"][data-pagename="${pageName}"]`).removeClass("fe-to-be-signed fe-signed").addClass("fe-to-be-signed");
                                else
                                    $(`.thumbnail-container div[data-page="${pageNum}"][data-pagename="${pageName}"]`).removeClass("fe-to-be-signed fe-signed").addClass("fe-signed");
                            } else {
                                $(`.thumbnail-container div[data-page="${pageNum}"][data-pagename="${pageName}"]`).removeClass("fe-to-be-signed fe-signed fe-optional");
                            }
                        }


                    });
                });
            },
            async signMultipleOnPDF(array, callback) {
                console.log("Signing Multiple", array);
                if (array.length == 0) {
                    if (callback)
                        callback();

                    return;
                }
                ApiService.GeneratePostRequest(
                    "transaction/tags/apply_multiple_changes", array
                ).then((results) => {
                    if (!results["isSuccess"]) {
                        // show error
                        return;
                    }
                    if (callback)
                        callback();
                });
            },
            updateChildTag(tagData) {
                console.log("this.pdfForm.allFormFields[this.setControlIndexForDrawSignature]", this.pdfForm.allFormFields[this.setControlIndexForDrawSignature]);
                var child = this.pdfForm.allFormFields[this.setControlIndexForDrawSignature].smartTag.childId;
                for (var i = 0; i < this.pdfForms.length; i++) {
                    if (this.pdfForms[i].pdfName === tagData.childTagParentPdfName) {// find correct document
                        for (var j = 0; j < this.pdfForms[i].allFormFields.length; j++) {// find correct tag for document
                            if (this.pdfForms[i].allFormFields[j].smartTag.id == child) {
                                //the child smart tag found
                                console.log("Auto Date Child Found");
                                this.updateControlData(this.pdfForms[i].allFormFields[j], "");
                                break;
                            }
                        }
                    }
                }
            },
            async signOnPDF(controlData, value, val) {
                // send control data to server
                let postObj = {
                    "id": controlData["smartTag"]["id"],
                    "belongsToPage": controlData["belongsToPage"]
                };

                if (controlData["smartTag"]["tagType"] === "AutomaticSignatureDate") {
                    postObj["displayText"] = val;
                } else {
                    postObj[value ? "imgUrl" : "displayText"] = val;
                }

                // put this call inside ajax below
                IS_CURRENTLY_SIGNING = true;

                let settings = await this.$store.getters["App/appSettings"];
                await ApiService.GeneratePostRequest(
                    "transaction/tags/apply_changes", postObj
                ).then((results) => {
                    console.log("Apply changes", controlData, results);
                    IS_CURRENTLY_SIGNING = false;
                    if (!results["isSuccess"]) {
                        // show error
                        return;
                    }

                    if (settings.autoNavigate == true) {
                        this.jumpToNextGuidedArrow(true);
                    }

                    // won't work for enotes - hotfix for demo
                    try {
                        this.BroadcastAppliedTags(controlData["smartTag"]["id"], val, results.content.replaceAll("\r", "<br>"));
                        if (controlData.smartTag.tagType === "Initials" || controlData.smartTag.tagType === "Signature") {
                            let imageWidth = $('#' + controlData.element.id).width();
                            $('#' + controlData.element.id).on('load', function () {
                                imageWidth = $('#' + controlData.element.id).width();
                                $('#' + controlData.element.id + "-time").css("left", (imageWidth + 10) + "px");
                                $('#' + controlData.element.id + "-time").off("load", "**");
                                $("iframe").each(function (idx, iframe) {
                                    iframe.contentWindow.postMessage("message", window.location.origin);
                                });
                            });
                            $(`<span id="${controlData.element.id}-time" style="z-index: 1;font-size: 0.35vw;position: absolute;left: ${imageWidth + 10}px;   display: inline-block;    width: 160px; color:${this.lineColor};">${results.content.replaceAll("\r", "<br>")}</span>`).insertAfter('#' + controlData.element.id);
                            this.updateChildTag(controlData);
                        }
                    } catch (e) {

                    }
                });
            },
            insertTimeStamp(controlData, text) {
                try {
                    if (controlData.smartTag.tagType === "Initials" || controlData.smartTag.tagType === "Signature") {
                        let imageWidth = $('#' + controlData.element.id).width();
                        $('#' + controlData.element.id).on('load', function () {
                            imageWidth = $('#' + controlData.element.id).width();
                            $('#' + controlData.element.id + "-time").css("left", (imageWidth + 10) + "px");
                            $('#' + controlData.element.id + "-time").off("load", "**");
                        });

                        $(`<span id="${controlData.element.id}-time" style="z-index: 1;font-size: 0.35vw;position: absolute;left: ${imageWidth + 10}px;   display: inline-block;    width: 160px; color:${this.lineColor};">${text}</span>`).insertAfter('#' + controlData.element.id);
                    }
                } catch (e) {

                }
            },
            setDocumentAsReviewed(frm) {
                if (frm < 0) {
                    return;
                }

                if (this.pdfForms[frm].formIsReviewed) {
                    return;
                }
                if ((this.files[frm].documentType == DOC_TYPE.REVIEW && this.files[frm].participantStatus == DOC_PARTICIPATION_STATUS.PENDING_REVIEW) || (this.files[frm].documentType == DOC_TYPE.SIGN && this.files[frm].participantStatus == DOC_PARTICIPATION_STATUS.PENDING_REVIEW && this.files[frm].smartTags.filter(x => x.signerId == this.files[frm].signerId && x.isOptional == false).length == 0)) {
                    this.pdfForms[frm].formIsReviewed = true;
                    this.files[frm].participantStatus = DOC_PARTICIPATION_STATUS.REVIEWED;
                    var item = this.items.find(x => x.docId == this.files[frm].documentID);
                    var idx = this.items.findIndex(x => x.docId == this.files[frm].documentID);
                    this.items.splice(idx, 1)
                    item.parentId = 3;
                    item.parent = 3;
                    this.items.push(item)
                    var parentIdx = this.items.findIndex(x => x.id == 1);
                    this.items[parentIdx].text = `Ready for Review (${this.items.filter(x => x.parentId == 1).length})`
                    this.items[parentIdx].disabled = this.items.filter(x => x.parentId == 1).length == 0
                    parentIdx = this.items.findIndex(x => x.id == 3);
                    this.items[parentIdx].text = `Completed (${this.items.filter(x => x.parentId == 3).length})`
                    if (this.initialLoad) {
                        this.initialLoad = false;
                    }
                    //let results = await ApiService.GeneratePostRequest("document/update/status", { "documentId": this.files[frm].documentID, "status": DOC_PARTICIPATION_STATUS.REVIEWED });
                } else if (this.files[frm].participantStatus == DOC_PARTICIPATION_STATUS.PENDING_REVIEW && this.files[frm].smartTags.filter(x => x.signerId == this.files[frm].signerId).length > 0) {
                    this.pdfForms[frm].formIsReviewed = true;
                    this.files[frm].participantStatus = DOC_PARTICIPATION_STATUS.PENDING_SIGNING;
                    if (this.isSignable) {
                        var item = this.items.find(x => x.docId == this.files[frm].documentID);
                        var idx = this.items.findIndex(x => x.docId == this.files[frm].documentID);
                        this.items.splice(idx, 1)
                        item.parentId = 2;
                        item.parent = 2;
                        const lastRSIdx = this.items.findIndex(x => x.id == 4);
                        this.items.splice(lastRSIdx - 1, 0, item)
                        var parentIdx = this.items.findIndex(x => x.id == 1);
                        this.items[parentIdx].text = `Ready for Review (${this.items.filter(x => x.parentId == 1).length})`
                        this.items[parentIdx].disabled = this.items.filter(x => x.parentId == 1).length == 0
                        parentIdx = this.items.findIndex(x => x.id == 2);
                        this.items[parentIdx].text = `Ready for Review and Sign (${this.items.filter(x => x.parentId == 2).length})`
                    }
                    if (this.initialLoad) {
                        this.initialLoad = false;
                    }
                    //let results = await ApiService.GeneratePostRequest("document/update/status", { "documentId": this.files[frm].documentID, "status": DOC_PARTICIPATION_STATUS.PENDING_SIGNING });
                }

                this.allDocumentsSignedAndReviewed();
            },
            onItemInView(element) {
                let formInViewName = $(element).attr("data-pagename");
                let pageNumberInView = $(element).attr("data-page");
                if (formInViewName == "DUMMYDOCUMENT")
                    return;

                // first check if current document is a signing document
                // - only change to a next document if all controls have finished signing for that document
                if (this.currentFormIndex >= 0 && this.files[this.currentFormIndex]["documentType"] === DOC_TYPE.SIGN && !this.pdfForm.allFieldsCompleted() && this.isSigningDate) {
                    return;
                }


                // find index of form in view - if index isn't current set index update
                let index = this.pdfForms.findIndex((item) => {
                    return item.pdfName === formInViewName;
                });

                if (index !== this.currentFormIndex) {


                    this.currentPage = 1;
                    this.currentControlIndex = -1;// reset current control index
                    this.currentFormIndex = index;
                    this.currentScrollDocName = this.files[this.currentFormIndex].name
                    this.currentDocType = this.files[this.currentFormIndex].documentType
                    // check index against data array to determine the type of action to do: Sign | Review
                    let fileData = this.files[this.currentFormIndex];
                    switch (fileData["documentType"]) {
                        case DOC_TYPE.REVIEW:
                            break;
                        case DOC_TYPE.SIGN:
                            // show guided tags
                            this.showReviewContinueButton = false;
                            if (this.pdfForm && !this.pdfForm.allFieldsCompleted() && (this.pdfForms.availableForPresign || this.isSignable)) {
                                this.pdfForm.allFormFields[0].guidedTagEnabled = true;
                                //if (this.isSigningDate)
                                //    this.scrollCurrentControlIntoView();
                            }
                            break;
                    }
                }

                let pageIndex = parseInt($(element).attr("data-page"));
                if (this.currentPage !== pageIndex && !this.isSigningDate) {
                    this.currentPage = pageIndex;
                }
                //  console.log("What index are we? ", index);

                // before we move on to next document check if previous document was review and if it was show confirmation for
                // marking it as reviewed
                if (!this.settings.isContinuousScroll)
                    this.hasToConfirmDocumentHasBeenReviewed(parseInt($(element).attr("data-page")));

                //console.log($(element).attr("data-pagename") + ' page ' + $(element).attr("data-page") + " is in view ");
            },
            async checkItemsInView(event) {
                IS_SCROLLING = true;
            },
            async onScrollHandler() {
                var self = this;
                var container = $(".signer-room-form-containter");
                function checkInView(container, elem, partial) {
                    if (elem) {

                        let cTop = container.scrollTop - container.clientHeight * 0.1;
                        let cBottom = cTop + container.clientHeight;
                        //Get element properties
                        let eTop = elem.offsetTop;
                        let eBottom = eTop + elem.clientHeight;

                        //Check if in view
                        let isTotal = (eTop >= cTop && eBottom <= cBottom);
                        let isPartial = partial && (
                            (eTop < cTop && eBottom > cTop) ||
                            (eBottom > cBottom && eTop < cBottom)
                        );

                        //Return outcome
                        return (isTotal || isPartial);
                    }
                    return false;
                }

                // will only ever have at least two pieces of page show on the ui
                let array = container.find("div[data-page]").get();






                // new code to determine if we are entering new page
                let firstItemInView = null;
                for (let i = 0; i < array.length; i++) {
                    let e = array[i];
                    let isInView = checkInView(container[0], e, true);
                    if (isInView) {
                        firstItemInView = e;
                        break;
                    }
                }
                let index = self.pdfForms.findIndex((item) => { return item.pdfName === $(firstItemInView).attr("data-pagename"); });

                if (this.files[index]) {
                    this.currentScrollDocName = this.files[index].name
                    this.currentDocType = this.files[index].documentType
                }

                if (firstItemInView) {
                    let elementRightAfter = $(firstItemInView).next();
                    let isInView = checkInView(container[0], elementRightAfter[0], true);


                    // set current page border
                    let name = $(firstItemInView).attr("data-pagename");
                    let num = $(firstItemInView).attr("data-page");
                    $('.thumbnail-container > div > div').removeClass("active-thumbnail");
                    $(`.thumbnail-container > div > div[data-page='${num}'][data-pagename='${name}']`).addClass("active-thumbnail");


                    if (LAST_DOCUMENT_TO_REVIEW.file !== null && name === LAST_DOCUMENT_TO_REVIEW.file.noSpaceName) {
                        this.handleLastDocumentToReviewPageCount(num);
                    }

                    if (isInView) {
                        index = self.pdfForms.findIndex((item) => { return item.pdfName === elementRightAfter.attr("data-pagename"); });
                        if (self.lastKnownVisibleForm !== elementRightAfter.attr("data-pagename")) {
                            self.onItemInView(elementRightAfter[0]);
                            //self.setDocumentAsReviewed(index);
                        }


                        self.lastKnownVisibleForm = elementRightAfter.attr("data-pagename");


                        //this.currentScrollDocName = this.files[index].name
                        //this.currentDocType = this.files[index].documentType
                        //console.log("Setting title ", self.files[index].name)
                        //self.pageTitle = self.files[index].name;// elementRightAfter.attr("data-pagename");
                    }
                }


                let currentScrollPos = $(".signer-room-form-containter").scrollTop();
                // find all documents who are before current scroll pos and mark as review
                var passedFiles = [];
                let lastPage = 0;
                this.files.forEach((file, idx) => {
                    let pageName = file.noSpaceName;
                    lastPage = file.pages;
                    let pageDiv = $(`.signer-room-form-containter > div > div[data-pagename='${pageName}'][data-page='${lastPage}']`);
                    if (pageDiv != null && pageDiv[0] != null && pageDiv[0].offsetTop < currentScrollPos && !ALREADY_REVIEWED_IDS.includes(file.documentID)) {
                        passedFiles.push(file.documentID);
                        ALREADY_REVIEWED_IDS.push(file.documentID);
                        this.setDocumentAsReviewed(idx);

                    }
                });
                if (passedFiles.length > 0)
                    this.setAllPassedDocument(passedFiles, lastPage);


                //$(".signer-room-form-containter > div > div:last-child").prev()[0].offsetTop
                if (currentScrollPos >= OFFSET_OF_LAST_PAGE) {
                    console.log("Offset conditions met");
                    this.allDocumentsSignedAndReviewed();
                }




                // check if current tag is in viewport
                this.updateCurrentTagInView();

                // set direction of guided arrow
                let direction = "bottom";

                if (this.currentTagInView === false) {
                    direction = "bottom";
                    let index = this.findNextAvailableTagIndex();
                    if (index >= 0) {
                        let currentScrollPos = $(".signer-room-form-containter").scrollTop();

                        let tag = this.allTagsCurrentlyAvailableToSign[index];
                        let pageName = tag.pdfName;
                        let lastPage = tag.belongsToPage;
                        let pageDiv = $(`.signer-room-form-containter > div > div[data-pagename='${pageName}'][data-page='${lastPage}']`);
                        let el = tag.element;
                        if (!el)
                            el = "#ga-" + tag.pdfName + "-" + tag.noSpaceId;
                        else
                            el = "#ga-" + tag.element.id;

                        if (currentScrollPos > (pageDiv[0].offsetTop + $(el)[0] ? $(el)[0].offsetTop : 0)) {
                            direction = "top";
                        } else {
                            direction = "bottom";
                        }
                        console.log("should point ", direction, pageDiv);
                    }
                }
                this.scrollTagDirection = direction;

                this.checkForIntersectingAndAdjust();

            },
            async setAllPassedDocument(doculist, lastPage) {
                if (this.signer.isWitness) { return false; }
                await ApiService.GeneratePostRequest("document/update/status/reviewed", {
                    docIds: doculist, jsonString: JSON.stringify({
                        lastActionedDoc: {
                            id: doculist[doculist.length - 1],
                            pageNo: lastPage
                        }
                    })
                });

            },
            repositionEnoteGuidedArrowTag() {
                let index = this.findNextAvailableTagIndex();
                let tag = this.allTagsCurrentlyAvailableToSign[index];
                let file = this.pdfForms.find(x => x.pdfName == tag.pdfName);
                $("iframe").each(function (idx, iframe) {
                    iframe.contentWindow.postMessage("message", window.location.origin);
                });

                if (file.isEnote) {
                    if (this.currentTagInView) {
                        setTimeout(() => {
                            let element = $("#ga-" + tag.pdfName + "-" + tag.noSpaceId);
                            $("#ga-navigateToNextControl").css("top", element.css('top'));
                            tag.y = parseInt(element.css('top').replaceAll("px", ""));
                        }, 300);
                    }
                }
            },
            initiateContinuousScroll(isLazyLoaded = false) {
                // set up array of all tags being signed
                if (this.pdfForms.length > 0) {
                    this.pdfForms.forEach((item, index) => {
                        if (this.files[index].tagsProcessed === false) {
                            item.allFormFields.forEach((field) => {
                                if (PROCESSED_TAGS.indexOf(field.smartTag.id) < 0) {
                                    PROCESSED_TAGS.push(field.smartTag.id);
                                    this.allTagsCurrentlyAvailableToSign.push({ ...field, pdfName: item.pdfName });
                                }
                            });
                            this.files[index].tagsProcessed = true;
                        }
                    });
                    this.allTagsCurrentlyAvailableToSign.sort(function (a, b) {
                        if (a.smartTag.tagType === "AutomaticSignatureDate")
                            return 1;

                        if (b.smartTag.tagType === "AutomaticSignatureDate")
                            return -1;
                        return 0;
                    });
                }
                if (isLazyLoaded === false) {

                    if (this.isSignable) {
                        this.renderJumpArrow = true;
                    }

                    console.log("CURRENTLY SIGNING FOR ", this.allTagsCurrentlyAvailableToSign);
                    if (this.enableSigningByNotary) {
                        this.renderJumpArrow = true;
                    }

                    this.$store.commit("App/updateLoaderOverride", {
                        showLoaderOverride: false,
                    });
                    var divs = $(".signer-room-form-containter > div > div:last-child").prev();
                    if (divs.length > 0) {
                        // find position of last document: we minus 500px because the height given to us by jquery / javascript has an additional 498px attached. i assume this is because of bottom borders, padding etc
                        OFFSET_OF_LAST_PAGE = divs[0].offsetTop + $(".signer-room-form-containter > div > div:last-child").prev().height() - 500;
                    }


                    this.lastKnownVisibleForm = this.pdfForm.pdfName;

                    console.log("Initiated ", this.pdfForms[this.currentFormIndex]);
                    console.log("Initiated Load", (this.initialLoad ? this.currentPage : (this.moveJumpArrowToNextPage && this.pdfForm.allFormFields[currentControlIndex] ? this.pdfForm.allFormFields[this.currentControlIndex].belongsToPage : this.pdfForm.allFormFields[this.currentControlIndex].belongsToPage)));
                    if (this.files[this.currentFormIndex]["documentType"] === DOC_TYPE.SIGN && this.isSignable) {
                        // if is signing and signing date show first guided tag only
                        if (this.pdfForm.allFormFields.length > 0) {
                            this.pdfForm.allFormFields[0].guidedTagEnabled = true;
                        }
                    } else if (!this.isSigningDate) {
                        this.pdfForms.forEach((form, index) => {
                            if (!form.isEnote && form.allFormFields.length > 0 && this.files[index]["documentType"] === DOC_TYPE.SIGN && form.availableForPresign) {
                                this.renderJumpArrow = true;
                                form.allFormFields.forEach((tag) => {
                                    tag.guidedTagEnabled = true;
                                });
                            }
                        });
                    }

                    $(`.thumbnail-container > div > div:first-child`).addClass("active-thumbnail");
                    $('.signer-room-form-containter > div > div:last-child');
                    let width = SIGNING_ROOM_RENDER_SIZE;


                    $('.signer-room-form-containter > div').append(`<div data-page="1" class="DUMMYDOCUMENT" data-pagename="DUMMYDOCUMENT" style="${this.isMobile() ? 'height:25vh' : 'height:10vh'}; width: ${width}px; margin-left:10px"></div>`);

                    // set up thumbnail listener
                    let self = this;
                    $('.thumbnail-container > div').on("click", "div[data-pagename]", function () {
                        let num = $(this).attr("data-page");
                        let name = $(this).attr("data-pagename");
                        $('.thumbnail-container > div > div').removeClass("active-thumbnail");
                        self.scrollPageIntoViewCentered(`.signer-room-form-containter div[data-page='${num}'][data-pagename='${name}']`);


                        $(this).addClass("active-thumbnail");
                    });
                    $(".guided-arrow-container").css('height', $(".signer-room-form-containter > div").height() + "px");

                    // resize event for eNote -

                    window.onresize = () => {
                        if (window.innerWidth <= 1280) {
                            if ($(".signer-room-form-containter iframe").parent().width() < 700) {
                                $("iframe").css("width", "100%");
                            } else {
                                $(".signer-room-form-containter iframe").css("width", "70%");
                            }
                        } else {
                            $(".signer-room-form-containter iframe").css("width", "100%");
                        }



                        // reposition enote tag location because text will wrap and overall document height changes
                        let eNoteIndex = this.pdfForms.findIndex((item) => item.isEnote);

                        let frame = $(".signer-room-form-containter iframe")[0];
                        let fields = frame.contentWindow.document.getElementsByTagName("signature_line");
                        let pdfList = this.pdfForms;
                        for (let field of fields) {
                            let client = field.getBoundingClientRect();
                            for (let i = 0; i < pdfList[eNoteIndex].allFormFields.length; i++) {
                                let tag = pdfList[eNoteIndex].allFormFields[i].smartTag;
                                let item = pdfList[eNoteIndex].allFormFields[i];
                                console.log("Fields", tag, item);
                                let img = tag.imgUrl || "";
                                if (tag.controlId === field.getAttribute("_id") && !tag.imgUrl) {
                                    item.x = client.left;
                                    item.y = client.top;
                                }
                            }

                        }




                        var newPx_ratio = window.devicePixelRatio || window.screen.availWidth / document.documentElement.clientWidth;
                        if (newPx_ratio != PIXEL_RATIO) {
                            PIXEL_RATIO = newPx_ratio;
                            console.log("zooming");
                        } else {
                            console.log("just resizing");
                        }

                        this.repositionEnoteGuidedArrowTag();

                    };

                    this.checkAndEnableGuidedTags();
                    this.allDocumentsSignedAndReviewed();

                    setInterval(() => {
                        if (IS_SCROLLING) {
                            IS_SCROLLING = false;
                            this.NotifyWitnessofScrolling();
                            this.onScrollHandler();
                            this.repositionEnoteGuidedArrowTag();
                        }
                    }, 300);


                    let length = this.files.length;
                    if (length > 0) {
                        var alreadyReviewed = ALREADY_REVIEWED_IDS.find(x => x === x === this.files[length - 1].documentID);
                        LAST_DOCUMENT_TO_REVIEW = {
                            file: this.files[length - 1],
                            ReviewedPageCount: alreadyReviewed !== null ? this.files[length - 1].pages : 0,
                            NumPages: this.files[length - 1].pages,
                            WaitingOnDocumentToClose: false
                        };
                    }
                }


                this.checkAndEnableGuidedTags();
                if (!this.isRon) {
                    this.lazyLoadNextDocumentSet();
                    $(".guided-arrow-container").css('height', $(".signer-room-form-containter > div").height() + "px");
                }
            },
            lazyLoadNextDocumentSet(documentsToLazyLoad = []) {
                let self = this;

                if (this.allDocumentsLoadedInUI) {
                    //WAY_POINT.destroy();
                    console.log("Destroyed Waypoint")
                    return;
                }



                let minCount = 0;
                let filesToInitiallyLoad = [];
                for (let i = 0; i < this.files.length; i++) {
                    if (this.files[i].isLoadedInUI === false && minCount <= 2) {
                        filesToInitiallyLoad.push(this.files[i]);
                        minCount++;
                    } else if (minCount > 2) {
                        break;
                    }
                }

                console.log("FIles TO LOAD ", filesToInitiallyLoad);
                if (filesToInitiallyLoad.length === 0)
                    return;

                IS_LAZY_LOADING = true;
                let eNotesToLoad = [];
                var pdfForm = new PDFForm();

                let width = SIGNING_ROOM_RENDER_SIZE;
                let thumbWidth = SIGNING_ROOM_THUMBNAIL_RENDER_SIZE;

                pdfForm.loadMulitplePDFs(this.signer.id, this.signer.id, filesToInitiallyLoad, 0, '.signer-room-form-containter > div', width, false, true, false, (page, currentObject, index) => {
                    // after main view loaded, load previews cause we need access to the form controls from the loaded pdf form
                    if (page === currentObject.pages) {// only execite when last page was rendered - for current processing document

                        if (!currentObject.isEnote) {
                            var previewForm = new PDFForm();
                            previewForm.loadPdf('.thumbnail-container > div', currentObject.noSpaceName, currentObject.path, thumbWidth, false, false, (pageNum) => {
                                // post render after last page was called
                                if (pageNum === previewForm.numpages) {

                                }
                            });
                        } else {
                            eNotesToLoad.push(currentObject);
                        }
                    }


                    // assign variables to SignerRoom object when all files loaded recursively
                    if ((filesToInitiallyLoad.length - 1) === index && page === currentObject.pages) {
                        // quick hack to have enote load last. when last pdf document is being rendered this code is called before the pdf is on the ui
                        setTimeout(() => {
                            var previewForm = new PDFForm();
                            eNotesToLoad.forEach((note) => {
                                previewForm.loadEnoteThumbnail(note.noSpaceName, note.html, null, thumbWidth);
                            });
                            self.updatePreviewBorder();
                        }, 1000)
                        self.pdfForms = self.pdfForms.concat(pdfForm.pdfForms);
                        for (let i = 0; i < filesToInitiallyLoad.length; i++) {
                            for (let j = 0; j < self.files.length; j++) {
                                if (filesToInitiallyLoad[i].documentId === self.files[j].documentId) {
                                    self.files[j].isLoadedInUI = true;
                                    console.log("Found boy");
                                }
                            }

                        }
                        $(".DUMMYDOCUMENT").appendTo($(".DUMMYDOCUMENT").parent());
                        self.initiateContinuousScroll(true);
                        IS_LAZY_LOADING = false;
                        if (NAVIGATED_WHILE_LAZY_LOADING)
                            self.jumpToNextGuidedArrow(false, true);
                        //self.updatePreviewBorder();
                    }

                });

            },
            handleLastDocumentToReviewPageCount(page) {
                let pageNum = parseInt(page);
                if (pageNum > LAST_DOCUMENT_TO_REVIEW.ReviewedPageCount)
                    LAST_DOCUMENT_TO_REVIEW.ReviewedPageCount = pageNum;

            },
            hasToConfirmDocumentHasBeenReviewed() {
                let fileData = this.files[this.currentFormIndex];
                let pdfData = this.pdfForms[this.currentFormIndex];
                if (fileData["documentType"] === DOC_TYPE.REVIEW && !this.settings.isContinuousScroll) {
                    this.showReviewContinueButton = true;
                    if (this.isSigningDate) {
                        $(".signer-room-form-containter").css('overflow-y', 'hidden');
                    }
                    return true;
                }
                return false;
            },
            userClickedReviewed() {
                // DO API Request to mark Document as reviewed or page
                if (this.settings.isContinuousScroll) {
                    this.pdfForm.formIsReviewed = true;
                    let nextDocument = this.currentFormIndex + 1;
                    let form = this.pdfForms[nextDocument];
                    if (form) {
                        this.currentControlIndex = -1;
                        this.currentFormIndex = nextDocument;
                        this.currentScrollDocName = this.files[this.currentFormIndex].name
                        this.currentDocType = this.files[this.currentFormIndex].documentType
                        if (this.files[this.currentFormIndex]["documentType"] !== DOC_TYPE.REVIEW) {
                            this.showReviewContinueButton = false;
                            this.pdfForm.allFormFields[0].guidedTagEnabled = true;
                        }

                        $(".signer-room-form-containter").css('overflow-y', 'auto');
                        this.scrollPageIntoViewCentered(`.signer-room-form-containter div[data-page="1"][data-pagename="${form.pdfName}"]`);
                    }
                } else {

                }
                this.allDocumentsSignedAndReviewed();
            },
            isTagOptional(smartTag) {
                //return SKIPPABLE_TAGS.indexOf(tagType) >= 0;
                return smartTag.isOptional;
            },
            getTagsPerPage(tags) {
                // this.$nextTick(() => {
                //   this.setUpMoveEventListeners();
                // });
                return tags.filter((t) => t.pageNo == this.activePageIndex + 1);
            },
            confirmTagTextInput(name) {
                if (
                    this.filesToSign[this.activeDocIndex]["smartTags"][this.activeSmartTagIndex][
                    "displayText"
                    ] != name
                ) {
                    const confirmed = window.confirm(
                        `Are you sure you want to replace tag with "${name}"`
                    );
                    return confirmed;
                }
                return false;
            },
            confirmTagImgInput(imgPath, name) {
                if (
                    this.filesToSign[this.activeDocIndex]["smartTags"][this.activeSmartTagIndex][
                    "imgUrl"
                    ] != imgPath
                ) {
                    const confirmed = window.confirm(
                        `Are you sure you want to replace tag with "${name}"`
                    );
                    return confirmed;
                }
                return false;
            },

            showSignModal() {
                this.erase();
                $(this.$refs.modalSignatureRef).modal("show");
            },
            showFreeFormModal() {
                $(this.$refs.modalFreeFormRef).modal("show");
            },
            showDateModal() {
                $(this.$refs.dateModal).modal("show");
            },
            erase() {
                this.signatureImageBase64 = "";
                if (this.signatureCanvasContext)
                    this.signatureCanvasContext.clearRect(0, 0, 360, 160);

                let ctx = this.$refs.signatureCanvas.getContext("2d");
                ctx.canvas.height = 160;
                ctx.canvas.width = 360;
            },
            cropImageFromCanvas(context) {
                let ctx = context || this.$refs.signatureCanvas.getContext("2d");
                var canvas = ctx.canvas,
                    w = canvas.width, h = canvas.height,
                    pix = { x: [], y: [] },
                    imageData = ctx.getImageData(0, 0, canvas.width, canvas.height),
                    x, y, index;

                for (y = 0; y < h; y++) {
                    for (x = 0; x < w; x++) {
                        index = (y * w + x) * 4;
                        if (imageData.data[index + 3] > 0) {
                            pix.x.push(x);
                            pix.y.push(y);
                        }
                    }
                }
                pix.x.sort(function (a, b) { return a - b });
                pix.y.sort(function (a, b) { return a - b });
                var n = pix.x.length - 1;

                w = 1 + pix.x[n] - pix.x[0];
                h = 1 + pix.y[n] - pix.y[0];
                var cut = ctx.getImageData(pix.x[0], pix.y[0], w, h);

                canvas.width = w;
                canvas.height = h;
                ctx.putImageData(cut, 0, 0);

                var image = canvas.toDataURL();  //open cropped image in a new window
                return image;
            },
            async saveDrawSignature() {
                let img = this.cropImageFromCanvas();// this.$refs.signatureCanvas.toDataURL();

                // replace input with image
                this.setImageInPdf(img);

                let results = await ApiService.GeneratePostRequest(
                    "signer/signature/upload",
                    {
                        subType: this.signatureTypeId,
                        imageData: img.split(",")[1],
                        signatureType: 1,
                    }
                );
                if (results.isSuccess && results.content) {
                    const imgPath = results.content + "?timestamp=" + new Date().getTime();

                    let control = this.pdfForm.allFormFields[this.setControlIndexForDrawSignature];
                    $(`#ga-${this.pdfForm.pdfName}-${control.noSpaceIdAlias}`).fadeOut('slow');
                    console.log("TAG TO HIDE", `#ga-${this.pdfForm.pdfName}-${control.noSpaceIdAlias}`);
                    this.updateControlData(this.pdfForm.allFormFields[this.setControlIndexForDrawSignature], imgPath);

                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-end',
                        showConfirmButton: false,
                        timer: 7000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })
                    Toast.fire({
                        icon: 'success',
                        title: 'Signature saved'
                    })
                }
                this.allDocumentsSignedAndReviewed();
                $(this.$refs.modalSignatureRef).modal("hide");
                this.erase();
            },
            async saveFreeForm() {
                if (this.freeFormText.trim() !== "") {
                    this.updateControlData(this.pdfForm.allFormFields[this.currentControlIndex]);
                    this.freeFormText = "";
                }
                $(this.$refs.modalFreeFormRef).modal("hide");
            },
            async saveDate() {
                this.updateControlData(this.pdfForm.allFormFields[this.currentControlIndex]);
                $(this.$refs.dateModal).modal("hide");
                this.dateFieldText = "";
            },
            async saveFontSignature() {
                let errorList = [];

                if (this.activeSignatureFontFamily == "") {
                    Swal.fire({
                        title: 'Please select a font',
                        text: '',
                        icon: 'info',
                        confirmButtonText: 'Ok'
                    });
                    return;
                }

                if (this.signatureText.length < 2) {
                    errorList.push("Signature length cannot be less than 2 characters");
                }
                if (this.signatureText.length > 150) {
                    errorList.push("Signature length cannot be greater than 150 characters");
                }
                const regex = new RegExp(/^[A-zÀ-ÿ][-,A-z. ']+$/);
                console.log("this.signatureText", this.signatureText)
                console.log("regex.test", regex.test(this.signatureText))
                if (regex.test(this.signatureText) == false) {
                    errorList.push("Signature contains invalid characters");
                }
                if (errorList.length > 0) {
                    this.typeErrorList = [...errorList];
                    return;
                } else {
                    this.typeErrorList = []
                }

                this.signatureType = "canvas";

                var canvas = document.createElement("canvas");
                canvas.width = 1000;
                canvas.height = 221;
                canvas.style.height = 250;
                var ctx = canvas.getContext("2d");

                ctx.font = "32px " + this.activeSignatureFontFamily;
                var text = this.signatureText;
                ctx.fillStyle = this.lineColor;
                ctx.textAlign = "center";
                ctx.fillText(text, 500, 110.5);



                let img = this.cropImageFromCanvas(ctx);//canvas.toDataURL();
                this.setImageInPdf(img);

                let results = await ApiService.GeneratePostRequest(
                    "signer/signature/upload",
                    {
                        subType: this.signatureTypeId,
                        imageData: img.split(",")[1],
                        signatureType: 2,
                    }
                );
                if (results.isSuccess && results.content) {
                    const imgPath =
                        results.content + "?timestamp=" + new Date().getTime();
                    let control = this.pdfForm.allFormFields[this.setControlIndexForDrawSignature];
                    $(`#ga-${this.pdfForm.pdfName}-${control.noSpaceIdAlias}`).fadeOut('slow');
                    this.updateControlData(this.pdfForm.allFormFields[this.setControlIndexForDrawSignature], imgPath);

                }
                this.allDocumentsSignedAndReviewed();
                $(this.$refs.modalSignatureRef).modal("hide");
                this.erase();
            },
            async saveImageSignature() {
                let results = await ApiService.GeneratePostRequest(
                    "signer/signature/upload",
                    {
                        subType: this.signatureTypeId,
                        imageData: this.signatureImageBase64.split(",")[1],
                        signatureType: 3,
                    }
                );
                if (results.isSuccess && results.content) {
                    const imgPath = results.content + "?timestamp=" + new Date().getTime();
                    // replace input with image
                    this.setImageInPdf(this.apiUrl + imgPath);


                    let control = this.pdfForm.allFormFields[this.setControlIndexForDrawSignature];
                    $(`#ga-${this.pdfForm.pdfName}-${control.noSpaceIdAlias}`).fadeOut('slow');
                    this.updateControlData(this.pdfForm.allFormFields[this.setControlIndexForDrawSignature], imgPath);
                }
                this.allDocumentsSignedAndReviewed();
                $(this.$refs.modalSignatureRef).modal("hide");
                this.erase();
            },
            convertToBase64(event) {
                const [file] = event.target.files;
                if (file) {
                    var reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        this.signatureImageBase64 = reader.result;
                    };
                }
            },
            async declineENote() {
                if (confirm("Are you sure you want to decline this consent?")) {
                    ;
                    var eNotes = this.filesToSign
                        .filter((x) => x.isEnote)
                        .map((x) => {
                            return {
                                id: x.packageDocumentId,
                                westSign: false,
                            };
                        });
                    let results = await ApiService.GeneratePostRequest(
                        "package/document/update/wetsign",
                        eNotes
                    );
                    if (results["isSuccess"]) {
                        Swal.fire({
                            title: 'eConsent declined. Closing Session',
                            text: '',
                            icon: 'warning',
                            confirmButtonText: 'Ok'
                        });
                        window.location = "/sign-out";
                    } else {
                        Swal.fire({
                            title: 'Error',
                            text: 'Something went wrong...',
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        });
                    }
                }
            },
            async acceptENote() {

                var eNotes = this.filesToSign
                    .filter((x) => x.isEnote)
                    .map((x) => {
                        return {
                            id: x.packageDocumentId,
                            westSign: false,
                        };
                    });
                let results = await ApiService.GeneratePostRequest(
                    "package/document/update/wetsign",
                    eNotes
                );
                if (results["isSuccess"]) {
                    this.showEnotes = true;
                    this.loadLegend();
                    $(this.$refs.modalENoteConsent).modal("hide");
                } else {
                    this.showEnotes = false;
                    Swal.fire({
                        title: 'Error',
                        text: 'Something went wrong...',
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    });
                }
            },
            //drawer functions
            handleClick() {
                this.expanded = !this.expanded;
                if (!this.expanded) {
                    this.items.forEach(item => item["data-expanded"] ? item["data-expanded"] = false : undefined);
                }
            },
            onSelect(ev) {
                if (this.signer.isWitness)
                    return;
                this.expanded = true;
                const currentItem = ev.itemTarget.props;
                const isParent = currentItem["data-expanded"] !== undefined;
                const nextExpanded = !currentItem["data-expanded"];

                if (!isParent) {

                    if (this.isSignable) {
                        this.renderJumpArrow = true;
                    }
                    this.activeDrawerIdx = currentItem.parent;

                    this.documentId = currentItem.docId;
                    var document = this.files.find(x => x.documentID == this.documentId);
                    this.currentScrollDocName = document.name
                    this.currentDocType = document.documentType
                    this.currentFormIndex = this.files.findIndex(x => x.documentID == this.documentId);
                    console.log("this.activeDrawerIdx", this.activeDrawerIdx, this.documentId);
                    console.log("itemList beforeMap", this.items);
                    if (currentItem.parent < 3) {
                        this.initialLoad = false;
                        this.currentControlIndex = -1;
                        this.scrollPageIntoViewCentered(`.signer-room-form-containter div[data-page="1"][data-pagename="${currentItem.nospacename}"]`);
                        // if (this.pdfForm && !this.pdfForm.allFieldsCompleted()) {
                        //     this.pdfForm.allFormFields[0].guidedTagEnabled = true;
                        // }
                    }

                } else {
                    this.activeDrawerIdx = currentItem.id;
                    this.documentId = 0;
                };

                const newData = this.items.map((item) => {
                    const {
                        selected,
                        ["data-expanded"]: currentExpanded,
                        id,
                        ...others
                    } = item;
                    const isCurrentItem = currentItem.id === id;
                    return {
                        selected: isCurrentItem,
                        ["data-expanded"]:
                            isCurrentItem && isParent ? nextExpanded : currentExpanded,
                        id,
                        ...others,
                    };
                });

                this.items = newData;
                console.log("itemList updated", this.items);
            },
            async allDocumentsLoaded() {
                if (this.mainBorrower || !this.isRon)
                    document.getElementById("overlay").style.display = "none";

                if (this.isRon) {
                    let isWaiting = this.mainBorrower ? false : true;
                    await this.canBorrowerSign();
                    await ApiService.GeneratePostRequest('signer/updateWaiting/' + this.NotarizeID + "/" + this.mainBorrower + "/" + isWaiting);

                }

            },
            formatStatus: function (status) {
                return GridDataHelper.normalizeStatus(status);
            },
            setImageInPdfFromNotary(imgUrl, pdfName, control,) {
                let new_element = $("<img/>");
                let selector = `#${pdfName}-${control.noSpaceIdAlias}`;

                if (!this.pdfForm.isEnote) {
                    $.each($(selector).get(0).attributes, function (i, attrib) {
                        $(new_element).attr(attrib.name, attrib.value);
                    });
                };
                let height = $(selector).height();
                let scaleHeight = height;
                $(new_element).attr('src', imgUrl);
                $(new_element).css('bottom', '0px');
                $(new_element).css('width', 'auto');
                $(new_element).css('height', scaleHeight + 'px');
                $(new_element).css('z-index', "1");

                $(new_element).css('max-height', '100%');
                $(new_element).css('max-width', "100%");
                if (!this.pdfForm.isEnote) {
                    $(new_element).css('position', 'absolute');
                    new_element.html($(selector).html());
                    $(selector).replaceWith(new_element);

                }
            },
            setImageInPdf: function (imgUrl, pdfName = false, control = false, isEnote = false) {
                let new_element = $("<img/>");
                let field = control || this.pdfForm.allFormFields[this.setControlIndexForDrawSignature];
                let name = pdfName || this.pdfForm.pdfName;
                let selector = `#${name}-${field.noSpaceIdAlias}`;
                if (!field.smartTag.isEnote) {
                    $.each($(selector).get(0).attributes, function (i, attrib) {
                        $(new_element).attr(attrib.name, attrib.value);
                    });
                };

                let height = $(selector).height();
                let scaleHeight = height;
                $(new_element).attr('src', imgUrl);
                $(new_element).css('bottom', '0px');
                $(new_element).css('width', 'auto');
                $(new_element).css('height', scaleHeight + 'px');
                $(new_element).css('z-index', "1");
                $(new_element).css('max-height', '100%');
                $(new_element).css('max-width', "100%");
                $(new_element).on('load', function () {
                    $("iframe").each(function (idx, iframe) {
                        iframe.contentWindow.postMessage("message", window.location.origin);
                    });
                });

                if (!this.pdfForm.isEnote) {
                    $(new_element).css('position', 'absolute');
                    new_element.html($(selector).html());
                    $(selector).replaceWith(new_element);

                } else {
                    let iframe = $(`#${this.pdfForm.pdfName}-EnotePage_1`)[0];
                    let imgContainers = iframe.contentWindow.document.documentElement.getElementsByTagName("signature_line");
                    for (let container of imgContainers) {
                        if ($(container).attr("_id") === this.pdfForm.allFormFields[this.setControlIndexForDrawSignature].id) {
                            // signatures not lining up with eNote fix
                            let marginLeft = $(container).closest("tr").css('margin-left');
                            if (marginLeft == "0" || marginLeft == "0px")
                                marginLeft = $(container).closest("p").css('text-indent');

                            console.log('$(container).closest("tr").css(margin - left)', marginLeft)
                            $(new_element).css('margin-left', marginLeft);
                            $(container).html(new_element);
                            $("iframe").each(function (idx, iframe) {
                                iframe.contentWindow.postMessage("message", window.location.origin);
                            });
                        }
                    }
                }


                if (pdfName && control) {
                    $(`#ga-${pdfName}-${control.noSpaceIdAlias}`).fadeOut('slow');
                }
            },
            setSignature: async function (tagType) {
                var tagTypeId = 1;
                switch (tagType) {
                    case "Signature":
                        tagTypeId = 1
                        break;

                    case "Initials":
                        tagTypeId = 3
                        break;

                    default:
                        break;
                }
                let result = await ApiService.GenerateGetRequest(
                    "signer/signature?subType=" + tagTypeId
                );
                if (result.isSuccess && result.content.exist) {
                    var imgPath = result.content.imageURL + "?timestamp=" + new Date().getTime();
                    let file = await ApiService.GenerateGetBlobRequest("document/load?path=" + imgPath);
                    const imageBlob = URL.createObjectURL(file);
                    //--Allow user the choice to use saved signature
                    // Swal.fire({
                    //         title: 'Use saved signature?',
                    //         imageUrl: imgPath,
                    //         imageHeight: 60,
                    //         imageAlt: 'saved_img_signature',
                    //         showDenyButton: true,
                    //         confirmButtonText: 'Use Saved Signature',
                    //           showCancelButton: true,
                    //         denyButtonText: "Change Signature",
                    //         allowOutsideClick:false,
                    // }).then(function (e) {
                    //     if(e.isConfirmed){
                    //         if(this.files[this.currentFormIndex]["isEnote"] == true){
                    //             this.setXmlSignature(imgPath, this.activeXmlTagId);
                    //         }else{
                    //         this.setImageInPdf(imgPath);
                    //         }
                    //         let control = this.pdfForm.allFormFields[this.setControlIndexForDrawSignature];
                    //         $(`#ga-${this.pdfForm.pdfName}-${control.noSpaceId}`).fadeOut('slow');
                    //         this.updateControlData(this.pdfForm.allFormFields[this.setControlIndexForDrawSignature], imgPath);
                    //         this.allDocumentsSignedAndReviewed();
                    //     }else if(e.isDenied){
                    //         this.showSignModal();
                    //     }
                    // }.bind(this))

                    //--Force user to use saved signature
                    this.setImageInPdf(imageBlob);
                    let control = this.pdfForm.allFormFields[this.setControlIndexForDrawSignature];
                    $(`#ga-${this.pdfForm.pdfName}-${control.noSpaceIdAlias}`).fadeOut('slow');
                    this.updateControlData(this.pdfForm.allFormFields[this.setControlIndexForDrawSignature], imgPath);
                    this.allDocumentsSignedAndReviewed();
                } else {
                    if (this.fastSignFont != null) {
                        this.saveFontSignature()
                    } else {
                        this.showSignModal();
                    }
                }

            },
            showTags(e) {
                //notifies signer when lender adds/updates a tag
                console.log("showTags called", e, e.tagSignerID, e.tagDocumentID);
                $(this.$refs.modalSignerTagsPlaced).modal('show');
                this.tag1 = e.tagSignerID;
                this.tag2 = e.tagDocumentID;
            },
            BroadcastAppliedTags(tagId, imgUrl, timestamp) {
                if (this.signer.isRon) {
                    ApiService.GeneratePostRequest(
                        `transaction/broadcastAppliedTags/?notarizeId=` + this.NotarizeID + '&tagDocumentID=' + tagId + "&imgUrl=" + imgUrl + "&timestamp=" + timestamp);
                }
            },
            SendMessageToNotary(message) {
                if (this.signer.isRon) {
                    ApiService.GeneratePostRequest(
                        `transaction/sendmessagetonotary/?message=${encodeURIComponent(message)}`);
                }
            },
            NotifyLenderofSigning(tagid, type, timestamp = "") {
                if (this.signer.isRon) {
                    //when signer signs a tag, send notification to lender
                    ApiService.GeneratePostRequest(
                        `transaction/signersignednotification/?tagID=` + tagid + '&type=' + encodeURIComponent(type) + '&timestamp=' + timestamp);
                    this.NotifyWitnessofSigning(tagid, type, timestamp)
                }
            },
            NotifyWitnessofSigning(tagid, type, timestamp = "") {
                if (this.signer.isRon) {
                    //when signer signs a tag, send notification to lender
                    ApiService.GeneratePostRequest(
                        `transaction/signersignednotificationtowitness/?from=b&tagID=` + tagid + '&type=' + type + '&timestamp=' + timestamp);
                }
            },
            NotifyWitnessofScrolling() {
                if (this.signer.isRon && this.signer.isWitness == false) {
                    //when signer signs a tag, send notification to lender
                    console.log("Sending position to witness");
                    let container = $(".signer-room-form-containter");
                    let top = container.scrollTop();
                    let height = container[0].scrollHeight;
                    ApiService.GeneratePostRequest(
                        `transaction/sendscrolltowitness/?pos=` + top + '&height=' + height);
                }
            },
            dynamicallyInsertPage(documentId, pages) {
                var self = this;
                self.$store.commit("App/updateLoaderOverride", {
                    showLoaderOverride: true,
                });

                let document = this.pdfForms.find(x => x.documentId === documentId);
                let pageToLoad = pages + 1;
                let width = SIGNING_ROOM_RENDER_SIZE;
                // load page in main view
                let pdfForm = new PDFForm();
                pdfForm.loadPdfPage(pageToLoad, '.signer-room-form-containter > div', document.pdfName, document.path, width, false, false, null, [], true, {
                    onRender(divElement) {
                        self.$store.commit("App/updateLoaderOverride", {
                            showLoaderOverride: false,
                        });
                        handleRender('.signer-room-form-containter', divElement);
                    }
                });

                // load in thumbnail view
                let thumbWidth = SIGNING_ROOM_THUMBNAIL_RENDER_SIZE;

                pdfForm.loadPdfPage(pageToLoad, '.thumbnail-container > div', document.pdfName, document.path, thumbWidth, false, false, null, [], true, {
                    onRender(divElement) {
                        handleRender('.thumbnail-container', divElement);
                    }
                });
                function handleRender(containerSelector, divElement) {
                    $(divElement).insertAfter(containerSelector + ' div[data-page="' + pages + '"][data-pagename="' + document.pdfName + '"]');
                    // update page numbers for newly inserted page
                    $(containerSelector + ' div[data-pagename="' + document.pdfName + '"]').each((index, item) => {
                        $(item).attr("data-page", index + 1)
                        $(item).find("form").each((i, form) => {
                            $(form).attr("id", document.pdfName + "-" + "Page_" + (index + 1));
                        });
                    });
                }
            },
            ReuploadDocumentsModal() {
                $(this.$refs.modalRetakePicture).modal('show');
            },
            isMobile() {
                if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    return true
                } else {
                    return false
                }
            },
            async refreshDocuments() {
                await this.fetchDocuments();
            },

        },
    };
</script>
<style>

    #overlay {
        position: fixed;
        display: none;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: white;
        z-index: 200;
        cursor: pointer;
    }

    #video-streams {
        position: absolute;
        right: 3px;
        top: 135px;
    }

    .full-screen {
        width: 50% !important;
        height: 100% !important;
        position: fixed !important;
        top: 10px !important;
        left: 0.5% !important;
        /* margin: auto; */
    }

    .videocalling.full-screen img.img-responsive.close-icon.position-left {
        display: block;
    }

    .videocalling img.img-responsive.close-icon.position-left {
        display: none;
    }

    .videocalling.full-screen {
        z-index: 999999 !important;
    }

        .videocalling.full-screen div#myPublisherDiv {
            width: 100% !important;
            height: 80% !important;
            z-index: 9999 !important;
        }

        .videocalling.full-screen img.img-responsive.close-icon.position-left {
            cursor: pointer;
        }

    .videocalling.ui-draggable.full-screen div#mySubscriberDiv {
        width: 100% !important;
        height: 100% !important;
        position: relative !important;
        top: 0 !important;
        left: 0 !important;
    }

        .videocalling.ui-draggable.full-screen div#mySubscriberDiv .OT_root {
            width: 100% !important;
            height: 100% !important;
        }

    #screen-page .videocalling.ui-draggable.full-screen img.img-responsive.close-icon.position-left {
        position: absolute;
        right: -30px !important;
        top: 0 !important;
        left: auto;
        background-color: #fff;
        z-index: 99999999 !important;
    }

        #screen-page .videocalling.ui-draggable.full-screen img.img-responsive.close-icon.position-left:hover {
            background-color: #f0f0f0;
        }

    section#screen-page {
        position: relative !important;
    }

    .videocalling.full-screen {
    }

        .videocalling.full-screen div#mySubscriberDiv {
            width: 100% !important;
            height: 100% !important;
        }

            .videocalling.full-screen div#mySubscriberDiv .OT_root {
                width: 100% !important;
                height: 100% !important;
            }

    #screen-page .videocalling.full-screen img.img-responsive.close-icon.position-left {
        left: auto;
        right: -30px !important;
        top: 0;
    }

    .activate-fullscreen {
        /* z-index: 100; */
        /* width: 450px; */
        /* height: 400px; */
        margin-left: -425px !important;
        min-width: 600px !important;
        min-height: 500px !important;
    }

    .errorLi {
        color: red;
        font-weight: bold;
        margin: 10px 0 0 20px !important;
        text-align: left;
        list-style-type: disc !important;
    }

    .showstatus {
        width: 100%;
        max-height: 60px;
        height: 60px;
        overflow-y: hidden;
        border-bottom: 1px solid #cdcdcd;
        box-shadow: 1px 1px 0 #ebebeb;
        font-size: 2em;
        line-height: 1.5em;
        text-align: center;
        margin: -20px 0 0 0;
        color: white;
    }

    .closing {
        background: #40e0a5;
    }

    .preclose {
        background: #3bc6f7;
    }

    .k-drawer-wrapper {
        margin-top: 0px;
    }

    #screen-page input {
        margin-bottom: initial;
    }

    .card {
        border-radius: 20px;
        margin-bottom: 20px;
        margin: 10px 20px;
    }
</style>

<style scoped>

    .k-card-body {
        padding: 0;
    }
    /*Drawer Styles*/
    .custom-toolbar {
        width: 50px;
        position: relative;
        float: left;
    }

    .k-icon::before {
        font-size: 14px;
    }

    .k-drawer-container .k-drawer-items .k-icon + .k-item-text {
        word-break: break-word;
        padding-left: 14px;
        line-height: 41px;
    }

    .k-drawer-content .row {
        margin-left: 0;
        margin-right: 0;
    }

    .k-drawer-item {
        padding: 1px 1px;
        margin: 0;
        font-size: 16px;
        line-height: 1.5;
    }

    .k-drawer-items li[data-expanded] {
        font-size: 16px;
        font-weight: bold;
        margin-top: 10px !important;
        margin-bottom: 0 !important;
    }

    .k-drawer-items li {
        padding: 0 0 0 15px;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        margin: 0;
        font-size: 14px;
    }

        .k-drawer-items li[data-expanded] .k-icon::before {
            font-size: 20px;
        }

        .k-drawer-items li.k-drawer-item.k-drawer-separator {
            margin-top: 10px !important;
        }

    .k-drawer-item .k-icon {
        line-height: initial;
    }

    .k-drawer-item .k-item-text {
        line-height: 25px !important;
        white-space: pre-line;
        margin-right: 2em;
    }
    /**NEW STYLES*/
    .signer-room-form-containter {
        background: #33363b;
        padding: 10px 0;
        overflow-x: hidden;
    }
    /**END NEW STYLES*/
    /* .smart-tag.ui-draggable.ui-draggable-handle.ui-resizable .ui-resizable {
      position: relative !important;
    } */
    #documentViewer {
        float: left;
        /*position: relative;*/
        display: block;
        width: 100%;
    }


    .smart-tag {
        float: left;
        cursor: move;
        border: 1px dotted rgb(168, 168, 168);
        background: #ffe6000e;
    }

    .tagText {
        font-family: Helvetica !important;
    }

    ul#nav-taggedpages a.tablinks {
        height: 30px;
        padding: 5px;
    }

    #img-viewer {
        border: 1px solid black;
        margin-top: 1px;
    }

    .img-viewer {
        margin: 0 auto;
        display: block;
        margin-bottom: 10px;
    }

    #myPublisherDiv,
    #mySubscriberDiv {
        width: 175px !important;
        height: 140px !important;
    }

    div#document-viewer div {
        cursor: pointer;
    }

    .thumbnail-container {
        background: #33363b;
        overflow: auto;
        padding: 0;
        border-right: 1px solid black;
    }

    .signer-room-form-containter, .thumbnail-container {
        height: calc(100vh - 220px);
    }

    .thumbnail-scroll img {
        border: 1px solid black;
        margin: 1px;
        margin-right: 0px;
    }

    .thumbnail-scroll {
        height: 100%;
        padding-bottom: 20px;
        overflow-y: auto;
        width: 100%;
    }

    nav#context-menu {
        position: absolute;
        border: 1px solid #000;
        border-radius: 3px;
        padding: 10px;
        z-index: 1001;
        background: transparent;
        box-shadow: none;
    }

    .context-menu {
        display: none;
        position: absolute;
        z-index: 10000;
        padding: 12px 0;
        width: 240px;
        background-color: #fff;
        border: solid 1px #dfdfdf;
        box-shadow: 1px 1px 2px #cfcfcf;
    }

    nav#context-menu span {
        cursor: pointer;
    }

    .highlight-xml-signature {
        border: 3px dotted rgba(0, 0, 0, 0.048);
        background: yellow;
    }


    .smart-tag > i {
        text-align: center;
        font: italic normal 90% Georgia, serif;
        line-height: 150%;
        color: black;
        text-shadow: white 0 1px;
        background: #ddd;
        background-clip: padding-box;
        box-shadow: 0 0px 2px rgba(0, 0, 0, 0.5);
        border: 2px solid rgba(0, 0, 0, 0.5);
        border-radius: 3px;
        position: absolute;
        width: 250px;
        left: 50%;
        margin-left: -125px;
        padding: 10px 0;
        bottom: 100%;
        margin-bottom: 15px;
        visibility: hidden;
        opacity: 0;
        -webkit-transition: opacity 0.5s linear;
        -moz-transition: opacity 0.5s linear;
        -ms-transition: opacity 0.5s linear;
        -o-transition: opacity 0.5s linear;
        transition: opacity 0.5s linear;
    }

        .smart-tag > i:before,
        .smart-tag > i:after {
            content: "";
            position: absolute;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            top: 100%;
            left: 50%;
            margin-left: -10px;
        }

        .smart-tag > i:before {
            border-top: 10px solid #111;
            border-top: 10px solid rgba(0, 0, 0, 0.5);
            margin-top: 5px;
        }

        .smart-tag > i:after {
            border-top: 10px solid #ddd;
            margin-top: -2px;
        }

    .smart-tag:hover > i {
        visibility: visible;
        opacity: 1;
        z-index: 1;
    }

    .review-button {
        position: absolute;
        color: #FFF;
        background: #7030A0;
        z-index: 1;
        width: 180px;
        padding: 10px 0;
        font-size: 1.2em;
        bottom: -55px;
        right: 0;
    }

    .thumbnail-container .signed {
        border: 3px solid green;
    }

    .thumbnail-container .to-be-signed {
        border: 3px solid yellow;
    }

    .watermark {
        pointer-events: none;
        user-select: none;
        position: absolute;
        color: gray;
        background: none;
        width: 100%;
        height: 100%;
        padding-left: 10%;
        font-size: 1500%;
        bottom: 5px;
        right: 5px;
        opacity: 0.16;
        border: none;
        z-index: 2;
        float: left;
        transform: rotate(-50deg);
        -ms-transform: rotate(-50deg); /* IE 9 */
        -webkit-transform: rotate(-50deg); /* Opera, Chrome, and Safari */
    }

    /*to remove later*/
    .k-drawer-item.k-state-selected, .k-drawer-item.k-state-selected:hover {
        color: #4d97ac;
        font-weight: bold;
    }

    .start-notary-session-button {
        background: #3F51B5;
        padding: 10px;
        border-radius: 22px;
        color: #FFF;
        font-weight: bold;
        border: 2px solid #3F51B5;
        width: 250px;
        display: inline-block;
        text-align: center;
        font-size: 1.4em;
        cursor: pointer;
    }

        .start-notary-session-button.disabled {
            background: #a19d9d;
            border: 2px solid #888686;
            cursor: auto;
        }

        .start-notary-session-button:hover {
            text-decoration: none;
        }

    .notary-session-countdown {
        width: 250px;
        text-align: center;
        font-weight: bold;
        margin-top: 5px;
    }

    .menu {
        display: inline-block;
        margin-right: 20px;
        float: left;
    }

        .menu p {
            margin-left: 50px;
            line-height: 18px;
            font-size: 18px;
            font-weight: bold;
            text-transform: uppercase;
            color: #000000;
            line-height: 40px;
        }

    .complete-btn {
        background-color: #4cb64c;
        font-weight: bold;
        padding: 12px 16px;
        font-size: 20px;
    }
</style>
<!-- MOBILE ONLY -->
<style>
    @media screen and (max-width: 600px) {
        .k-card-header .menu {
            display: none !important;
        }

        .k-drawer-mini .k-drawer.k-drawer-start {
            display: none !important;
        }

        .k-drawer-mini .k-drawer-content .thumbnail-container {
            display: none !important;
        }

        .k-drawer-mini .k-drawer-content .signer-room-form-containter {
            overflow-x: scroll;
        }

        .complete-btn {
            padding: 8px 16px !important;
            font-size: 14px !important;
        }

        .k-drawer-container {
            overflow: auto;
        }
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
        .k-drawer-mini .k-drawer-content .thumbnail-container {
            display: none !important;
        }
    }
</style>
<!-- MOBILE ONLY-->