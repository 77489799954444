<template>
    <div>
        <content-loader-overlay ref="contentLoader" :totalDocumentCount="files.length"/>
        <div class="row" v-show="showJournal">
            <div class="col-md-12">
                <notary-journal v-if="packageId != 0 && packageId != null && NotarizeID && NotarizeID!=0" :NotarizeID="NotarizeID" :pckgId="packageId" @backToSigning="showJournal = false" ref="journal" />
            </div>
        </div>

        <div class="row" v-show="!showScreen && !showJournal">
            <div :class="{ 'col-md-12': !isNotary,'col-md-9': isNotary }" style="margin-top:0; padding-right:0 ">
                <div class="participants-grid">
                    <div class="title-block" style="padding-left:0">
                        <card class="card" style="margin-left:0;">
                            <cardHeader>
                                <div class="row">
                                    <div class="col-md-10 col-sm-8" v-if="files.length > 0">
                                        <div style="float: left;">
                                            <div class="text-center">
                                                <span><strong>Tags Remaining</strong></span>
                                            </div>
                                            <div>
                                                <span style="display:block;"><strong>Mandatory: {{remainingMandatoryTags}} </strong></span>
                                                <span style="display:block;"><strong>Optional: {{remainingOptionalTags}}</strong></span>
                                            </div>
                                        </div>

                                        <h2 style="width: 80%; display: inline-block; float: right; font-size: 20px;" v-if="this.activeDrawerIdx != 3" class="text-center">{{this.formatStatus(this.currentDocType)}}: {{this.currentScrollDocName}}</h2>
                                    </div>
                                </div>
                            </cardHeader>
                            <cardBody>
                                <section>
                                    <div class="row" style="margin: 0">
                                        <div class="col-md-1 thumbnail-container">
                                            <div><div class="dummy-thumbnail"></div></div>
                                        </div>
                                        <div class="col-md-11 signer-room-form-containter" @wheel="checkItemsInView($event)" @scroll="checkItemsInView($event)" style="overflow-y:auto;">
                                            <section class="guided-arrow-container" style="position:absolute; width:100%;"></section>
                                            <div>

                                                <teleport v-if="renderJumpArrow && log(pdfForms[currentFormIndex]) && !canDisplayTagNav"
                                                          :to="teleportLocationForJumpArrow">
                                                    <guided-arrow @click.prevent="jumpToNextGuidedArrow"
                                                                  :pending="false"
                                                                  :enabled="true"
                                                                  :main="true"
                                                                  :elementInView="currentTagInView"
                                                                  :id="'navigateToNextControl'"
                                                                  :direction="jumpArrowDirection"
                                                                  :left="parseInt(pdfForm.viewport.width) + 15"
                                                                  :top="jumpArrowTopPosition"
                                                                  :text="jumpArrowText" />
                                                </teleport>
                                                <template v-if="pdfForms[currentFormIndex]">
                                                    <teleport :to="'.signer-room-form-containter div[data-pagename=\'' + pdfForms[currentFormIndex].pdfName + '\']' + '[data-page=\'' + pdfForms[currentFormIndex].numpages + '\']'">
                                                        <button @click="userClickedReviewed" v-if="showReviewContinueButton" class="review-button btn">
                                                            {{isSigningDate ? 'Continue' : 'Mark as Reviewed' }}
                                                        </button>
                                                    </teleport>
                                                </template>

                                                <template v-if="pdfForms.length > 0">
                                                    <template v-for="(pdf, pdfIndex) in pdfForms">
                                                        <template v-if="pdf.allFormFields && pdf.allFormFields.length > 0" v-for="(data, i) in pdf.allFormFields">
                                                            <teleport :to="'.signer-room-form-containter #' + pdf.pdfName + '-Page_' + data.belongsToPage">
                                                                <guided-arrow v-if="data.smartTag.tagType != 'AutomaticSignatureDate'"
                                                                              @click.prevent="guidedArrowClicked(data, i, pdfIndex)"
                                                                              :pending="data.value === ''"
                                                                              :enabled="data.guidedTagEnabled"
                                                                              :id="pdf.pdfName + '-' + data.noSpaceIdAlias"
                                                                              :direction="data.smartTag ? data.smartTag.tagType == 'CheckBox' ? 'right' : 'left' : 'left'"
                                                                              :increaseZIndex="true"
                                                                              :optional="isTagOptional(data.smartTag)"
                                                                              :left="data.smartTag ? data.smartTag.tagType != 'CheckBox' ? data.x + data.width + 25 :  data.x - 38 :  data.x + data.width + 25"
                                                                              :top="data.y - 8 " :text="guidedArrowText(data.smartTag ? data.smartTag.tagType : '', data)" />
                                                            </teleport>
                                                        </template>
                                                    </template>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </cardBody>
                        </card>

                        <div ref="dateModal" class="modal" style="display: none;">
                            <div>
                                <div class="modal-dialog">
                                    <div class="modal-content" style=" background: white;">
                                        <div class="modal-header">
                                            <div>
                                                <button type="button" class="close" data-dismiss="modal">
                                                    x
                                                </button>
                                            </div>
                                        </div>
                                        <div class="modal-body">
                                            <input type="date"
                                                   name="date-form-text"
                                                   v-model="dateFieldText"
                                                   placeholder="Type here"
                                                   class="form-control"
                                                   maxlength="80" />

                                            <div class="modal-footer">
                                                <div align="right" class="pull-right">
                                                    <button type="button"
                                                            class="btn btn-success"
                                                            @click.prevent="saveDate">
                                                        Accept
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div ref="modalFreeFormRef" class="modal" style="display: none;">
                            <div>
                                <div class="modal-dialog">
                                    <div class="modal-content" style=" background: white;">
                                        <div class="modal-header">
                                            <div>
                                                <button type="button" class="close" data-dismiss="modal">
                                                    x
                                                </button>
                                            </div>
                                        </div>
                                        <div class="modal-body">
                                            <input type="text"
                                                   name="free-form-text"
                                                   v-model="freeFormText"
                                                   placeholder="Type here"
                                                   class="form-control"
                                                   maxlength="80" />

                                            <div class="modal-footer">
                                                <div align="right" class="pull-right">
                                                    <button type="button"
                                                            class="btn btn-success"
                                                            @click.prevent="saveFreeForm">
                                                        Accept
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <section id="screen-page" style="padding:0 !important; background:none !important;">
                            <div class="modal fade" ref="incomingCallModal" role="dialog" id="incomingCallModal">
                                <div class="modal-dialog">
                                    <div class="modal-content" style="padding: 20px;">
                                        <setup @deviceSetup="getSetup" v-if="devicesConfig == false" />
                                        <div v-if="devicesConfig == true">
                                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                                            <div class="modal-header">
                                                <h4 class="modal-title">Waiting Signers Queue</h4>
                                            </div>
                                            <div class="modal-body">
                                                <div class="row callingdetails">
                                                    <div class="form-group" id="lstSignersInQueue">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="modal-footer">
                                                <button data-bb-handler="danger" type="button" class="btn btn-secondary" @click="hideSetup">Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>

                        <div ref="modalRetakePicture" class="modal fade" role="dialog" style="display: none;">
                            <div class="modal-dialog">
                                <!-- Modal content-->
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <button type="button" class="close" data-dismiss="modal">×</button>
                                        <h2 class="modal-title" id="modalRetakePictureTitle">--</h2>

                                        <select name="" id="videoSource" style="display:none"></select>

                                        <input type="hidden" id="hdnRetakePictureIsFront" />
                                        <input type="hidden" id="hdnRetakePictureType" />
                                        <input type="hidden" id="hdnRetakePictureSignerIdentity" />
                                    </div>
                                    <div class="modal-body">
                                        <div id="divRetakePicture">
                                            <button type="button" class="btn btn-primary" id="btnCaptureRetakePicture" @click="retakePicture">Retake Photo</button>
                                            <div v-show="hideVideo">
                                                <video id="retakeVideoPreview" autoplay class="img-responsive"></video>
                                                <button type="button" class="btn btn-primary" id="btnSaveRetakePicture" @click="btnCapturePic">Capture</button>
                                                <button type="button" class="btn btn-green" @click="cancelVideoCapture">Cancel</button>
                                            </div>
                                            <div v-show="pictureTaken">
                                                <img v-bind:src="ImageBinary" style="width:50%; height:50%" />
                                                <canvas id="canvas" style="display: none;"></canvas>
                                                <br />
                                            </div>

                                        </div>
                                        <div id="divReUploadPicture">
                                            <input type="file" id="reuploadPictureUploader" @change="showReUploadPreview($event)" />
                                        </div>
                                        <div id="divRetakePreview" style="display: none">
                                            <canvas id="canvasReUploadPicturePreview" style="width: 500px; height: 350px"></canvas>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-green" id="btnSaveRetakePicture" @click="saveReTakenPicture">Save</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div ref="modalSignerTagsPlaced" class="modal" style="display: none;">
                            <div>
                                <!-- Modal -->
                                <div class="modal fade" role="dialog">
                                    <div class="modal-dialog modal-lg">
                                        <!-- Modal content-->
                                        <div class="modal-content" style="padding: 20px 0px;">
                                            <button type="button" class="close" data-dismiss="modal">&times;</button>
                                            <div class="modal-body">
                                                <div class="row text-center">
                                                    <div class="col-md-6">
                                                        <!--<img src="~/images/icon-recyle.png" class="icon-recyle" alt="">-->
                                                        <h3>Signer Tag Placed </h3>
                                                        <h3 style="font-weight: 300">Estimating wait time</h3>
                                                        <p> <small> A tag has been placed. </small></p>
                                                        <div class="btn-screen-page text-center">
                                                            <!--@Html.ActionLink("CANCEL MEETING", "CancelMeeting", "Document", null, new { @class = "btn-secondary" })-->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="col-md-3" style="margin-top:20px; padding-left:0" v-if="isNotary">
                <videosession v-if="NotarizeID && NotarizeID!=0" :NotarizeID="NotarizeID" :Type="'Notary'" @startJournal="startJournalCall"></videosession><br />
                <notary-control-panel
                                      :connectedSigners="connectedSigners"
                                      @selectedParticiapnt="participantChanged"
                                      @tagClicked="controlPaneTagClicked" 
                                      @enableSigning="enableSigning" 
                                      @signerDetailsClick="showJournalModal"
                                      @abort="abortMeeting"
                                      @showIAttest="SendShowIAttestToLegalWitness"
                                      @showModalNotarialAct="getModalNotarialActToggle"
                                      @notarialActType="getNotarialType"
                                      @addParticipant="showParticipant"
                                      @updateList="refreshSigners"
                                      :NotarizeID="NotarizeID"
                                      :documents="notarizeDocuments" />
                <!--<notary-waiting-list v-if="NotarizeID && NotarizeID != null" :NotarizeID="NotarizeID" :key="connectedSigners"></notary-waiting-list>-->
                <kbutton style="background-color: #4cb64c; color: white; margin-top: 20px; margin-right: 1em;" @click="completeSigning" v-show="isNotary">Complete Signing</kbutton>
                <!--<kbutton style="background-color: #3694da; color: white; margin-top: 20px; margin-right: 1em;" @click="showParticipant" v-show="isNotary">Invite Participant(s)</kbutton>-->
                <kbutton style="background: rgb(255 104 32 / 87%); color: white; margin-top: 20px;" @click="handleForceClose" v-show="showForceCloseButton">Force Close</kbutton>
            </div>
        </div>
        <div v-show="showScreen">
            <h2>Signer Screen</h2>
            <!--<screenshare :NotarizeID="NotarizeID" :sType="'Notary'" v-if="OTSession != null" :screenSession="OTSession"></screenshare>-->
        </div>

        <div ref="modalSignatureRef" class="modal" style="display: none;">
            <input type="hidden" id="MS_Mode" value="Signature" />
            <div id="divDrawing">
                <link href="https://fonts.googleapis.com/css?family=Allura&amp;display=swap"
                      rel="stylesheet" />
                <link href="https://fonts.googleapis.com/css?family=Dancing+Script&amp;display=swap"
                      rel="stylesheet" />
                <link href="https://fonts.googleapis.com/css?family=Mansalva&amp;display=swap"
                      rel="stylesheet" />
                <link href="https://fonts.googleapis.com/css?family=Satisfy&amp;display=swap"
                      rel="stylesheet" />
                <link href="https://fonts.googleapis.com/css?family=Sacramento&amp;display=swap"
                      rel="stylesheet" />
                <link href="https://fonts.googleapis.com/css?family=Vibes&amp;display=swap"
                      rel="stylesheet" />

                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <ul class="nav nav-tabs">
                                <li class="active">
                                    <a v-show="signingTypes.Draw" data-toggle="tab" href="#Draw">Draw</a>
                                </li>
                                <li class="">
                                    <a v-show="signingTypes.Type" data-toggle="tab" href="#Type">Type</a>
                                </li>
                                <li><a v-show="signingTypes.Upload" data-toggle="tab" href="#Upload">Upload</a></li>
                            </ul>
                            <div>
                                <button type="button" class="close" data-dismiss="modal">
                                    x
                                </button>
                            </div>
                        </div>
                        <div class="modal-body">
                            <div class="tab-content">
                                <div id="Draw" class='tab-pane fade' v-show="signingTypes.Draw">
                                    <div>
                                        <div class="img-relative">
                                            <canvas ref="signatureCanvas"
                                                    style=" width: 360px; height: 160px;"></canvas>
                                            <img id="commentImg"
                                                 style="border: solid; display: none;"
                                                 height="360px"
                                                 width="160px" />
                                        </div>
                                        <div class="modal-footer">
                                            <div align="right">
                                                <button type="button"
                                                        class="btn btn-secondary"
                                                        @click.prevent="erase">
                                                    Clear
                                                </button>
                                                <button type="button"
                                                        style="background-color: #5cb85c; border-color: #4cae4c;"
                                                        class="btn btn-secondary"
                                                        @click.prevent="saveDrawSignature">
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="Type" class='tab-pane fade' v-show="signingTypes.Type">
                                    <input type="text"
                                           name="signature-text"
                                           readonly
                                           :value="signatureText"
                                           placeholder="Type here"
                                           class="form-control"
                                           :style="typeErrorList.length>0 ? 'border-color:red' : ''" />
                                    <ul v-show="typeErrorList.length>0">
                                        <li class="errorLi" v-for="item in typeErrorList" :key="item">
                                            {{ item }}
                                        </li>
                                    </ul>
                                    <div class="font-sample-preview">
                                        <div v-for="(item, index) in fonts"
                                             :key="index"
                                             class="signature-view"
                                             :class="{active: activeSignatureFontFamily == item.fontFam}"
                                             :style="{color: lineColor }"
                                             @click.prevent="activeSignatureFontFamily = item.fontFam">
                                            <h1 :style="{ fontFamily: item.fontFam }">
                                                {{ signatureText }}
                                            </h1>
                                            <!--<canvas width="250" height="80" v-insert-message="signatureText" :ref="item.fontFam" :id="item.fontFam" :style="{fontFamily: item.fontFam}"></canvas>-->
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <div align="right" class="pull-right">
                                            <button type="button"
                                                    class="btn btn-success"
                                                    @click.prevent="saveFontSignature">
                                                Accept
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div id="Upload" class='tab-pane fade' v-show="signingTypes.Upload">
                                    <div class="bdr upload-files">
                                        <img v-if="signatureImageBase64 !== ''"
                                             class="img-fluid img-responsive"
                                             :src="signatureImageBase64" />
                                        <img v-else
                                             src="/images/upload-icon.png"
                                             alt=""
                                             class="img-fluid img-responsive" />
                                        <p>
                                            <input ref="uploadSignatureFile"
                                                   type="file"
                                                   class="custom-file-input"
                                                   accept="image/x-png,image/jpeg"
                                                   @change="convertToBase64($event)" />
                                        </p>
                                    </div>
                                    <div class="modal-footer">
                                        <div align="right">
                                            <button type="button"
                                                    class="btn btn-success btn-block"
                                                    @click.prevent="saveImageSignature">
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div ref="modalParticipantRef" class="modal" style="display: none;">
            <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                        <h5 class="modal-title">Add Participant</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        <div class="modal-body">
                            <div class="tab-content">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <k-input :label="'Participant First Name*'"
                                                 :name="'PFirstName'"
                                                 v-model="PFirstName"
                                                 :disabled="false"
                                                 :read-only="false"
                                                 :required="true">
                                        </k-input>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <k-input :label="'Participant Middle Name'"
                                                 :name="'PMiddleName'"
                                                 v-model="PMiddleName"
                                                 :disabled="false"
                                                 :read-only="false"
                                                 :required="false">
                                        </k-input>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <k-input :label="'Participant Last Name*'"
                                                 :name="'PLastName'"
                                                 v-model="PLastName"
                                                 :disabled="false"
                                                 :read-only="false"
                                                 :required="true">
                                        </k-input>
                                </div>
                                </div>
                                </div>

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <k-input :label="'Participant Email*'"
                                                 :name="'PEmailAddress'"
                                                 v-model="PEmailAddress"
                                                 :disabled="false"
                                                 :read-only="false"
                                                 :required="true"
                                                 pattern="[A-Za-z0-9._%+-]{2,}@[a-zA-Z]{1,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})">
                                        </k-input>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <k-input :label="'Participant Mobile Number'"
                                                 :name="'PMobileNumber'"
                                                 v-model="PMobileNumber"
                                                 :disabled="false"
                                                 :read-only="false"
                                                 :required="false">
                                        </k-input>
                                    </div>
                                </div>
                                </div>

                            <div class="form-group">
                                <k-input :label="'Participant SSN'"
                                         :name="'PSSN'"
                                         v-model="PSSN"
                                         :disabled="false"
                                         :read-only="false"
                                         :required="false">
                                </k-input>
                                </div>

                            <div class="form-group">
                                <dropdownlist :label="'Participant Type'"
                                              :data-items="participantTypes" 
                                              :data-item-key="'id'" 
                                              :text-field="'text'" 
                                              :name="'selectedParticipantType'"
                                              :value="selectedParticipantType" 
                                              @change="handleChange">
                                </dropdownlist>
                                </div>

                            <div class="row">
                                <kbutton type="button" :primary="true" class="btn btn-secondary pull-right" @click.prevent="addParticipant" :disabled="formValid">Send Invitation</kbutton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <teleport :to="dynamicTagContainerLocation">
            <div id="dynamicTagContainer"></div>
        </teleport>

        <teleport v-if="teleportLocation !== 'body'" :to="teleportLocation">
            <!-- currentHighlightedTag.width + "px" -->
            <div id="actionBar" style="position: fixed;z-index:1;  display: flex; " :style="{ width:   '300px', top: currentHighlightedTag.top + 'px', left: currentHighlightedTag.left + 'px' }">
                <div style=" width: 100%; display: flex; justify-content: space-between; align-items: center;">
                    <kbutton primary="true" @click.prevent="placeSeal">Place {{currentHighlightedTag.smartTag.tagType}}</kbutton>
                    <kbutton style="background: #F01E2C; " primary="true" @click.prevent="removeSeal">Remove</kbutton>
                </div>
            </div>
        </teleport>
        <div ref="outstandingTagsModal" class="modal" style="display: none;">
            <div>
                <div class="modal-dialog">
                    <div class="modal-content" style=" background: white;">
                        <div class="modal-header">
                            <h2 class="modal-title" style="text-align:center;">Error Closing Package</h2>
                            <div>
                                <button type="button" class="close" data-dismiss="modal">
                                    x
                                </button>
                            </div>
                        </div>

                        <div class="modal-body">
                            <card class="card" v-for="(a, aKey, aIndex) in groupedOutstandingTags">
                                <cardHeader class="text-center">
                                    <h3 style="margin-left:.5rem;">{{aKey}}</h3>
                                </cardHeader>
                                <cardBody>
                                    <div class="table-responsive">
                                        <table class="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th scope="col" class="col-md-9">Document</th>
                                                    <th scope="col" class="col-md-3" style="text-align:center;">Mandatory Outstanding Tags</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(b, bKey, bIndex) in a" :key="bIndex">
                                                    <td class="col-md-9">{{b.documentDescription}}</td>
                                                    <td class="col-md-3" style="text-align:center;">{{b.outstandingTags}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </cardBody>
                            </card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <popup :show="showSealType" :popup-class="'popup-content'" :offset="offset" style="width:35vw; margin:0; position:absolute; top:25vh; left:75vh;">
        <div id='seal-type' style="padding:1em;">
            <div class="row pb-m">
                <div class='col-lg-6'>
                    <h4>Select Notarial Act</h4>
                </div>
                <div class='col-lg-6' style="text-align:right;">
                    <button @click="saveSealType" class="k-button k-primary" style="margin-right:1em;">
                        Select
                    </button>
                </div>
            </div>
            <br />
            <div class='col-md-12'>
                <dropdownlist :style="{ width: '100%' }"
                              :data-items='sealTypes'
                              :data-item-key="'id'"
                              :text-field="'notarialActName'"
                              :value="selectedSealType != null ? selectedSealType : {}"
                              :required="true"
                              @change="changeSealType">
                </dropdownlist>
            </div>
        </div>
    </popup>
</template>

<script>
	import { required, email } from '@vuelidate/validators';
    import ApiService from "@/core/services/api-service";
    import PDFForm from "@/core/services/pdf-form";
    import { PanelBar } from '@progress/kendo-layout-vue-wrapper';
    import { loadFileUrl } from "@/core/services/api-service";
    import Checklist from "@/components/Checklist.vue";
    import NotaryControlPanel from "@/components/NotaryControlPanel.vue";
    import GuidedArrow from "@/components/GuidedArrow.vue";
    import { Button } from "@progress/kendo-vue-buttons";
    import { Drawer, DrawerContent, DrawerItem } from "@progress/kendo-vue-layout";
    import {
        Card,
        CardHeader,
        CardBody,
        CardTitle,
        CardSubtitle,
        CardActions,
        TabStrip,
        TabStripTab
    } from "@progress/kendo-vue-layout";
    import moment from "moment";
    import { PDFDocument, StandardFonts, rgb, degrees } from 'pdf-lib'
    import Swal from "sweetalert2";
    import CompletedSection from "@/components/CompletedDocuments/DocumentGrid"
    import CompletedDocumentView from "@/components/CompletedDocuments/DocumentView"
    import GridDataHelper from "@/core/services/grid-data-helper";
    import SmartTagManager from "@/core/services/smart-tag-manager";
    import OT from '@opentok/client';
    import Webcam from 'webcam-easy';
    import { startConnection } from "@/video.js";
    import VideoSessionVue from "../../components/VideoSession.vue";
    import NotaryJournal from "@/components/NotaryJournal.vue";
    import ContentLoaderOverlay from "@/components/ContentLoaderOverlay.vue";
    import NotaryWaitingList from "@/components/NotaryWaitingList.vue";
    import ScreenShare from "../../components/ShareScreenSession.vue";
    import Session from "../../components/Video/Session.vue";
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    import { Popup } from '@progress/kendo-vue-popup';
    import { Input } from "@progress/kendo-vue-inputs";
    import useVuelidate from '@vuelidate/core';

    const DOC_TYPE = {
        SIGN: "Review_And_Sign",
        REVIEW: "Review_Only"
    };
    const DOC_PARTICIPATION_STATUS = {
        PENDING_REVIEW: "Pending_Review",
        PENDING_SIGNING: "Pending_Signing",
        REVIEWED: "Reviewed",
        SIGNED: "Signed",
        UPLOADED: "Uploaded"
    };

    const SKIPPABLE_TAGS = [
        "CheckBox",
        "AutomaticSignatureDate",
        "FreeForm",
        "Seal",
        "Date",
        "FullName",
        "LastName",
        "FirstName",
        "DateOfBirth",
        "Email",
        "Address",
        "ZipCode",
        "Whiteout",
        "OptionGroup",
        "OptionGroupValue",
    ];
    let SIGNING_ROOM_RENDER_SIZE = 0;
    let SIGNING_ROOM_THUMBNAIL_RENDER_SIZE = 0;

    const UPLOADED = "Uploaded";

    const COMPLETED_DOCUMENTS_STATUS = [DOC_PARTICIPATION_STATUS.REVIEWED, DOC_PARTICIPATION_STATUS.SIGNED]
    const INCOMPLETE_DOCUMENTS_STATUS = [DOC_PARTICIPATION_STATUS.PENDING_REVIEW, DOC_PARTICIPATION_STATUS.PENDING_SIGNING]

    const CHECKBOX_SKIPPED_VALUE = "skipped";
    const CHECKBOX_CHECKED_VALUE = "checked";

    const REPLACE_TAG = (tag, replacementT) => {
        var replacementTag = replacementT || 'div';
        tag = (tag instanceof jQuery) ? tag[0] : tag;
        var outer = tag.outerHTML;


        // Replace opening tag
        var regex = new RegExp('<' + tag.tagName, 'i');
        var newTag = outer.replace(regex, '<' + replacementTag);

        // Replace closing tag
        regex = new RegExp('</' + tag.tagName, 'i');
        newTag = newTag.replace(regex, '</' + replacementTag);

        $(tag).text("Signature");
        $(tag).replaceWith(newTag);

        return $(newTag)[0].id;
    }

    let OFFSET_OF_LAST_PAGE = 0;
    const ALREADY_REVIEWED_IDS = [];
    let SHOW_I_ATTEST = true;
    let IS_SCROLLING = false;


    let IS_CURRENTLY_SIGNING = false;
    export default {
        name: "AdminNSigningRoom",
        emits: ["imageBlob"],
        //props: ['UserID', 'ContextID', 'NotarizeID'],
        //emits: ["completed"], - emit function called completed and computed called completed. conflicts.
        components: {
            Checklist,
            'kendo-panelbar': PanelBar,
            GuidedArrow,
            Drawer,
            DrawerItem,
            DrawerContent,
            "kbutton": Button,
            card: Card,
            cardHeader: CardHeader,
            cardBody: CardBody,
            cardTitle: CardTitle,
            cardSubtitle: CardSubtitle,
            cardActions: CardActions,
            completedSection: CompletedSection,
            completedDocumentView: CompletedDocumentView,
            videosession: VideoSessionVue,
            NotaryControlPanel: NotaryControlPanel,
            NotaryJournal: NotaryJournal,
            ContentLoaderOverlay: ContentLoaderOverlay,
            screenshare: ScreenShare,
            tabstrip: TabStrip,
            tabstripTab: TabStripTab,
            session: Session,
            dropdownlist: DropDownList,
            'popup': Popup,
            'k-input': Input,
            "notary-waiting-list": NotaryWaitingList,
        },
		setup() {
			return { validate: useVuelidate() }
        },
        data() {
            return {
                polling:null,
				formValid: true,
                showSealType: false,
				PFirstName: "",
				PLastName: "",
				PEmailAddress: "",
                sealTypes: [],
                selectedSealType: null,
                loanNotary: null,
                showJournal: false,
                packageId: 0,
                retakeVideoPreview: null,
                ImageBinary: null,
                canvas: document.getElementById("canvasReUploadPicturePreview"),
                videoElement: document.getElementById('retakeVideoPreview'),
                videoSelect: document.querySelector('select#videoSource'),
                pictureTaken: false,
                hideVideo: false,
                signatureType: "font", // Canvas / Font / Image
                signatureText: "Insert Signature",
                freeFormText: "",
                dateFieldText: "",
                activeSignatureFontFamily: "",
                signatureCanvasContext: null,
                signatureImageBase64: "",
                initialsImageBase64: "",
                files: [],
                activeDocIndex: 0,
                activePageIndex: 0,
                apiUrl: loadFileUrl,
                tagsPerPage: [],
                activeSmartTagIndex: 0,
                signatureTypeId: 1,
                activeXmlTagId: "",
                signer: {},
                showEnotes: false,
                activeDrawerIdx: 1,
                currentScrollDocName: "",
                currentDocType: "",
                fonts: [
                    { fontFam: "Allura" },
                    { fontFam: "Dancing Script" },
                    { fontFam: "Sacramento" },
                    { fontFam: "Mansalva" },
                    { fontFam: "Satisfy" },
                    { fontFam: "Vibes" },
                ],
                documentId: 0,

                checklist: [],
                numOfLoadedThumbnailPreviews: 0,
                isScrolling: false,

                signingTypes: {
                    Draw: true,
                    Type: true,
                    Upload: true,
                },
                typeErrorList: [],
                // new items for signeer room rework
                settings: {
                    isContinuousScroll: true,
                    signingDate: new Date("2/22/2022")
                },
                disableComplete: true,
                showReviewContinueButton: false,
                pdfForms: [],
                currentControlIndex: -1,
                currentFormIndex: 0,
                currentPage: 1,
                initialLoad: true,
                renderJumpArrow: false,
                moveJumpArrowToNextPage: false,
                setControlIndexForDrawSignature: 0,
                needsToGoToNextForm: false,
                currentTagInView: false,
                allTagsCurrentlyAvailableToSign: [],
                scrollTagDirection: "bottom",


                checkboxesToSignOnPDFAfterIAttest: [],

                lastKnownVisibleForm: '',
                // helps in detecting if user is scroll up or down - NOT USED RIGHT NOW, SOME OF THIS MAY BE REMOVED
                lastScrollTop: 0,
                programmaticScroll: false,
                waitForUserScroll: false,
                programmaticScrollToNextPage: false,

                lineColor: "pink",
                // kendo Drawer
                downClass: "k-icon k-i-arrow-chevron-down",
                rightClass: "k-icon k-i-arrow-chevron-right",
                items: [
                    {
                        text: "Ready for Review",
                        icon: "k-i-eye",
                        ["data-expanded"]: this.isMobile() ? false : true,
                        id: 1,
                        selected: true,
                    },
                    {
                        separator: true,
                    },
                    {
                        text: "Ready for Review and Sign",
                        ["data-expanded"]: this.isMobile() ? false : true,
                        icon: "k-i-pencil",
                        id: 2,
                    },
                    {
                        separator: true,
                    },
                    {
                        text: "Completed",
                        icon: "k-i-check-outline",
                        ["data-expanded"]: this.isMobile() ? false : true,
                        id: 3,
                    },
                ],
                expanded: this.isMobile() ? false : true,
                selectedId: 0,
                position: "start",
                mode: "push",
                currentElement: {
                    documentId: 0,
                    pageNo: 0
                },
                selectedTab: 0,
                NotarizeID: 0,
                ContextID: 0,
                UserID: 0,
                signerId: 0,
                videoSession: null,
                videoStream: null,
                notarialActToggle: false,
                notarialType: null,
                userProfile: [],
                selectedNotarialAct: [],
                userRole: "",
                isNotary: false,
                tagsAddedDuringSigning: [],
                sealImage: "",
                teleportLocation: 'body',
                dynamicTagContainerLocation: 'body',
                sealContainerLocation: 'body',
                currentHighlightedTag: {},
                PFirstName: "",
                PMiddleName: "",
                PLastName: "",
                PEmailAddress: "",
                PMobileNumber: "",
                PParticipantType: "Observer",
                PSSN: "",
                signingRoomData: this.$store.getters["Auth/signingRoomData"],
                lenderSettings: [],
                selectedParticipant: {},
                signingParticipants: [],
                loanDetail: {},
                showForceCloseButton: false,
                groupedOutstandingTags: {},
                connectedSigners: [],
                participantTypes: [{id:"Observer", text:"Observer"}, {id:"Legal_Witness",text:"Legal Witness"}],
                selectedParticipantType:""
            };
        },
        async created() {
            $(".signer-room-form-containter > div, .thumbnail-container > div").html("");

            var settings = await ApiService.GenerateGetRequest("Admin/LenderSettings");
            if (settings != null && settings.isSuccess) {
                this.lenderSettings = settings.content;
            }

            await ApiService.GenerateGetRequest("Admin/IsNotary").then(
                response => {
                    this.userRole = response.content.participantType;
                    this.isNotary = response.content.isNotary;
                }
            );

            let response;
            this.UserID = this.signingRoomData.userId;
            this.ContextID = this.signingRoomData.contextId;
            this.NotarizeID = this.signingRoomData.notarizeId;            

            const signerResponse = await ApiService.GenerateGetRequest('signer/ndetails?NotarizeID=' + this.NotarizeID);
            this.signer = signerResponse["content"];

            //fetching doc and tags for admin user (LO / Notary)
            response = await ApiService.GenerateGetRequest(`document/nsigner?NotarizeID=` + this.NotarizeID);
            if(this.signer.isRon){//Starts connection if RON
                startConnection(this.callbackFunct);
                if (this.isNotary) {
                    await ApiService.GeneratePostRequest(`transaction/notaryconnected/?receiverSignerID=` + this.UserID);

                    this.refreshSigners();
                    this.polling = setInterval(() => {
                        this.refreshSigners();
                    }, 10000);

                }
            }

            this.lastScrollTop = $(".signer-room-form-containter").scrollTop();

            if (response && response["isSuccess"]) {
                console.log("response docs", response["content"]);
                //response["content"].shift();
                let files = response["content"].map((file) => {
                    var smartTags = file.smartTags.map((item) => {
                        let obj = { ...item, controlIdAlias: item.controlId.replaceAll(".`0", ""), isAdmin: this.signer.id === item.signerId, isNotaryOnly: file.isNotaryOnly };
                        return obj;
                    });
                    //smartTags = smartTags.filter(x => x.tagType !== "AutomaticSignatureDate");
                    //var watermark = "";
                    //if (file.wetSigned) {
                    //    watermark = this.lenderSettings.wetSignWatermark;
                    //}
                    //if (file.status == "Notarized") {
                    //    watermark = this.lenderSettings.notarizeWatermark;
                    //}
                    //if (file.isEnote) {
                    //    watermark = this.lenderSettings.eSignDocWatermark;
                    //}
                    return {
                        documentID: file.documentID,
                        path: ApiService.EnvironmentURL() + "document/load?path=" + file.docURL+"&isSigning=true",
                        name: file.description,
                        noSpaceName: "_" + file.documentName.replaceAll(" ", "").replaceAll(/[^\w\s]/gi, '_'),
                        pages: file.isEnote ? 1 : file.noOfPages,
                        html: file.html,
                        documentStatus: file.status,
                        isEnote: file.isEnote,
                        isNotaryOnly: file.isNotaryOnly,
                        signerId: file.signerId,
                        documentType: file.documentType, //"Signing" => "Review_And_Sign", "Reviewing" => "Review_Only",
                        participantStatus: file.participantStatus,
                        smartTags: smartTags,
                        fieldLinks: [],
                        wetSigned: file.wetSigned
                    }
                });
                // console.log("Notary Tagss", notaryTags, files)
                this.files = files;
                this.currentScrollDocName = this.files[0].name;
                this.currentDocType = this.files[0].documentType;
                if (this.completed.length == this.files.length) {
                    this.documentId = 0;
                    this.activeDrawerIdx = 3
                }
                var idx = 4;
                this.items = [];
                this.items = [{
                    text: `Ready for Review (${this.toreview.length})`,
                    icon: "k-i-eye",
                    ["data-expanded"]: this.isMobile() ? false : true,
                    docId: 0,
                    id: 1,
                    disabled: this.toreview.length == 0
                }];
                this.items.push(...this.toreview.map((item) => {
                    idx++;
                    return {
                        text: item.name,
                        nospacename: item.noSpaceName,
                        icon: "k-i-minus",
                        id: idx,
                        parentId: 1,
                        parent: 1,
                        docId: item.documentID,

                    };
                }));
                idx++;
                this.items.push(...[{
                    separator: true,
                },
                {
                    text: `Ready for Review and Sign (${this.tosign.length})`,
                    ["data-expanded"]: this.isMobile() ? false : true,
                    icon: "k-i-pencil",
                    id: 2,
                    docId: 0,
                    disabled: this.tosign.length == 0
                }]);
                this.items.push(...this.tosign.map((item) => {
                    idx++;
                    return {
                        text: item.name,
                        nospacename: item.noSpaceName,
                        icon: "k-i-minus",
                        id: idx,
                        parentId: 2,
                        parent: 2,
                        docId: item.documentID,
                        data: {
                            path: "#",
                        },
                    };
                }));
                idx++;
                this.items.push(...[{
                    separator: true,
                },
                {
                    text: `Completed (${this.completed.length})`,
                    icon: "k-i-check-outline",
                    ["data-expanded"]: this.isMobile() ? false : true,
                    id: 3,
                    docId: 0,
                    disabled: false
                }]);
                this.items.push(...this.completed.map((item) => {
                    idx++;
                    return {
                        text: item.name,
                        nospacename: item.noSpaceName,
                        icon: "k-i-minus",
                        id: idx,
                        parentId: 3,
                        parent: 3,
                        docId: item.documentID,
                        data: {
                            path: "#",
                        },
                    };
                }));
                console.log("itemList created", this.items);

                //set lender settings - font color and signing types
                //let lenderid = this.$store.state.App.lenderId;
                ApiService.GenerateGetRequest(`signer/notarySettings`).then(
                    response => {
                        this.lineColor = response.content.fontColor;
                        this.signingTypes.Draw = response.content.drawSignature;
                        this.signingTypes.Type = response.content.typeSignature;
                        this.signingTypes.Upload = response.content.uploadSignature;
                        for (const [key, value] of Object.entries(this.signingTypes)) {
                            if (value == true) {
                                $('#' + key).addClass("active in")
                                break;
                            }
                        }
                    }
                );

                response = await ApiService.GeneratePostRequest(`package/PackageIDByNotarizeId/${this.NotarizeID}`);
                this.packageId = response;

                //get loan package details
                await ApiService.GeneratePostRequest(
                    "package/GetLoanPackageById/" + this.packageId
                ).then((response) => {
                    this.loanDetail = response.content;
                });

                if (this.isNotary == true) {
                    if (this.normalizeStatus(this.loanDetail.statusDescription) != "Completed" && this.normalizeStatus(this.loanDetail.statusDescription) != "Closed") {
                        this.showForceCloseButton = true;
                    }

                }

                var userId = this.$store.state.Admin.loginInformation.userId;

                response = await ApiService.GenerateGetRequest(`Notarize/activenotarizeact?userId=` + userId);
                if (response.isSuccess) {
                    this.notarizeDocuments = response.content
                }

                // SET UP PDF UI
                let self = this;
                this.$nextTick(() => {
                    // let documents load before user interaction
                    //this.$store.commit("App/updateLoaderOverride", {
                    //    showLoaderOverride: true,
                    //});
                    if (this.settings.isContinuousScroll) {
                        $(".signer-room-form-containter > div, .thumbnail-container > div").html("");
                        // let files = this.isSigningDate ? self.files : self.files.filter((file) => !file.isEnote);
                        let files = self.files;
                        let width = $('.signer-room-form-containter').width() * 0.80;
                        if (files && files.length > 0 && files[0].isEnote) {
                            // if first item is enote we need to add a dummy node to the UI. eNote rendeirng code copies the skeleton and injects the eNote iframe
                            $('.signer-room-form-containter > div').append(`<div style="width: ${width}px;" data-page="1" class="DUMMYDOCUMENT" data-pagename="DUMMYDOCUMENT"></div>`);
                        }
                        // loading multiple pdfs
                        var pdfForm = new PDFForm();
                        let thumbWidth = $('.thumbnail-container').width();
                        if (thumbWidth < 70)
                            thumbWidth *= 0.50;// smalls screen devices
                        else
                            thumbWidth *= 0.75;


                        SIGNING_ROOM_RENDER_SIZE = width;
                        SIGNING_ROOM_THUMBNAIL_RENDER_SIZE = thumbWidth;

                        let eNotesToLoad = [];

                        this.$refs.contentLoader.updateNextDocName(files[0].name);
                        pdfForm.loadMulitplePDFs(this.signer.id, this.UserID, files, 0, '.signer-room-form-containter > div', width, false, true, true, (page, currentObject, index) => {
                            // after main view loaded, load previews cause we need access to the form controls from the loaded pdf form
                            if (page === currentObject.pages) {// only execite when last page was rendered - for current processing document

                                if (!currentObject.isEnote) {
                                    var previewForm = new PDFForm();
                                    previewForm.loadPdf('.thumbnail-container > div', currentObject.noSpaceName, currentObject.path, thumbWidth, false, false, (pageNum) => {
                                        // post render after last page was called
                                        if (pageNum === previewForm.numpages) {
                                            this.$refs.contentLoader.updateNextDocCount();
                                            let name = files[index + 1] ? files[index + 1].name : "";
                                            this.$refs.contentLoader.updateNextDocName(name);
                                        }
                                    });
                                } else {
                                    eNotesToLoad.push(currentObject);
                                }
                            }


                            // assign variables to SignerRoom object when all files loaded recursively
                            if ((files.length - 1) === index && page === currentObject.pages) {
                                // quick hack to have enote load last. when last pdf document is being rendered this code is called before the pdf is on the ui
                                setTimeout(() => {
                                    var previewForm = new PDFForm();
                                    eNotesToLoad.forEach((note) => {
                                        this.$refs.contentLoader.updateNextDocName('eNote');
                                        this.$refs.contentLoader.updateNextDocCount();
                                        previewForm.loadEnoteThumbnail(note.noSpaceName, note.html, null, thumbWidth);
                                    });
                                    self.updatePreviewBorder();
                                }, 1000)
                                console.log("Assigning PDF FORMs", pdfForm.pdfForms);
                                self.pdfForms = pdfForm.pdfForms;

                                self.initiateContinuousScroll();
                                self.updatePreviewBorder();
                                //Diabled this feature because it was causing broadcasting of applied tags some issues

                                // if (self.completed.length !== self.files.length) {
                                //     if (self.signer.sessionJson != null && self.signer.sessionJson != "") {
                                //         try {
                                //             var session = JSON.parse(self.signer.sessionJson);
                                //             var doc = self.files.find(x => x.documentID == session.lastActionedDoc.id);
                                //             this.currentElement.pageNo = session.lastActionedDoc.pageNo;
                                //             this.currentElement.documentID = doc.documentID;
                                //             self.scrollPageIntoViewCentered(`.signer-room-form-containter div[data-page="${parseInt(session.lastActionedDoc.pageNo)}"][data-pagename="${doc.noSpaceName}"]`);
                                //             const Toast = Swal.mixin({
                                //                 toast: true,
                                //                 position: 'bottom-end',
                                //                 showConfirmButton: false,
                                //                 timer: 7000,
                                //                 timerProgressBar: true,
                                //                 didOpen: (toast) => {
                                //                     toast.addEventListener('mouseenter', Swal.stopTimer)
                                //                     toast.addEventListener('mouseleave', Swal.resumeTimer)
                                //                 }
                                //             })
                                //             Toast.fire({
                                //                 icon: 'success',
                                //                 title: 'We have picked up where you left off'
                                //             })
                                //         } catch (error) {
                                //             console.log(error)
                                //         }

                                //     }

                                // }

                            }

                        });

                    } else {
                        // opposite of continuous scroll goes here
                    }
                    return;

                });

                // LEAVING IN HERE FOR NOW AS WE DON"T HAVE PROPER ENOTES IN THE SYSTEM
                // modify data from server to add extra properties we may need to determine whhen and where to wetsignb
                // and show relevant documentsd
                //for (let j = 0; j < this.files.length; j++) {
                //    this.files[j];
                //    if (j == 1) {
                //        this.files[j].isEnote = true;
                //    }
                //    if (j == 0) {
                //        this.files[j].isEnote = false;
                //    }
                //}
            }




            //this.loadLegend();
            //this.allNoneENoteFilesFilledOut();

        },
		watch: {
			// watch these variables for changes and verify form is valid - if not valid emit
			PFirstName() { this.emitValidState() },
			PLastName() { this.emitValidState() },
			PEmailAddress() { this.emitValidState() },
        },
		validations() {
			return {
				PFirstName: { required},
				PLastName: { required},
				PEmailAddress: { required, email }
			}
        },
        beforeUnmount() {
            clearInterval(this.polling);
        },
        mounted: async function () {

            //get user profile
            await ApiService.GenerateGetRequest(
                "Admin/GetUserProfile"
            ).then((response) => {
                this.userProfile = response.content;
            });

            this.$nextTick(() => {
                // signatture
                let signatureCanvas = this.$refs.signatureCanvas;

                let currentMousePosition = {
                    x: 0,
                    y: 0,
                };

                //previous mouse position
                let previousMousePosition = {
                    x: 0,
                    y: 0,
                };

                //make a 2D context
                this.signatureCanvasContext = signatureCanvas.getContext("2d");

                //set the line parameters
                this.signatureCanvasContext.lineWidth = 3;
                this.signatureCanvasContext.lineJoin = "round";
                this.signatureCanvasContext.lineCap = "round";
                this.signatureCanvasContext.strokeStyle = this.lineColor;

                //capture mouse moves
                this.$refs.signatureCanvas.addEventListener("mousemove", function (e) {
                    //store the old current mouse position and the previous mouse position
                    previousMousePosition.x = currentMousePosition.x;
                    previousMousePosition.y = currentMousePosition.y;

                    var rect = signatureCanvas.getBoundingClientRect();
                    //get the new mouse position
                    currentMousePosition.x = e.clientX - rect.left;
                    currentMousePosition.y = e.clientY - rect.top;
                });

                //mouse down
                signatureCanvas.addEventListener("mousedown", function (e) {
                    //add an additional listener to draw
                    signatureCanvas.addEventListener("mousemove", onPaint);
                });

                //mouse up
                signatureCanvas.addEventListener("mouseup", function () {
                    //remove the additional mouse move listener
                    signatureCanvas.removeEventListener("mousemove", onPaint);
                });

                //an additional mouse listener to paint part of a line
                var onPaint = () => {
                    //draw the line
                    this.signatureCanvasContext.beginPath();
                    this.signatureCanvasContext.moveTo(
                        previousMousePosition.x,
                        previousMousePosition.y
                    );
                    this.signatureCanvasContext.lineTo(
                        currentMousePosition.x,
                        currentMousePosition.y
                    );
                    this.signatureCanvasContext.strokeStyle = this.lineColor;
                    this.signatureCanvasContext.closePath();
                    this.signatureCanvasContext.stroke();
                };
            });

        },
        computed: {
            remainingMandatoryTags() {
                return this.allTagsCurrentlyAvailableToSign.filter((item) => {
                    return !this.isTagOptional(item.smartTag) && item.isAdmin && item.value == ""
                }).length;
            },
            remainingOptionalTags() {
                return this.allTagsCurrentlyAvailableToSign.filter((item) => {
                    return this.isTagOptional(item.smartTag) && item.isAdmin && item.value == ""
                }).length;
            },
            completed: function () {
                return this.files.filter((f) => (COMPLETED_DOCUMENTS_STATUS.includes(f.participantStatus) || (f.participantStatus == DOC_PARTICIPATION_STATUS.PENDING_SIGNING && f.documentType == DOC_TYPE.REVIEW)) && f.documentType != UPLOADED && f.wetSigned != true);
            },
            toreview: function () {
                return this.files.filter((f) => f.documentType != UPLOADED && ((f.participantStatus == DOC_PARTICIPATION_STATUS.PENDING_REVIEW && f.documentType == DOC_TYPE.REVIEW) || (f.participantStatus == DOC_PARTICIPATION_STATUS.PENDING_REVIEW && f.documentType == DOC_TYPE.SIGN && f.smartTags.filter((x) => x.signerId == f.signerId).length == 0)));
            },
            tosign: function () {
                return this.files.filter((f) => f.wetSigned == false && f.smartTags.filter((x) => x.signerId == f.signerId).length > 0 && DOC_TYPE.SIGN == f.documentType && (f.participantStatus == DOC_PARTICIPATION_STATUS.PENDING_REVIEW || f.participantStatus == DOC_PARTICIPATION_STATUS.PENDING_SIGNING));
            },
            toupload: function () {
                return this.files.filter((f) => f.wetSigned == true || f.documentType == UPLOADED);
            },
            assetsPath: function (path) {
                return path;
            },
            pdfForm() {

                return this.pdfForms.length > 0 ? this.pdfForms[this.currentFormIndex] : {};
            },
            isSigningDate() {
                let signerData = this.$store.getters["Auth/signerSessionInformation"];
                return signerData["isDateSignable"];
            },
            filesToSign() {
                if (this.files.length > 0 && this.files[0].isEnote) {
                    this.showEnotes = true;
                    $(this.$refs.modalENoteConsent).modal("show");
                }
                return this.files.filter((f) => f.isEnote === this.showEnotes);
            },
            dataItems() {

                const newItems = this.items.map((item) => {
                    const { parentId, ...others } = item;
                    if (parentId !== undefined) {
                        const parent = this.items.find((parent) => parent.id === parentId);
                        return { ...others, visible: parent["data-expanded"] };
                    }
                    return item;
                });
                console.log("from dataItems", newItems);
                return newItems;
            },
            isCurrentTagOptional() {
                let index = this.findNextAvailableTagIndex();
                if (!this.allTagsCurrentlyAvailableToSign[index])
                    return true;
                let smartTag = this.allTagsCurrentlyAvailableToSign[index]["smartTag"];
                let tagName = smartTag["tagType"];
                return smartTag.isOptional;
            },
            jumpArrowDirection() {
                let index = this.findNextAvailableTagIndex();
                let val = "left";
                if (index < 0)
                    return "bottom";
                if (!this.currentTagInView && this.allTagsCurrentlyAvailableToSign[index].value === '') {
                    val = "bottom";
                }

                if (this.currentTagInView === false)
                    val = this.scrollTagDirection;

                return val;








                 // SGN-1193 requirements. Leaving code below in case they change their minds.
                //let index = this.findNextAvailableTagIndex();
                //let val = index < 0 ? 'bottom' : this.currentTagInView && this.allTagsCurrentlyAvailableToSign[index].value === '' ? 'left' : 'bottom';

                //if (this.currentTagInView === false) {
                //    val = this.scrollTagDirection;

                //} else if (this.currentTagInView) {
                //    val = "left";
                //}


                //if (this.isCurrentTagOptional) {
                //    console.log("Tag is skippable ");
                //    val = "bottom";
                //}

                //return val;
            },
            jumpArrowText() {
                let index = this.findNextAvailableTagIndex();
                if (index < 0)
                    return 'NEXT';
                let val = "";

                //this.currentTagInView && this.allTagsCurrentlyAvailableToSign[index].value === '' ? 'SIGN HERE' : 'NEXT/SKIP'

                if (this.currentTagInView && this.allTagsCurrentlyAvailableToSign[index].value === '' && this.isCurrentTagOptional) {
                    val = "SKIP (OPTIONAL)";
                } else if (this.currentTagInView && this.allTagsCurrentlyAvailableToSign[index].value === '' && !this.isCurrentTagOptional) {
                    val = "SIGN HERE";
                } else if (!this.currentTagInView && this.allTagsCurrentlyAvailableToSign[index].value === '') {
                    val = "NEXT";
                }

                if (this.initialLoad) {
                    val = "Start Signing";
                }


                //if (this.isCurrentTagOptional) {
                //    console.log("Tag is skippable ");
                //    val = "NEXT/SKIP";
                //}
                return val;
            },
            jumpArrowTopPosition() {
                let index = this.findNextAvailableTagIndex();
                let val = this.allTagsCurrentlyAvailableToSign[index] ? this.allTagsCurrentlyAvailableToSign[index].y - 8 : 0;

                if (index < 0)
                    return 0;

                if (!this.currentTagInView)
                    val = this.allTagsCurrentlyAvailableToSign[0].y - 8;


                if (this.currentTagInView)
                    val = this.allTagsCurrentlyAvailableToSign[index].y - 8;

                return val;
            },
            teleportLocationForJumpArrow() {
                let loc = '.signer-room-form-containter #' + this.pdfForms[this.currentFormIndex].pdfName + '-Page_' + 1;

                let index = this.findNextAvailableTagIndex();
                if (this.currentTagInView && index >= 0)
                    loc = '.signer-room-form-containter #' + this.allTagsCurrentlyAvailableToSign[index].pdfName + '-Page_' + this.allTagsCurrentlyAvailableToSign[index].belongsToPage;

                if (!this.currentTagInView)
                    loc = '.guided-arrow-container';

                return loc;
            },
            canDisplayTagNav(){
                return this.activeDrawerIdx == 3 || this.activeDrawerIdx == 4
            },
        },
        methods: {
            handleChange(event){
                this[event.target.name] = event.target.value;
            },
            async refreshSigners() {
                //refresh connectedSigners state
                const signingPartResponse = await ApiService.GenerateGetRequest('signer/signingParticipants?NotarizeID=' + this.NotarizeID);
                if (signingPartResponse.isSuccess && this.connectedSigners != signingPartResponse.content) {
                    this.connectedSigners = signingPartResponse.content;
            }
        },
            async addParticipant() {
                Swal.fire({
                    title: 'Are you sure you want to add this participant?',
                    icon: 'info',
                    showCancelButton: true,
                }).then(async (result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        let response = await ApiService.GeneratePostRequest(
                            "package/participants/sendLink", {
                            NotarizeId: this.NotarizeID,
                            PackageId: this.packageId,
                            FirstName: this.PFirstName,
                            MiddleName: this.PMiddleName,
                            LastName: this.PLastName,
                            EmailAddress: this.PEmailAddress,
                            MobileNumber: this.PMobileNumber,
                            SSN: this.PSSN,
                            ParticipantType : this.selectedParticipantType.id,
                            ParticipantMappingId: 0,
                            ParticipantTypeId: 0,
                            InvitationStatusId: 0,
                            ParticipantStatusId: 0,
                            AdminUserId: 0
                        }
                        );
                        if (response.isSuccess) {
                            this.refreshSigners();
                            $(this.$refs.modalParticipantRef).modal("hide");
                            this.PFirstName = "";
                            this.PMiddleName = "";
                            this.PLastName = "";
                            this.PEmailAddress = "";
                            this.PMobileNumber = "";
                            this.PSSN = "";
                            Swal.fire({
                                title: 'Participant invitation link was successfully sent',
                                text: '',
                                icon: 'success',
                                confirmButtonText: 'Ok'
                            });
                            this.refreshSigners();
                            this.$refs.journal.getIdDetails();
                        } else {
                            Swal.fire({
                                title: 'Error sending invitation link',
                                text: response.message,
                                icon: 'error',
                                confirmButtonText: 'Ok'
                            });
                        }
                    }
                })
                
            },
			emitValidState() {
				this.validate.$touch();
				let hasErrors = this.validate.$error;
				this.formValid = hasErrors;
            },
            navToWaitingRoom() {
                this.$router.push({
                    name: 'Waiting',
                    path: `/admin/waiting`
                });
            },
            showParticipant() {
                $(this.$refs.modalParticipantRef).modal("show");
            },
            /*****************************************************
                         Functions for seal/page insertion
             *****************************************************/
            getModalNotarialActToggle(value) {
                console.log("getModalNotarialActToggle: ", value);
                var pages = parseInt(this.currentPage);
                var docId = this.files[this.currentFormIndex].documentID;
                console.log("current element page no: ", pages);
                console.log("current element document no: ", docId);
                if (value != "") {
                    this.notarialActToggle = value;
                    if (this.notarialType == "Seal") {
                        var obj = {
                            NotarizeID: this.NotarizeID,
                            DocumentID: docId,
                            PageNo: pages,
                            Seal: this.userProfile.seal
                        };
                        ApiService.GeneratePostRequest("document/AddSealInPDF", obj)
                            .then((response) => {
                                console.log("document/AddSealInPDF: ", response);
                                if (response["isSuccess"] && response["content"]) {
                                    console.log(response["content"]);
                                }
                            });
                    } else {
                        var userId = this.$store.state.Admin.loginInformation.userId;

                        //get notarial act id
                        ApiService.GenerateGetRequest(
                            "notarize/notarialactbyname?name=" + this.notarialType + "&userId=" + userId
                        ).then((response) => {
                            console.log("notarize/notarialactbyname: ", response);
                            this.selectedNotarialAct = response.content;
                            this.addNewPage(this.selectedNotarialAct.id, docId, pages);
                        });
                    }
                }
            },
            toProperCase(txt) {
                return txt.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
            },
            getNotarialType(value) {
                console.log("getNotarialType: ", value);
                if (value != "") {
                    this.notarialType = value;
                }
            },
            async addNewPage(notarialActID, docId, pages) {
                var obj = {
                    NotarizeID: this.NotarizeID,
                    DocumentID: docId,
                    PageNo: pages,
                    NotarialActID: notarialActID
                };
                await ApiService.GeneratePostRequest("document/AddPageInPDF", obj)
                    .then((response) => {
                        console.log("document/AddPageInPDF: ", response);
                        if (response["isSuccess"] && response["content"]) {
                            console.log(response["content"]);
                        }
                        this.dynamicallyInsertPage(obj.DocumentID, pages);

                        var document = this.files.find((file) => file.documentID === docId);
                        if (document.isNotaryOnly === false)
                            this.SendNewPageAlertToBorrower(obj.DocumentID, pages);
                    });
            },
            dynamicallyInsertPage(documentId, pages) {
                var self = this;
                self.$store.commit("App/updateLoaderOverride", {
                    showLoaderOverride: true,
                });

                let document = this.pdfForms.find(x => x.documentId === documentId);
                let pageToLoad = pages + 1;
                let width = SIGNING_ROOM_RENDER_SIZE;
                // load page in main view
                let pdfForm = new PDFForm();
                pdfForm.loadPdfPage(pageToLoad, '.signer-room-form-containter > div', document.pdfName, document.path, width, false, false, null, [], true, {
                    onRender(divElement) {
                        self.$store.commit("App/updateLoaderOverride", {
                            showLoaderOverride: false,
                        });
                        handleRender('.signer-room-form-containter', divElement);
                    }
                });

                // load in thumbnail view
                let thumbWidth = SIGNING_ROOM_THUMBNAIL_RENDER_SIZE;

                pdfForm.loadPdfPage(pageToLoad, '.thumbnail-container > div', document.pdfName, document.path, thumbWidth, false, false, null, [], true, {
                    onRender(divElement) {
                        handleRender('.thumbnail-container', divElement);
                    }
                });
                function handleRender(containerSelector, divElement) {
                    $(divElement).insertAfter(containerSelector + ' div[data-page="' + pages + '"][data-pagename="' + document.pdfName + '"]');
                    // update page numbers for newly inserted page
                    $(containerSelector + ' div[data-pagename="' + document.pdfName + '"]').each((index, item) => {
                        $(item).attr("data-page", index + 1)
                        $(item).find("form").each((i, form) => {
                            $(form).attr("id", document.pdfName + "-" + "Page_" + (index + 1));
                        });
                    });
                }
            },
            /*****************************************************
                         Functions for video/audio
             *****************************************************/
            changeShowScreen() {
                this.showScreen = !this.showScreen;
            },
            onTabSelect: function (e) {
                console.log("this tab selected", e);
                this.selectedTab = e.selected;
                console.log(this.selectedTab, "this is the se;ected tab");
            },
            async callbackFunct(ctype, data) {
                console.log("callback in nsigningroom from video.js", ctype, data);
                var self = this;
                switch (ctype) {
                    case "signerUploadedID":
                        console.log("signer uploaded id");
                        this.$refs.journal.getIdDetails();
                        break;
                    case "loadAppliedTags":
                        if (data.tagSignerID == this.signer.id) {// skip for broadcaster
                            return;
                        }
                        let { tagDocumentID: tagID, imgUrl: value, timestamp } = data;
                        this.pdfForms.forEach((form) => {
                            let matchFound = false;
                            form.allFormFields.forEach((field) => {
                                console.log(field.smartTag.id, " == ", tagID)
                                if (field.smartTag.id == tagID) {
                                    if(field.value != "" && field.smartTag.tagType != "CheckBox"){
                                        return;
                                    }
                                    field.value = field.smartTag.id;
                                    matchFound = true;
                                    this.scrollCurrentControlIntoView(null, field, form.pdfName, form.isEnote);
                                    if (field.smartTag.tagType == "Signature" || field.smartTag.tagType == "Initials" || field.smartTag.tagType == "Seal") {
                                        if (value.indexOf(this.apiUrl) < 0)
                                            value = this.apiUrl + value;

                                        this.setImageInPdf(value, form.pdfName, field, form.isEnote);
                                    }
                                    if (field.smartTag.tagType == "Date" || field.smartTag.tagType == "DateOfBirth") {
                                        this.dateFieldText = value;
                                    }
                                    $(`#ga-${form.pdfName}-${field.noSpaceIdAlias}`).fadeOut('slow');
                                    this.updateControlData(field, value, false, true);
                                    this.insertSignerTimeStamp(field, timestamp);
                                    field.pdfName = form.pdfName;
                                    this.checkAndEnableGuidedTags();
                                }
                            });

                            if (matchFound === false) {
                                this.tagsAddedDuringSigning.forEach((tag) => {
                                    if (tag.serverSmartTag.id == tagID) {
                                        matchFound = true;
                                        let control = $(`#${tag.pdfName}-${tag.serverSmartTag.controlId.replaceAll("-", "_")}`);
                                        if (tag.serverSmartTag.tagType === "Signature" || tag.serverSmartTag.tagType === "Initials") {
                                            if (value.indexOf(this.apiUrl) < 0)
                                                value = this.apiUrl + value;
                                            control.text("").append(`<img src="${value}" style='max-width: 100%; max-height: 100%'/>`)
                                            control.append(`<span id="${tag.serverSmartTag.controlId.replaceAll("-", "_")}-time" style="z-index: 1;font-size: 0.35vw;position: absolute;left: ${tag.controlWidth + 5}px;   display: inline-block;    width: 160px; color:${this.lineColor};">${timestamp.replaceAll("\r", "<br>")}</span>`);
                                            control.css("overflow", "initial");
                                        } else if (tag.serverSmartTag.tagType === "CheckBox") {
                                            if (value == "true") {
                                                control.text("").append(`<img src="/checkmark.png" style='max-width: 100%; max-height: 100%'/>`)
                                            } else {
                                                control.html("")
                                            }
                                        } else {
                                            $(`#${tag.pdfName}-${tag.serverSmartTag.controlId.replaceAll("-", "_")}`).text(value);
                                        }
                                    }
                                });
                            }
                        });
                        //this.updateControlData(data.tagID, data.control);
                        break;
                    case "sendBorrowerWaiting":
                        var popupMsg = "";
                        if (data.isFirst == true) {
                            popupMsg = data.participantName + ' has entered the signing room';
                        } else {
                            popupMsg = data.participantName + ' is waiting to be admitted';
                        }
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'bottom',
                            showConfirmButton: false,
                            timer: 7000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', Swal.stopTimer)
                                toast.addEventListener('mouseleave', Swal.resumeTimer)
                            }
                        })
                        Toast.fire({
                            icon: 'success',
                            title: popupMsg
                        })
                        this.refreshSigners();
                        this.$refs.journal.getIdDetails();
                        break;

                    case "refreshSignerList":
                        this.refreshSigners();
                    break;

                    case "sendmessagetonotary":
                        Swal.fire("Failed", data.message, "error");
                        break;
                    case "signersCompletedSigning":
                        this.scrollCurrentControlIntoView();
                        break;
                    default:
                        console.log("method not found");

                }

            },
            imageUpdated(event) {
                const [file] = event.target.files;
                if (file) {
                    var reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        this.$emit("imageBlob", reader.result);
                    };
                }
            },
            retakePicture() {

                this.pictureTaken = false;
                this.hideVideo = true;
                const videoElement = document.getElementById('retakeVideoPreview');
                const canvasElement = document.getElementById('canvas');

                if (!this.retakeVideoPreview) {
                    this.retakeVideoPreview = new Webcam(videoElement, 'user', canvasElement);
                }
                this.retakeVideoPreview.start();

            },
            cancelVideoCapture() {
                this.pictureTaken = false;
                this.hideVideo = false;
                this.retakeVideoPreview.stop();
            },
            showMeetingWaitModal() {
                $(this.$refs.modalMeetingWaitRef).modal("show");
            },
            showRetakePictureModal() {
                $(this.$refs.modalRetakePicture).modal("show");
            },
            async saveReTakenPicture() {
                var token = "6fcf5fe82dfb402f9b623f45e4b9f8a6";

                return await ApiService.GeneratePostRequest(
                    "verify/reuploadpic",
                    {
                        imageData: this.ImageBinary,
                        isFront: "true",
                        signerIdentity: token
                    }
                ).then((response) => {
                    if (response.success) {
                        Swal.fire("Changes Saved!", "", "success");
                        this.pictureTaken = false;
                        this.hideVideo = false;
                        $(this.$refs.modalRetakePicture).modal("hide");

                    } else { Swal.fire("Failed", response.message, "error"); }

                });

            },
            showReUploadPreview(event) {
                let input = $('#reuploadPictureUploader')[0];
                if (input.files && input.files[0]) {
                    if (input.files[0].size >= 10485760) //10mb
                    {
                        $('#ImageBinary,#reuploadPictureUploader').val('');
                        showMessage('Error', 'Please upload image less than 10mb!');
                        return;
                    }
                }
                const image = event.target.files[0];
                const reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onload = (event) => {
                    this.pictureTaken = true;
                    this.ImageBinary = event.target.result;
                };

            },
            btnCapturePic() {
                this.pictureTaken = true;
                const videoElement = document.getElementById('retakeVideoPreview');
                const canvasElement = document.getElementById('canvas');

                var context = canvasElement.getContext('2d');

                canvas.width = videoElement.offsetWidth;
                canvas.height = videoElement.offsetHeight;

                context.drawImage(videoElement, videoElement.offsetLeft, videoElement.offsetTop, 500, 350);

                var dataURL = canvasElement.toDataURL();
                dataURL = dataURL;

                this.ImageBinary = dataURL;
            },
            hideSetup() {
                $(this.$refs.incomingCallModal).modal("hide");
            },
            signerTagPlaced() {
                $(this.$refs.modalSignerTagsPlaced).modal("show");
            },
            /*****************************************************
                            END AV FUNCTIONS
             *****************************************************/
            guidedArrowText(tagType, data) {
                let str = "";
                switch (tagType) {
                    case "Initials":
                        str = "Initial";
                        break;
                    case "Signature":
                        str = "Sign";
                        break;
                    case "CheckBox":
                        console.log("Checkbox data", data);
                        if (data.value === "" || data.value === CHECKBOX_SKIPPED_VALUE)
                            str = "";
                        else
                            str = "";
                        break;
                    case "FreeForm":
                        str = "Text";
                        break;
                    default:
                        str = tagType;
                }

                return str;
            },
            guidedArrowLeftPosition(tagType, data) {
                let value = data.x + data.width + 25;
                if (tagType === "CheckBox")
                    value = data.x - 38
                return value;
            },
            log(val) {
                // console.log("LOGG", val);
                return true;
            },
            setDocumentId(id) {
                this.documentId = id
            },
            allDocumentsSignedAndReviewed() {
                this.disableComplete = true;
                console.log("Closing", this.pdfForms);
                if (this.pdfForms.length === 0) {
                    return false;
                }

                for (var i = 0; i < this.pdfForms.length; i++) {
                    let documentData = this.files[i];
                    let pdfData = this.pdfForms[i];
                    switch (documentData["documentType"]) {
                        case DOC_TYPE.SIGN:
                            if (!pdfData.allFieldsCompleted(true)) {
                                console.log("all fields not completed", documentData, pdfData);
                                return;

                            } else {
                                if (this.files[i].documentType == DOC_TYPE.SIGN && this.files[i].smartTags.filter(x => x.signerId == this.signer.id).length > 0) {
                                    this.pdfForms[i].formIsReviewed = true;
                                    this.files[i].participantStatus = DOC_PARTICIPATION_STATUS.SIGNED;
                                    var item = this.items.find(x => x.docId == this.files[i].documentID);
                                    var idx = this.items.findIndex(x => x.docId == this.files[i].documentID);
                                    //Not sure if this is used, but catering for undefined
                                    if (item !== undefined) {
                                        this.items.splice(idx, 1)
                                        item.parentId = 3;
                                        item.parent = 3;
                                        this.items.push(item)
                                        var parentIdx = this.items.findIndex(x => x.id == 2);
                                        this.items[parentIdx].text = `Ready for Review and Signing (${this.items.filter(x => x.parentId == 2).length})`
                                        this.items[parentIdx].disabled = this.items.filter(x => x.parentId == 2).length == 0
                                        parentIdx = this.items.findIndex(x => x.id == 3);
                                        this.items[parentIdx].text = `Completed (${this.items.filter(x => x.parentId == 3).length})`
                                    }
                                } else if (this.files[i].smartTags.filter(x => x.signerId == this.signer.id).length == 0) {
                                    if (this.files[i].participantStatus != DOC_PARTICIPATION_STATUS.REVIEWED) {
                                        return;
                                    }
                                }

                            }
                            break;
                        case DOC_TYPE.REVIEW:
                            if (this.files[i].participantStatus == DOC_PARTICIPATION_STATUS.PENDING_REVIEW) {
                                return;
                            }
                            break;
                    }
                }
                console.log("showing closing");
                // if all documents are signed but the page is still loaded show render arrow to atleast jump to complete button
                if (!this.initialLoad) {
                    this.renderJumpArrow = false;
                }
                // this.disableComplete = false;
                if (!this.initialLoad) {
                    let array = this.checkboxesToSignOnPDFAfterIAttest.map((id) => {
                        return {
                            id: id,
                            displayText: CHECKBOX_CHECKED_VALUE
                        }
                    });
                    console.log("signing multiple");



                    //Disabled I attest for notary signing room
                    if (!this.initialLoad && SHOW_I_ATTEST && !this.isNotary) {
                        SHOW_I_ATTEST = false;
                        let interval = setInterval(() => {
                            if (!IS_CURRENTLY_SIGNING) {
                                this.signMultipleOnPDF(array, () => {
                                    Swal.fire({
                                        title: 'Signing Completed',
                                        text: 'I attest that by my signature, I acknowledge that I have read, understand, and agree to the terms and conditions outlined.',// Todo move to config or something
                                        icon: 'success',
                                        confirmButtonText: 'Confirm'
                                    }).then(function () {
                                        //  var login = this.$store.getters["Admin/loginInformation"];
                                        //  var payload = {
                                        //      "SignerId": this.signer.id,
                                        //      "SignerName": this.signer.firstName + " " + this.signer.lastName,
                                        //      "SignerEmail": this.signer.emailAddress,
                                        //      "PackageName": "",
                                        //      "NotarizeId": this.NotarizeID,
                                        //      "RequesterId": login.userId,
                                        //      "Lender": ""
                                        //  };
                                        //  ApiService.GeneratePostRequest(`econsent/signingComplete/sendLenderNotification`, payload);
                                        ApiService.GeneratePostRequest("signer/logAllSignedByAppUser?notarizeId="+this.NotarizeID);
                                        this.activeDrawerIdx = 3
                                        this.documentId = 0
                                    }.bind(this))
                                });
                                clearInterval(interval);
                            }
                        }, 100);

                    }
                }
            },
            scrollCurrentControlIntoView(onComplete = null, controlData, pdfName, isEnote = false) {
                this.isScrolling = true;
                var self = this;
                var prefix = "";
                var control = controlData || this.pdfForm.allFormFields[this.currentControlIndex];
                var name = pdfName || self.pdfForm.pdfName;
                // if current control to scroll does not exist "i.e" first  document is a review document
                if (!control) {
                    let isSuccessful = this.findAndSetNextSignableTag();
                    if (!isSuccessful) {
                        // if no more signing forms to fill scroll to complete button instead
                        this.scrollItemIntoView(".signer-room-form-containter", ".review-button.btn");
                        this.allDocumentsSignedAndReviewed();
                        return;
                    }
                }
                if (self.pdfForm.isEnote || isEnote) {
                    // element should be signable tag not actual input
                    prefix = "ga-";
                }
                var index = pdfName ? this.findTagIndexById(controlData.smartTag.id) : this.findNextAvailableTagIndex();
                let tag = this.allTagsCurrentlyAvailableToSign[index];
                var element = document.getElementById(`${prefix}${tag.pdfName}-${tag.noSpaceIdAlias}`);


                $('.signer-room-form-containter').animate({
                    scrollTop: $('.signer-room-form-containter').scrollTop() - $('.signer-room-form-containter').offset().top + $(element).offset().top - 50,
                    complete: onComplete
                }, 1000, () => {
                    this.isScrolling = false;
                    this.checkForIntersectingAndAdjust();
                });
            },
            scrollItemIntoView(containerSelector, elementSelector) {
                this.isScrolling = true;
                $(containerSelector).animate({
                    scrollTop: $(containerSelector).scrollTop() - $(containerSelector).offset().top + $(elementSelector).offset().top
                }, 1000);
            },
            scrollPageIntoViewCentered(elementSelector, cb) {
                this.isScrolling = true;
                $('.signer-room-form-containter').animate({
                    scrollTop: $('.signer-room-form-containter').scrollTop() - $('.signer-room-form-containter').offset().top + $(elementSelector).offset().top - 50,
                }, 1000, () => {
                    if (cb)
                        cb();
                    this.isScrolling = false;
                    this.needsToGoToNextForm = false;
                });
                return;
                //document.querySelector(elementSelector).scrollIntoView({
                //    behavior: 'smooth',
                //    block: 'center',
                //    inline: 'center'
                //});
            },
            isElementInViewport(el) {
                if (typeof jQuery === "function" && el instanceof jQuery) {
                    el = el[0];
                }

                var rect = el.getBoundingClientRect();

                return (
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
                );
            },
            setCheckBoxValue(compareNoSpaceId, value) {
                this.pdfForms.forEach((item) => {
                    item.allFormFields.forEach((field) => {
                        if (field.noSpaceId === compareNoSpaceId) {
                            item.setField(field.noSpaceIdAlias, value);
                        }
                    });
                });
                this.allTagsCurrentlyAvailableToSign.forEach((item) => {
                    if (item.noSpaceId === compareNoSpaceId) {
                        item.value = value;
                    }
                });
            },
            async jumpToNextGuidedArrow(isAuto = false) {
                if (this.findNextAvailableTagIndex() < 0) {
                    console.log("disabling arrow");
                    this.renderJumpArrow = false;
                    return;
                }
                this.moveJumpArrowToNextPage = true;
                this.checkAndEnableGuidedTags();
                let index = this.findNextAvailableTagIndex();
                if (this.allTagsCurrentlyAvailableToSign[index] &&
                    this.allTagsCurrentlyAvailableToSign[index].value === '' && !this.initialLoad && this.currentTagInView && isAuto != true) {
                    console.log("Complete current tag to move on", this.pdfForm.allFormFields[this.currentControlIndex]);
                    if (!this.isCurrentTagOptional)
                        return;
                    console.log("Skipping");
                    this.setCheckBoxValue(this.allTagsCurrentlyAvailableToSign[index].noSpaceId, CHECKBOX_SKIPPED_VALUE);

                    this.allTagsCurrentlyAvailableToSign[index].value = CHECKBOX_SKIPPED_VALUE;
                    this.checkAndEnableGuidedTags();
                    let settings = await this.$store.getters["App/appSettings"];
                    if (settings.autoNavigate == true) {
                        this.jumpToNextGuidedArrow(true);
                    }
                }


                if (this.initialLoad) {
                    this.initialLoad = false;
                    this.scrollCurrentControlIntoView(() => {
                        this.moveJumpArrowToNextPage = false;
                    });
                } else {
                    if (!this.isSigningDate) {
                        // in pre close the user can skip
                    }

                    this.currentControlIndex++;
                    if (this.needsToGoToNextForm) {
                        this.setNextSigningForm()
                    }
                    this.moveToNextGuidedTag(() => {
                        this.moveJumpArrowToNextPage = false;
                    });
                }
                this.checkAndEnableGuidedTags();
                this.checkForIntersectingAndAdjust();
                console.log("moving to index", this.currentControlIndex)
            },
            async guidedArrowClicked(control, index, pdfIndex) {
                console.log("guidedArrowClicked ", control)
                this.moveJumpArrowToNextPage = true;
                // handle case where use manually scrolls to tag and clicks it to update jump arrow
                if (this.initialLoad) {
                    this.initialLoad = false;
                }


                if (!control.guidedTagEnabled)
                    return;




                this.setControlIndexForDrawSignature = index;
                this.currentFormIndex = pdfIndex;
                this.currentScrollDocName = this.files[this.currentFormIndex].name
                this.currentDocType = this.files[this.currentFormIndex].documentType
                this.currentControlIndex = index;

                let tagType = control.smartTag.tagType;

                // if a checkbox tax is clicked and it was previously checked unset the control value & remove image on UI.
                // Remove the checkbox from the list of checkboxes to be submitted at the end of the signing process
                if (tagType === "CheckBox" && control.value === CHECKBOX_CHECKED_VALUE) {
                    this.setCheckBoxValue(control.noSpaceIdAlias, CHECKBOX_SKIPPED_VALUE);
                    console.log("Unchecking checked tag", $('#' + this.pdfForm.pdfName + '-' + control.noSpaceIdAlias));
                    var id = REPLACE_TAG(control.element, 'div');
                    control.element = document.querySelector("#" + id);

                    this.checkboxesToSignOnPDFAfterIAttest = this.checkboxesToSignOnPDFAfterIAttest.filter((item) => {
                        if (item === control["smartTag"]["id"]) {
                            this.BroadcastAppliedTags(control["smartTag"]["id"], false, "");
                        }
                        return item !== control["smartTag"]["id"];
                    });

                    return;
                }

                this.signatureTypeId = tagType === "Initials" ? 3 : 1;

                var loginInformaiton = this.$store.getters["Admin/loginInformation"];
                if (tagType === "Signature") {
                    this.signatureText = loginInformaiton.firstName + " " + loginInformaiton.lastName
                } else if (tagType === "Initials") {
                    this.signatureText = loginInformaiton.firstName.charAt(0) + "." + loginInformaiton.lastName.charAt(0)
                }
                if (tagType === "Signature" || tagType === "Initials" || tagType === "Seal") {
                    this.setSignature(tagType);
                } else if (tagType === "FreeForm" || tagType === "Email" || tagType === "Address" || tagType === "ZipCode") {
                    this.showFreeFormModal();
                } else if (tagType === "Date" || tagType === "DateOfBirth") {
                    this.showDateModal();
                } else if (control.value === "" || control.value === CHECKBOX_SKIPPED_VALUE) {
                    let doAPICall = tagType !== "CheckBox";
                    // insert control text here
                    this.updateControlData(this.pdfForm.allFormFields[this.currentControlIndex], null, doAPICall);
                    let control = this.pdfForm.allFormFields[this.currentControlIndex];

                    // only fadeout if not checkbox since user can uncheck
                    if (tagType !== "CheckBox"){
                        $(`#ga-${this.pdfForm.pdfName}-${control.noSpaceIdAlias}`).fadeOut('slow');
                    }else{
                        this.checkboxesToSignOnPDFAfterIAttest.push(control["smartTag"]["id"]);
                        this.setCheckBoxValue(control.noSpaceIdAlias, CHECKBOX_CHECKED_VALUE);
                        this.BroadcastAppliedTags(control["smartTag"]["id"], true, "");
                        let settings = await this.$store.getters["App/appSettings"];
                        if (settings.autoNavigate == true) {
                            this.jumpToNextGuidedArrow(true);
                        }
                    }

                }
                console.log("Pending CHeckboxes to sign", this.checkboxesToSignOnPDFAfterIAttest);

                //if (!this.signingDate && this.settings.isContinuousScroll)
                //    this.currentControlIndex = index;




                // check if current page has anymore fields to fill and if not move to next pdfForm
                this.checkAndEnableGuidedTags();
                this.allDocumentsSignedAndReviewed();
            },
            checkAndEnableGuidedTags() {
                let index = this.findNextAvailableTagIndex();
                let tag = this.allTagsCurrentlyAvailableToSign[index];
                if (index >= 0) {
                    // find pdf form and enable control
                    let found = false;
                    this.pdfForms.forEach((item) => {
                        if (item.pdfName === tag.pdfName) {
                            item.allFormFields.forEach((field, idx) => {
                                if (field.noSpaceId === this.allTagsCurrentlyAvailableToSign[index].noSpaceId && found === false) {
                                    found = true;
                                    field.guidedTagEnabled = true;
                                    console.log("Tag Beging Activated", item, idx);
                                }
                            });
                        }
                    });
                } else {
                    console.log("No more documents to tag prince");
                    //this.renderJumpArrow = false;
                }
                return;
            },
            moveToNextGuidedTag(cb) {
                this.scrollCurrentControlIntoView(cb);
                return;
            },
            findNextAvailableTagIndex() {
                return this.allTagsCurrentlyAvailableToSign.findIndex((item) => item.value == "" && item.isAdmin);
            },
            findTagIndexById(id) {
                return this.allTagsCurrentlyAvailableToSign.findIndex((item) => item.smartTag.id == id);
            },
            findAndSetNextSignableTag() {
                var fieldIndex = -1;
                for (var i = 0; i < this.pdfForms.length; i++) {
                    var form = this.pdfForms[i];
                    if (form.allFormFields.length > 0) {
                        // in sure form fields aren't filled out
                        form.allFormFields.forEach((item, index) => {
                            if (item.value === "" && fieldIndex < 0 && item.isAdmin)
                                fieldIndex = index;
                        });
                        if (fieldIndex >= 0) {
                            this.currentControlIndex = fieldIndex;
                            this.currentFormIndex = i;
                            this.currentPage = this.pdfForm.allFormFields[this.currentControlIndex].belongsToPage;
                            this.currentScrollDocName = this.files[this.currentFormIndex].name
                            this.currentDocType = this.files[this.currentFormIndex].documentType
                            this.pdfForm.allFormFields[this.currentControlIndex].guidedTagEnabled = true;
                            return true;
                        }
                    }
                }
                return false;
            },
            findNextSigningFormIndex() {
                let tag = this.allTagsCurrentlyAvailableToSign[0];
                let searchValue = tag ? tag.pdfName : null;
                let val = -1;
                for (var i = 0; i < this.files.length; i++) {
                    if (this.files[i].documentType === DOC_TYPE.SIGN && this.files[i].noSpaceName === searchValue) {
                        var form = this.pdfForms[i];
                        if (form.allFormFields.length > 0) {
                            // ensure form fields aren't filled out
                            val = form.allFormFields.findIndex((item) => item.noSpaceId === tag.noSpaceId);
                            break;
                        }
                    }
                }
                return val;
            },
            setNextSigningForm() {
                let file = null;
                // scroll next document into view if all form fields filled out on current document
                let nextDocumentIndex = this.findNextSigningFormIndex();
                console.log("Next SIgning DOc", nextDocumentIndex, this.files[nextDocumentIndex]);
                file = this.files[nextDocumentIndex];
                this.resetNewFormProperties(file, nextDocumentIndex);


                return file;
            },
            resetNewFormProperties(file, newFormIndex) {
                if (file) {
                    this.currentControlIndex = -1;
                    this.setControlIndexForDrawSignature = 0;
                    this.currentPage = 1;
                    this.currentFormIndex = newFormIndex;
                    this.needsToGoToNextForm = false;
                    this.currentScrollDocName = this.files[this.currentFormIndex].name
                    this.currentDocType = this.files[this.currentFormIndex].documentType
                    if (this.pdfForm.allFormFields[this.currentControlIndex])
                        this.pdfForm.allFormFields[this.currentControlIndex].guidedTagEnabled = true;
                }
            },
            fetchSmartTagValueFromType(smartTag) {
                let val = "";
                var loginInformaiton = this.$store.getters["Admin/loginInformation"];
                switch (smartTag["tagType"]) {
                    case "FullName":
                        val = loginInformaiton.firstName + " " + loginInformaiton.lastName
                        break;
                    case "FirstName":
                        val = loginInformaiton.firstName
                        break;
                    case "LastName":
                        val = loginInformaiton.lastName
                        break;
                    default:
                        return "";
                }

                return val;
            },
            updateControlData(controlData, value, apiCallToSignPDF = true, useIncomingValue = false) {
                console.log("updateControlData CONTROL CLICKED", controlData);
                console.log("updateControlData VALUE CLICKED", value);
                console.log("updateControlData APICALLTOSIGNPDF CLICKED", apiCallToSignPDF);
                console.log("updateControlData USEINCOMINGVALUE CLICKED", useIncomingValue);
                let pdfName = useIncomingValue === true ? (controlData.pdfName || this.pdfForm.pdfName) : this.pdfForm.pdfName;
                let control = $(`#${this.pdfForm.pdfName}-${controlData.noSpaceIdAlias}`);
                let val = value || "";
                // DO SWITCH CASE TO DETERMINE WHAT TO ADD TO CONTROL
                var loginInformaiton = this.$store.getters["Admin/loginInformation"];
                switch (controlData["smartTag"]["tagType"]) {
                    case "FullName":
                        val = useIncomingValue ? value : loginInformaiton.firstName + " " + loginInformaiton.lastName
                        break;
                    case "FirstName":
                        val = useIncomingValue ? value : loginInformaiton.firstName
                        break;
                    case "LastName":
                        val = useIncomingValue ? value : loginInformaiton.lastName
                        break;
                    case "CheckBox":
                        if (useIncomingValue) {
                            if (value === 'true') {
                                var id = REPLACE_TAG(controlData.element, 'img'); // by default UI locations are input. change to a more relevant tag for text
                                $("#" + id).attr("src", "/checkmark.png");
                                $("#" + id).css("max-width", "100%");
                                $("#" + id).css("opacity", "1");
                                $("#" + id).css("z-index", "1");
                                $("#" + id).parent().css("z-index", "1");
                                controlData.element = document.querySelector("#" + id);
                                val = CHECKBOX_CHECKED_VALUE;
                            } else {
                                var id = REPLACE_TAG(controlData.element, 'div');
                                controlData.element = document.querySelector("#" + id);
                                val = CHECKBOX_SKIPPED_VALUE;
                            }
                        } else {
                            var id = REPLACE_TAG(controlData.element, 'img'); // by default UI locations are input. change to a more relevant tag for text
                            $("#" + id).attr("src", "/checkmark.png");
                            $("#" + id).css("max-width", "100%");
                            $("#" + id).css("opacity", "1");
                            $("#" + id).css("z-index", "1");
                            $("#" + id).parent().css("z-index", "1");
                            controlData.element = document.querySelector("#" + id);
                            val = CHECKBOX_CHECKED_VALUE;
                        }
                        break;
                    case "Address":
                    case "Email":
                    case "ZipCode":
                    case "FreeForm":
                        val = useIncomingValue ? value : this.freeFormText;
                        var id = controlData.element.id
                        if (!controlData.isDynamic) {
                            id = REPLACE_TAG(controlData.element, 'div'); // by default UI locations are input. change to a more relevant tag for text
                            controlData.element = document.querySelector("#" + id);
                        }
                        $("#" + id).css("font-family", "'Helvetica', 'Arial', sans-serif").css("overflow", "hidden").css("outline", "none");
                        $("#" + id).css("z-index", "1");
                        $("#" + id).parent().css("z-index", "1");
                        $("#" + id).text(val);
                        $("#" + id).css("z-index", "1");
                        $("#" + id).parent().css("z-index", "1");
                        let control = this.pdfForm.allFormFields[this.setControlIndexForDrawSignature];
                        $(`#ga-${this.pdfForm.pdfName}-${control.noSpaceIdAlias}`).fadeOut('slow');
                        break;
                    case "AutomaticSignatureDate":
                        // create a Date object
                        const date = new Date();
                        // get the current date of the month
                        const currDate = date.getDate();
                        // get the current month of the year
                        const currMonth = date.getMonth() + 1;
                        // get the current year
                        const currYear = date.getFullYear();
                        // combine above fields to get current date
                        val = useIncomingValue ? value : `${currMonth}/${currDate}/${currYear}`;
                        var id2 = controlData.element.id
                        if (!controlData.isDynamic) {
                            id2 = REPLACE_TAG(controlData.element, 'div'); // by default UI locations are input. change to a more relevant tag for text
                            controlData.element = document.querySelector("#" + id2);
                        }
                        $("#" + id2).css("font-family", "'Helvetica', 'Arial', sans-serif").css("font-size", "0.7vw").css("overflow", "hidden").css("outline", "none");
                        $("#" + id2).css("z-index", "1");
                        $("#" + id2).parent().css("z-index", "1");
                        $("#" + id2).text(val);
                        $("#" + id2).css("z-index", "1");
                        $("#" + id2).parent().css("z-index", "1");
                        break;
                    case "Date":
                    case "DateOfBirth":
                        if(controlData.smartTag.params){
                            let format = JSON.parse(controlData.smartTag.params)["format"];
                            val = moment(useIncomingValue ? value : this.dateFieldText).format(format);
                        }else{
                            val = moment(useIncomingValue ? value : this.dateFieldText).format("MM/DD/YYYY");
                        }
                        $(`#ga-${this.pdfForm.pdfName}-${controlData.noSpaceIdAlias}`).fadeOut('slow');
                        break;

                }

                control.css("font-family", "'Helvetica', 'Arial', sans-serif").css("font-size", "1vw").css("overflow", "hidden").css("outline", "none").css("z-index", "1");
                control.parent().css("z-index", "1");
                this.pdfForm.setField(controlData.noSpaceIdAlias, val);
                if (controlData.isDynamic) {
                    control.text(val);
                    control.parent().css("overflow", "initial");
                    //var el = controlData.element;
                    //let top = parseInt(el.css("top").replaceAll("px", ""));
                    // el.css("top", (top - (el.height() - 20)) + "px")
                } else
                    control.val(val);
                // control.css("color", this.lineColor).css("outline", "none");

                // check if other fields need to be updated

                this.updatePreviewBorder();
                this.allDocumentsSignedAndReviewed();

                // remove tag from signable list of tags
                if (controlData["smartTag"]["tagType"] !== "CheckBox") {
                    let index = this.findNextAvailableTagIndex();
                    if (useIncomingValue) {
                        index = this.findTagIndexById(controlData.smartTag.id);
                    }
                    if (index >= 0) {
                        //let tag = this.allTagsCurrentlyAvailableToSign[index];
                        let noSpaceId = controlData.noSpaceId;
                        //let pdfName = tag.pdfName;
                        let idx = this.allTagsCurrentlyAvailableToSign.findIndex(item => item.noSpaceId == noSpaceId && item.smartTag.id == controlData.smartTag.id);
                        this.allTagsCurrentlyAvailableToSign.splice(idx, 1);
                    }
                }
                this.updateCurrentTagInView();


                this.checkAndEnableGuidedTags();



                if (!apiCallToSignPDF)
                    return;

                controlData.childTagParentPdfName = pdfName;
                this.signOnPDF(controlData, value, val, controlData["smartTag"]["tagType"]);
            },
            updateCurrentTagInView() {
                let index = this.findNextAvailableTagIndex();
                if (index >= 0) {
                    let field = this.allTagsCurrentlyAvailableToSign[index];
                    let el = field["element"];
                    if (!el)
                        el = $("#ga-" + field.pdfName + "-" + field.noSpaceId)[0];// because enotes are in an iframe their "element" isn't created at instantiation
                    let tagInView = this.isElementInViewport(el);
                    this.currentTagInView = tagInView;
                }

            },
            getControlText(control) {
                return "Data";
            },
            updatePreviewBorder() {
                var self = this;

                // process initial colors for PDF forms in thumbnail
                $(".thumbnail-container > div > div").each(function (index, item) {
                    var pageNum = parseInt($(item).data("page"));
                    var pageName = $(item).data("pagename");
                    self.pdfForms.forEach((pdfForm) => {
                        var controlsForPage = pdfForm.allFormFields.filter((item) => item.belongsToPage === pageNum && pageName === pdfForm.pdfName);



                        if (controlsForPage.length > 0) {
                            // check if all controls in this page  are optional
                            let allAreOptional = true;

                            for (var i = 0; i < controlsForPage.length; i++) {
                                if (!self.isTagOptional(controlsForPage[i].smartTag)) {
                                    allAreOptional = false;
                                    break;
                                }
                            }


                            // check if all controls are complete
                            var isPageCompleted = true;
                            for (var i = 0; i < controlsForPage.length; i++) {
                                if (controlsForPage[i].value === "") {
                                    isPageCompleted = false;
                                    break;
                                }
                            }

                            if (allAreOptional)
                                $(`.thumbnail-container div[data-page="${pageNum}"][data-pagename="${pageName}"]`).removeClass("fe-to-be-signed fe-signed fe-optional").addClass("fe-optional");
                            else if (!isPageCompleted)
                                $(`.thumbnail-container div[data-page="${pageNum}"][data-pagename="${pageName}"]`).removeClass("fe-to-be-signed fe-signed").addClass("fe-to-be-signed");
                            else
                                $(`.thumbnail-container div[data-page="${pageNum}"][data-pagename="${pageName}"]`).removeClass("fe-to-be-signed fe-signed").addClass("fe-signed");
                        }
                    });
                });
            },
            async signMultipleOnPDF(array, callback) {
                console.log("Signing Multiple", array);
                if (array.length == 0) {
                    if (callback)
                        callback();

                    return;
                }
                ApiService.GeneratePostRequest(
                    "transaction/notary/tags/apply_multiple_changes", array.map(x => { return { id: x.id, signerId: this.signer.id, notarizeId: this.NotarizeID}})
                ).then((results) => {
                    if (!results["isSuccess"]) {
                        // show error
                        return;
                    }
                    if (callback)
                        callback();
                });
            },
            updateChildTag(tagData) {
                console.log("this.pdfForm.allFormFields[this.setControlIndexForDrawSignature]", this.pdfForm.allFormFields[this.setControlIndexForDrawSignature]);
                var child = this.pdfForm.allFormFields[this.setControlIndexForDrawSignature].smartTag.childId;
                for (var i = 0; i < this.pdfForms.length; i++) {
                    if (this.pdfForms[i].pdfName === tagData.childTagParentPdfName) {// find correct document
                        for (var j = 0; j < this.pdfForms[i].allFormFields.length; j++) {// find correct tag for document
                            if (this.pdfForms[i].allFormFields[j].smartTag.id == child) {
                                //the child smart tag found
                                console.log("Auto Date Child Found");
                                this.updateControlData(this.pdfForms[i].allFormFields[j], "");
                                break;
                            }
                        }
                    }
                }
            },
            async signOnPDF(controlData, value, val) {
                // send control data to server
                let postObj = {
                    "id": controlData["smartTag"]["id"],
                    "signerId": controlData["smartTag"]["signerId"],
                    "notarizeId": controlData["smartTag"]["notarizeId"],
                };
                // "value" is set when called from the SaveSignature Function. If you're manually calling updateControlData with the value passed, update the check below
                if (controlData["smartTag"]["tagType"] === "AutomaticSignatureDate") {
                    postObj["displayText"] = val;
                } else {
                    postObj[value ? "imgUrl" : "displayText"] = val;
                }

                // put this call inside ajax below
                IS_CURRENTLY_SIGNING = true;
                let settings = await this.$store.getters["App/appSettings"];
                
                
                await ApiService.GeneratePostRequest(
                    "transaction/notary/tags/apply_changes", postObj
                ).then((results) => {
                    console.log("Apply changes", controlData, results);
                    IS_CURRENTLY_SIGNING = false;
                    if (!results["isSuccess"]) {
                        // show error
                        return;
                    }

                    if (settings.autoNavigate == true) {
                        this.jumpToNextGuidedArrow(true);
                    }

                    // won't work for enotes - hotfix for demo
                    try {
                        // only notary needs to send action to borrower
                        if (controlData.smartTag.isNotaryOnly === undefined || controlData.smartTag.isNotaryOnly === false) this.BroadcastAppliedTags(controlData["smartTag"]["id"], val, results.content.replaceAll("\r", "<br>"))// send tags to all participants beside notary
                        if (controlData.smartTag.tagType === "Initials" || controlData.smartTag.tagType === "Signature" || controlData.smartTag.tagType === "Seal") {
                            let imageWidth = $('#' + controlData.element.id).width();
                            $('#' + controlData.element.id).on('load', function () {
                                imageWidth = $('#' + controlData.element.id).width();
                                $('#' + controlData.element.id + "-time").css("left", (imageWidth + 10) + "px");
                                $('#' + controlData.element.id + "-time").off("load", "**");
                            });
                            $(`<span id="${controlData.element.id}-time" style="z-index: 1;font-size: 0.35vw;position: absolute;left: ${imageWidth + 10}px;   display: inline-block;    width: 160px; color:${this.lineColor};">${results.content.replaceAll("\r", "<br>")}</span>`).insertAfter('#' + controlData.element.id);
                            this.updateChildTag(controlData);
                        }
                    } catch (e) {

                    }
                });
            },
            insertSignerTimeStamp(controlData, text) {
                try {
                    if (controlData.smartTag.tagType === "Initials" || controlData.smartTag.tagType === "Signature") {
                        let imageWidth = $('#' + controlData.element.id).width();
                        $('#' + controlData.element.id).on('load', function () {
                            imageWidth = $('#' + controlData.element.id).width();
                            $('#' + controlData.element.id + "-time").css("left", (imageWidth + 10) + "px");
                            $('#' + controlData.element.id + "-time").off("load", "**");
                        });
                        $(`<span id="${controlData.element.id}-time" style="font-size: 0.35vw;z-index: 1;position: absolute;left: ${imageWidth + 10}px;   display: inline-block;    width: 160px; color:${this.lineColor};">${text}</span>`).insertAfter('#' + controlData.element.id);
                    }
                } catch (e) {

                }
            },
            setDocumentAsReviewed(frm) {
                if (frm < 0) {
                    return;
                }

                if (this.pdfForms[frm].formIsReviewed) {
                    return;
                }

                if ((this.files[frm].documentType == DOC_TYPE.REVIEW && this.files[frm].participantStatus == DOC_PARTICIPATION_STATUS.PENDING_REVIEW) || (this.files[frm].documentType == DOC_TYPE.SIGN && this.files[frm].participantStatus == DOC_PARTICIPATION_STATUS.PENDING_REVIEW && this.files[frm].smartTags.filter(x => x.signerId == this.files[frm].signerId && x.isOptional == false).length == 0)) {
                    this.pdfForms[frm].formIsReviewed = true;
                    this.files[frm].participantStatus = DOC_PARTICIPATION_STATUS.REVIEWED;
                    var item = this.items.find(x => x.docId == this.files[frm].documentID);
                    var idx = this.items.findIndex(x => x.docId == this.files[frm].documentID);
                    this.items.splice(idx, 1)
                    item.parentId = 3;
                    item.parent = 3;
                    this.items.push(item)
                    var parentIdx = this.items.findIndex(x => x.id == 1);
                    this.items[parentIdx].text = `Ready for Review (${this.items.filter(x => x.parentId == 1).length})`
                    this.items[parentIdx].disabled = this.items.filter(x => x.parentId == 1).length == 0
                    parentIdx = this.items.findIndex(x => x.id == 3);
                    this.items[parentIdx].text = `Completed (${this.items.filter(x => x.parentId == 3).length})`
                    if (this.initialLoad) {
                        this.initialLoad = false;
                    }
                    //let results = await ApiService.GeneratePostRequest("document/update/status", { "documentId": this.files[frm].documentID, "status": DOC_PARTICIPATION_STATUS.REVIEWED });
                } else if (this.files[frm].participantStatus == DOC_PARTICIPATION_STATUS.PENDING_REVIEW && this.files[frm].smartTags.filter(x => x.signerId == this.files[frm].signerId).length > 0) {
                    this.pdfForms[frm].formIsReviewed = true;
                    this.files[frm].participantStatus = DOC_PARTICIPATION_STATUS.PENDING_SIGNING;
                    if (this.initialLoad) {
                        this.initialLoad = false;
                    }
                    //let results = await ApiService.GeneratePostRequest("document/update/status", { "documentId": this.files[frm].documentID, "status": DOC_PARTICIPATION_STATUS.PENDING_SIGNING });
                }
                this.allDocumentsSignedAndReviewed();
            },
            onItemInView(element) {
                let formInViewName = $(element).attr("data-pagename");
                let pageNumberInView = $(element).attr("data-page");
                if (formInViewName == "DUMMYDOCUMENT")
                    return;

                // first check if current document is a signing document
                // - only change to a next document if all controls have finished signing for that document
                if (this.files[this.currentFormIndex] && this.files[this.currentFormIndex]["documentType"] === DOC_TYPE.SIGN && !this.pdfForm.allFieldsCompleted() && this.isSigningDate) {
                    return;
                }


                // find index of form in view - if index isn't current set index update
                let index = this.pdfForms.findIndex((item) => {
                    return item.pdfName === formInViewName;
                });

                if (index !== this.currentFormIndex) {


                    this.currentPage = 1;
                    this.currentControlIndex = -1;// reset current control index
                    this.currentFormIndex = index;
                    this.currentScrollDocName = this.files[this.currentFormIndex].name
                    this.currentDocType = this.files[this.currentFormIndex].documentType
                    // check index against data array to determine the type of action to do: Sign | Review
                    let fileData = this.files[this.currentFormIndex];
                    switch (fileData["documentType"]) {
                        case DOC_TYPE.REVIEW:
                            break;
                        case DOC_TYPE.SIGN:
                            // show guided tags
                            this.showReviewContinueButton = false;
                            if (this.pdfForm && !this.pdfForm.allFieldsCompleted()) {
                                this.checkAndEnableGuidedTags();
                                //if (this.isSigningDate)
                                //    this.scrollCurrentControlIntoView();
                            }
                            break;
                    }
                }

                let pageIndex = parseInt($(element).attr("data-page"));
                if (this.currentPage !== pageIndex && !this.isSigningDate) {
                    this.currentPage = pageIndex;
                }
                //  console.log("What index are we? ", index);

                // before we move on to next document check if previous document was review and if it was show confirmation for
                // marking it as reviewed
                if (!this.settings.isContinuousScroll)
                    this.hasToConfirmDocumentHasBeenReviewed(parseInt($(element).attr("data-page")));

                //console.log($(element).attr("data-pagename") + ' page ' + $(element).attr("data-page") + " is in view ");
            },
            async checkItemsInView(event) {
                IS_SCROLLING = true;
            },
            onScrollHandler() {
                var self = this;
                var container = $(".signer-room-form-containter");
                function checkInView(container, elem, partial) {
                    let cTop = container.scrollTop - container.clientHeight * 0.1;
                    let cBottom = cTop + container.clientHeight;
                    //Get element properties
                    let eTop = elem.offsetTop;
                    let eBottom = eTop + elem.clientHeight;

                    //Check if in view
                    let isTotal = (eTop >= cTop && eBottom <= cBottom);
                    let isPartial = partial && (
                        (eTop < cTop && eBottom > cTop) ||
                        (eBottom > cBottom && eTop < cBottom)
                    );

                    //Return outcome
                    return (isTotal || isPartial);
                }

                // will only ever have at least two pieces of page show on the ui
                let array = container.find("div[data-page]").get();
                //let foundInViewTimes = 1; //when this is 2 means the page after the current one is in view - at any given time only two pages can be in view
                //for (let i = 0; i < array.length; i++) {
                //    if (foundInViewTimes >= 2) break;
                //    let e = array[i];
                //    let isInView = checkInView(container[0], e, true);
                //    if (isInView) {
                //        if (self.lastKnownVisibleForm !== $(e).attr("data-pagename")) {
                //            self.onItemInView(e);
                //            let index = self.pdfForms.findIndex((item) => { return item.pdfName === $(e).attr("data-pagename"); });
                //            self.setDocumentAsReviewed(index);
                //            foundInViewTimes += 1;
                //        }
                //    }
                //}





                // new code to determine if we are entering new page
                let firstItemInView = null;
                for (let i = 0; i < array.length; i++) {
                    let e = array[i];
                    let isInView = checkInView(container[0], e, true);
                    if (isInView) {
                        firstItemInView = e;
                        break;
                    }
                }
                let index = self.pdfForms.findIndex((item) => { return item.pdfName === $(firstItemInView).attr("data-pagename"); });

                if (this.files[index]) {
                    this.currentScrollDocName = this.files[index].name
                    this.currentDocType = this.files[index].documentType
                }

                if (firstItemInView) {
                    let elementRightAfter = $(firstItemInView).next();
                    let isInView = checkInView(container[0], elementRightAfter[0], true);


                    // set current page border
                    let name = $(firstItemInView).attr("data-pagename");
                    let num = $(firstItemInView).attr("data-page");
                    this.currentPage = parseInt(num);
                    this.currentFormIndex = self.pdfForms.findIndex((item) => { return item.pdfName === $(firstItemInView).attr("data-pagename"); });
                    $('.thumbnail-container > div > div').removeClass("active-thumbnail");
                    $(`.thumbnail-container > div > div[data-page='${num}'][data-pagename='${name}']`).addClass("active-thumbnail");


                    if (isInView) {
                        index = self.pdfForms.findIndex((item) => { return item.pdfName === elementRightAfter.attr("data-pagename"); });
                        if (self.lastKnownVisibleForm !== elementRightAfter.attr("data-pagename")) {
                            self.onItemInView(elementRightAfter[0]);

                            //self.setDocumentAsReviewed(index);
                        }


                        self.lastKnownVisibleForm = elementRightAfter.attr("data-pagename");


                        //this.currentScrollDocName = this.files[index].name
                        //this.currentDocType = this.files[index].documentType
                        //console.log("Setting title ", self.files[index].name)
                        //self.pageTitle = self.files[index].name;// elementRightAfter.attr("data-pagename");
                    }
                }


                let currentScrollPos = $(".signer-room-form-containter").scrollTop();


                // find all documents who are before current scroll pos and mark as review
                var passedFiles = [];
                let lastPage = 0;
                this.files.forEach((file, idx) => {
                    let pageName = file.noSpaceName;
                    lastPage = file.pages;
                    let pageDiv = $(`.signer-room-form-containter > div > div[data-pagename='${pageName}'][data-page='${lastPage}']`);
                    if (pageDiv != null && pageDiv[0] != null && pageDiv[0].offsetTop < currentScrollPos && !ALREADY_REVIEWED_IDS.includes(file.documentID)) {
                        passedFiles.push(file.documentID);
                        ALREADY_REVIEWED_IDS.push(file.documentID);
                        this.setDocumentAsReviewed(idx);
                        if (file.isEnote)
                            console.log("Enote Index", idx, this.files[idx]);
                    }
                });
                if (passedFiles.length > 0)
                    this.setAllPassedDocument(passedFiles, lastPage);

                //$(".signer-room-form-containter > div > div:last-child").prev()[0].offsetTop
                if (currentScrollPos >= OFFSET_OF_LAST_PAGE) {
                    console.log("Offset conditions met");
                    this.allDocumentsSignedAndReviewed();
                }




                // check if current tag is in viewport
                this.updateCurrentTagInView();

                // set direction of guided arrow
                let direction = "bottom";

                if (this.currentTagInView === false) {
                    direction = "bottom";
                    let index = this.findNextAvailableTagIndex();
                    if (index >= 0) {
                        let currentScrollPos = $(".signer-room-form-containter").scrollTop();

                        let tag = this.allTagsCurrentlyAvailableToSign[index];
                        let pageName = tag.pdfName;
                        let lastPage = tag.belongsToPage;
                        let pageDiv = $(`.signer-room-form-containter > div > div[data-pagename='${pageName}'][data-page='${lastPage}']`);
                        let el = tag.element;
                        if (!el)
                            el = "#ga-" + tag.pdfName + "-" + tag.noSpaceId;
                        else
                            el = "#ga-" + tag.element.id;

                        if (currentScrollPos > (pageDiv[0].offsetTop + $(el)[0].offsetTop)) {
                            direction = "top";
                        } else {
                            direction = "bottom";
                        }
                        console.log("should point ", direction, pageDiv);
                    }
                }
                this.scrollTagDirection = direction;

                this.checkForIntersectingAndAdjust();

                this.handleDynamicTagsOnScroll();

            },

            async setAllPassedDocument(doculist, lastPage) {
                await ApiService.GeneratePostRequest("document/notary/update/status/reviewed", {
                    docIds: doculist,
                    jsonString: JSON.stringify({
                        lastActionedDoc: {
                            id: doculist[doculist.length - 1],
                            pageNo: lastPage
                        }
                    }),
                    notarizeId: this.NotarizeID
                });

            },
            isIntersecting(mainElementSelector, possibleIntersectingElementSelector) {
                var selection = $(mainElementSelector);
                var rectSelection = selection[0].getBoundingClientRect();
                var rect = typeof possibleIntersectingElementSelector === 'string' ? $(possibleIntersectingElementSelector)[0].getBoundingClientRect() : possibleIntersectingElementSelector[0].getBoundingClientRect();
                if (rect.top + rect.height > rectSelection.top
                    && rect.left + rect.width > rectSelection.left
                    && rect.bottom - rect.height < rectSelection.bottom
                    && rect.right - rect.width < rectSelection.right) {
                    return true;
                }
                return false;
            },
            intersectOverlapPercent(mainElementSelector, possibleIntersectingElementSelector) {
                var selection = $(mainElementSelector);
                var rectSelection = selection[0].getBoundingClientRect();
                var rect = typeof possibleIntersectingElementSelector === 'string' ? $(possibleIntersectingElementSelector)[0].getBoundingClientRect() : possibleIntersectingElementSelector[0].getBoundingClientRect();

                const top1 = parseInt(rectSelection.top);
                const height1 = parseInt(rectSelection.height);
                const top2 = parseInt(rect.top);

                const overlap = 1 - (top2 - top1) / height1;

                return overlap;
            },
            isGuidedArrowIntersecting(id) {
                try {
                    return this.isIntersecting("#ga-navigateToNextControl", `#${id}`);
                } catch (e) {
                    console.log("isIntersecting in catch")
                }
                return false;
            },
            checkForIntersectingAndAdjust() {
                //let idx = this.findNextAvailableTagIndex();
                //let tag = this.allTagsCurrentlyAvailableToSign[idx];
                for (var i = 0; i < this.allTagsCurrentlyAvailableToSign.length; i++) {
                    let tag = this.allTagsCurrentlyAvailableToSign[i];
                    let isIntersecting = this.isGuidedArrowIntersecting('ga-' + tag.pdfName + '-' + tag.noSpaceIdAlias);
                    if (isIntersecting) {
                        let top = parseInt($("#ga-navigateToNextControl").css("top").replaceAll("px", ""));
                        $("#ga-navigateToNextControl").css("top", (top + 40) + "px")
                    }
                }
                //if (tag && this.isTagOptional(tag.smartTag)) {
                //    console.log("Adjusting optional tags");

                //    let isIntersecting = this.isGuidedArrowIntersecting('ga-' + tag.pdfName + '-' + tag.noSpaceIdAlias);
                //    if (isIntersecting) {
                //        let top = parseInt($("#ga-navigateToNextControl").css("top").replaceAll("px", ""));
                //        $("#ga-navigateToNextControl").css("top", (top + 40) + "px")
                //    }
                //}

            },
            initiateContinuousScroll() {
                // set up array of all tags being signed
                if (this.pdfForms.length > 0) {
                    this.pdfForms.forEach((item) => {
                        item.allFormFields.forEach((field) => {
                            this.allTagsCurrentlyAvailableToSign.push({ ...field, pdfName: item.pdfName });
                            console.log("this.allTagsCurrentlyAvailableToSign", this.allTagsCurrentlyAvailableToSign);
                        });
                    });

                    this.allTagsCurrentlyAvailableToSign.sort(function (a, b) {
                        if (a.smartTag.tagType === "AutomaticSignatureDate")
                            return 1;

                        if (b.smartTag.tagType === "AutomaticSignatureDate")
                            return -1;
                        return 0;
                    });
                }
                console.log("CURRENTLY SIGNING FOR ", this.allTagsCurrentlyAvailableToSign);


                this.$store.commit("App/updateLoaderOverride", {
                    showLoaderOverride: false,
                });
                var divs = $(".signer-room-form-containter > div > div:last-child").prev();
                if (divs.length > 0) {
                    // find position of last document: we minus 500px because the height given to us by jquery / javascript has an additional 498px attached. i assume this is because of bottom borders, padding etc
                    OFFSET_OF_LAST_PAGE = divs[0].offsetTop + $(".signer-room-form-containter > div > div:last-child").prev().height() - 500;
                }


                this.lastKnownVisibleForm = this.pdfForm.pdfName;
                this.renderJumpArrow = true;



                console.log("Initiated ", this.pdfForms[this.currentFormIndex]);
                console.log((this.initialLoad ? this.currentPage : (this.moveJumpArrowToNextPage && this.pdfForm.allFormFields[currentControlIndex] ? this.pdfForm.allFormFields[this.currentControlIndex].belongsToPage : this.pdfForm.allFormFields[this.currentControlIndex].belongsToPage)));
                if (this.files[this.currentFormIndex]["documentType"] === DOC_TYPE.SIGN && this.isSigningDate) {
                    // if is signing and signing date show first guided tag only
                    this.checkAndEnableGuidedTags();
                } else if (!this.isSigningDate) {
                    this.checkAndEnableGuidedTags();
                    //this.pdfForms.forEach((form, index) => {
                    //    if (!form.isEnote && form.allFormFields.length > 0 && this.files[index]["documentType"] === DOC_TYPE.SIGN) {
                    //        form.allFormFields.forEach((tag) => {
                    //            tag.guidedTagEnabled = true;
                    //        });
                    //    }
                    //});
                }

                $(`.thumbnail-container > div > div:first-child`).addClass("active-thumbnail");
                $('.signer-room-form-containter > div > div:last-child');
                $('.signer-room-form-containter > div').append('<div data-page="1" class="DUMMYDOCUMENT" data-pagename="DUMMYDOCUMENT" style="height:10vh"></div>');

                // set up thumbnail listener
                let self = this;
                $('.thumbnail-container > div').on("click", "div[data-pagename]", function () {
                    console.log("thumnbail clicked", this);
                    let num = $(this).attr("data-page");
                    let name = $(this).attr("data-pagename");
                    $('.thumbnail-container > div > div').removeClass("active-thumbnail");
                    self.scrollPageIntoViewCentered(`.signer-room-form-containter div[data-page='${num}'][data-pagename='${name}']`);


                    $(this).addClass("active-thumbnail");
                });
                $(".guided-arrow-container").css('height', $(".signer-room-form-containter > div").height() + "px");

                // resize event for eNote -

                window.onresize = () => {
                    if (window.innerWidth <= 1280) {
                        if ($(".signer-room-form-containter iframe").parent().width() < 700) {
                            $("iframe").css("width", "100%");
                        } else {
                            $(".signer-room-form-containter iframe").css("width", "70%");
                        }
                    } else {
                        $(".signer-room-form-containter iframe").css("width", "100%");
                    }


                    try {

                        // reposition enote tag location because text will wrap and overall document height changes
                        let eNoteIndex = this.pdfForms.findIndex((item) => item.isEnote);

                        let frame = $(".signer-room-form-containter iframe")[0];
                        let fields = frame.contentWindow.document.getElementsByTagName("signature_line");
                        let pdfList = this.pdfForms;
                        for (let field of fields) {
                            let client = field.getBoundingClientRect();
                            for (let i = 0; i < pdfList[eNoteIndex].allFormFields.length; i++) {
                                let tag = pdfList[eNoteIndex].allFormFields[i].smartTag;
                                let item = pdfList[eNoteIndex].allFormFields[i];
                                console.log(tag, item);
                                let img = tag.imgUrl || "";
                                if (tag.controlId === field.getAttribute("_id") && !tag.imgUrl) {
                                    item.x = client.left;
                                    item.y = client.top;
                                }
                            }

                        }
                    } catch (e) { }
                };

                this.checkAndEnableGuidedTags();
                this.allDocumentsSignedAndReviewed();

                setInterval(() => {
                    if (IS_SCROLLING) {
                        IS_SCROLLING = false;
                        this.onScrollHandler();
                    }
                }, 300);
            },
            hasToConfirmDocumentHasBeenReviewed() {
                let fileData = this.files[this.currentFormIndex];
                let pdfData = this.pdfForms[this.currentFormIndex];
                if (fileData["documentType"] === DOC_TYPE.REVIEW && !this.settings.isContinuousScroll) {
                    this.showReviewContinueButton = true;
                    if (this.isSigningDate) {
                        $(".signer-room-form-containter").css('overflow-y', 'hidden');
                    }
                    return true;
                }
                return false;
            },
            userClickedReviewed() {
                // DO API Request to mark Document as reviewed or page
                if (this.settings.isContinuousScroll) {
                    this.pdfForm.formIsReviewed = true;
                    let nextDocument = this.currentFormIndex + 1;
                    let form = this.pdfForms[nextDocument];
                    if (form) {
                        this.currentControlIndex = -1;
                        this.currentFormIndex = nextDocument;
                        this.currentScrollDocName = this.files[this.currentFormIndex].name
                        this.currentDocType = this.files[this.currentFormIndex].documentType
                        if (this.files[this.currentFormIndex]["documentType"] !== DOC_TYPE.REVIEW) {
                            this.showReviewContinueButton = false;
                            this.pdfForm.allFormFields[0].guidedTagEnabled = true;
                        }

                        $(".signer-room-form-containter").css('overflow-y', 'auto');
                        this.scrollPageIntoViewCentered(`.signer-room-form-containter div[data-page="1"][data-pagename="${form.pdfName}"]`);
                    }
                } else {

                }
                this.allDocumentsSignedAndReviewed();
            },
            userClickedClose() {
                this.allDocumentsSignedAndReviewed();
                ApiService.GeneratePostRequest(
                    "signer/logAllSigned"
                );
                if (this.disableComplete)
                    return;

                Swal.fire({
                    title: 'Signing Completed',
                    text: 'I attest that by my signature, I acknowledge that I have read, understand, and agree to the terms and conditions outlined.',// Todo move to config or something
                    icon: 'success',
                    confirmButtonText: 'Ok'
                });
                //audit log here

            },
            isTagOptional(smartTag) {
                //return SKIPPABLE_TAGS.indexOf(tagType) >= 0;
                return smartTag.isOptional;
            },
            getTagsPerPage(tags) {
                // this.$nextTick(() => {
                //   this.setUpMoveEventListeners();
                // });
                return tags.filter((t) => t.pageNo == this.activePageIndex + 1);
            },
            confirmTagTextInput(name) {
                if (
                    this.filesToSign[this.activeDocIndex]["smartTags"][this.activeSmartTagIndex][
                    "displayText"
                    ] != name
                ) {
                    const confirmed = window.confirm(
                        `Are you sure you want to replace tag with "${name}"`
                    );
                    return confirmed;
                }
                return false;
            },
            confirmTagImgInput(imgPath, name) {
                if (
                    this.filesToSign[this.activeDocIndex]["smartTags"][this.activeSmartTagIndex][
                    "imgUrl"
                    ] != imgPath
                ) {
                    const confirmed = window.confirm(
                        `Are you sure you want to replace tag with "${name}"`
                    );
                    return confirmed;
                }
                return false;
            },

            showJournalModal() {
                this.showJournal = true;
            },
            abortMeeting() {
                // another method to pass values
                localStorage.setItem('abortConnection', JSON.stringify(
                    {
                        NotarizeID: this.NotarizeID
                    })
                );
                this.$router.push({
                    name: 'MeetingFailure',
                    path: `/admin/meetingfailure`
                });

            },
            showFreeFormModal() {
                $(this.$refs.modalFreeFormRef).modal("show");
            },
            showDateModal() {
                $(this.$refs.dateModal).modal("show");
            },
            erase() {
                this.signatureImageBase64 = "";
                if (this.signatureCanvasContext)
                    this.signatureCanvasContext.clearRect(0, 0, 360, 160);

                let ctx = this.$refs.signatureCanvas.getContext("2d");
                ctx.canvas.height = 160;
                ctx.canvas.width = 360;
            },
            cropImageFromCanvas(context) {
                let ctx = context || this.$refs.signatureCanvas.getContext("2d");
                var canvas = ctx.canvas,
                    w = canvas.width, h = canvas.height,
                    pix = { x: [], y: [] },
                    imageData = ctx.getImageData(0, 0, canvas.width, canvas.height),
                    x, y, index;

                for (y = 0; y < h; y++) {
                    for (x = 0; x < w; x++) {
                        index = (y * w + x) * 4;
                        if (imageData.data[index + 3] > 0) {
                            pix.x.push(x);
                            pix.y.push(y);
                        }
                    }
                }
                pix.x.sort(function (a, b) { return a - b });
                pix.y.sort(function (a, b) { return a - b });
                var n = pix.x.length - 1;

                w = 1 + pix.x[n] - pix.x[0];
                h = 1 + pix.y[n] - pix.y[0];
                var cut = ctx.getImageData(pix.x[0], pix.y[0], w, h);

                canvas.width = w;
                canvas.height = h;
                ctx.putImageData(cut, 0, 0);

                var image = canvas.toDataURL();  //open cropped image in a new window
                return image;
            },
            async saveDrawSignature() {
                let img = this.cropImageFromCanvas();// this.$refs.signatureCanvas.toDataURL();

                // replace input with image
                this.setImageInPdf(img);

                let results = await ApiService.GeneratePostRequest(
                    "signer/nsignature/upload",
                    {
                        subType: this.signatureTypeId,
                        imageData: img.split(",")[1],
                        contextID: 'yOMInvZbx5gR_acv0bcPOC',
                        NotarizeID: this.NotarizeID,
                        signerId: this.signer.id,
                        signatureType: 1,
                    }
                );
                if (results.isSuccess && results.content) {
                    const imgPath = results.content + "?timestamp=" + new Date().getTime();
                    let control = this.pdfForm.allFormFields[this.setControlIndexForDrawSignature];
                    $(`#ga-${this.pdfForm.pdfName}-${control.noSpaceIdAlias}`).fadeOut('slow');
                    console.log("TAG TO HIDE", `#ga-${this.pdfForm.pdfName}-${control.noSpaceIdAlias}`);
                    this.updateControlData(this.pdfForm.allFormFields[this.setControlIndexForDrawSignature], imgPath);

                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-end',
                        showConfirmButton: false,
                        timer: 7000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    })
                    Toast.fire({
                        icon: 'success',
                        title: 'Signature saved'
                    })
                }
                this.allDocumentsSignedAndReviewed();
                $(this.$refs.modalSignatureRef).modal("hide");
                this.erase();
            },
            async saveFreeForm() {
                if (this.freeFormText.trim() !== "") {
                    this.updateControlData(this.pdfForm.allFormFields[this.currentControlIndex]);
                    this.freeFormText = "";
                }
                $(this.$refs.modalFreeFormRef).modal("hide");
            },
            async saveDate() {
                this.updateControlData(this.pdfForm.allFormFields[this.currentControlIndex]);
                $(this.$refs.dateModal).modal("hide");
                this.dateFieldText = "";
            },
            async saveFontSignature() {
                let errorList = [];

                if (this.activeSignatureFontFamily == "") {
                    Swal.fire({
                        title: 'Please select a font',
                        text: '',
                        icon: 'info',
                        confirmButtonText: 'Ok'
                    });
                    return;
                }

                if (this.signatureText.length < 2) {
                    errorList.push("Signature length cannot be less than 2 characters");
                }
                if (this.signatureText.length > 150) {
                    errorList.push("Signature length cannot be greater than 150 characters");
                }
                const regex = new RegExp(/^[A-zÀ-ÿ][-,A-z. ']+$/);
                console.log(this.signatureText)
                console.log(regex.test(this.signatureText))
                if (regex.test(this.signatureText) == false) {
                    errorList.push("Signature contains invalid characters");
                }
                if (errorList.length > 0) {
                    this.typeErrorList = [...errorList];
                    return;
                } else {
                    this.typeErrorList = []
                }

                this.signatureType = "canvas";

                var canvas = document.createElement("canvas");
                canvas.width = 300;
                canvas.height = 130;
                canvas.style.height = 250;
                var ctx = canvas.getContext("2d");

                ctx.font = "32px " + this.activeSignatureFontFamily;
                var text = this.signatureText;
                ctx.fillStyle = this.lineColor;
                ctx.textAlign = "center";
                ctx.fillText(text, 150, 65);



                let img = this.cropImageFromCanvas(ctx);//canvas.toDataURL();
                this.setImageInPdf(img);

                let results = await ApiService.GeneratePostRequest(
                    "signer/nsignature/upload",
                    {
                        subType: this.signatureTypeId,
                        imageData: img.split(",")[1],
                        contextID: this.ContextID,
                        NotarizeID: this.NotarizeID,
                        signerId: this.signer.id,
                        signatureType: 2,
                    }
                );
                if (results.isSuccess && results.content) {
                    const imgPath =
                        results.content + "?timestamp=" + new Date().getTime();
                    let control = this.pdfForm.allFormFields[this.setControlIndexForDrawSignature];
                    $(`#ga-${this.pdfForm.pdfName}-${control.noSpaceIdAlias}`).fadeOut('slow');
                    this.updateControlData(this.pdfForm.allFormFields[this.setControlIndexForDrawSignature], imgPath);

                }
                this.allDocumentsSignedAndReviewed();
                $(this.$refs.modalSignatureRef).modal("hide");
                this.erase();
            },
            async saveImageSignature() {
                let results = await ApiService.GeneratePostRequest(
                    "signer/nsignature/upload",
                    {
                        subType: this.signatureTypeId,
                        imageData: this.signatureImageBase64.split(",")[1],
                        contextID: this.ContextID,
                        NotarizeID: this.NotarizeID,
                        signerId: this.signer.id,
                        signatureType: 3,
                    }
                );
                if (results.isSuccess && results.content) {
                    const imgPath = results.content + "?timestamp=" + new Date().getTime();
                    // replace input with image
                    this.setImageInPdf(this.apiUrl + imgPath);


                    let control = this.pdfForm.allFormFields[this.setControlIndexForDrawSignature];
                    $(`#ga-${this.pdfForm.pdfName}-${control.noSpaceIdAlias}`).fadeOut('slow');
                    this.updateControlData(this.pdfForm.allFormFields[this.setControlIndexForDrawSignature], imgPath);
                }
                this.allDocumentsSignedAndReviewed();
                $(this.$refs.modalSignatureRef).modal("hide");
                this.erase();
            },

            convertToBase64(event) {
                const [file] = event.target.files;
                if (file) {
                    var reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        this.signatureImageBase64 = reader.result;
                    };
                }
            },
            async declineENote() {
                if (confirm("Are you sure you want to decline this consent?")) {
                    ;
                    var eNotes = this.filesToSign
                        .filter((x) => x.isEnote)
                        .map((x) => {
                            return {
                                id: x.packageDocumentId,
                                westSign: false,
                            };
                        });
                    let results = await ApiService.GeneratePostRequest(
                        "package/document/update/wetsign",
                        eNotes
                    );
                    if (results["isSuccess"]) {
                        Swal.fire({
                            title: 'eConsent declined. Closing Session',
                            text: '',
                            icon: 'warning',
                            confirmButtonText: 'Ok'
                        });
                        window.location = "/sign-out";
                    } else {
                        Swal.fire({
                            title: 'Error',
                            text: 'Something went wrong...',
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        });
                    }
                }
            },
            async acceptENote() {

                var eNotes = this.filesToSign
                    .filter((x) => x.isEnote)
                    .map((x) => {
                        return {
                            id: x.packageDocumentId,
                            westSign: false,
                        };
                    });
                let results = await ApiService.GeneratePostRequest(
                    "package/document/update/wetsign",
                    eNotes
                );
                if (results["isSuccess"]) {
                    this.showEnotes = true;
                    this.loadLegend();
                    $(this.$refs.modalENoteConsent).modal("hide");
                } else {
                    this.showEnotes = false;
                    Swal.fire({
                        title: 'Error',
                        text: 'Something went wrong...',
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    });
                }
            },
            //drawer functions
            handleClick() {
                this.expanded = !this.expanded;
                if (!this.expanded) {
                    this.items.forEach(item => item["data-expanded"] ? item["data-expanded"] = false : undefined);
                }
            },
            onSelect(ev) {
                this.expanded = true;
                const currentItem = ev.itemTarget.props;
                const isParent = currentItem["data-expanded"] !== undefined;
                const nextExpanded = !currentItem["data-expanded"];

                if (!isParent) {
                    this.renderJumpArrow = true;
                    this.activeDrawerIdx = currentItem.parent;

                    this.documentId = currentItem.docId;
                    var document = this.files.find(x => x.documentID == this.documentId);
                    this.currentScrollDocName = document.name
                    this.currentDocType = document.documentType
                    this.currentFormIndex = this.files.findIndex(x => x.documentID == this.documentId);
                    console.log(this.activeDrawerIdx, this.documentId);
                    console.log("itemList beforeMap", this.items);
                    if (currentItem.parent < 3) {
                        this.initialLoad = false;
                        this.currentControlIndex = -1;
                        this.scrollPageIntoViewCentered(`.signer-room-form-containter div[data-page="1"][data-pagename="${currentItem.nospacename}"]`);
                        // if (this.pdfForm && !this.pdfForm.allFieldsCompleted()) {
                        //     this.pdfForm.allFormFields[0].guidedTagEnabled = true;
                        // }
                    }

                } else {
                    this.activeDrawerIdx = currentItem.id;
                    this.documentId = 0;
                };

                const newData = this.items.map((item) => {
                    const {
                        selected,
                        ["data-expanded"]: currentExpanded,
                        id,
                        ...others
                    } = item;
                    const isCurrentItem = currentItem.id === id;
                    return {
                        selected: isCurrentItem,
                        ["data-expanded"]:
                            isCurrentItem && isParent ? nextExpanded : currentExpanded,
                        id,
                        ...others,
                    };
                });

                this.items = newData;
                console.log("itemList updated", this.items);
            },
            formatStatus: function (status) {
                return GridDataHelper.normalizeStatus(status);
            },
            setImageInPdf: function (imgUrl, pdfName, control, isEnote = false) {
                let new_element = $("<img/>");
                let field = control || this.pdfForm.allFormFields[this.setControlIndexForDrawSignature];
                let name = pdfName || this.pdfForm.pdfName;
                let selector = `#${name}-${field.noSpaceIdAlias}`;
                if (!isEnote) {
                    $.each($(selector).get(0).attributes, function (i, attrib) {
                        $(new_element).attr(attrib.name, attrib.value);
                    });
                };
                let height = $(selector).height();
                let scaleHeight = height;
                $(new_element).attr('src', imgUrl);
                $(new_element).css('bottom', '0px');
                $(new_element).css('width', 'auto');
                $(new_element).css('height', scaleHeight + 'px');
                $(new_element).css('z-index', "1");

                $(new_element).css('max-height', '100%');
                $(new_element).css('max-width', "100%");
                if (!isEnote) {
                    $(new_element).css('position', 'absolute');
                    new_element.html($(selector).html());
                    $(selector).replaceWith(new_element);

                } else {
                    let iframe = $(`#${name}-EnotePage_1`)[0];
                    let imgContainers = iframe.contentWindow.document.documentElement.getElementsByTagName("signature_line");

                    for (let container of imgContainers) {
                        if ($(container).attr("_id") === field.id)
                            $(container).html(new_element);
                    }
                }

                if (pdfName && control) {
                    $(`#ga-${pdfName}-${control.noSpaceIdAlias}`).fadeOut('slow');
                }
            },
            setSignature: async function (tagType) {

                var tagTypeId = 1;
                switch (tagType) {
                    case "Signature":
                        tagTypeId = 1
                        break;

                    case "Initials":
                        tagTypeId = 3
                        break;

                    case "Seal":
                        tagTypeId = 2
                        break;


                    default:
                        break;
                }
                let result = await ApiService.GenerateGetRequest(
                    "signer/admin_signature?subType=" + tagTypeId + "&NotarizeID=" + this.NotarizeID
                );
                if (result.isSuccess && result.content.exist) {
                    var imgPath = this.apiUrl + result.content.imageURL + "?timestamp=" + new Date().getTime();

                    //--Force user to use saved signature
                    this.setImageInPdf(imgPath);
                    let control = this.pdfForm.allFormFields[this.setControlIndexForDrawSignature];
                    $(`#ga-${this.pdfForm.pdfName}-${control.noSpaceIdAlias}`).fadeOut('slow');
                    this.updateControlData(this.pdfForm.allFormFields[this.setControlIndexForDrawSignature], imgPath);
                    
                    this.allDocumentsSignedAndReviewed();
                } else {
                    this.showSignModal();
                }

            },
            showSignModal() {
                this.erase();
                $(this.$refs.modalSignatureRef).modal("show");
            },
            // SendTagToBorrower(tagId, imgUrl, timestamp) {
            //     ApiService.GeneratePostRequest(
            //     `transaction/sendtags/?receiverSignerID=` + this.UserID + '&tagSignerID=' + this.ContextID + '&tagDocumentID=' + tagId + "&imgUrl=" + encodeURIComponent(imgUrl) + "&timestamp=" + timestamp);
            //     this.SendTagToWitness(tagId, imgUrl, timestamp);
            // },
            BroadcastAppliedTags(tagId, imgUrl, timestamp) {
                if (this.signer.isRon) {
                ApiService.GeneratePostRequest(
                        `transaction/broadcastAppliedTags/?notarizeId=` + this.NotarizeID + '&tagDocumentID=' + tagId + "&imgUrl=" + imgUrl + "&timestamp=" + timestamp);
                }
            },

            SendDynamicTagToBorrower(stringifiedTag) {
                ApiService.GeneratePostRequest(
                    `transaction/senddynamictag/?receiverSignerID=` + this.UserID + '&stringifiedTagData=' + stringifiedTag);
            },
            SendShowIAttestToLegalWitness() {
                ApiService.GeneratePostRequest(
                    `transaction/showiattesttolegalwitness/?receiverSignerID=` + this.UserID);
            },
            SendDynamicTagToWitness(stringifiedTag) {
                ApiService.GeneratePostRequest(
                    `transaction/senddynamictagtowitness/?receiverSignerID=` + this.UserID + '&stringifiedTagData=' + stringifiedTag);
            },
            SendNewPageAlertToBorrower(documentId, page) {
                ApiService.GeneratePostRequest(
                    `transaction/sendnewpage/?receiverSignerID=` + this.UserID + '&documentId=' + documentId + '&page=' + page);

                this.SendNewPageAlertToWitness(documentId, page);
            },

            SendNewPageAlertToWitness(documentId, page) {
                ApiService.GeneratePostRequest(
                    `transaction/sendnewpagetowitness/?receiverSignerID=` + this.UserID + '&documentId=' + documentId + '&page=' + page);
            },
            async enableSigning(enableSigning = true) {
                //check if signer is verified
                var response = await ApiService.GenerateGetRequest(`signer/getSignerVerification?signerId=${this.UserID}&notarizeId=${this.NotarizeID}`);

                if (response.content == true) {
                    ApiService.GeneratePostRequest(`transaction/enableSigning/?receiverSignerID=` + this.UserID + '&tagSignerID=' + this.ContextID + '&enableSigning=' + enableSigning);

                    // visually show tags enable for user
                    let connectedUser = this.connectedSigners.find(x => x.id === this.selectedParticipant.id);
                    if (connectedUser) {
                        connectedUser.isEnabled = true;
                    }
                }
                else {
                    Swal.fire("Signer Not Verified", "Signer has to be verified before tags can be enabled.", "error");
                   
                }
                
            },
            isMobile() {
                if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    return true
                } else {
                    return false
                }
            },

            /** Manage Tag Functionality **/
            participantChanged(value) {
                console.log("participantChanged", value);
                this.selectedParticipant = value;
                this.UserID = this.selectedParticipant.id;
                this.ContextID = this.selectedParticipant.emailAddress;
            },
            async placeSeal() {
                let smartTagData = this.currentHighlightedTag.smartTag;
                var document = this.files.find((file) => file.documentID === smartTagData.documentId);
                if (document.isEnote) {
                    Swal.fire("Cannot Modify Seal", "Unable to add tags to eNote.", "error");
                    return;
                }
                if (document.isNotaryOnly && this.selectedParticipant.id !== 0) {
                    Swal.fire("Notary Only Document", "Unable to add tags to Notary Only documents.", "error");
                    return;
                }

                this.$store.commit("App/updateLoaderOverride", { showLoaderOverride: true });
                $("body").css("overflow", "hidden");

                let participantId = this.selectedParticipant.participantType === "Notary" ? 0 : this.selectedParticipant.id;

                let postObj = await this.getDynamicTagCoordinates(smartTagData);
                postObj.DocId = smartTagData.documentId;
                postObj.pageNo = smartTagData.belongsToPage;
                postObj.NotarizeId = this.NotarizeID;
                postObj.DisplayText = this.fetchSmartTagValueFromType(this.currentHighlightedTag.smartTag);
                postObj.ImgUrl = "test.png";
                postObj.SignerId = participantId;
                postObj.TagSubType = this.selectedSealType ? this.selectedSealType.notarialActName : null ;
                console.log("Seal Cooridnates ", postObj);
                await ApiService.GeneratePostRequest(
                    "transaction/notary/tags/new", postObj
                ).then((results) => {
                    this.$store.commit("App/updateLoaderOverride", { showLoaderOverride: false });
                    $("body").css("overflow", "auto");
                    if (!results["isSuccess"]) {
                        // show error
                        return;
                    }

                    this.currentHighlightedTag.smartTag.dynamicTagApplied = true;
                    this.currentHighlightedTag.smartTag.serverSmartTag = results.content;
                    let sealElement = $("#" + this.currentHighlightedTag.smartTag.id);
                    let pageElement = $(this.dynamicTagContainerLocation);
                    let sealRect = sealElement[0].getBoundingClientRect();
                    let pageRect = pageElement[0].getBoundingClientRect();
                    let top = Math.abs(sealRect.top - pageRect.top);
                    let left = Math.abs(sealRect.left - pageRect.left);

                    $("#" + smartTagData.id).off();
                    $("#" + smartTagData.id + " .ui-resizable-handle").remove();

                    $("#" + smartTagData.id).css("position", "absolute").css("position", "absolute").css("top", top + "px").css("left", left + "px").css("background", "transparent");
                    $("#" + smartTagData.id).detach().appendTo(this.dynamicTagContainerLocation);
                    if (this.currentHighlightedTag.smartTag.tagType === "CheckBox")
                        $("#" + smartTagData.id).css("overflow", "hidden").css("height", "30px").text("Check");

                    if (postObj.SignerId !== 0) {
                        var prefix = this.selectedParticipant.participantType === 'Signer' ? 'Borrower' : this.selectedParticipant.participantType.replaceAll("_", " ");
                        $("#" + smartTagData.id).text(prefix + " to Insert: " + this.currentHighlightedTag.smartTag.tagType);
                    }

                    // show guided arrow for these specific tags
                    switch (this.currentHighlightedTag.smartTag.tagType) {
                        case "Signature":
                        case "FullName":
                        case "Initials":
                        case "FirstName":
                        case "LastName":
                        case "CheckBox":
                        case "Date":
                        case "DateOfBirth":
                        case "Address":
                        case "Email":
                        case "ZipCode":
                        case "FreeForm":
                            let smartTag = results.content;
                            let pdfName = this.pdfForms.find((pdfForm) => pdfForm.documentId === this.pdfForm.documentId).pdfName;
                            $("#" + smartTagData.id).attr("id", pdfName + "-" + smartTag.controlId.replaceAll("-", "_"));
                            var elementId = pdfName + "-" + smartTag.controlId.replaceAll("-", "_");
                            var controlId = smartTag.controlId.replaceAll("-", "_");
                            //if is notary and is image tags
                            let isImageTags = smartTagData.tagType == "Signature" || smartTagData.tagType == "Initials";

                            if (postObj.SignerId == 0 && smartTagData.tagType && isImageTags) {
                                let imageWidth = $('#' + elementId).width();
                                $('#' + elementId).css("overflow", "initial");
                                $(`<span id="${controlId}-time" style="z-index: 1;font-size: 0.35vw;position: absolute;left: ${imageWidth + 10}px;   display: inline-block;    width: 160px; color:${this.lineColor};">${smartTag.timeStamp.replaceAll("\r", "<br>")}</span>`).appendTo('#' + elementId);
                                if (imageWidth <= 0) {
                                    $('#' + elementId).on('load', function () {
                                        imageWidth = $('#' + elementId).width();
                                        $('#' + elementId + "-time").css("left", (imageWidth + 10) + "px").css("z-index", "1");
                                        $('#' + elementId + "-time").off("load", "**");
                                    });
                                } else {
                                    $('#' + elementId + "-time").css("left", (imageWidth + 10) + "px").css("z-index", "1");
                                    $('#' + elementId + "-time").off("load", "**");
                                }
                            }

                            var signableSmartTag = {
                                x: left,
                                y: top,
                                controlWidth: postObj.Width,
                                controlHeight: postObj.Height,
                                pdfName: this.pdfForms.find((pdfForm) => pdfForm.documentId === this.pdfForm.documentId).pdfName,
                                belongsToPage: smartTag.pageNo,
                                id: smartTag.id,
                                noSpaceIdAlias: smartTag.controlId.replaceAll("-", "_"),
                                noSpaceId: smartTag.controlId.replaceAll("-", "_"),
                                documentId: smartTagData.documentId,
                                controlId: smartTag.controlId.replaceAll("-", "_"),
                                guidedTagEnabled: true,
                                isAdmin: typeof this.selectedParticipant.isAdmin === 'undefined' || this.selectedParticipant.isAdmin,
                                width: 0,
                                value: "",
                                element: $("#" + pdfName + "-" + smartTag.controlId.replaceAll("-", "_")),
                                isDynamic: true,
                                smartTag: {
                                    ...smartTag,
                                    params: smartTagData.params,
                                    isAdmin: true,
                                    value: ""
                                }
                            }
                            isImageTags = smartTagData.tagType == "Signature" || smartTagData.tagType == "Seal" || smartTagData.tagType == "Initials";

                           
                            if (postObj.SignerId == 0 && smartTagData.tagType && !isImageTags) {
                                this.pdfForms.forEach((form) => {
                                    if (form.documentId === smartTagData.documentId) {
                                        form.allFormFields.push(signableSmartTag);
                                    }
                                });
                                this.allTagsCurrentlyAvailableToSign.push(signableSmartTag);
                                this.checkAndEnableGuidedTags();
                            }
                            this.currentHighlightedTag.smartTag.pdfName = signableSmartTag.pdfName;                           

                            // send dynamic tag to borrower
                            let newObj = { ...signableSmartTag, y: postObj.Top, x: postObj.Left };
                            newObj.element = null;
                            this.SendDynamicTagToBorrower(JSON.stringify(newObj));
                            break;
                    }


                    // reset seal state
                    this.currentHighlightedTag = {};
                    this.teleportLocation = "body";
                    this.dynamicTagContainerLocation = "body";
                    this.selectedSealType = null;
                });

            },
            removeSeal() {
                let smartTagData = this.currentHighlightedTag.smartTag;
                var tagId = smartTagData.id;
                this.tagsAddedDuringSigning = this.tagsAddedDuringSigning.filter((item) => item.id !== tagId);
                $("#" + tagId).remove();

                this.currentHighlightedTag = {};
                this.teleportLocation = "body";
                this.dynamicTagContainerLocation = "body";
                $("#dynamicTagContainer").html("");
                this.selectedSealType = null;
            },
            handleDynamicTagsOnScroll() {
                this.tagsAddedDuringSigning.forEach((tag) => {
                    if (!tag.dynamicTagApplied) {
                        console.log("PDF FORMS", this.pdfForms, this.currentFormIndex);
                        let belongsToPage = this.currentPage;
                        let dynamicTagTeleportLocation = `.signer-room-form-containter div[data-page="${this.currentPage}"][data-pagename="${this.pdfForm.pdfName}"]`;
                        tag.documentId = this.pdfForms.find((pdfForm) => pdfForm.pdfName === this.pdfForm.pdfName).documentId;
                        // when user is scrolling we want to insert seal into the DOM of the page it's mostly on. This will help in translating coordinates.

                        // Step 1: get current page we are on and check if seal is intersecting.
                        var currentPageElement = $("#dynamicTagContainer").parent();// $(`.signer-room-form-containter div[data-page="${this.currentPage}"][data-pagename="${this.pdfForm.pdfName}"]`);
                        // Step 2: Check if tag is intersecting with neighbors. If is intersecting with neighbors, selected page should be the highest percent
                        //if (currentPageElement.next().hasClass("DUMMYDOCUMENT")) {
                        //    tag.documentId = this.pdfForms.find((pdfForm) => pdfForm.pdfName === currentPageElement.attr("data-pagename")).documentId;
                        //    tag.belongsToPage = parseInt(currentPageElement.attr("data-page"));
                        //    return;
                        //}
                        var intersectingWithPageAfter = this.isIntersecting("#" + tag.id, currentPageElement.next());

                        if (intersectingWithPageAfter && this.intersectOverlapPercent("#" + tag.id, currentPageElement.next()) > 0.5) {
                            tag.documentId = this.pdfForms.find((pdfForm) => pdfForm.pdfName === currentPageElement.next().attr("data-pagename")).documentId;
                            belongsToPage = parseInt(currentPageElement.next().attr("data-page"));
                            dynamicTagTeleportLocation = `.signer-room-form-containter div[data-page="${currentPageElement.next().attr("data-page")}"][data-pagename="${currentPageElement.next().attr("data-pagename")}"]`;
                        } else if (this.intersectOverlapPercent("#" + tag.id, currentPageElement) < 0.5) {
                            tag.documentId = this.pdfForms.find((pdfForm) => pdfForm.pdfName === currentPageElement.prev().attr("data-pagename")).documentId;

                            belongsToPage = parseInt(currentPageElement.prev().attr("data-page"));
                            dynamicTagTeleportLocation = `.signer-room-form-containter div[data-page="${currentPageElement.prev().attr("data-page")}"][data-pagename="${currentPageElement.prev().attr("data-pagename")}"]`;
                        } else {
                            return;
                        }
                        tag.belongsToPage = belongsToPage;
                        this.dynamicTagContainerLocation = dynamicTagTeleportLocation;
                        this.teleportLocation = this.dynamicTagContainerLocation;

                    }
                });
            },
            async getDynamicTagCoordinates(smartTag) {
                console.log("PDF FORM", this.pdfForm);
                let coordinates = {};
                if (smartTag.tagType == "Seal" || smartTag.tagType == "Signature" || smartTag.tagType == "Initials")
                    coordinates = await SmartTagManager.ImageFixedToAbsolute("#" + smartTag.id, this.dynamicTagContainerLocation, smartTag, this.pdfForm.path)
                else
                    coordinates = await SmartTagManager.TextFixedToAbsolute("#" + smartTag.id, this.dynamicTagContainerLocation, smartTag, this.pdfForm.path)
                return coordinates;

                return {
                    Top: top,
                    Left: left,
                    PageRatio: scale,
                    ResizeRatio: imageScale,
                    TagType: "Seal",
                }

            },
            async controlPaneTagClicked(tagType) {
                if (this.tagsAddedDuringSigning.findIndex((item) => item.dynamicTagApplied == false) >= 0) {
                    Swal.fire("Pending Tag", "There is a pending tag waiting to be applied.", "error");
                    return;
                }

                if (tagType == "Seal" && this.selectedSealType == null) {
                    this.showSealPopup();
                    return;
                }

                var self = this;
                let pageElement = $(`.signer-room-form-containter div[data-page="${this.currentPage}"][data-pagename="${this.pdfForm.pdfName}"]`);


                if (typeof this.selectedParticipant.isAdmin === 'undefined' || this.selectedParticipant.isAdmin || tagType === 'Seal') {
                    switch (tagType) {
                        case "Initials":
                        case "Signature":
                        case "Seal":
                            let settings = await this.getProfileSettings();
                            let image = settings[tagType.toLowerCase()];
                            let imageBase64Reponse = await ApiService.GeneratePostRequest("document/GetBase64?path=" + image);
                            if (imageBase64Reponse.isSuccess)
                                image = "data:image/png;base64," + imageBase64Reponse["content"];

                            SmartTagManager.CreateImageTag(tagType, image, this.currentPage, this.pdfForm.pdfName,
                                this.pdfForm.documentId, this.tagsAddedDuringSigning.length, pageElement, (tagOptions, html) => {
                                    self.dynamicTagContainerLocation = `.signer-room-form-containter div[data-page="${self.currentPage}"][data-pagename="${self.pdfForm.pdfName}"]`;
                                    self.tagsAddedDuringSigning.push(tagOptions);
                                    html.appendTo("#dynamicTagContainer")

                                    SmartTagManager.InitializeSmartTag($(`#${tagOptions.id}`), false, tagType, {
                                        onDragStop: onDragStop,
                                        onResize: onResize,
                                        onMouseEnter: onMouseEnter
                                    });

                            });

                            break;

                        default:
                            SmartTagManager.CreateTag(tagType, this.currentPage, this.pdfForm.pdfName, this.pdfForm.documentId, this.tagsAddedDuringSigning.length, pageElement, (tagOptions, html) => {
                                self.dynamicTagContainerLocation = `.signer-room-form-containter div[data-page="${self.currentPage}"][data-pagename="${self.pdfForm.pdfName}"]`;
                                self.tagsAddedDuringSigning.push(tagOptions);
                                html.appendTo("#dynamicTagContainer")

                                SmartTagManager.InitializeSmartTag($(`#${tagOptions.id}`), false, tagType, {
                                    onDragStop: onDragStop,
                                    onResize: onResize,
                                    onMouseEnter: onMouseEnter
                                });

                            });
                            break;
                    }

                } else {
                    SmartTagManager.CreateTag(tagType, this.currentPage, this.pdfForm.pdfName, this.pdfForm.documentId, this.tagsAddedDuringSigning.length, pageElement, (tagOptions, html) => {
                        self.dynamicTagContainerLocation = `.signer-room-form-containter div[data-page="${self.currentPage}"][data-pagename="${self.pdfForm.pdfName}"]`;
                        self.tagsAddedDuringSigning.push(tagOptions);
                        html.appendTo("#dynamicTagContainer")

                        SmartTagManager.InitializeSmartTag($(`#${tagOptions.id}`), false, tagType, {
                            onDragStop: onDragStop,
                            onResize: onResize,
                            onMouseEnter: onMouseEnter
                        });
                    });
                }


                function onDragStop(tagId, jElement) {
                    var index = self.tagsAddedDuringSigning.findIndex((x) => tagId == x.id);
                    self.tagsAddedDuringSigning[index]["controlTop"] = jElement.css("top");
                    self.tagsAddedDuringSigning[index]["controlLeft"] = jElement.css("left");

                    jElement.trigger("mouseenter");
                    self.handleDynamicTagsOnScroll();
                }

                function onResize(tagId, size, jElement) {
                    console.log("resize called");
                    var index = self.tagsAddedDuringSigning.findIndex((x) => tagId == x.id);
                    self.tagsAddedDuringSigning[index]["controlHeight"] = size.height + "px";
                    self.tagsAddedDuringSigning[index]["controlWidth"] = size.width + "px";

                    if (size.width > size.height) {
                        self.tagsAddedDuringSigning[index]["fontSize"] = size.width / 8 + "px";
                    } else {
                        self.tagsAddedDuringSigning[index]["fontSize"] = size.height / 5 + "px";
                    }
                    console.log("new size ", tagId, self.tagsAddedDuringSigning[index]);

                    jElement.trigger("mouseenter");
                    self.handleDynamicTagsOnScroll();
                }

                function onMouseEnter(jElement) {
                    let obj = {};
                    let height = jElement.height();
                    obj.width = jElement.width();
                    obj.obj = jElement[0];
                    obj.top = parseInt(jElement.css("top").replace("px", "")) - ($("#actionBar").height() || 36);
                    obj.left = jElement.css("left").replace("px", "");
                    self.currentHighlightedTag = obj;
                    let smartTagId = jElement.attr("data-index");
                    var index = self.tagsAddedDuringSigning.findIndex((x) => smartTagId == x.id);
                    self.currentHighlightedTag.smartTag = self.tagsAddedDuringSigning[index];
                    console.log("currentHighlightedTag", self.currentHighlightedTag)

                    if (self.teleportLocation === "body")
                        self.teleportLocation = self.dynamicTagContainerLocation;
                }


            },
            changeSealType(obj) {
                this.selectedSealType = obj.value;
            },
            async saveSealType() {
                if (this.selectedSealType != null) {
                    this.showSealType = false;
                    await this.controlPaneTagClicked("Seal");
                }
            },
            async showSealPopup() {
                this.showSealType = true;
                var userId = this.$store.state.Admin.loginInformation.userId;
                await ApiService.GenerateGetRequest(`Notarize/getNotarialActs?userId=` + userId).then(
                    response => {
                        this.sealTypes = response.content;
                        console.log(this.sealTypes, "seal Types");
                    }
                );
            },
            changeSealType(obj) {
                this.selectedSealType = obj.value;
            },
            async saveSealType() {
                if (this.selectedSealType != null) {
                    this.showSealType = false;
                    await this.controlPaneTagClicked("Seal");
                }
            },
            async showSealPopup() {
                this.showSealType = true;
                var userId = this.$store.state.Admin.loginInformation.userId;
                await ApiService.GenerateGetRequest(`Notarize/getNotarialActs?userId=` + userId).then(
                    response => {
                        this.sealTypes = response.content;
                    }
                );
            },
            async getProfileSettings() {
                let response = await ApiService.GenerateGetRequest(
                    "Admin/GetUserProfile"
                );
                if (response.isSuccess)
                    return response.content;

                return null;
            },
            async getSeal() {
                let response = await this.getProfileSettings();
                if (response.isSuccess)
                    return response.content.seal;

                return "";
            },
            async getSignature() {
                let response = await this.getProfileSettings();
                if (response.isSuccess)
                    return response.content.signature;

                return "";
            },
            async getInitials() {
                let response = await this.getProfileSettings();
                console.log("Get Signature", response);
                if (response.isSuccess)
                    return response.content.initials;

                return "";
            },
            async completeSigning() {
                var self = this;
                self.signerData = self.$store.getters["Auth/signerSessionInformation"];
                Swal.fire({
                    title: 'Are you sure you want to complete this signing?',
                    icon: 'info',
                    showCancelButton: true,
                }).then(async (result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        //let controlData = this.pdfForm.allFormFields[i];
                        let array = this.checkboxesToSignOnPDFAfterIAttest.map((id) => {
                            return {
                                id: id,
                                //"signerId": controlData["smartTag"]["signerId"],
                                //"notarizeId": controlData["smartTag"]["notarizeId"],
                                displayText: CHECKBOX_CHECKED_VALUE
                            }
                        });
                        console.log("signing multiple");

                        await this.signMultipleOnPDF(array, null);
                        await ApiService.GeneratePostRequest("signer/logAllSignedByAppUser?notarizeId="+self.NotarizeID);


                        let response = await ApiService.GeneratePostRequest(
                            "signer/CompleteSigningByNotary", {
                            notarizeId: this.NotarizeID
                        }
                        );
                        if (response.isSuccess) {
                            self.signerData = self.$store.getters["Auth/signerSessionInformation"];

                            var payload = {
                                "SignerId": self.signer.id,
                                "SignerName": self.signer["firstName"] + " " + self.signer["lastName"],
                                "SignerEmail": self.signer["emailAddress"],
                                "PackageName": "",
                                "NotarizeId": self.NotarizeID,
                                "RequesterId": self.$store.state.Admin.loginInformation.userId,
                                "Lender": ""
                            };
                            ApiService.GeneratePostRequest(`econsent/signingComplete/sendLenderNotification`, payload);

                            Swal.fire({
                                title: 'Signing Completed',
                                text: '',
                                icon: 'success',
                                confirmButtonText: 'Ok'
                            });
                        } else {
                            Swal.fire({
                                title: 'Error Completing Signing',
                                text: response.message,
                                icon: 'error',
                                confirmButtonText: 'Ok'
                            });
                        }
                    }
                })

            },
            startJournalCall() {
                this.$refs.journal.startCall();
            },
            groupBy(data, property) {
                return data.reduce((acc, obj) => {
                    const key = obj[property];
                    if (!acc[key]) {
                        acc[key] = [];
                    }
                    acc[key].push(obj);
                    return acc;
                }, {});
            },
            async handleForceClose() {
                var self = this;
                Swal.fire({
                    title: 'Are you sure you want to force close this package?',
                    icon: 'info',
                    showCancelButton: true,
                }).then(async (result) => {
                    if (!result.isConfirmed) { return; }
                    let response = await ApiService.GeneratePostRequest(
                        "signer/ForceCloseLoanByNotary", {
                        loanPackageID: this.loanDetail.loanPackageID,
                        notarizeId: this.loanDetail.notarizeID,
                    }
                    );
                    console.log("FORCE CLOSE RESPONSE", response);
                    if (response.isSuccess) {
                        self.signerData = self.$store.getters["Auth/signerSessionInformation"];
                        var payload = {
                            "SignerId": self.signer.id,
                            "SignerName": self.signer["firstName"] + " " + self.signer["lastName"],
                            "SignerEmail": self.signer["emailAddress"],
                            "PackageName": self.signerData["description"],
                            "NotarizeId": self.NotarizeID,
                            "RequesterId": self.$store.state.Admin.loginInformation.userId,
                            "Lender": self.signerData["lender"]
                        };
                        ApiService.GeneratePostRequest(`econsent/signingComplete/sendLenderNotification`, payload);
                        Swal.fire({
                            title: 'Loan Closed Successfully',
                            text: '',
                            icon: 'success',
                            confirmButtonText: 'Ok'
                        });
                        this.$router.push("/admin/detail/" + this.loanDetail.loanPackageID);
                    } else {
                        if (response.content == null) {
                            Swal.fire({
                                title: 'Force Close Failed',
                                text: response.message,
                                icon: 'error',
                                confirmButtonText: 'Ok'
                            });
                        } else {
                        this.groupedOutstandingTags = this.groupBy(response.content, "signerName");
                        console.log("GROUPED OUTSTANDING TAGS", this.groupedOutstandingTags);
                        //$(this.$refs.outstandingTagsModal).modal("show");
                    }
                    }
                })
            },
            normalizeStatus(string) {
                return GridDataHelper.normalizeStatus(string);
            },
        },
    };
</script>

<style>
	.k-rounded-md{
        border-radius: 0;
    }

    .activate-fullscreen {
        margin-left: -425px !important;
        min-width: 600px !important;
        min-height: 500px !important;
    }

    .errorLi {
        color: red;
        font-weight: bold;
        margin: 10px 0 0 20px !important;
        text-align: left;
        list-style-type: disc !important;
    }

    .showstatus {
        width: 100%;
        max-height: 60px;
        height: 60px;
        overflow-y: hidden;
        border-bottom: 1px solid #cdcdcd;
        box-shadow: 1px 1px 0 #ebebeb;
        font-size: 2em;
        line-height: 1.5em;
        text-align: center;
        margin: -20px 0 0 0;
        color: white;
    }

    .closing {
        background: #40e0a5;
    }

    .preclose {
        background: #3bc6f7;
    }

    .k-drawer-wrapper {
        margin-top: 0px;
    }

    #screen-page input {
        margin-bottom: initial;
    }

    .card {
        border-radius: 20px;
        margin-bottom: 20px;
        margin: 10px 20px;
    }
</style>
<style scoped>
    .k-card-body {
        padding: 0;
    }
    /*Drawer Styles*/
    .custom-toolbar {
        width: 50px;
        position: absolute;
        margin-top: 5px;
    }

    .k-icon::before {
        font-size: 14px;
    }

    .k-drawer-container .k-drawer-items .k-icon + .k-item-text {
        word-break: break-word;
        padding-left: 14px;
        line-height: 41px;
    }

    .k-drawer-content .row {
        margin-left: 0;
        margin-right: 0;
    }

    .k-drawer-item {
        padding: 1px 1px;
        margin: 0;
        font-size: 16px;
        line-height: 1.5;
    }

    .k-drawer-items li[data-expanded] {
        font-size: 16px;
        font-weight: bold;
        margin-top: 10px !important;
        margin-bottom: 0 !important;
    }

    .k-drawer-items li {
        padding: 0 0 0 15px;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        margin: 0;
        font-size: 14px;
    }

        .k-drawer-items li[data-expanded] .k-icon::before {
            font-size: 20px;
        }

        .k-drawer-items li.k-drawer-item.k-drawer-separator {
            margin-top: 10px !important;
        }

    .k-drawer-item .k-icon {
        line-height: initial;
    }

    .k-drawer-item .k-item-text {
        line-height: 25px !important;
        white-space: pre-line;
        margin-right: 2em;
    }
    /**NEW STYLES*/
    .signer-room-form-containter {
        background: #33363b;
        padding: 10px 0;
        overflow-x: hidden;
    }
    /**END NEW STYLES*/
    /* .smart-tag.ui-draggable.ui-draggable-handle.ui-resizable .ui-resizable {
          position: relative !important;
        } */
    #documentViewer {
        float: left;
        /*position: relative;*/
        display: block;
        width: 100%;
    }


    .smart-tag {
        float: left;
        cursor: move;
        border: 1px dotted rgb(168, 168, 168);
        background: #ffe6000e;
    }

    .tagText {
        font-family: Helvetica !important;
    }

    ul#nav-taggedpages a.tablinks {
        height: 30px;
        padding: 5px;
    }

    #img-viewer {
        border: 1px solid black;
        margin-top: 1px;
    }

    .img-viewer {
        margin: 0 auto;
        display: block;
        margin-bottom: 10px;
    }

    /*    #myPublisherDiv,
        #mySubscriberDiv {
            width: 175px !important;
            height: 140px !important;
        }
    */
    div#document-viewer div {
        cursor: pointer;
    }

    .thumbnail-container {
        background: #33363b;
        overflow: auto;
        padding: 0;
        border-right: 1px solid black;
    }

    .signer-room-form-containter, .thumbnail-container {
        height: calc(100vh - 220px);
    }

    .thumbnail-scroll img {
        border: 1px solid black;
        margin: 1px;
        margin-right: 0px;
    }

    .thumbnail-scroll {
        height: 100%;
        padding-bottom: 20px;
        overflow-y: auto;
        width: 100%;
    }

    nav#context-menu {
        position: absolute;
        border: 1px solid #000;
        border-radius: 3px;
        padding: 10px;
        z-index: 1001;
        background: transparent;
        box-shadow: none;
    }

    .context-menu {
        display: none;
        position: absolute;
        z-index: 10000;
        padding: 12px 0;
        width: 240px;
        background-color: #fff;
        border: solid 1px #dfdfdf;
        box-shadow: 1px 1px 2px #cfcfcf;
    }

    nav#context-menu span {
        cursor: pointer;
    }

    .highlight-xml-signature {
        border: 3px dotted rgba(0, 0, 0, 0.048);
        background: yellow;
    }


    .smart-tag > i {
        text-align: center;
        font: italic normal 90% Georgia, serif;
        line-height: 150%;
        color: black;
        text-shadow: white 0 1px;
        background: #ddd;
        background-clip: padding-box;
        box-shadow: 0 0px 2px rgba(0, 0, 0, 0.5);
        border: 2px solid rgba(0, 0, 0, 0.5);
        border-radius: 3px;
        position: absolute;
        width: 250px;
        left: 50%;
        margin-left: -125px;
        padding: 10px 0;
        bottom: 100%;
        margin-bottom: 15px;
        visibility: hidden;
        opacity: 0;
        -webkit-transition: opacity 0.5s linear;
        -moz-transition: opacity 0.5s linear;
        -ms-transition: opacity 0.5s linear;
        -o-transition: opacity 0.5s linear;
        transition: opacity 0.5s linear;
    }

        .smart-tag > i:before,
        .smart-tag > i:after {
            content: "";
            position: absolute;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            top: 100%;
            left: 50%;
            margin-left: -10px;
        }

        .smart-tag > i:before {
            border-top: 10px solid #111;
            border-top: 10px solid rgba(0, 0, 0, 0.5);
            margin-top: 5px;
        }

        .smart-tag > i:after {
            border-top: 10px solid #ddd;
            margin-top: -2px;
        }

    .smart-tag:hover > i {
        visibility: visible;
        opacity: 1;
        z-index: 1;
    }

    .review-button {
        position: absolute;
        color: #FFF;
        background: #7030A0;
        z-index: 1;
        width: 180px;
        padding: 10px 0;
        font-size: 1.2em;
        bottom: -55px;
        right: 0;
    }


    .thumbnail-container .signed {
        border: 3px solid green;
    }

    .thumbnail-container .to-be-signed {
        border: 3px solid yellow;
    }

    .watermark {
        pointer-events: none;
        user-select: none;
        position: absolute;
        color: gray;
        background: none;
        width: 100%;
        height: 100%;
        padding-left: 10%;
        font-size: 1500%;
        bottom: 5px;
        right: 5px;
        opacity: 0.16;
        border: none;
        z-index: 2;
        float: left;
        transform: rotate(-50deg);
        -ms-transform: rotate(-50deg); /* IE 9 */
        -webkit-transform: rotate(-50deg); /* Opera, Chrome, and Safari */
    }

    .Tabs {
        position: relative;
        background-color: #fff;
        margin: 10px 0;
        padding: 0;
        list-style: none;
        font-family: 'Roboto Slab';
    }

        .Tabs label a:hover, .Tabs label a {
            text-decoration: none;
            color: #666;
        }

        .Tabs:after {
            content: ' ';
            display: table;
            clear: both;
        }

    .Tabs__tab {
        float: left;
        padding: 15px 20px 10px 25px;
        text-align: left;
    }

    .Tabs__presentation-slider {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 10px;
        height: 4px;
        background-color: #4d97ac;
        transform-origin: 0 0;
        transition: all 0.3s ease;
        margin: 0 0 0 25px;
    }

        .Tabs__presentation-slider button {
            float: right;
            margin: -33px 7px 0 0;
            width: 20px;
            border: none;
            background: none;
        }

    .Tab > a {
        display: block;
        padding: 10px 12px;
        text-decoration: none;
        color: #666;
        transition: color .15s;
    }

    .Tab .active > a {
        color: #222;
    }

    .Tab .active:hover > a {
        color: #222;
    }

    /*to remove later*/
    .k-drawer-item.k-state-selected, .k-drawer-item.k-state-selected:hover {
        color: #4d97ac;
        font-weight: bold;
    }
</style>