<template>
    <div class="main">
        <div class="container">
            <div class="col-md-8">
                <!--<img class="logo" src="/images/esign-logo.png" />-->
                <h2>You are now logged out. </h2>
                <p>Thank you and have a great day. </p>
            </div>
            <!--<div class="col-md-2">
                <img class="stamp" src="/images/image-1.png" />
            </div>-->
        </div>
    </div>
</template>

<style scoped>
	.main {
		background-color: #ddd !important;
        width: 100%;
        height: 100vh;
	}
    .contact-info {
        margin-top: 60px;
    }

    h6 {
        text-transform: uppercase;
    }

    .logo {
        display: block;
        width: 260px;
        height: auto;
        margin-top: 200px;
    }

    ul {
        margin: 0;
        padding: 0;
    }

    li {
        list-style: none;
        font-family: 'Lato', sans-serif
    }

    h2 {
        font-weight: bolder;
        font-size: 50px;
        width: 75%;
    }

    p {
        font-size: 20px;
        line-height: 30px;
    }

    .stamp {
        display: block;
        width: 260px;
        height: auto;
        margin-top: 300px;
    }
</style>

<script>
    export default {
        name: 'ThankYou',
        components: {
        },
        data() {
            return {
            }
        },
        computed: {
        },
        created() {
        },
        methods: {
        }
    }
</script>