<template>
    <div class="profile-main-col-md-12">
        <div class="profile-row">
            <div class="form-fields">
                <div class="profile-col-md-12">
                    <h4>Role</h4>
                    <input class="form-control bdr-rad-0 disabled"
                           disabled="disabled"
                           id="role"
                           name="role"
                           placeholder="Your Role"
                           type="text"
                           v-model="role" />
                </div>
                <br />
                <div class="profile-col-md-12">
                    <h4>Email Address</h4>
                    <input class="form-control bdr-rad-0 disabled"
                           disabled="disabled"
                           id="email"
                           name="email"
                           placeholder="example@example.com"
                           type="text"
                           v-model="email" />
                </div>
                <br />
                <div class="profile-col-md-12">
                    <h4>First Name</h4>
                    <input class="form-control"
                           data-val="true"
                           data-val-required="Please enter First Name."
                           id="fname" name="fname"
                           placeholder="First Name"
                           type="text"
                           v-model="v$.fname.$model" />
                    <div v-if="v$.fname.$error" class="field-validation-valid text-danger">First Name field is required.</div>
                </div>
                <br />
                <div class="profile-col-md-12">
                    <h4>Last Name</h4>
                    <input class="form-control"
                           data-val="true"
                           data-val-required="Please enter Last Name."
                           id="lname"
                           name="lane"
                           placeholder="Last Name"
                           type="text"
                           v-model="v$.lname.$model" />
                    <div v-if="v$.lname.$error" class="field-validation-valid text-danger">Last Name field is required.</div>
                </div>
                <br />
                <div class="profile-col-md-12">
                    <h4>Phone Number</h4>
                    <input class="form-control bdr-rad-0"
                           data-val="true"
                           data-val-required="Please enter Cell Phone #."
                           id="phone"
                           name="phone"
                           placeholder="Phone Number"
                           type="text"
                           v-model="v$.phone.$model" />
                    <div v-if="v$.phone.$error" class="field-validation-valid text-danger">Phone Number is required</div>
                </div>
                <br />
                <div class="profile-col-md-12"  v-if="showNotaryItem">
                    <h4>State</h4>
                    <div>
                        <b>
                            <dropdownlist id="dropdownlist"
                                          :style="{ width: '100%' }"
                                          :data-items='states'
                                          :data-item-key="'id'"
                                          :text-field="'stateName'"
                                          :value="selectedState"
                                          @change="changeState"
                                          :steps="steps">
                            </dropdownlist>
                        </b>
                    </div>
                </div>
                <br />
                <div class="profile-col-md-12" v-if="showNotaryItem">
                    <h4>County</h4>
                    <div>
                        <b>
                            <dropdownlist id="dropdownlist"
                                          :style="{ width: '100%' }"
                                          :data-items='counties'
                                          :data-item-key="'id'"
                                          :text-field="'countyName'"
                                          :value="selectedCounty"
                                          @change="changeCounty"
                                          :steps="steps">
                            </dropdownlist>
                        </b>
                    </div>
                </div>
                <br />
                <div class="profile-col-md-12" v-if="showNotaryItem">
                    <h4>Commission Expiry Date</h4>
                    <div>
                        <b>
                            <datepicker :required="true"
                                        :style="{ width: '100%'}"
                                        v-model="this.expiryDate"
                                        :default-value="this.expiryDate"
                                        :default-Show="false"
                                        :format="'yyyy-MM-dd'"
                                        :steps="steps">
                            </datepicker>
                        </b>
                    </div>
                </div>

                <div class="update-button">
                    <div class="profile-col-md-12">
                        <button class="k-button k-primary" @click="updateProfile">Update</button>
                    </div>
                </div>

            </div>
            <div class="sigs">
                <h4>Signature</h4>
                <div class="row k-pb-md">
                    <div class="col-lg-6 col-md-6">
                        <div>
                            Preview
                        </div>
                        <div class="img-relative">
                            <canvas ref="signatureCanvas"
                                    style=" width: 360px; height: 160px;"></canvas>
                            <img :src="signatureFile" alt="" style="width: 100%;height: 100%;" />
                        </div>
                    </div>
                </div>
                <div style="vertical-align:bottom;">
                    <k-button class="k-button k-primary" @click="showSignModal(1)" ref="button">
                        Upload Signature
                    </k-button>
                </div>
                <hr>
                <h4>Initials</h4>
                <div class="row k-pb-md">
                    <div class="col-lg-6 col-md-6">
                        <div>
                            Preview
                        </div>
                        <div class="img-relative">
                            <canvas ref="signatureCanvas"
                                    style=" width: 360px; height: 160px;"></canvas>
                            <img :src="initialsFile" alt="" style="width: 100%;height: 100%;" />

                        </div>
                    </div>
                </div>
                <div style="vertical-align:bottom;">
                    <k-button class="k-button k-primary" @click="showSignModal(2)" ref="button">
                        Upload Initials
                    </k-button>
                </div>
                <div v-if="showNotaryItem">
                    <hr>
                    <h4>Seal</h4>
                    <div class="row k-pb-md">
                        <div class="col-lg-6 col-md-6">
                            <div>
                                Preview
                            </div>
                            <div class="img-relative">
                                <canvas ref="signatureCanvas"
                                        style=" width: 360px; height: 160px;"></canvas>
                                <img :src="sealFile" alt="" style="width: 100%;height: 100%;" />
                            </div>
                        </div>
                    </div>
                </div>
                <div style="vertical-align:bottom;" v-if="showNotaryItem">
                    <k-button class="k-button k-primary" @click="showSealModal" ref="button">
                        Upload Seal
                    </k-button>

                </div>

            </div>

        </div>
        


        
    </div>

    <!--Modal Signature/Initials-->
    <div ref="modalSignatureRef" class="modal" style="display: none;">
        <input type="hidden" id="MS_Mode" value="Signature" />
        <div id="divDrawing">
            <link href="https://fonts.googleapis.com/css?family=Allura&amp;display=swap"
                  rel="stylesheet" />
            <link href="https://fonts.googleapis.com/css?family=Dancing+Script&amp;display=swap"
                  rel="stylesheet" />
            <link href="https://fonts.googleapis.com/css?family=Mansalva&amp;display=swap"
                  rel="stylesheet" />
            <link href="https://fonts.googleapis.com/css?family=Satisfy&amp;display=swap"
                  rel="stylesheet" />
            <link href="https://fonts.googleapis.com/css?family=Sacramento&amp;display=swap"
                  rel="stylesheet" />
            <link href="https://fonts.googleapis.com/css?family=Vibes&amp;display=swap"
                  rel="stylesheet" />

            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <ul class="nav nav-tabs">
                            <li class="active">
                                <a v-show="signingTypes.Draw" data-toggle="tab" href="#Draw">Draw</a>
                            </li>
                            <li class="">
                                <a v-show="signingTypes.Type" data-toggle="tab" href="#Type">Type</a>
                            </li>
                            <li><a v-show="signingTypes.Upload" data-toggle="tab" href="#Upload">Upload</a></li>
                        </ul>
                        <div>
                            <button type="button" class="close" data-dismiss="modal">
                                x
                            </button>
                        </div>
                    </div>
                    <div class="modal-body">
                        <div class="tab-content">
                            <div id="Draw" class='tab-pane fade' v-show="signingTypes.Draw">
                                <div>
                                    <div class="img-relative">
                                        <canvas ref="signatureCanvas"
                                                style=" width: 360px; height: 160px;"></canvas>
                                        <img id="commentImg"
                                             style="border: solid; display: none;"
                                             height="360px"
                                             width="160px" />
                                    </div>
                                    <div class="modal-footer">
                                        <div align="right">
                                            <button type="button"
                                                    class="btn btn-secondary"
                                                    @click.prevent="erase">
                                                Clear
                                            </button>
                                            <button type="button"
                                                    style="background-color: #5cb85c; border-color: #4cae4c;"
                                                    class="btn btn-secondary"
                                                    @click.prevent="saveDrawSignature">
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="Type" class='tab-pane fade' v-show="signingTypes.Type">
                                <input type="text"
                                       name="signature-text"
                                       v-model="signatureText"
                                       placeholder="Type here"
                                       class="form-control"
                                       :style="typeErrorList.length>0 ? 'border-color:red' : ''" />
                                <ul v-show="typeErrorList.length>0">
                                    <li class="errorLi" v-for="item in typeErrorList" :key="item">
                                        {{ item }}
                                    </li>
                                </ul>
                                <div class="font-sample-preview">
                                    <div v-for="(item, index) in fonts"
                                         :key="index"
                                         class="signature-view"
                                         :class="{active: activeSignatureFontFamily == item.fontFam}"
                                         :style="{color: lineColor }"
                                         @click.prevent="activeSignatureFontFamily = item.fontFam">
                                        <h1 :style="{ fontFamily: item.fontFam }">
                                            {{ signatureText }}
                                        </h1>
                                        <!--<canvas width="250" height="80" v-insert-message="signatureText" :ref="item.fontFam" :id="item.fontFam" :style="{fontFamily: item.fontFam}"></canvas>-->
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <div align="right" class="pull-right">
                                        <button type="button"
                                                class="btn btn-success"
                                                @click.prevent="saveFontSignature">
                                            Accept
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div id="Upload" class='tab-pane fade' v-show="signingTypes.Upload">
                                <div class="bdr upload-files">
                                    <img v-if="signatureTypeId === 1 && signatureImageBase64 !== ''"
                                         class="img-fluid img-responsive"
                                         :src="signatureImageBase64" />
                                    <img v-if="signatureTypeId === 2 && initialsImageBase64 !== ''"
                                         class="img-fluid img-responsive"
                                         :src="initialsImageBase64" />
                                    <img v-else
                                         src="/images/upload-icon.png"
                                         alt=""
                                         class="img-fluid img-responsive" />
                                    <p>
                                        <input ref="uploadSignatureFile"
                                               type="file"
                                               class="custom-file-input"
                                               accept="image/x-png,image/jpeg"
                                               @change="convertToBase64($event)" />
                                    </p>
                                </div>
                                <div class="modal-footer">
                                    <div align="right">
                                        <button type="button"
                                                class="btn btn-success btn-block"
                                                @click.prevent="saveImageSignature">
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
     
    <!--Modal Seal-->
    <div ref="modalSealRef" class="modal" style="display: none;">
        <input type="hidden" id="MS_Mode" value="Seal" />
        <div id="divDrawing">
            <link href="https://fonts.googleapis.com/css?family=Allura&amp;display=swap"
                  rel="stylesheet" />
            <link href="https://fonts.googleapis.com/css?family=Dancing+Script&amp;display=swap"
                  rel="stylesheet" />
            <link href="https://fonts.googleapis.com/css?family=Mansalva&amp;display=swap"
                  rel="stylesheet" />
            <link href="https://fonts.googleapis.com/css?family=Satisfy&amp;display=swap"
                  rel="stylesheet" />
            <link href="https://fonts.googleapis.com/css?family=Sacramento&amp;display=swap"
                  rel="stylesheet" />
            <link href="https://fonts.googleapis.com/css?family=Vibes&amp;display=swap"
                  rel="stylesheet" />

            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <div>
                            <button type="button" class="close" data-dismiss="modal">
                                x
                            </button>
                        </div>
                    </div>
                    <div class="modal-body">
                        <div class="bdr upload-files">
                            <img v-if="sealImageBase64 !== ''"
                                 class="img-fluid img-responsive"
                                 :src="sealImageBase64" />
                            <img v-else
                                 src="/images/upload-icon.png"
                                 alt=""
                                 class="img-fluid img-responsive" />
                            <p>
                                <input ref="uploadSealFile"
                                       type="file"
                                       class="custom-file-input"
                                       accept="image/x-png,image/jpeg"
                                       @change="convertSealToBase64($event)" />
                            </p>
                        </div>
                        <div class="modal-footer">
                            <div align="right">
                                <button type="button"
                                        class="btn btn-success btn-block"
                                        @click.prevent="saveSealSignature('Seal')">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>

    h4 {
        font-size: 12px; 
        font-weight: 500;
    }

    .sigs {
        float: right;
        padding-right: 16%;
    }

    .form-fields {
        float: left;
        padding-left: 5%;
        width: 40%;
    }

    .update-button {
        clear: both;
        position: relative;
        padding-top: 5%;
        left: 45%;
        right: 45%
    }

    profile-main-col-md-12 {
        /*display: flex;
        justify-content: space-between;*/
    }

    profile-col-md-12, profile-row, profile, input, h3 {
        /*        margin-left: auto !important;
        margin-right: auto !important;
        width: 35% !important;*/
    }

    .update {
        background: #28a745;
        font-size: 20px;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 5px;
        padding-bottom: 5px;
        border-radius: 5px;
        cursor: pointer;
        font-weight: bold;
        color: #fff;
    }

        .update:hover {
            background: linear-gradient(rgba(0, 153, 0, 1), rgba(0, 153, 0, 1));
            cursor: pointer;
        }
</style>
<script>
    import ApiService from "@/core/services/api-service";
    import useVuelidate from '@vuelidate/core';
    import moment from "moment";
    import { required, email } from '@vuelidate/validators';
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    import { DatePicker, DateTimePicker } from "@progress/kendo-vue-dateinputs";
    import { Button } from "@progress/kendo-vue-buttons";
    import Swal from "sweetalert2/dist/sweetalert2.all.min.js";
    import { Drawer, DrawerContent, DrawerItem } from "@progress/kendo-vue-layout";
    import {
        Card,
        CardHeader,
        CardBody,
        CardTitle,
        CardSubtitle,
        CardActions,
    } from "@progress/kendo-vue-layout";

    export default {
        name: 'Profile',
        setup() {
            return { v$: useVuelidate() }
        },
        components: {
            'datepicker': DatePicker,
            'datetimepicker': DateTimePicker,
            'dropdownlist': DropDownList,
            "k-button": Button,
            Drawer,
            DrawerItem,
            DrawerContent,
            //'kendo-panelbar': PanelBar,
            card: Card,
            cardHeader: CardHeader,
            cardBody: CardBody,
            cardTitle: CardTitle,
            cardSubtitle: CardSubtitle,
            cardActions: CardActions,
        },
        data() {
            return {
                states: [],
                counties: [],
                fname: "",
                lname: "",
                email: "",
                phone: "",
                selectedState: "",
                selectedCounty: "",
                expiryDate: "",
                signature: "",
                initials: "",
                seal: "",
                role: "",
                showResponse: false,
                responseMsg: "",
                userId: null,
                fontColorItems: ['Blue', 'Red', 'Black'],
                fontColor: '',
                lineColor: "Black",
                Logo: url.endPoint + '/api/admin/logo',
                signatureType: "font", // Canvas / Font / Image
                signatureText: "Insert Signature",
                activeSignatureFontFamily: "",
                signatureCanvasContext: null,
                signatureImageBase64: "",
                sealImageBase64: "",
                initialsImageBase64: "",
                files: [],
                fonts: [
                    { fontFam: "Allura" },
                    { fontFam: "Dancing Script" },
                    { fontFam: "Sacramento" },
                    { fontFam: "Mansalva" },
                    { fontFam: "Satisfy" },
                    { fontFam: "Vibes" },
                ],
                signingTypes: {
                    Draw: true,
                    Type: true,
                    Upload: true,
                },
                typeErrorList: [],
                commExp: null,
                visibleSealDialog: false,
                pdfForms: [],
                signatureTypeId: 1,
                sealFile: "",
                signatureFile: "",
                initialsFile: "",
                isNotary: false
            }
        },
        async created() {
            var role = this.$store.state.Admin.loginInformation.role;
            if (role == "Notary") this.isNotary = true;
            //load settings
            this.getUserProfile();

            if (this.isNotary) {
                //load counties dropdown
                this.loadCounties();

                //load states dropdown
                this.loadStates();
            }
        },
        computed: {
            moment: () => moment,
            showNotaryItem: function () { return this.isNotary },
        },
        async mounted() {
            //default
            //this.reviewValue = 'Continuous Scroll';
            //this.fontColor = 'Blue';          

        },
        methods: {
            loadStates: function () {
                return ApiService.GeneratePostRequest("Country/states").then(
                    (response) => {
                        this.states = response.content;
                        //console.log("states settings ", response.content);
                    });
            },
            loadCounties: function () {
                return ApiService.GeneratePostRequest("Country/countiesUS").then(
                    (response) => {
                        this.counties = response.content;
                    });
            },
            changeState(event) {

                this.selectedState = event.value;
                //console.log(this.selectedState);
            },
            changeCounty(event) {

                this.selectedCounty = event.value;
            },
            updateProfile: function () {

                if (this.fname == null || this.fname == " " || this.fname=="") {
                    Swal.fire("Failed", "Please enter a first name", "error");
                    return false;
                }
                if (this.lname == null || this.lname == " " || this.lname == "") {
                    Swal.fire("Failed", "Please enter a last name", "error");
                    return false;
                }
                if (this.isNotary && (this.selectedState == null || this.selectedState == " " || this.selectedState == "")) {
                    Swal.fire("Failed", "Please select a state", "error");
                    return false;
                }
                if (this.isNotary && (this.selectedCounty == null || this.selectedCounty == " " || this.selectedCounty == "")) {
                    Swal.fire("Failed", "Please select a county", "error");
                    return false;
                }
                if (this.isNotary && (this.expiryDate == null || this.expiryDate == " " || this.expiryDate == "")) {
                    Swal.fire("Failed", "Please select a date for commission expiry date", "error");
                    return false;
                }
                else {
                    return ApiService.GeneratePostRequest(
                        "Admin/UpdateProfile",
                        {
                            FirstName: this.fname,
                            LastName: this.lname,
                            PhoneNumber: this.phone,
                            StateName: this.isNotary ? this.selectedState.stateName : "",
                            CountyName: this.isNotary ? this.selectedCounty.countyName: "",
                            ExpiryDate: this.expiryDate
                        }
                    ).then(async (response) => {
                        if (response.isSuccess) {
                            Swal.fire("Changes Saved!", "", "success");
                            this.updateMenuAccess();
                        } else {
                            Swal.fire("Failed", response.message, "error");
                        }
                    });
                }
            },
            convertSealToBase64(event) {
                const [file] = event.target.files;
                if (file) {
                    var reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        this.sealImageBase64 = reader.result;
                    };
                }
            },
            async saveSealSignature(signatureType) {
                let response = await ApiService.GeneratePostRequest("admin/seal/upload", {
                    "imageData": this.sealImageBase64.replace(/data:image\/[a-zA-Z]+;base64,/, ""),
                    "signatureType": signatureType
                });
                //console.log("Upload Response");
                //console.log(response);

                if (response["isSuccess"] && response["content"]) {
                    //console.log(response["content"]);
                    //update seal with base64
                    this.getBase64OfFile("Seal", response["content"]);
                    this.updateMenuAccess();
                }
                Swal.fire("Changes Saved!", "", "success");
                $(this.$refs.modalSealRef).modal("hide");
                this.eraseSeal();
            },
            showSignModal(val) {
                this.signatureTypeId = val;
                if (this.signatureTypeId === 1) {
                    this.signatureText = "Insert Signature";
                } else if (this.signatureTypeId === 2) {
                    this.signatureText = "Insert Initials";
                }
                this.erase();
                $(this.$refs.modalSignatureRef).modal("show");
            },
            showSealModal() {
                this.erase();
                $(this.$refs.modalSealRef).modal("show");
            },
            showFreeFormModal() {
                $(this.$refs.modalFreeFormRef).modal("show");
            },
            eraseSeal() {
                this.sealImageBase64 = "";
            },
            erase() {
                this.signatureImageBase64 = "";
                this.initialsImageBase64 = "";
                if (this.signatureCanvasContext)
                    this.signatureCanvasContext.clearRect(0, 0, 360, 160);

                let ctx = this.$refs.signatureCanvas.getContext("2d");
                ctx.canvas.height = 160;
                ctx.canvas.width = 360;
            },
            async saveDrawSignature() {
                let img = this.cropImageFromCanvas();// this.$refs.signatureCanvas.toDataURL();

                // replace input with image
                this.setImageInPdf(img);

                let results = await ApiService.GeneratePostRequest(
                    "admin/signature/upload",
                    {
                        subType: this.signatureTypeId,
                        imageData: img.split(",")[1],
                    }
                );

                if (results.isSuccess && results.content) {
                    //console.log(results.content);
                    //update signature with base64
                    if (this.signatureTypeId === 1) {
                        this.getBase64OfFile("Signature", results.content);
                    } else if (this.signatureTypeId === 2) {
                        this.getBase64OfFile("Initials", results.content);
                    }
                    this.updateMenuAccess();
                }
                Swal.fire("Changes Saved!", "", "success");
                $(this.$refs.modalSignatureRef).modal("hide");
                this.erase();
            },
            async saveFreeForm() {
                this.updateControlData(this.pdfForm.allFormFields[this.currentControlIndex]);
                $(this.$refs.modalFreeFormRef).modal("hide");
                this.freeFormText = "";
            },
            async saveFontSignature() {
                let errorList = [];

                if (this.activeSignatureFontFamily == "") {
                    Swal.fire({
                        title: 'Please select a font',
                        text: '',
                        icon: 'info',
                        confirmButtonText: 'Ok'
                    });
                    return;
                }

                if (this.signatureText.length < 2) {
                    errorList.push("Signature length cannot be less than 2 characters");
                }
                if (this.signatureText.length > 50) {
                    errorList.push("Signature length cannot be greater than 50 characters");
                }
                const regex = new RegExp(/^[A-zÀ-ÿ][-,A-z. ']+$/);
                //console.log(this.signatureText)
                //console.log(regex.test(this.signatureText))
                if (regex.test(this.signatureText) == false) {
                    errorList.push("Signature contains invalid characters");
                }
                if (errorList.length > 0) {
                    this.typeErrorList = [...errorList];
                    return;
                } else {
                    this.typeErrorList = []
                }

                this.signatureType = "canvas";

                var canvas = document.createElement("canvas");
                canvas.width = 250;
                canvas.height = 80;
                canvas.style.height = 200;
                var ctx = canvas.getContext("2d");

                ctx.font = "32px " + this.activeSignatureFontFamily;
                var text = this.signatureText;
                ctx.fillStyle = this.lineColor;
                ctx.textAlign = "center";
                ctx.fillText(text, 125, 40);

                let img = this.cropImageFromCanvas(ctx);//canvas.toDataURL();
                this.setImageInPdf(img);

                let results = await ApiService.GeneratePostRequest(
                    "admin/signature/upload",
                    {
                        subType: this.signatureTypeId,
                        imageData: img.split(",")[1],
                    }
                );
                if (results.isSuccess && results.content) {
                    const imgPath =
                        results.content + "?timestamp=" + new Date().getTime();
                    //update signature with base64
                    if (this.signatureTypeId === 1) {
                        this.getBase64OfFile("Signature", results.content);
                    } else if (this.signatureTypeId === 2) {
                        this.getBase64OfFile("Initials", results.content);
                    }
                    this.updateMenuAccess();
                    Swal.fire("Changes Saved!", "", "success");
                    $(this.$refs.modalSignatureRef).modal("hide");
                    
                }
                this.erase();
            },
            async saveImageSignature() {

                let img = "";

                if (this.signatureTypeId === 1) {
                    img = this.signatureImageBase64.split(",")[1];
                } else if (this.signatureTypeId === 2) {
                    img = this.initialsImageBase64.split(",")[1];
                }

                let results = await ApiService.GeneratePostRequest(
                    "admin/signature/upload",
                    {
                        subType: this.signatureTypeId,
                        imageData: img,
                    }
                );
                if (results.isSuccess && results.content) {
                    const imgPath = results.content + "?timestamp=" + new Date().getTime();
                    // replace input with image
                    this.setImageInPdf(this.apiUrl + imgPath);
                    //update signature with base64
                    if (this.signatureTypeId === 1) {
                        this.getBase64OfFile("Signature", results.content);
                    } else if (this.signatureTypeId === 2) {
                        this.getBase64OfFile("Initials", results.content);
                    }
                    this.updateMenuAccess();
                }
                Swal.fire("Changes Saved!", "", "success");
                $(this.$refs.modalSignatureRef).modal("hide");
                this.erase();

            },
            convertToBase64(event) {
                const [file] = event.target.files;
                if (file) {
                    var reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        if (this.signatureTypeId === 1) {
                            this.signatureImageBase64 = reader.result;
                        } else if (this.signatureTypeId === 2) {
                            this.initialsImageBase64 = reader.result;
                        }
                    };
                }
            },
            setImageInPdf: function (imgUrl) {
                let new_element = $("<img/>");
                //console.log(imgUrl);
            },
            cropImageFromCanvas(context) {
                let ctx = context || this.$refs.signatureCanvas.getContext("2d");
                var canvas = ctx.canvas,
                    w = canvas.width, h = canvas.height,
                    pix = { x: [], y: [] },
                    imageData = ctx.getImageData(0, 0, canvas.width, canvas.height),
                    x, y, index;

                for (y = 0; y < h; y++) {
                    for (x = 0; x < w; x++) {
                        index = (y * w + x) * 4;
                        if (imageData.data[index + 3] > 0) {
                            pix.x.push(x);
                            pix.y.push(y);
                        }
                    }
                }
                pix.x.sort(function (a, b) { return a - b });
                pix.y.sort(function (a, b) { return a - b });
                var n = pix.x.length - 1;

                w = 1 + pix.x[n] - pix.x[0];
                h = 1 + pix.y[n] - pix.y[0];
                var cut = ctx.getImageData(pix.x[0], pix.y[0], w, h);

                canvas.width = w;
                canvas.height = h;
                ctx.putImageData(cut, 0, 0);

                var image = canvas.toDataURL();  //open cropped image in a new window
                return image;
            },
            async getUserProfile() {
                await ApiService.GenerateGetRequest(
                    "Admin/GetUserProfile"
                ).then((response) => {
                    //console.log("profile settings ", response);

                    this.expiryDate = new Date(response.content.expiryDate);
                    this.fname = response.content.firstName;
                    this.lname = response.content.lastName;
                    this.email = response.content.emailAddress;
                    this.phone = response.content.phoneNumber;
                    this.selectedState = response.content.state;
                    this.selectedCounty = response.content.county;
                    this.signature = response.content.signature;
                    this.initials = response.content.initials;
                    this.seal = response.content.seal;
                    this.role = response.content.role;

                    //update signature with base64
                    this.getBase64OfFile("Signature", this.signature);
                    //update seal with base64
                    this.getBase64OfFile("Seal", this.seal);
                    //update initials with base64
                    this.getBase64OfFile("Initials", this.initials);

                    $('#Draw').addClass("active in")
                    this.$nextTick(() => {
                        // signattureconsole.log(signatureCanvas, "canvas");
                        let signatureCanvas = this.$refs.signatureCanvas;
                        //console.log(signatureCanvas, "canvas");
                        let currentMousePosition = {
                            x: 0,
                            y: 0,
                        };

                        //previous mouse position
                        let previousMousePosition = {
                            x: 0,
                            y: 0,
                        };

                        //make a 2D context
                        this.signatureCanvasContext = signatureCanvas.getContext("2d");

                        //set the line parameters
                        this.signatureCanvasContext.lineWidth = 3;
                        this.signatureCanvasContext.lineJoin = "round";
                        this.signatureCanvasContext.lineCap = "round";
                        this.signatureCanvasContext.strokeStyle = this.lineColor;

                        //capture mouse moves
                        this.$refs.signatureCanvas.addEventListener("mousemove", function (e) {
                            //store the old current mouse position and the previous mouse position
                            previousMousePosition.x = currentMousePosition.x;
                            previousMousePosition.y = currentMousePosition.y;

                            var rect = signatureCanvas.getBoundingClientRect();
                            //get the new mouse position
                            currentMousePosition.x = e.clientX - rect.left;
                            currentMousePosition.y = e.clientY - rect.top;
                        });

                        //mouse down
                        signatureCanvas.addEventListener("mousedown", function (e) {
                            //add an additional listener to draw
                            signatureCanvas.addEventListener("mousemove", onPaint);
                        });

                        //mouse up
                        signatureCanvas.addEventListener("mouseup", function () {
                            //remove the additional mouse move listener
                            signatureCanvas.removeEventListener("mousemove", onPaint);
                        });

                        //an additional mouse listener to paint part of a line
                        var onPaint = () => {
                            //draw the line
                            this.signatureCanvasContext.beginPath();
                            this.signatureCanvasContext.moveTo(
                                previousMousePosition.x,
                                previousMousePosition.y
                            );
                            this.signatureCanvasContext.lineTo(
                                currentMousePosition.x,
                                currentMousePosition.y
                            );
                            this.signatureCanvasContext.strokeStyle = this.lineColor;
                            this.signatureCanvasContext.closePath();
                            this.signatureCanvasContext.stroke();
                        };
                    });
                });
            },
            async getBase64OfFile(signatureType, path) {

                await ApiService.GeneratePostRequest("document/GetBase64?path=" + path)
                    .then((response) => {
                        //console.log("Base64 Response");
                        //console.log(response);

                        if (response["isSuccess"] && response["content"]) {
                            //console.log(response["content"]);
                            if (signatureType === "Signature") {
                                this.signatureFile = "data:image/png;base64," + response["content"];
                                //console.log("signature file: ", this.signatureFile);
                            } else if (signatureType === "Seal") {
                                this.sealFile = "data:image/png;base64," + response["content"];
                                //console.log("seal file: ", this.sealFile);
                            } else if (signatureType === "Initials") {
                                this.initialsFile = "data:image/png;base64," + response["content"];
                                //console.log("initials file: ", this.sealFile);
                            }
                        }
                    });
            },
            async updateMenuAccess() {
                let res = await ApiService.GenerateGetRequest("Admin/hasMenuPermission");
                console.log("Get Request Res", res);
                if (res.isSuccess)
                    this.$store.commit("Admin/updateMenuAccess", { hasAccessToMenu: res.content });
            }
        },
        validations() {
            return {
                fname: { required },
                lname: { required },
                phone: { required },
                selectedState: { required },
                selectedCounty: { required }
            }
        },

    }
</script>
