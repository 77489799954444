<template>
  <div style="width:100%; text-align:right">
    <k-button
      title="Download Selected"
      :icon="'download'"
      @click="downloadSelected"
      class="k-button k-primary"
      v-if="!hiddenActions.includes('downloadAll')"
    >
      Download Selected
      <span class="select-counter">({{ this.selectedTotal }})</span>
    </k-button>
  </div>
  <div style="width:100%">
    <Grid
      ref="uploadGrid"
      :data-items="gridDocuments"
      :columns="columns"
      :total="total"
      :take="take"
      :skip="skip"
      :pageable="true"
      @pagechange="pageChangeHandler"
      :selected-field="selectedField"
      @selectionchange="onSelectionChange"
      @headerselectionchange="onHeaderSelectionChange"
    >
      <template v-slot:cellActions="{ props }">
        <td>
          <command-cell
            :data-item="props.dataItem"
            @download="download"
            @view="view"
            @print="print"
            @delete="deleteDoc"
            :hiddenActions="hiddenActions"
          />
        </td>
      </template>
      <grid-norecords>
        No documents found
      </grid-norecords>
    </Grid>
  </div>
</template>
<script>
import { Grid, GridToolbar, GridNoRecords } from "@progress/kendo-vue-grid";
import ApiService from "../../core/services/api-service";
import GridDataHelper from "../../core/services/grid-data-helper";
import moment from "moment";
import CommandCell from "./CommandCell";
import ViewCommandCell from "./ViewCommandCell";
import UploadDocuments from "./UploadDocuments";
import { loadFileUrl } from "@/core/services/api-service";
import { PanelBar } from "@progress/kendo-layout-vue-wrapper";
import { Button } from "@progress/kendo-vue-buttons";
import Swal from "sweetalert2";

export default {
  name: "PrintAndUpload",
  props: ["docs", "visibleFields", "title", "hiddenActions"],
  components: {
    Grid: Grid,
    "grid-toolbar": GridToolbar,
    "grid-norecords": GridNoRecords,
    "command-cell": CommandCell,
    "view-command-cell": ViewCommandCell,
    "upload-documents": UploadDocuments,
    "kendo-panelbar": PanelBar,
    "k-button": Button,
  },
  watch: {
    docs: async function() {
      this.documents = this.docs;
    },
  },
  computed: {
    gridDocuments: {
      get: function() {
        console.log("prop documents", this.documents);
        return this.documents.slice(this.skip, this.take + this.skip);
      },
    },
    total() {
      return this.documents ? this.documents.length : 0;
    },
    moment: () => moment,
    areAllSelected() {
      return this.documents.findIndex((item) => item.selected === false) === -1;
    },
    selectedTotal() {
      return this.documents.filter((x) => x.selected == true).length;
    },
    columns() {
      return [
        {
          field: "selected",
          width: "70px",
          headerSelectionValue: this.areAllSelected,
          hidden: !this.visibleFields.includes("checkbox"),

        },
        ...this.staticColumns,
      ];
    },
  },
  data: function() {
    return {
      skip: 0,
      take: 10,
      staticColumns: [
        {
          field: "documentId",
          editable: false,
          hidden: true,
        },
        {
          field: "description",
          title: "Name",
          hidden: !this.visibleFields.includes("description"),
        },
        {
          field: "dateAdded",
          title: "Date Uploaded",
          hidden: !this.visibleFields.includes("dateAdded"),
          cell: this.dateFormat,
        },
        {
          field: "participantStatus",
          title: "Status",
          hidden: !this.visibleFields.includes("participantStatus"),
          cell: this.statusFunction,
        },
        { cell: "cellActions", width: 200 },
      ],
      documents: this.docs,
      uploadGridKey: 0,
      uploadDocKey: 0,
      host: loadFileUrl,
      selectedField: "selected",
      fileUrl: "",
    };
  },
  methods: {
    pageChangeHandler: function(event) {
      this.skip = event.page.skip;
      this.take = event.page.take;
    },
    statusFunction: function(h, tdElement, props, listeners) {
      return h(
        "td",
        {
          on: {
            click: function(e) {
              listeners.custom(e);
            },
          },
        },
        [GridDataHelper.normalizeStatus(props.dataItem.participantStatus)]
      );
    },
    typeFormat: function(h, tdElement, props, listeners) {
      return h(
        "td",
        {
          on: {
            click: function(e) {
              listeners.custom(e);
            },
          },
        },
        [GridDataHelper.normalizeStatus(props.dataItem.documentType)]
      );
    },
    dateFormat: function(h, tdElement, props, listeners) {
      return h(
        "td",
        {
          on: {
            click: function(e) {
              listeners.custom(e);
            },
          },
        },
        [
          props.dataItem.dateAdded
            ? moment(props.dataItem.dateAdded).format("YYYY-MM-DD hh:mm A")
            : " - ",
        ]
      );
    },
    formatDocType: function(h, tdElement, props, listeners) {
      return h(
        "td",
        {
          on: {
            click: function(e) {
              listeners.custom(e);
            },
          },
        },
        [props.dataItem.isEnote ? "eNote" : "PDF"]
      );
    },
    download: function(item) {
        fetch(this.host + item.renderType)
        .then((res) => {
          return res.blob();
        })
        .then((data) => {
          var a = document.createElement("a");
          a.href = window.URL.createObjectURL(data);
          a.download = item.documentName;
          a.click();
        });
    },
    view: function(item) {
      this.$emit("viewDocument", item.documentId);
    },
    onHeaderSelectionChange(event) {
      let checked = event.event.target.checked;
      this.documents = this.documents.map((item) => {
        return { ...item, selected: checked };
      });
    },
    onSelectionChange(event) {
      event.dataItem[this.selectedField] = !event.dataItem[this.selectedField];
    },
    onRowClick(event) {
      event.dataItem[this.selectedField] = !event.dataItem[this.selectedField];
    },
    downloadSelected: async function() {
      return ApiService.GeneratePostBlobRequest(
        `document/download/selected`,
        this.documents
          .filter((x) => x.selected == true)
          .map((x) => {
              return { docUrl: x.renderType, description: x.documentName };
          })
      )
        .then((blob) => {
          var a = document.createElement("a");
          a.href = window.URL.createObjectURL(blob);
          a.download = `${this.title}_${moment(Date.now()).format(
            "YYYY-MM-DD"
          )}.zip`;
          a.click();
        })
        .catch((e) => {
          console.error(e);
        });
    },
    print(item) {
        fetch(this.host + item.renderType)
        .then((res) => {
          return res.blob();
        })
        .then((data) => {
          var a = document.createElement("a");
          var url = window.URL.createObjectURL(data);
          var iframe = document.createElement("iframe");
          // Hide the IFrame.
          iframe.style.visibility = "hidden";
          // Define the source.
          iframe.src = url;
          // Add the IFrame to the web page.
          document.body.appendChild(iframe);
          iframe.contentWindow.focus();
          iframe.contentWindow.print();
        });
    },
    async deleteDoc(documentId) {
      let formData = new FormData();
      formData.append("DocumentId", documentId); // 1044);
      return ApiService.GeneratePostRequest(
        "package/document/delete",
        formData,
        { "Content-Type": "multipart/form-data" }
      ).then((response) => {
        if (response["isSuccess"]) {
          Swal.fire("Success", "Document deleted sucessfully", "success");
          this.$emit("refetchDocuments", documentId);
        } else {
          Swal.fire(
            "Operation failed",
            "Document could not be deleted",
            "error"
          );
        }
      });
    },
  },
};
</script>
<style scoped>
.select-counter {
  color: white;
  font-weight: 900;
}
</style>
