<template>
    <!--<router-link-bread-crumbs :navItems="navItems" />-->



    <card class="card" style=" overflow: initial;" :style="docid ? {margin: '10px 0', 'border-top-left-radius' : 0, 'border-top-right-radius' : 0} : {}">
        <cardHeader>
            <div class="row">
                <div class="col-md-12">
                    <div class="title-block" style="display: flex; justify-content: center; align-items: center; text-align: center; padding: 0; margin-bottom: 0; ">
                        <div style="width: 100%;display: inline-block;">
                            <h3 style="margin-bottom: 0;">{{file.description}}</h3>
                            <div style="width: 20%; text-align: center; display: inline-block;">
                                <h3>
                                    <button style="float:left;"
                                            class="navigation-arrow"
                                            @click.prevent="previousButtonClicked">
                                        &larr;
                                    </button>
                                    <strong>Page {{currentPage0Based + 1}}</strong>

                                    <button class="navigation-arrow"
                                            style="float:right;"
                                            @click.prevent="nextButtonClicked">
                                        &rarr;
                                    </button>
                                </h3>
                            </div>

                        </div>

                        <k-button :primary="true" @click="applyChanges" style="position: relative;left: -10px;">
                            Apply
                        </k-button>
                    </div>

                </div>
            </div>
        </cardHeader>
        <cardBody>
            <section class="row">
                <div class="col-md-10" style="margin-top:20px">
                    <div style="width:100%; " id="pdfContainer"></div>
                </div>
                <div class="col-md-2" style="position: sticky; top: 100px;margin-top:30px;">

                    <document-tag-type-list :items="systemTags"
                                            @itemClicked="handleTaglistItemClicked" />
                </div>
            </section>
        </cardBody>
    </card>


    <teleport v-if="teleportLocation !== 'body'" :to="teleportLocation">
        <!-- currentHighlightedTag.width + "px" -->
        <div id="participantSelector" style="position: absolute; display: flex; z-index: 1;" :style="{ width:   '300px', top: currentHighlightedTag.top + 'px', left: currentHighlightedTag.left + 'px' }">
            <div style="width: 74%; display: inline-block;">
                <dropdownlist id="participantDropdown"
                              :label="'Participant'"
                              :data-items='participants'
                              :text-field="'value'"
                              :value-field="'id'"
                              :name="'participant'"
                              :data-item-key="'id'"
                              v-model="selectedParticipant"
                              :required="true"
                              @change="changeParticipant">
                </dropdownlist>
            </div>
            <div style=" width: 26%; display: flex; justify-content: space-between; align-items: center;">
                <k-button :icon="'delete'" primary="true" @click.prevent="deleteTag"></k-button>
                <k-button v-if="currentHighlightedTag.smartTag.tagType !== 'CheckBox'" :icon="'gear'" primary="true" @click.prevent="showTagSettings"></k-button>
            </div>

        </div>
    </teleport>



    <div ref="settingsModal" class="modal" style="display: none;">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <div>
                        <h3>{{currentTagManaging}} Settings</h3>
                        <button type="button" class="close" data-dismiss="modal">
                            x
                        </button>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="row" v-if="currentTagManaging === 'Date' || currentTagManaging === 'DateOfBirth'">
                        <div class="col-md-9">
                            <label class="form-label">Preferred Date Format</label>
                            <input type="text" class="form-control" v-model="dateFormatField" placeholder="Select Option" />
                            <p>Example: {{exampleDate}}</p>
                            <table v-if="showDateHelp" class="table table-striped table-bordered">
                                <tbody>
                                    <tr>
                                        <th></th>
                                        <th>Token</th>
                                        <th>Output</th>
                                    </tr>
                                    <tr>
                                        <td><b>Month</b></td>
                                        <td>M</td>
                                        <td>1 2 ... 11 12</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Mo</td>
                                        <td>1st 2nd ... 11th 12th</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>MM</td>
                                        <td>01 02 ... 11 12</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>MMM</td>
                                        <td>Jan Feb ... Nov Dec</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>MMMM</td>
                                        <td>January February ... November December</td>
                                    </tr>
                                    <tr>
                                        <td><b>Quarter</b></td>
                                        <td>Q</td>
                                        <td>1 2 3 4</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Qo</td>
                                        <td>1st 2nd 3rd 4th</td>
                                    </tr>
                                    <tr>
                                        <td><b>Day of Month</b></td>
                                        <td>D</td>
                                        <td>1 2 ... 30 31</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Do</td>
                                        <td>1st 2nd ... 30th 31st</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>DD</td>
                                        <td>01 02 ... 30 31</td>
                                    </tr>
                                    <tr>
                                        <td><b>Day of Year</b></td>
                                        <td>DDD</td>
                                        <td>1 2 ... 364 365</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>DDDo</td>
                                        <td>1st 2nd ... 364th 365th</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>DDDD</td>
                                        <td>001 002 ... 364 365</td>
                                    </tr>
                                    <tr>
                                        <td><b>Day of Week</b></td>
                                        <td>d</td>
                                        <td>0 1 ... 5 6</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>do</td>
                                        <td>0th 1st ... 5th 6th</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>dd</td>
                                        <td>Su Mo ... Fr Sa</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>ddd</td>
                                        <td>Sun Mon ... Fri Sat</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>dddd</td>
                                        <td>Sunday Monday ... Friday Saturday</td>
                                    </tr>
                                    <tr>
                                        <td><b>Day of Week (Locale)</b></td>
                                        <td>e</td>
                                        <td>0 1 ... 5 6</td>
                                    </tr>
                                    <tr>
                                        <td><b>Day of Week (ISO)</b></td>
                                        <td>E</td>
                                        <td>1 2 ... 6 7</td>
                                    </tr>
                                    <tr>
                                        <td><b>Week of Year</b></td>
                                        <td>w</td>
                                        <td>1 2 ... 52 53</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>wo</td>
                                        <td>1st 2nd ... 52nd 53rd</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>ww</td>
                                        <td>01 02 ... 52 53</td>
                                    </tr>
                                    <tr>
                                        <td><b>Week of Year (ISO)</b></td>
                                        <td>W</td>
                                        <td>1 2 ... 52 53</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Wo</td>
                                        <td>1st 2nd ... 52nd 53rd</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>WW</td>
                                        <td>01 02 ... 52 53</td>
                                    </tr>
                                    <tr>
                                        <td><b>Year</b></td>
                                        <td>YY</td>
                                        <td>70 71 ... 29 30</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>YYYY</td>
                                        <td>1970 1971 ... 2029 2030</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>YYYYYY</td>
                                        <td>
                                            -001970 -001971 ... +001907 +001971
                                            <br>
                                            <b>Note:</b> <a href="https://tc39.es/ecma262/#sec-expanded-years">Expanded Years</a> (Covering the full time value range of approximately 273,790 years forward or backward from 01 January, 1970)
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Y</td>
                                        <td>
                                            1970 1971 ... 9999 +10000 +10001
                                            <br>
                                            <b>Note:</b> This complies with the ISO 8601 standard for dates past the year 9999
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>Era Year</b></td>
                                        <td>y</td>
                                        <td>1 2 ... 2020 ... </td>
                                    </tr>
                                    <tr>
                                        <td><b>Era</b></td>
                                        <td>N, NN, NNN</td>
                                        <td>
                                            BC AD<br>
                                            <b>Note:</b> Abbr era name
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>NNNN</td>
                                        <td>
                                            Before Christ, Anno Domini <br>
                                            <b>Note:</b> Full era name
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>NNNNN</td>
                                        <td>
                                            BC AD<br>
                                            <b>Note:</b> Narrow era name
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>Week Year</b></td>
                                        <td>gg</td>
                                        <td>70 71 ... 29 30</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>gggg</td>
                                        <td>1970 1971 ... 2029 2030</td>
                                    </tr>
                                    <tr>
                                        <td><b>Week Year (ISO)</b></td>
                                        <td>GG</td>
                                        <td>70 71 ... 29 30</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>GGGG</td>
                                        <td>1970 1971 ... 2029 2030</td>
                                    </tr>
                                    <tr>
                                        <td><b>AM/PM</b></td>
                                        <td>A</td>
                                        <td>AM PM</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>a</td>
                                        <td>am pm</td>
                                    </tr>
                                    <tr>
                                        <td><b>Hour</b></td>
                                        <td>H</td>
                                        <td>0 1 ... 22 23</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>HH</td>
                                        <td>00 01 ... 22 23</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>h</td>
                                        <td>1 2 ... 11 12</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>hh</td>
                                        <td>01 02 ... 11 12</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>k</td>
                                        <td>1 2 ... 23 24</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>kk</td>
                                        <td>01 02 ... 23 24</td>
                                    </tr>
                                    <tr>
                                        <td><b>Minute</b></td>
                                        <td>m</td>
                                        <td>0 1 ... 58 59</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>mm</td>
                                        <td>00 01 ... 58 59</td>
                                    </tr>
                                    <tr>
                                        <td><b>Second</b></td>
                                        <td>s</td>
                                        <td>0 1 ... 58 59</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>ss</td>
                                        <td>00 01 ... 58 59</td>
                                    </tr>
                                    <tr>
                                        <td><b>Fractional Second</b></td>
                                        <td>S</td>
                                        <td>0 1 ... 8 9</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>SS</td>
                                        <td>00 01 ... 98 99</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>SSS</td>
                                        <td>000 001 ... 998 999</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>SSSS ... SSSSSSSSS</td>
                                        <td>000[0..] 001[0..] ... 998[0..] 999[0..]</td>
                                    </tr>
                                    <tr>
                                        <td><b>Time Zone</b></td>
                                        <td>z or zz</td>
                                        <td>
                                            EST CST ... MST PST
                                            <br>
                                            <b>Note:</b> as of <b>1.6.0</b>, the z/zz format tokens have been deprecated from plain moment objects. <a href="https://github.com/moment/moment/issues/162">Read more about it here.</a>
                                            However, they *do* work if you are using a specific time zone with the moment-timezone addon.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>Z</td>
                                        <td>-07:00 -06:00 ... +06:00 +07:00</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>ZZ</td>
                                        <td>
                                            -0700 -0600 ... +0600 +0700
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>Unix Timestamp</b></td>
                                        <td>X</td>
                                        <td>1360013296</td>
                                    </tr>
                                    <tr>
                                        <td><b>Unix Millisecond Timestamp</b></td>
                                        <td>x</td>
                                        <td>1360013296123</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="col-md-3">
                            <label class="form-label" style="display:block;">&nbsp;</label>
                            <k-button :icon="'help'" primary="true" @click.prevent="showDateHelp = !showDateHelp"></k-button>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-md-12">
                            <label class="form-label">
                                <span style="margin-right">Is Optional</span> <k-switch @change="isOptionalSwitchOnChange" v-model="isOptional" />
                            </label>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <div align="right" class="pull-right">
                            <button type="button"
                                    class="btn btn-success"
                                    @click.prevent="saveTagSettings">
                                Accept
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div ref="optionGroupModal" class="modal" style="display: none;">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <div>
                        <h3>Option Group</h3>
                        <button type="button" class="close" data-dismiss="modal">
                            x
                        </button>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-9">
                            <label class="form-label">Group name</label>
                            <input type="text"
                                   class="form-control"
                                   v-model="groupName"
                                   placeholder="Select Option" />
                        </div>

                        <div class="col-md-12">
                            <hr />
                            <label class="form-label">Group Option Items</label>
                            <button type="button"
                                    class="btn btn-warning"
                                    style="float:right;"
                                    @click.prevent="addOptionGroupItem">
                                Add Option
                            </button>
                        </div>

                        <template v-for="(item, i) in optionGroupItems">
                            <div class="col-md-9" style="margin:10px 0">
                                <input type="text"
                                       class="form-control"
                                       :value="item"
                                       @keyup="optionGroupItemValueChanged($event, i)"
                                       placeholder="Option Value: e.g Male" />
                            </div>
                            <div class="col-md-3" style="margin:10px 0">
                                <button type="button"
                                        v-if="i > 1"
                                        class="btn btn-danger"
                                        @click.prevent="removeGroupItem(i)">
                                    Remove
                                </button>
                            </div>
                        </template>
                    </div>

                    <div class="modal-footer">
                        <div align="right" class="pull-right">
                            <button type="button"
                                    class="btn btn-success"
                                    @click.prevent="createOptionGroupItems">
                                Accept
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ApiService from "@/core/services/api-service";
    import { loadFileUrl } from "@/core/services/api-service";
    import DocumentTagTypeList from "@/components/DocumentTagTypeList.vue";
    import { Button } from "@progress/kendo-vue-buttons";
    import { MaskedTextBox } from "@progress/kendo-inputs-vue-wrapper";
    import { DatePicker } from "@progress/kendo-dateinputs-vue-wrapper";
    import RouterLinkBreadCrumbsVue from "../../components/RouterLinkBreadCrumbs.vue";
    import Swal from "sweetalert2";
    import { useRoute } from 'vue-router';
    import { DropDownList } from '@progress/kendo-vue-dropdowns';

    import { PDFDocument, rgb, PDFForm, degrees } from 'pdf-lib';
    import PDFFormRenderer from "@/core/services/pdf-form";
    import moment from "moment";
    import { Switch } from "@progress/kendo-inputs-vue-wrapper";
    function generateUUID() {
        // Public Domain/MIT
        var d = new Date().getTime(); //Timestamp
        var d2 =
            (typeof performance !== "undefined" &&
                performance.now &&
                performance.now() * 1000) ||
            0; //Time in microseconds since page-load or 0 if unsupported
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
            var r = Math.random() * 16; //random number between 0 and 16
            if (d > 0) {
                //Use timestamp until depleted
                r = (d + r) % 16 | 0;
                d = Math.floor(d / 16);
            } else {
                //Use microseconds since page-load if supported
                r = (d2 + r) % 16 | 0;
                d2 = Math.floor(d2 / 16);
            }
            return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
        });
    }
    export default {
        name: "DocumentTagging",
        props: ["docid"],
        components: {
            DocumentTagTypeList,
            "k-button": Button,
            "kendo-maskedtextbox": MaskedTextBox,
            "kendo-datepicker": DatePicker,
            'dropdownlist': DropDownList,
            RouterLinkBreadCrumbs: RouterLinkBreadCrumbsVue,
            "k-switch": Switch,

        },
        async created() {
            $("#pdfContainer").html("");
            this.$store.commit("App/updateLoaderOverride", {
                showLoaderOverride: false,
            });
            const route = useRoute();
            this.docToTag = this.docid;
            if (!this.docid) {
                let docToTag = route.params.documentId;
                let signerId = route.params.signerId;
                this.docToTag = parseInt(docToTag);
                this.signerId = parseInt(signerId);
            }


            // use above Doc ID to fetch

            console.log("this.docToTag", this.docToTag);
            let response = await ApiService.GenerateGetRequest(
                `package/document/${this.docToTag}/tags`
            );
            this.navItems = [
                {
                    id: 1,
                    label: "Home",
                    url: "/admin",
                    icon: "home",
                },
                {
                    id: 2,
                    label: "Your Package",
                    icon: "folder",
                    //url: "/admin/detail/" + response.packageID,
                },
                {
                    id: 3,
                    label: "Your Document",
                    icon: "file",
                    //url: "admin/document/" + response.DocumentID,
                },
                {
                    id: 4,
                    label: "Tagging",
                    icon: "pin",
                },
            ];

            if (response && response["isSuccess"]) {
                this.file = response["content"]; // should only be one document
                console.log("file prince", this.file)

                this.file.noSpaceName = this.file.documentName.replaceAll(" ", "").replaceAll(/[^\w\s]/gi, '_');
                if (this.file.isEnote) {
                    //only allow managing of tags for pdf
                    this.$router.push("/admin");
                }
                console.log("loaded file", this.file);
                this.file["smartTags"] = this.file["smartTags"].map((item) => {
                    return { ...item, controlIdAlias: item.controlId.replaceAll(".`0", "") }
                });


                await ApiService.GenerateGetRequest(
                    `package/document/${this.docToTag}/participants`
                ).then((response) => {
                    if (response["isSuccess"]) {
                        this.participants = response["content"].map((item) => {
                            return {
                                id: item.participantMappingId,
                                value: `${item.firstName} ${item.lastName}`
                            }
                        });
                        console.log("this.participants", response["content"], this.participants)
                    }
                    //this.packageParticipants = response.content;
                });

                if (this.participants.length === 0)
                    Swal.fire({
                        title: "Participants Error",
                        text: `There was an error loading participants.`,
                        icon: "error",
                        confirmButtonText: "Ok",
                    });

                // get tags for doc
                // response = await ApiService.GenerateGetRequest(`document/${docToTag}/tags?notarizeId=${this.file.notarizeId}`);
                // console.log("response doc tags", response);
                // if (!response["isSuccess"])
                //     alert("Could not find any existing smart tags for this document,");
                // else
                //     this.file.smartTags = response["content"];

                //this.$nextTick(() => this.setUpMoveEventListeners());
            }

            // fetchs smart tag types
            response = await ApiService.GenerateGetRequest("document/tag/types");
            if (response && response["isSuccess"]) {
                this.systemTags = response["content"].map((item) => {
                    return { text: item.name, type: item.type, id: item.id };
                });
            }
            let params = this.$route.params;
            this.signer.fullName = this.file.signerName;
            this.signer.signerId = this.file.signerId;


            $("#participantSelector > span").css("margin-top", "-7px");

            this.initializePDF();

        },

        data() {
            return {
                navItems: [],
                // MANAGE OPTION GROUP MODAL UI
                groupName: "",
                optionGroupItems: ["", ""],
                // END MANAGE OPTION GROUP MODAL UI
                docToTag: 0,
                signerId: 0,
                adminCreatedTags: [],
                signer: {
                    fullName: "",
                    signerId: 0,
                },
                file: {
                    createdOn: "",
                    description: "",
                    docImgPaths: [],
                    docImgURL: "",
                    docURL: "",
                    documentStatus: "",
                    documentStatusID: 0,
                    documentTitle: "",
                    html: "",
                    id: 0,
                    identityDocUrl1: "",
                    identityToken: "",
                    isReview: "",
                    isEnote: "",
                    noOfPages: "",
                    notarizeID: "",
                    notarizeStatus: "",
                    notarizeStatusID: "",
                    notarizeType: "",
                    signingDate: "",
                    smartTags: [],
                    thumbnailPaths: [],
                },
                activePageIndex: 0,
                apiUrl: loadFileUrl,
                tagsPerPage: [],
                systemTags: [],
                activeSmartTagIndex: 0,

                numOfLoadedThumbnailPreviews: 0,
                dynamicTagId: 0,

                // variables for tags
                showDateHelp: false,
                dateFormatField: "",

                // new field options
                isOptional: false,
                teleportLocation: "body",
                currentHighlightedTag: { width: 0 },
                pdfDoc: null,
                currentPage0Based: 0,
                pageScale: 2,
                selectedParticipant: null,
                participants: [
                ]
            };
        },
        computed: {
            assetsPath: function (path) {
                return path;
            },
            currentTagManaging() {
                return this.currentHighlightedTag.smartTag ? this.currentHighlightedTag.smartTag.tagType : "";
            },
            exampleDate() {
                return moment().format(this.dateFormatField);
            }
        },
        methods: {
            async reloadPDFAndTags() {
                let response = await ApiService.GenerateGetRequest(
                    `package/document/${this.docToTag}/tags`
                );
                if (response && response["isSuccess"]) {
                    this.file = response["content"]; // should only be one document
                    this.file.noSpaceName = this.file.documentName.replaceAll(" ", "").replaceAll(/[^\w\s]/gi, '_');
                    if (this.file.isEnote) {
                        //only allow managing of tags for pdf
                        this.$router.push("/admin");
                    }
                    this.file["smartTags"] = this.file["smartTags"].map((item) => {
                        return { ...item, controlIdAlias: item.controlId.replaceAll(".`0", "") }
                    });


                    const url = ApiService.EnvironmentURL() + "document/load-doc?document=" + this.file.documentID + "&isSigning=true&type=" +this.file.renderType;//'https://pdf-lib.js.org/assets/with_update_sections.pdf'

                    const existingPdfBytes = await fetch(url, {
                        headers: {
                            'Authorization': `Bearer ${this.$store.getters["App/token"]}`,
                        }
                    }).then(res => res.arrayBuffer());
                    this.pdfDoc = await PDFDocument.load(existingPdfBytes);

                    this.renderPage(this.currentPage0Based);
                }
            },
            async initializePDF() {
                this.$store.commit("App/updateLoaderOverride", {
                    showLoaderOverride: true,
                });

                const url = ApiService.EnvironmentURL() + "document/load-doc?document=" + this.file.documentID + "&isSigning=true&type=" + this.file.renderType;//'https://pdf-lib.js.org/assets/with_update_sections.pdf'
                const existingPdfBytes = await fetch(url, {
                    headers: {
                        'Authorization': `Bearer ${this.$store.getters["App/token"]}`,
                    }
                }).then(res => res.arrayBuffer());
                this.pdfDoc = await PDFDocument.load(existingPdfBytes);

                // testing code
                //const page = this.pdfDoc.getPage(0);
                //const form = this.pdfDoc.getForm();
                //const textField = form.createTextField("lulu");

                // IMPORTANT. This library considers the bottom of left of the file 0 0 x and y respectively. our units start from top left.
                // hence the maths
                //textField.addToPage(page, { x: 0, y: page.getHeight() - 20, width: 140, height: 20, borderColor: rgb(1, 1, 1) });
                //console.log("Form FIelds", form.getFields());

                this.renderPage(this.currentPage0Based);

            },
            async renderPage(pageNum0Based) {
                this.$store.commit("App/updateLoaderOverride", {
                    showLoaderOverride: true,
                });
                this.teleportLocation = "body";
                this.currentHighlightedTag = { width: 0 };

                this.currentPage0Based = pageNum0Based;
                const page = this.pdfDoc.getPage(pageNum0Based);

                const pdfDataUri = await this.pdfDoc.saveAsBase64({ dataUri: true });

                let pdfRenderer = new PDFFormRenderer();
                let fileName = this.file.noSpaceName;
                let w = page.getWidth() * this.pageScale;
                let h = page.getHeight() * this.pageScale;
                $("#pdfContainer").html("");

                let self = this;
                pdfRenderer.loadPdfPage(pageNum0Based + 1, "#pdfContainer", fileName, pdfDataUri, w, h, true, () => {
                    this.$store.commit("App/updateLoaderOverride", {
                        showLoaderOverride: false,
                    });
                    // load all existing tags which do not have a field embed yet. i.e user adds a tag but navigates to another page and back
                    this.file.smartTags.forEach((tag) => {
                        if (tag["controlId"].indexOf("dp_le_") >= 0 && tag["belongsToPage"] === (this.currentPage0Based + 1)) {

                            $(`<div>${tag.displayText}</div>`).attr({
                                id: tag.id,
                                name: 'bar',
                                'data-index': tag.id,
                                style: 'font-size: 18px;position:absolute;top:' + tag.controlTop + '; left: ' + tag.controlLeft + ';width: ' + tag.controlWidth + '; height: ' + tag.controlHeight + ';background:yellow;    cursor: grab;z-index: 1;'
                            }).appendTo(`div[data-page="${this.currentPage0Based + 1}"][data-pagename="${this.file.noSpaceName}"] > form`);

                            this.setUpMoveEventListeners($(`#${tag.id}`));
                            if (tag.tagType === "CheckBox")
                                $(`#${tag.id}`).css("width", "40px").css("font-size", "10px").css("overflow", "hidden").css("background-color", "rgba(255, 255, 0, 0.4)");
                        }
                    });





                    // forms doesn't have width & height. html dynamically generated doesn't apply css from local scope
                    $("div[data-pagename] form").css("width", "100%").css("height", "100%");


                    // update file smart tags with coordinates extracted from PDF
                    console.log(pdfRenderer.allFormFields);
                    pdfRenderer.allFormFields.forEach((field) => {
                        self.file["smartTags"].forEach((item, index) => {
                            console.log(item.controlIdAlias, " === ", field.noSpaceIdAlias);
                            if ((item.controlId === field.id) || (item.controlIdAlias === field.noSpaceIdAlias)) {
                                self.file["smartTags"][index]["controlTop"] = field["y"];
                                self.file["smartTags"][index]["controlLeft"] = field["x"];
                                self.file["smartTags"][index]["controlWidth"] = field["width"];
                                self.file["smartTags"][index]["controlHeight"] = field["height"];
                            }
                        });
                    });


                    // on the front end the locations tagged are usually "inputs"
                    // we just want to replace those inputs with DIVs on admin
                    var replacementTag = 'div';
                    $("div[data-pagename] form input").each(function () {
                        if ($(this).attr("id") === "participantSelector") return;
                        var outer = this.outerHTML;


                        // Replace opening tag
                        var regex = new RegExp('<' + this.tagName, 'i');
                        var newTag = outer.replace(regex, '<' + replacementTag);

                        // Replace closing tag
                        regex = new RegExp('</' + this.tagName, 'i');
                        newTag = newTag.replace(regex, '</' + replacementTag);

                        //TODO search for tag type in smartTags list
                        let st = self.file["smartTags"].find((tag) => tag["id"] === parseInt($(this).attr("data-index")));
                        $(this).text(st["tagType"]);
                        $(this).replaceWith(newTag);


                        let signer = self.participants.find((p) => p["id"] === st["signerId"]);
                        $(`#${$(newTag).attr("id")}`).text(st["tagType"] + " - " + signer["value"]).css("font-size", "18px");
                        $(`#${$(newTag).attr("id")}`).parent().css("background", "yellow").css("cursor", "grab").css("font-size", "18px").css("z-index", "1");

                        if (st.tagType === "CheckBox") {
                            $(`#${$(newTag).attr("id")}`).parent().css("width", "40px").css("font-size", "10px").css("overflow", "hidden").css('background-color', 'rgba(255, 255, 0, 0.4)');
                            $(`#${$(newTag).attr("id")}`).css("font-size", "10px");
                        }
                    });




                    // make existing tags moveable
                    $("div[data-pagename] form > div").each((idx, item) => {
                        if ($(item).attr("id") === "participantSelector") return;
                        this.setUpMoveEventListeners($(item), true, $(item).find("[data-tagtype]").attr("data-tagtype"));
                    });




                }, this.file["smartTags"], true);
            },
            handleTaglistItemClicked(item) {
                let prefix = item === "OptionGroup" ? "Choose" : "Insert";
                let dateParams = JSON.stringify({ "format": "MM/DD/YYYY" });

                let params = '';
                if (item === "Date" || item === "DateOfBirth")
                    params = dateParams;

                let tagOptions = {
                    belongsToPage: this.currentPage0Based + 1,
                    controlHeight: "40px",
                    controlLeft: "10px",
                    controlTop: "10px",
                    controlWidth: item !== "CheckBox" ? "300px" : "40px",
                    displayText: prefix + " " + item,
                    originalDisplayText: item,
                    pageNo: this.currentPage0Based + 1,
                    tagType: item,
                    isOptional: item !== "Signature" && item !== "Initials",
                    signerId: "",
                    params: params,
                    notarizeId: this.file.notarizeId,
                    documentId: this.file.documentID,
                    controlId: this.file["smartTags"].length + "dp_le_", // to differeniate client ids from server
                    controlIdAlias: this.file["smartTags"].length + "dp_le_", // to differeniate client ids from server
                    id: this.file["smartTags"].length + "_", // to differeniate client ids from server
                };


                let bgColor = "yellow";
                if (item === "CheckBox")
                    bgColor = 'rgba(255, 255, 0, 0.4)';

                $(`<div>${prefix} ${item}</div>`).attr({
                    id: tagOptions.id,
                    name: 'bar',
                    'data-index': tagOptions.id,
                    style: 'overflow:hidden;font-size: 18px;position:absolute;top:0px; left: 50px; width: ' + tagOptions.controlWidth + '; height: ' + tagOptions.controlHeight + ';background:' + bgColor + ';    cursor: grab;z-index: 1'
                }).appendTo(`div[data-page="${this.currentPage0Based + 1}"][data-pagename="${this.file.noSpaceName}"] > form`);

                //this.setUpMoveEventListeners($("#foo"))
                //return;
                // create tag


                if (item === "OptionGroup") {
                    // allow user to set up option group data
                    $(this.$refs.optionGroupModal).modal("show");
                } else {
                    this.file["smartTags"].push(tagOptions);
                    this.adminCreatedTags.push(tagOptions);
                    this.setUpMoveEventListeners($(`#${tagOptions.id}`), false, item);//$("#" + tagOptions.id));
                    if (item === "CheckBox") {

                        $(`#${tagOptions.id}`).css("width", "40px").css("font-size", "10px").css("overflow", "hidden");
                        return;
                        $(`#${tagOptions.id}`).on("mouseleave", function () {
                            if (item === "CheckBox") {
                                setTimeout(() => {
                                    $(this).css("width", "40px").css("font-size", "10px");
                                }, 1200);
                            }
                        });
                        $(`#${tagOptions.id}`).on("mouseenter", function () {
                            if (item === "CheckBox") {
                                $(this).css("width", "300px").css("font-size", "18px");
                            }
                        });
                    }
                }
            },
            setUpMoveEventListeners(tagJquerySelector, existingTags = false, tagType) {
                let self = this;
                let selector = tagJquerySelector;



                // similar to jquery document onready -
                selector.draggable({
                    containment: "parent",
                    stop: function (event) {
                        var tagId = $(this).attr("data-index");
                        var index = self.file["smartTags"].findIndex((x) => tagId == x.id);
                        console.log(index, tagId, self.file["smartTags"]);
                        self.file["smartTags"][index]["controlTop"] = $(this).css("top");
                        self.file["smartTags"][index]["controlLeft"] = $(this).css("left");
                        $(this).trigger("mouseenter");
                    },
                });

                var miW = tagType == 'CheckBox' ? 20 : 300;
                var miH = tagType == 'CheckBox' ? 20 : 40;

                var maxH = tagType == 'FreeForm' ? 40 : undefined;

                selector.resizable({
                    minWidth: miW,
                    minHeight: miH,
                    maxHeight: maxH,
                    resize: function (event, ui) {
                        var size = ui.size;
                        var tagId = $(this).attr("data-index");
                        var index = self.file["smartTags"].findIndex((x) => tagId == x.id);
                        self.file["smartTags"][index]["controlHeight"] = size.height + "px";
                        self.file["smartTags"][index]["controlWidth"] = size.width + "px";

                        if (size.width > size.height) {
                            self.file["smartTags"][index]["fontSize"] = size.width / 8 + "px";
                        } else {
                            self.file["smartTags"][index]["fontSize"] = size.height / 5 + "px";
                        }

                        $(this).trigger("mouseenter");
                    },
                });



                selector.on("mouseenter", function () {
                    console.log("mouse entered");
                    let obj = {};
                    let height = $(this).height();
                    obj.width = $(this).width();
                    obj.obj = this;
                    obj.top = parseInt($(this).css("top").replace("px", "")) + height;
                    obj.left = $(this).css("left").replace("px", "");
                    if (self.teleportLocation === "body")
                        self.teleportLocation = "#pdfContainer form";

                    self.currentHighlightedTag = obj;
                    console.log("self.currentHighlightedTag", obj);
                    console.log(self.participants)

                    let smartTagId = $(this).attr("data-index");
                    var index = self.file["smartTags"].findIndex((x) => smartTagId == x.id);
                    self.currentHighlightedTag.smartTag = self.file["smartTags"][index];

                    let participant = self.file["smartTags"][index]["participant"] || null;
                    if (!participant) {
                        if (self.file["smartTags"][index]["signerId"]) {
                            participant = self.participants.find((item) => item.id === self.file["smartTags"][index]["signerId"]);
                            participant = JSON.parse(JSON.stringify(participant));// needs a raw object not a proxy
                            self.file["smartTags"][index]["participant"] = participant;
                        }
                    }
                    self.selectedParticipant = participant;

                });


                if (existingTags) {
                    let itemId = selector.find("div[data-index]").attr("data-index");
                    selector.attr("data-index", itemId);
                }
            },
            async applyChanges() {
                // check and ensure a participant is assigned to each tag
                for (var i = 0; i < this.file["smartTags"].length; i++) {
                    let tag = this.file["smartTags"][i];
                    if (!tag["signerId"]) {
                        Swal.fire({
                            title: "Participant Missing",
                            text: `${tag.tagType} Tag is missing participant on Page ${tag.pageNo}`,
                            icon: "error",
                            confirmButtonText: "Ok",
                        });
                        return;
                    }

                    if (!tag["controlHeight"] || !tag["controlWidth"] || !tag["controlLeft"] || !tag["controlTop"]) {
                        Swal.fire({
                            title: "All Tags Not Confirmed.",
                            text: `You have not confirmed the locations of tags on page ${tag.pageNo}. Do so by visiting the page.`,
                            icon: "error",
                            confirmButtonText: "Ok",
                        });
                        return;
                    }

                }

                this.$store.commit("App/updateLoaderOverride", {
                    showLoaderOverride: true,
                });
                //let pos = this.file.renderType.lastIndexOf(".");
                //var ext = pos < 0 ? '' : this.file.renderType.substr(pos - this.file.renderType.length);
                //const blankDoc = this.file.renderType.substr(0, pos < 0 ? this.file.renderType.length : pos) + "_Blank" + ext;
                const url = ApiService.EnvironmentURL() + "document/load-doc?document=" + this.file.documentID + "&type=" + (this.file.renderType ? this.file.renderType : "blank");//'https://pdf-lib.js.org/assets/with_update_sections.pdf'
                const existingPdfBytes = await fetch(url, {
                    headers: {
                        'Authorization': `Bearer ${this.$store.getters["App/token"]}`,
                    }
                }).then(res => res.arrayBuffer());
                const pdfDoc = await PDFDocument.load(existingPdfBytes);

                // must be calld to normalize/'unduplicate' dynamically generated names.
                this.updateDuplicateControlIdNames();
                for (var i = 0; i < this.file["smartTags"].length; i++) {
                    let tag = this.file["smartTags"][i];
                    let pageNo = tag["pageNo"];


                    let value = tag.controlId;
                    let fieldName = value, // <- do some dynamic name here
                        width = parseInt((tag["controlWidth"] + "").replaceAll("px", "")) / this.pageScale,
                        height = parseInt((tag["controlHeight"] + "").replaceAll("px", "")) / this.pageScale,

                        // we divide because these coordinates were scaled * 2
                        left = parseInt((tag["controlLeft"] + "").replaceAll("px", "")) / this.pageScale,
                        top = parseInt((tag["controlTop"] + "").replaceAll("px", "")) / this.pageScale;

                    this.addTagToPage(pdfDoc, pageNo - 1, fieldName, width, height, left, top, tag.tagType);
                }


                await this.saveChanges();

                const pdfBytes = await pdfDoc.saveAsBase64();

                let results = await ApiService.GeneratePostRequest(
                    "package/document/save_pdf",
                    {
                        "documentId": this.file.documentID,
                        "base64String": pdfBytes
                    }
                );

                // retrieve new saved tags
                await this.reloadPDFAndTags();
                this.$store.commit("App/updateLoaderOverride", {
                    showLoaderOverride: false,
                });
                if (results["isSuccess"]) {
                    Swal.fire({
                        title: "Document Saved!",
                        text: "",
                        icon: "success",
                        confirmButtonText: "Ok",
                    });
                    return;
                }
                Swal.fire({
                    title: "Document Error!",
                    text: "There was a problem saving the document.",
                    icon: "error",
                    confirmButtonText: "Ok",
                });
            },
            async saveChanges() {
                let data = this.file.smartTags;
                data = data.map((item) => {
                    let id = item.id;
                    let controlId = item.controlId;
                    if (typeof item.id == "string") {
                        id = 0;
                    }
                    return { ...item, isOptional: item.tagType == 'CheckBox' || item.isOptional, id: id, controlId: controlId, displayText: "" }
                });
                console.log("this.file.smartTags Save", data);

                var user = this.$store.state.Admin.loginInformation;
                let results = await ApiService.GeneratePostRequest(
                    `document/tags/save?adminUserId=${user.userId}`,
                    data
                );

                this.$store.commit("App/updateLoaderOverride", {
                    showLoaderOverride: false,
                });

                console.log("SmartTags Save", results);
                // only showing error here if we show saved successfully for document and tags it would redundant.
                if (!results["isSuccess"]) {
                    Swal.fire({
                        title: "Error Saving Tags!",
                        text: "There was an error saving the tags",
                        icon: "error",
                        confirmButtonText: "Ok",
                    });
                    return;
                }
            },
            addTagToPage(pdfDoc, pageNo, fieldName, width, height, left, top, tagType) {
                const page = pdfDoc.getPage(pageNo);
                const form = pdfDoc.getForm();
                let bgColor = tagType === "Whiteout" ? rgb(1, 1, 1) : undefined;// undefined is transparent.
                const textField = form.createTextField(fieldName);
                // IMPORTANT. This library considers the bottom of left of the file 0 0 x and y respectively. our units start from top left.

                let roationAngle = page.getRotation().angle;

                // if angle is 0 - default oritentation
                let x = left;
                let y = page.getHeight() - (top + height);

                if (roationAngle === 90) {
                    // at this rotation - origin is top left & think of the PDF as in landscape mode. and x is now Y and Y is now X
                    x = top + height;
                    y = left;
                }

                if (roationAngle === 180) {
                    x = page.getWidth() - left;
                    y = top + height;
                }

                if (roationAngle === 270) {
                    // at this rotation - origin is bottom right & think of the PDF as in landscape mode. and x is now Y and Y is now X
                    x = page.getWidth() - (top + height);
                    y = page.getHeight() - left;
                }




                // hence the maths
                textField.addToPage(page,
                    {
                            /*y*/x: x,
                            /*x*/y: y,// page.getWidth() - (top + width),
                        width: width,
                        height: height,
                        backgroundColor: bgColor,
                        borderColor: bgColor,
                        rotate: degrees(roationAngle)
                    });
            },
            updateDuplicateControlIdNames() {
                let counter = 0;
                let startIdx = (new Date()).getTime(); //get the current millisecond value
                for (var i = 0; i < this.file["smartTags"].length; i++) {
                    let tag = this.file["smartTags"][i];
                    let isClientSideTag = tag.controlId.indexOf("dp_le_") >= 0;
                    if (isClientSideTag) {
                        let template = "field_";
                        let value = template + (startIdx + (counter++)); // field_0
                        for (var j = 0; j < this.file["smartTags"].length; j++) {
                            if (value == this.file["smartTags"][j].controlId) {
                                value = template + (startIdx + (counter++));
                                j = 0;
                            }
                        }
                        this.file["smartTags"][i].controlId = value;
                    }
                }
            },
            changeParticipant(value) {
                let smartTagId = $(this.currentHighlightedTag.obj).attr("data-index");
                var index = this.file["smartTags"].findIndex((x) => smartTagId == x.id);
                this.file["smartTags"][index]["signerId"] = value.value.id;
                this.file["smartTags"][index]["participant"] = value.value;

                this.file["smartTags"][index]["displayText"] = this.file["smartTags"][index]["tagType"] + " - " + value.value.value;
                $(this.currentHighlightedTag.obj).text(this.file["smartTags"][index]["displayText"]);
            },
            async deleteTag() {
                if (confirm("Are you sure you want to remove this tag?")) {
                    let tagIDToRemove = $(this.currentHighlightedTag.obj).attr("data-index");
                    console.log("Tag to remove", tagIDToRemove);

                    await this.deleteSmartTag(tagIDToRemove)

                    this.teleportLocation = "body";
                    this.currentHighlightedTag = { width: 0 };
                    $("form > div[data-index='" + tagIDToRemove + "']").remove();
                }
            },
            async deleteSmartTag(tagIDToRemove, i) {
                const found = this.adminCreatedTags.find((item) => item.id == tagIDToRemove);
                this.adminCreatedTags = this.adminCreatedTags.filter(
                    (item) => item.id != tagIDToRemove
                );
                this.file["smartTags"] = this.file["smartTags"].filter(
                    (item) => item.id != tagIDToRemove
                );
                if (!found) {
                    // tag was not created on client side
                    let results = await ApiService.GeneratePostRequest(
                        "document/tags/delete",
                        [tagIDToRemove]
                    );
                    await this.reloadPDFAndTags();
                    console.log("deleye results", results);
                    if (results["isSuccess"])
                        Swal.fire({
                            title: "Tag deleted successfully",
                            text: "",
                            icon: "success",
                            confirmButtonText: "Ok",
                        });
                    else
                        Swal.fire({
                            title: "Unable to delete tag",
                            text: "",
                            icon: "error",
                            confirmButtonText: "Ok",
                        });
                }
            },

            showTagSettings() {
                $(this.$refs.settingsModal).modal("show");
                let tagType = this.currentHighlightedTag.smartTag.tagType;
                if (tagType === "Date" || tagType === "DateOfBirth") {
                    console.log(this.currentHighlightedTag.smartTag);
                    this.dateFormatField = JSON.parse(this.currentHighlightedTag.smartTag.params)["format"];
                }
                this.isOptional = this.currentHighlightedTag.smartTag.isOptional;
                console.log("Current Highlighted Tag", this.currentHighlightedTag);
            },
            saveTagSettings() {
                let tagType = this.currentHighlightedTag.smartTag.tagType;
                var index = this.file["smartTags"].findIndex((x) => this.currentHighlightedTag.smartTag.id == x.id);
                if (tagType === "Date" || tagType === "DateOfBirth") {
                    this.file["smartTags"][index]["params"] = JSON.stringify({ format: this.dateFormatField });
                    console.log("saved setting", this.file["smartTags"][index]);
                }
                this.file["smartTags"][index]["isOptional"] = this.isOptional;
                $(this.$refs.settingsModal).modal("hide");
            },

            createOptionGroupItems() {
                if (
                    this.optionGroupItems.length >= 2 ||
                    this.groupName.trim().length === 0
                ) {
                    let groupItems = [];
                    let groupId = generateUUID();
                    let counter = this.file["smartTags"].length;
                    for (var i = 0; i < this.optionGroupItems.length; i++) {
                        let groupValue = this.optionGroupItems[i];
                        if (groupValue.trim().length === 0) {
                            Swal.fire({
                                title: "Please fill out all fields",
                                text: "",
                                icon: "error",
                                confirmButtonText: "Ok",
                            });
                            return;
                        } else {
                            let tagOptions = {
                                id: ++counter,
                                pageNo: this.activePageIndex + 1,
                                signerId: this.file.signerId,
                                documentId: this.file.documentID,
                                displayText: groupValue,
                                tagType: "OptionGroupValue",
                                controlTop: "10px",
                                controlLeft: "10px",
                                controlHeight: "30px",
                                controlWidth: "210px",
                                notarizeId: this.file.notarizeId,
                                params: `{'optionGroupId': ${groupId}}`,
                                tagTypeId: this.systemTags.find((el) => {
                                    return el.type === "OptionGroupValue";
                                })["id"],
                            };
                            groupItems.push(tagOptions);
                        }
                    }

                    let group = {
                        pageNo: 5,
                        signerId: this.file.signerId,
                        documentId: this.file.documentID,
                        displayText: this.groupName,
                        tagType: "OptionGroup",
                        controlTop: "890.3px",
                        controlLeft: "159.175px",
                        controlHeight: "26px",
                        controlWidth: "151px",
                        notarizeId: this.file.notarizeId,
                        params: `{ optionGroupId: ${groupId} }`,
                        tagTypeId: this.systemTags.find((el) => {
                            return el.type === "OptionGroup";
                        })["id"],
                    };

                    this.adminCreatedTags.push(group);
                    this.adminCreatedTags.concat(groupItems);

                    groupItems.forEach((tagOptions) => {
                        this.file["smartTags"].push(tagOptions); // concat don't work on vue arrays
                    });
                    console.log("smartTags group item", this.file["smartTags"]);
                    this.$nextTick(() => {
                        groupItems.forEach((tagOptions) => {
                            console.log("item", tagOptions);
                            this.setUpMoveEventListeners($("#" + tagOptions.id));
                        });
                    });
                    $(this.$refs.optionGroupModal).modal("hide");
                } else {
                    Swal.fire({
                        title: "Please fill out all fields!",
                        text: "",
                        icon: "error",
                        confirmButtonText: "Ok",
                    });
                }
            },
            addOptionGroupItem() {
                this.optionGroupItems.push("");
            },
            removeGroupItem(i) {
                this.optionGroupItems.splice(i, 1);
            },
            optionGroupItemValueChanged(event, index) {
                console.log(event.target.value);
                this.optionGroupItems[index] = event.target.value;
            },
            previousButtonClicked() {
                let nextPage = this.currentPage0Based - 1;
                if (nextPage >= 0)
                    this.renderPage(nextPage)
            },
            nextButtonClicked() {
                let nextPage = this.currentPage0Based + 1;
                if (nextPage <= (this.file["noOfPages"] - 1))
                    this.renderPage(nextPage);
            },

            getTagsPerPage(tags) {
                // this.$nextTick(() => {
                //   this.setUpMoveEventListeners();
                // });
                console.log(
                    "Fetching tags",
                    tags.filter((t) => t.pageNo == this.activePageIndex + 1)
                );
                return tags.filter((t) => t.pageNo == this.activePageIndex + 1);
            },
            changeSelectedPage(index) {
                this.activePageIndex = index;
                this.$nextTick(() => {
                    this.setUpMoveEventListeners();
                });
            },
        },
    };
</script>

<style scoped>
    /* .smart-tag.ui-draggable.ui-draggable-handle.ui-resizable .ui-resizable {
      position: relative !important;
    } */
    cardbody {
        background: #DDD;
    }

    .card {
        overflow-y: auto;
        padding-bottom: 100px;
    }

        .card div[data-page][data-name] {
            margin-bottom: 10px;
        }

    #documentViewer {
        float: left;
        /*transform: translateX(-50%);*/
        /*  position: relative;*/
        /*left: 50%;*/
    }

    .smart-tag {
        float: left;
        cursor: move;
        border: 1px dotted rgb(168, 168, 168);
        background: #f7eb05;
    }

    .tagText {
        font-family: Helvetica !important;
    }

    ul#nav-taggedpages a.tablinks {
        height: 30px;
        padding: 5px;
    }

    #img-viewer {
        border: 1px solid black;
        margin-top: 1px;
    }

    #myPublisherDiv,
    #mySubscriberDiv {
        width: 175px !important;
        height: 140px !important;
    }

    div#document-viewer div {
        cursor: pointer;
    }

    .thumbnail-container {
        height: 100vh;
        overflow: auto;
    }

    .thumbnail-scroll img {
        border: 1px solid black;
        margin: 1px;
        margin-right: 0px;
    }

    .thumbnail-scroll {
        height: 100%;
        padding-bottom: 20px;
        overflow-y: auto;
        width: 160px;
    }

    nav#context-menu {
        position: absolute;
        border: 1px solid #000;
        border-radius: 3px;
        padding: 10px;
        z-index: 1001;
        background: transparent;
        box-shadow: none;
    }

    .context-menu {
        display: none;
        position: absolute;
        z-index: 10000;
        padding: 12px 0;
        width: 240px;
        background-color: #fff;
        border: solid 1px #dfdfdf;
        box-shadow: 1px 1px 2px #cfcfcf;
    }

    nav#context-menu span {
        cursor: pointer;
    }

    .highlight-xml-signature {
        border: 3px dotted rgba(0, 0, 0, 0.048);
        background: yellow;
    }

    .smart-tag > i {
        text-align: center;
        font: italic normal 90% Georgia, serif;
        line-height: 150%;
        color: black;
        text-shadow: white 0 1px;
        background: #ddd;
        background-clip: padding-box;
        box-shadow: 0 0px 2px rgba(0, 0, 0, 0.5);
        border: 2px solid rgba(0, 0, 0, 0.5);
        border-radius: 3px;
        position: absolute;
        width: 250px;
        left: 50%;
        margin-left: -125px;
        padding: 10px 0;
        bottom: 100%;
        margin-bottom: 15px;
        visibility: hidden;
        opacity: 0;
        -webkit-transition: opacity 0.5s linear;
        -moz-transition: opacity 0.5s linear;
        -ms-transition: opacity 0.5s linear;
        -o-transition: opacity 0.5s linear;
        transition: opacity 0.5s linear;
    }

        .smart-tag > i:before,
        .smart-tag > i:after {
            content: "";
            position: absolute;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            top: 100%;
            left: 50%;
            margin-left: -10px;
        }

        .smart-tag > i:before {
            border-top: 10px solid #111;
            border-top: 10px solid rgba(0, 0, 0, 0.5);
            margin-top: 5px;
        }

        .smart-tag > i:after {
            border-top: 10px solid #ddd;
            margin-top: -2px;
        }

    .smart-tag:hover > i {
        visibility: visible;
        opacity: 1;
        z-index: 1;
    }

    .custom-style-tabs a {
        color: #000;
        font-size: 1.8em;
        font-weight: bold;
        height: auto !important;
    }

    .navigation-arrow {
        color: #fff;
        background: darkblue;
        border: none;
    }

        .navigation-arrow:hover {
            background: #0064fe;
        }




    #participantSelector {
        background: rgba(255, 255, 255, .8);
    }

        #participantSelector:hover {
            background: #FFF;
        }
</style>
