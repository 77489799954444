<template>
    <div>
        <div class="sd-header">
            <img src="@/assets/stepsv2.png" width="346" height="55" alt="steps" />
        </div>
        <div style="position: absolute; z-index: 0; text-align: center; width: 100%; top: 50%; transform: translateY(-50%); -ms-transform: translateY(-50%);">
            <div>
                <i class="fa fa-3x fa-spinner fa-spin"></i>
                <div style="font-size: 20px; padding: 2px;">
                    Your signing experience is loading.
                </div>
            </div>
        </div>
        <div style="position: relative; z-index: 1;">
            <iframe id="iframe"
                    style="width: 100%; height: 100vh; border: none;"
                    :src="invitationLink"></iframe>
        </div>

        <!-- Custom Modal for Complete -->
        <div v-if="showModalComplete" class="modal-overlay">
            <div class="modal-content">
                <h3>Last Step!</h3>
                <p>You have successfully e-signed the promissory note.</p>
                <p>We have stored the note in a secure eVault and notified your lender and settlement company that it has been signed.</p>
                <p><strong>As a last step, you will eSign your remaining documents now.</strong></p>
                <p>Please wait a moment, it'll just take a moment to load the rest of your documents. You'll be automatically redirected.</p>
                <button @click="hideModal">Close</button>
            </div>
        </div>

        <!-- Custom Modal for Decline -->
        <div v-if="showModalDecline" class="modal-overlay">
            <div class="modal-content">
                <h3>One Moment</h3>
                <p>You have successfully declined e-signing the promissory note.</p>
                <p>Please wait a moment, it'll just take a moment to take you back to your closing dashboard.</p>
                <button @click="hideModal">Close</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SnapDocs',
        data() {
            return {
                invitationLink: '',
                participantId: '',
                showModalComplete: false,
                showModalDecline: false,
            };
        },
        mounted() {
            this.invitationLink = this.$route.query.invitationLink || '';
            this.participantId = this.$route.query.participantId || '';
            this.postBackUrl = this.$route.query.postBackUrl || this.$route.query.postbackURL || this.$route.query.postbackUrl || "NA";

            // window object 1
            window.CustomSigningRedirect = this.CustomSigningRedirect;
        },
        methods: {
            hideHeaderInIframe() {
                let attempts = 0;
                const interval = setInterval(() => {
                    if (attempts >= 20) {
                        clearInterval(interval);
                    }
                    attempts++;
                    const iframe = document.getElementById('iframe');
                    if (iframe && iframe.contentDocument) {
                        const head = iframe.contentDocument.head;
                        if (head) {
                            head.insertAdjacentHTML('beforeend', '<style> header{display: none;}</style>');
                        }
                    }
                }, 500);
            },
            CustomSigningRedirect() {
                const postBackUrl = `${this.postBackUrl}?event=ParticipantSigningComplete&participant=${this.participantId}`;
                this.showModalComplete = true;
                setTimeout(() => {
                    window.location = postBackUrl;
                }, 11000);
            },
            CustomSigningDecline() {
                const postBackUrl = `${this.postBackUrl}?event=EsignConsentDeclined&participant=${this.participantId}`;

                this.showModalDecline = true;
                setTimeout(() => {
                    window.location = postBackUrl;
                }, 11000);
            },
            hideModal() {
                this.showModalComplete = false;
                this.showModalDecline = false;
            }
        },
    };
</script>

<style scoped>
    html {
        box-sizing: border-box;
        font-family: sans-serif;
        color: #212529;
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
    }

    *, *:before, *:after {
        box-sizing: inherit;
    }

    body {
        margin: 0;
        padding: 0;
    }

    .sd-header {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 62px;
        box-shadow: 0 2px 3px 0 rgba(100, 100, 100, 0.17);
        border: solid 1px #dddddd;
        background-color: #fff;
    }

        .sd-header img {
            max-width: 300px;
            height: auto;
        }

    @media only screen and (min-width: 641px) {
        .sd-header {
            height: 78px;
        }

            .sd-header img {
                max-width: 100%;
            }
    }

    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 999;
    }

    .modal-content {
        background: #fff;
        padding: 20px;
        border-radius: 8px;
        max-width: 500px;
        width: 90%;
        text-align: center;
    }

    button {
        background-color: #007bff;
        color: #fff;
        padding: 10px 20px;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        font-size: 16px;
    }

        button:hover {
            background-color: #0056b3;
        }
</style>
