<template>
  <k-button
    title="Download Selected"
    :icon="'download'"
    @click="downloadSelected"
    >Download Selected
    <span class="select-counter">({{ this.selectedTotal }})</span>
  </k-button>
  <Grid
    ref="grid"
    :data-items="docs"
    :columns="columns"
    :total="total"
    :take="take"
    :skip="skip"
    :pageable="true"
    @pagechange="pageChangeHandler"
    :selected-field="selectedField"
    @selectionchange="onSelectionChange"
    @headerselectionchange="onHeaderSelectionChange"
    @rowclick="onRowClick"
  >
    <template v-slot:cellActions="{ props }">
      <td>
        <command-cell
          :data-item="props.dataItem"
          @download="download"
          @view="view"
          @print="print"
        />
      </td>
    </template>
    <grid-norecords>
      There is no data available
    </grid-norecords>
  </Grid>
</template>
<script>
import { Grid, GridToolbar, GridNoRecords } from "@progress/kendo-vue-grid";
import ApiService from "../../core/services/api-service";
import GridDataHelper from "../../core/services/grid-data-helper";
import moment from "moment";
import CommandCell from "./CommandCell";
import { loadFileUrl } from "@/core/services/api-service";
import { PanelBar } from "@progress/kendo-layout-vue-wrapper";
import { Button } from "@progress/kendo-vue-buttons";
import { Chip } from "@progress/kendo-vue-buttons";
import { downloadFileUrl } from "@/core/services/api-service";

export default {
  name: "CompletedSignerDocuments",
  props: [],
  components: {
    Grid: Grid,
    "grid-toolbar": GridToolbar,
    "grid-norecords": GridNoRecords,
    "command-cell": CommandCell,
    "kendo-panelbar": PanelBar,
    "k-button": Button,
  },
  computed: {
    docs: {
      get: function() {
        return this.documents.slice(this.skip, this.take + this.skip);
      },
    },
    signedDocs: {
      get: function() {
        return this.documents
          .filter((x) => x.documentType == "Review_And_Sign")
          .slice(this.skip, this.take + this.skip);
      },
    },
    total() {
      return this.documents ? this.documents.length : 0;
    },
    moment: () => moment,
    areAllSelected() {
      return this.documents.findIndex((item) => item.selected === false) === -1;
    },
    selectedTotal() {
      return this.documents.filter((x) => x.selected == true).length;
    },
    columns() {
      return [
        {
          field: "selected",
          width: "70px",
          headerSelectionValue: this.areAllSelected,
        },
        ...this.staticColumns,
      ];
    },
  },
  data: function() {
    return {
      skip: 0,
      take: 10,
      staticColumns: [
        {
          field: "documentId",
          editable: false,
          hidden: true,
        },
        { field: "description", title: "Name" },
        { field: "isEnote", title: "Document Type", cell: this.formatDocType },
        {
          field: "participantStatus",
          title: "Participant Status",
          cell: this.statusFunction,
        },
        { field: "documentType", title: "Signing Type", cell: this.typeFormat },
        {
          field: "lastModified",
          title: "Last Modified",
          cell: this.dateFormat,
        },
        { cell: "cellActions" },
      ],
      documents: [],
      host: loadFileUrl,
      selectedField: "selected",
      downloadUrl: downloadFileUrl
    };
  },
  async created() {
    let response = await ApiService.GenerateGetRequest("document/signer/completed");
    if (response && response["isSuccess"]) {
      console.log("response docs", response["content"]);
      this.documents = response["content"].map((item) => {
        return { ...item, selected: false };
      });
    }
  },
  methods: {
    pageChangeHandler: function(event) {
      this.skip = event.page.skip;
      this.take = event.page.take;
    },
    statusFunction: function(h, tdElement, props, listeners) {
      return h(
        "td",
        {
          on: {
            click: function(e) {
              listeners.custom(e);
            },
          },
        },
        [GridDataHelper.normalizeStatus(props.dataItem.participantStatus)]
      );
    },
    typeFormat: function(h, tdElement, props, listeners) {
      return h(
        "td",
        {
          on: {
            click: function(e) {
              listeners.custom(e);
            },
          },
        },
        [GridDataHelper.normalizeStatus(props.dataItem.documentType)]
      );
    },
    dateFormat: function(h, tdElement, props, listeners) {
      return h(
        "td",
        {
          on: {
            click: function(e) {
              listeners.custom(e);
            },
          },
        },
        [props.dataItem.lastModified ? moment(props.dataItem.lastModified).format("MM/DD/YYYY hh:mm A") : " - "]
      );
    },
    formatDocType: function(h, tdElement, props, listeners) {
      return h(
        "td",
        {
          on: {
            click: function(e) {
              listeners.custom(e);
            },
          },
        },
        [props.dataItem.isEnote ? "eNote" : "PDF"]
      );
    },
      download: function (item) {
          fetch(this.downloadUrl + item.documentID + "&type=" + item.renderType + "&i=" + item.documentID, {
              headers: {
                  'Authorization': `Bearer ${this.$store.getters["App/token"]}`,
              }
          })
        .then((res) => {
          return res.blob();
        })
        .then((data) => {
          var a = document.createElement("a");
          a.href = window.URL.createObjectURL(data);
          var docName = item.description;
          if (docName.lastIndexOf('.xml') > -1)
            docName = docName.substr(0, docName.lastIndexOf('.xml'));
          a.download = docName;
          a.click();
        });
    },
    print(item) {
        fetch(this.host + item.documentID + "&type=" + item.renderType, {
            headers: {
                'Authorization': `Bearer ${this.$store.getters["App/token"]}`,
            }
        })
        .then((res) => {
          return res.blob();
        })
        .then((data) => {
          var a = document.createElement("a");
          var url = window.URL.createObjectURL(data);
          var iframe = document.createElement("iframe");
          // Hide the IFrame.
          iframe.style.visibility = "hidden";
          // Define the source.
          iframe.src = url;
          // Add the IFrame to the web page.
          document.body.appendChild(iframe);
          iframe.contentWindow.focus();
          iframe.contentWindow.print();
        });
    },
    view: function(item) {
        this.$emit("setDocumentId", item.documentID);
    //   this.$router.push("/signer/room/completed/" + item.documentID);
    },
    onHeaderSelectionChange(event) {
      let checked = event.event.target.checked;
      this.documents = this.documents.map((item) => {
        return { ...item, selected: checked };
      });
    },
    onSelectionChange(event) {
      event.dataItem[this.selectedField] = !event.dataItem[this.selectedField];
    },
    onRowClick(event) {
      event.dataItem[this.selectedField] = !event.dataItem[this.selectedField];
    },
      downloadSelected: function () {
      return ApiService.GeneratePostBlobRequest(
        `document/download/selected`,
          this.documents.filter((x) => x.selected == true).map((x) => ({ 'renderType': x.renderType, 'documentId': x.documentID, 'description': x.description }))
      )
        .then((blob) => {
          var a = document.createElement("a");
          a.href = window.URL.createObjectURL(blob);
          a.download = `completed-documents-${moment(Date.now()).format('MM/DD/YYYY')}.zip`;            
          a.click();
        })
        .catch((e) => {
          console.error(e);
        });
    },
  },
};
</script>
<style scoped>
    .select-counter {
      color: #3f51b5;
      font-weight: 900;
    }
</style>
